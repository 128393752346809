import makerjs from 'makerjs'

function RectangularLink(
  plateWidth,
  plateHeight,
  cornerRadius,
  marginX,
  marginY,
  rows,
  columns,
  rowGap,
  columnGap,
  holeDiameter
) {
  const sheet = new makerjs.models.RoundRectangle(plateWidth, plateHeight, cornerRadius)
  const points = []
  const holeRadius = holeDiameter / 2

  // Accumulative height and width of holes.
  const holesHeight = rowGap * (rows - 1) + holeDiameter * rows

  // Generate points, which will later be used for holes, for first row.
  for (let i = 0; i < rows; i++) {
    const y = i * (holeDiameter + rowGap)

    for (let j = 0; j < columns; j++) {
      points.push([j * (holeDiameter + columnGap), y])
    }
  }

  const holes = new makerjs.models.Holes(holeRadius, points)

  marginX += holeRadius
  marginY = plateHeight - holesHeight - marginY + holeRadius

  // Move the holes marginX from the left of the sheet and margin y from the top of the sheet.
  makerjs.model.move(holes, [marginX, marginY])

  this.models = {
    sheet_group: sheet,
    holes
  }
}

RectangularLink.metaParameters = [
  {
    title: "Plate Width",
    type: "range",
    min: 0,
    max: 47,
    value: 9,
    default: 9,
    tooltip_text: "Overall width of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectangular_link_width2.png",
    group: 'sheet_group',
    group_title: 'Plate'
  },
  {
    title: "Plate Height",
    type: "range",
    min: 0,
    max: 47,
    value: 6,
    default: 6,
    tooltip_text: "Overall height of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectangular_link_height2.png",
    group: 'sheet_group',
    group_title: 'Plate'
  },
  {
    title: "Corner Radius",
    type: "range",
    min: 0,
    max: 6,
    value: 0.25,
    default: 0.25,
    tooltip_text: "Radius of outside corners",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectangular_link_corner.png",
    group: 'sheet_group',
    group_title: 'Plate'
  },
  {
    title: "Horizontal Margin",
    type: "range",
    min: 0,
    max: 24,
    value: 0.25,
    default: 0.25,
    tooltip_text: "Distance between the left edge of the sheet and the first column of holes",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectangular_link_horizontal_margin.png",
    group: 'sheet_group',
    group_title: 'Plate'
  },
  {
    title: "Vertical Margin",
    type: "range",
    min: 0,
    max: 24,
    value: 0.25,
    default: 0.25,
    tooltip_text: "Distance between the top edge of the sheet and the first row of holes",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectangular_link_vertical_margin.png",
    group: 'holes',
    group_title: 'Holes'
  },
  {
    title: "Rows",
    type: "range",
    min: 0,
    max: 100,
    value: 8,
    default: 8,
    units: 'none',
    step: 1,
    tooltip_text: "The amount of holes from top to bottom",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectangular_link_rows.gif",
    group: 'holes',
    group_title: 'Holes'
  },
  {
    title: "Columns",
    type: "range",
    min: 0,
    max: 100,
    value: 12,
    default: 12,
    units: 'none',
    step: 1,
    tooltip_text: "The amount of holes from left to right",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectangular_link_cols.gif",
    group: 'holes',
    group_title: 'Holes'
  },
  {
    title: "Row Gap",
    type: "range",
    min: 0,
    max: 24,
    value: 0.5,
    default: 0.5,
    tooltip_text: "Vertical spacing between subsequent rows",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectangular_link_row_gap.png",
    group: 'holes',
    group_title: 'Holes'
  },
  {
    title: "Column Gap",
    type: "range",
    min: 0,
    max: 24,
    value: 0.5,
    default: 0.5,
    tooltip_text: "Horizontal spacing between subsequent columns",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectangular_link_col_gap.png",
    group: 'holes',
    group_title: 'Holes'
  },
  {
    title: "Hole Diameter",
    type: "range",
    min: 0,
    max: 24,
    value: 0.25,
    default: 0.25,
    tooltip_text: "Diameter of all holes",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectangular_link_diameter.png",
    group: 'holes',
    group_title: 'Holes'
  },
  { type: "bends", value: [], default: [], hidden: true, group: ['bend0', 'bend1', 'bend2', 'bend3'] }
]

export default RectangularLink
