export default [
  // https://docs.google.com/spreadsheets/d/1FCcurCpqObIeT6850lH68y3AixCNH1M1FZv0VBfyqhE/edit#gid=624466339
  /*
    *major = major diameter
    *drill = pilot or minor diameter
    *threshold = threshold range for pilot sizes
  */

  // Metric

  { id: 1, label: 'M2 x 0.4mm'    , major: 0.157, drill: 0.099, pilot: 0.094, threshold: { low: 0.03, high: 0.03 }, angle: 90 },
  { id: 2, label: 'M2.5 x 0.045mm', major: 0.197, drill: 0.103, pilot: 0.096, threshold: { low: 0.03, high: 0.03 }, angle: 90 },
  { id: 3, label: 'M3 x 0.5mm'    , major: 0.236, drill: 0.130, pilot: 0.125, threshold: { low: 0.03, high: 0.03 }, angle: 90 },
  { id: 4, label: 'M4 x 0.7mm'    , major: 0.315, drill: 0.164, pilot: 0.159, threshold: { low: 0.03, high: 0.03 }, angle: 90 },
  { id: 5, label: 'M5 x 0.8mm'    , major: 0.394, drill: 0.202, pilot: 0.197, threshold: { low: 0.03, high: 0.03 }, angle: 90 },
  { id: 6, label: 'M6 x 1mm'      , major: 0.472, drill: 0.255, pilot: 0.250, threshold: { low: 0.03, high: 0.03 }, angle: 90 },
  { id: 7, label: 'M8 x 1.25mm'   , major: 0.630, drill: 0.320, pilot: 0.315, threshold: { low: 0.03, high: 0.03 }, angle: 90 },

  // Standard

  { id: 8, label: '4-40',     major: 0.255, drill: 0.130, pilot: 0.125, threshold: { low: 0.03, high: 0.03 }, angle: 82 },
  { id: 9, label: '6-32',     major: 0.307, drill: 0.164, pilot: 0.159, threshold: { low: 0.03, high: 0.03 }, angle: 82 },
  { id: 10, label: '8-32',    major: 0.359, drill: 0.193, pilot: 0.188, threshold: { low: 0.03, high: 0.03 }, angle: 82 },
  { id: 11, label: '10-24',   major: 0.411, drill: 0.199, pilot: 0.194, threshold: { low: 0.03, high: 0.03 }, angle: 82 },
  { id: 12, label: '1/4-20',  major: 0.531, drill: 0.255, pilot: 0.250, threshold: { low: 0.03, high: 0.03 }, angle: 82 },
  { id: 13, label: '5/16-18', major: 0.656, drill: 0.318, pilot: 0.313, threshold: { low: 0.03, high: 0.03 }, angle: 82 },
]
