import Vue from 'vue'
import {
  getBends,
  fetchSvg,
  addBendsByColor,
  assignEmptyBendIds
} from 'shared/util/svg'

export default {
  namespaced: true,

  state: {
    loading: true,
    bends: {},
    bendErrors: [],
    errorsValid: false,
    bendLines: {},
    selected: [],
    highlighted: [],
    svg: '',
  },

  getters: {
    bendLines(state) {
      const relatedBendErrors = state.bendErrors.filter(e => e.joinedTo)

      return Object.keys(state.bendLines).map(id => state.bendLines[id]).map(bendLine => {
        const relatedError = relatedBendErrors.find(({ id }) => id === bendLine.id)
        return { ...bendLine, joined_to: relatedError ? relatedError.joinedTo : null }
      })
    },
    getRelatedLineIds: (state, getters) => (ids) => {
      // Return all the lines that are related to the given ids.
      // This includes lines they are joined to and lines that
      // are joined to them.
      const { bendLines } = getters

      const joinedLineIds = bendLines
        .filter(bendLine => ids.includes(bendLine.id))
        .filter(bendLine => bendLine.joined_to)
        .map(bendLine => bendLine.joined_to)

      const joinedToLineIds = getters.bendLines
        .filter(bendLine => ids.includes(bendLine.joined_to))
        .map(bendLine => bendLine.id)

      return [...joinedLineIds, ...joinedToLineIds]
    }
  },

  actions: {
    clearBends({ commit }) {
      commit('setBends', {})
    },

    clearSelected({ commit }) {
      commit('setSelected', [])
    },

    async findBends({ commit }, svgUrl) {
      commit('setLoading', true)

      let svg = await fetchSvg(svgUrl) // Retrieve svg
      // In older SVGs, there may be bends outside of the .bends layer that can
      // be identified by color. We need to keep support for these files
      svg = addBendsByColor(svg)

      // Some versions of SVGs do not have ids on each of the bend elements.
      // For backwards compatibility, we need to come up with ids for any bends
      // with empty ids
      svg = assignEmptyBendIds(svg)

      const bendList = getBends(svg)     // Parse bends list

      commit('setSvg', svg)

      // Map bends into an id addressable hash
      commit('setBendLines', bendList.reduce((bends, bend) => {
        bends[bend.id] = bend
        return bends
      }, {}))

      commit('setLoading', false)
    },

    setBendAngle({ commit, state, getters }, { id, angle }) {
      commit('setBendConfig', { ...state.bends[id], id, angle })

      getters.bendLines.filter(bendLine => bendLine.joined_to === id).forEach(bendLine => {
        commit('setBendConfig', { ...state.bends[bendLine.id], id: bendLine.id, angle, joined_to: bendLine.joined_to })
      })
    },

    setBendDirection({ commit, state, getters }, { id, direction }) {
      commit('setBendConfig', { ...state.bends[id], id, direction })

      getters.bendLines.filter(bendLine => bendLine.joined_to === id).forEach(bendLine => {
        commit('setBendConfig', { ...state.bends[bendLine.id], id: bendLine.id, direction, joined_to: bendLine.joined_to })
      })
    },

    setBends({ commit }, bends) {
      const bendConfig = bends.reduce((all, bend) => ({ ...all, [bend.id]: bend }), {})

      commit('setConfig', bendConfig)
    },

    setHighlighted({ commit, getters }, ids) {
      const relatedLineIds = getters.getRelatedLineIds(ids)
      commit('setHighlighted', [...ids, ...relatedLineIds])
    },

    setSelected({ commit, getters }, ids) {
      const relatedLineIds = getters.getRelatedLineIds(ids)
      commit('setSelected', [...ids, ...relatedLineIds])
    },
    setBendErrors({ commit }, errors) {
      commit('setBendErrors', errors)
    }
  },

  mutations: {
    setBendConfig(state, bend) {
      bend.direction = bend.direction || 'up'
      bend.angle = bend.angle || 0
      bend.joined_to = bend.joined_to || null
      Vue.set(state.bends, bend.id, bend)
    },

    setBends(state, bends) {
      state.bends = bends
    },

    setBendErrors(state, errors) {
      state.bendErrors = errors
    },

    setErrorsValid(state, valid) {
      state.errorsValid = valid
    },

    setBendLines(state, bendLines) {
      state.bendLines = bendLines
    },

    setHighlighted(state, ids) {
      state.highlighted = ids
    },

    setLoading(state, on) {
      state.loading = on
    },

    setConfig(state, bends) {
      state.bends = bends
    },

    setSelected(state, ids) {
      state.selected = ids
    },

    setSvg(state, svg) {
      state.svg = svg
    },
  }
}
