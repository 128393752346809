import makerjs from 'makerjs'

function Mountain(height) {
  const paths = [
    "M12.0261,8.1673 A0.0806013,0.0806013 0 0 1 11.8864,8.1673 L10.8983,6.4561 L8.0748,11.3464 A0.0783588,0.0783588 0 0 1 7.9391,11.3464 L5.1155,6.4561 L4.1267,8.1684 A0.079929,0.079929 0 0 1 3.9883,8.1685 L2.069,4.844 A6.27516,6.27516 0 0 0 1.7696,5.9322 A6.39157,6.39157 0 0 0 1.6681,7.0667 A6.26729,6.26729 0 0 0 2.1562,9.4915 A6.39898,6.39898 0 0 0 3.5546,11.5629 A6.40036,6.40036 0 0 0 5.6392,12.9405 A6.27055,6.27055 0 0 0 8.0683,13.4052 A6.33645,6.33645 0 0 0 12.5105,11.5278 A6.3398,6.3398 0 0 0 14.3457,7.0667 A6.39244,6.39244 0 0 0 14.2442,5.9323 A6.27731,6.27731 0 0 0 13.9449,4.844 Z",
    "M1.0573,7.0667 A6.97506,6.97506 0 0 1 1.2858,5.2959 A6.8993,6.8993 0 0 1 1.9537,3.6506 A0.0757094,0.0757094 0 0 1 2.085,3.6503 L4.0575,7.0667 L4.7628,5.8452 L2.8252,2.4894 A0.0808783,0.0808783 0 0 1 2.8148,2.4405 A0.0797253,0.0797253 0 0 1 2.835,2.3952 A6.98371,6.98371 0 0 1 2.9974,2.2214 A6.99939,6.99939 0 0 1 3.1659,2.0531 A0.0785821,0.0785821 0 0 1 3.2887,2.0702 L8.0069,10.2423 L12.7252,2.0698 A0.0784284,0.0784284 0 0 1 12.8476,2.0527 A6.96471,6.96471 0 0 1 13.0151,2.2201 A7.12266,7.12266 0 0 1 13.1787,2.395 A0.0801567,0.0801567 0 0 1 13.199,2.4405 A0.0814213,0.0814213 0 0 1 13.1885,2.4896 L11.251,5.8452 L11.9563,7.0667 L13.9261,3.6553 A0.0786464,0.0786464 0 0 1 14.0628,3.6557 A6.90028,6.90028 0 0 1 14.7289,5.2988 A6.9738,6.9738 0 0 1 14.9568,7.0667 A6.94977,6.94977 0 0 1 1.0573,7.0667"
  ]

  const mountainModels = { }

  paths.forEach((p, i) => {
    mountainModels[i] = makerjs.model.mirror(makerjs.importer.fromSVGPathData(p), true, false)
  })

  class MountainGroup {
    constructor(models) {
      this.models = models
    }
  }

  this.models = {
    mountain_group: new MountainGroup(mountainModels)
  }

  // Apply a scaling factor
  const m = makerjs.measure.modelExtents(this)
  makerjs.model.scale(this, height / m.height)
  makerjs.model.rotate(this, 180)
}

Mountain.metaParameters = [
  { title: "Height", type: "range", min: 3, max: 24, value: 5, default: 5, group: 'mountain_group', group_title: 'Plate' },
]

export default Mountain
