export default {
  '3d_entities': `
    <h3><b>It looks like you have uploaded a 3D part in a 2D file format.</b></h3>
    <br />
    <div style="width: 90%"><img src="https://cdn.sendcutsend.com/images/dfm/3d-entities-dfm.svg" alt="3D Entities" /></div>
    <br />
    <br />
    <article class="message is-danger">
      <div class="message-body" style="color: #98282e">
        <b><a href="https://sendcutsend.com/guidelines/drawing/#3d-entities class="id-default target="_blank">Click here</a>
        to learn how to fix your 2D file.</b>
        <br />
        <br />
        Alternatively, you can also upload your file in <b><a href="https://sendcutsend.com/guidelines/3d-files/" class="is-default" target="_blank"> STEP format.</a></b>
      </div>
    </article>
  `,

  '3d_faces': `
    <h3><b>It looks like you have uploaded a 3D part in a 2D file format.</b></h3>
    <br />
    <div style="width: 90%"><img src="https://cdn.sendcutsend.com/images/dfm/3d-entities-dfm.svg" alt="3D Faces" /></div>
    <br />
    <br />
    <article class="message is-danger">
      <div class="message-body" style="color: #98282e">
        <b><a href="https://sendcutsend.com/guidelines/drawing/#3d-faces class="id-default target="_blank">Click here</a>
        to learn how to fix your 2D file.</b>
        <br />
        <br />
        Alternatively, you can also upload your file in <b><a href="https://sendcutsend.com/guidelines/3d-files/" class="is-default target="_blank"> STEP format.</a></b>
      </div>
    </article>
  `,

  bad_entities: `
    <article class="message is-danger">
      <div class="message-body" style="color: #98282e">
        <b>We found some unexpected entity types in your drawing. <a href="https://sendcutsend.com/guidelines/drawing/#bad-entities" class="is-default" target="_blank">Click here</a>
        to learn how to fix your file.</b>
      </div>
    </article>
  `,

  open_entities: `
    <h3><b>This part has open entities that cannot be manufactured</b></h3>
    <br />
    <div style="display: flex; justify-content: center; align-items: center;">
      <div><p>Open entities, cannot be manufactured.</p></div>
      <div style="width: 90%"><img src="https://cdn.sendcutsend.com/images/dfm/open-entities.svg" alt="Open Entities" /></div>
      <div><p>Closed entities, good to go!</p></div>
    </div>
    <br />
    <br />
    <article class="message is-warning">
      <div class="message-body" style="color: #785f0d">
        <b>You can fix this by closing all open entities and reuploading your part. <a href="https://sendcutsend.com/guidelines/drawing/#open-entities" class="is-default" target="_blank">Learn more</a>.</b>
        <br />
        <br />
        You can have us help resolve the issue, but it may <b>add additional lead time</b> to your order.
      </div>
    </article>
  `,

  no_entities: `
  <article class="message is-danger">
    <div class="message-body" style="color: #98282e">
      <b>This drawing appears to be empty.</b>
      <br />
      <br />
      Check out our <b><a href="https://sendcutsend.com/guidelines/drawing/#no-entities" class="is-default" target="_blank">drawing guidelines</a></b>
      to make sure your file is exporting properly.
    </div>
  </article>
  `,

  not_dxf: `
    <article class="message is-danger">
      <div class="message-body" style="color: #98282e">
        <b>This file does not match our supported formats.</b> We can accept files in the following formats: <b>.DXF, .DWG, .EPS, .AI, .STP, and .STEP.</b>
        <br />
        <br />
        Check out our <b><a href="https://sendcutsend.com/guidelines/drawing/#no-dxf" class="is-default" target="_blank">drawing guidelines</a></b>
        to make sure your file is exporting properly.
      </div>
    </article>
  `,

  invalid_dxf: `
    <article class="message is-danger">
      <div class="message-body" style="color: #98282e">
        <b>This file does not match our supported formats.</b> We can accept files in the following formats: <b>.DXF, .DWG, .EPS, .AI, .STP, and .STEP.</b>
        <br />
        <br />
        Check out our <b><a href="https://sendcutsend.com/guidelines/drawing/#invalid-dxf" class="is-default target="_blank">drawing guidelines</a></b>
        to make sure your file is exporting properly.
      </div>
    </article>
  `,

  invalid_input_file: `
    <article class="message is-danger">
      <div class="message-body" style="color: #98282e">
        <b>This file does not match our supported formats.</b> We can accept files in the following formats: <b>.DXF, .DWG, .EPS, .AI, .STP, and .STEP.</b>
        <br />
        <br />
        Check out our <b><a href="https://sendcutsend.com/guidelines/drawing/#invalid-input" class="is-default target="_blank">drawing guidelines</a></b>
        to make sure your file is exporting properly.
      </div>
    </article>
  `,

  invalid_parts: `
    <h3><b>We found some unexpected entity types in your drawing.</b></h3>
    <br />
    <div style="width: 90%"><img src="https://cdn.sendcutsend.com/images/dfm/unexpected-entities-dfm.svg" alt="Invalid Parts" /></div>
    <br />
    <br />
    <article class="message is-warning" style="color: #785f0d">
      <div class="message-body">
        <b>You can fix this issue by removing any parts nested within other parts, and re-uploading your file.
        <a href="https://sendcutsend.com/guidelines/drawing/#invalid-parts" class="is-default" target="_blank">Learn more</a>.</b>
        <br />
        <br />
        You can also have us help you resolve the issue, but it will add <b>additional lead time</b> to your order.
      </div>
    </article>
  `,

  invalid_file_type: `
    <article class="message is-danger">
      <div class="message-body" style="color: #98282e">
        <b>This file does not match our supported formats.</b> We can accept files in the following formats: <b>.DXF, .DWG, .EPS, .AI, .STP, and .STEP.</b>
        <br />
        <br />
        Check out our <b><a href="https://sendcutsend.com/guidelines/drawing/#unsupported-filetype" class="is-default target="_blank">drawing guidelines</a></b>
        to make sure your file is exporting properly.
      </div>
    </article>
  `,

  compound_shape: `
    <h3><b>This STEP/STP contains multiple bodies and cannot be manufactured.</b></h3>
    <br />
    <div style="width: 90%"><img src="https://cdn.sendcutsend.com/images/dfm/single-body-dfm.png" alt="Compound Shape" /></div>
    <br />
    <br />
    <article class="message is-danger">
      <div class="message-body" style="color: #98282e">
        <b>You can fix this issue by uploading a single body. <a href="https://sendcutsend.com/guidelines/3d-files/#assemblies" class="is-default target="_blank">Learn more.</a></b>
      </div>
    </article>
  `,

  no_objects: `
    <article class="message is-danger">
      <div class="message-body" style="color: #98282e">
        <b>Your STEP/STP file appears to be empty and cannot be manufactured.</b>
        <br />
        <br />
        You can fix this issue by ensuring your STEP/STP is not blank before exporting. <b><a href="https://sendcutsend.com/guidelines/3d-files/#invalid-file" class="is-default target="_blank">Learn more.</a></b>
      </div>
    </article>
  `,

  step_import_failed: `
    <article class="message is-danger">
      <div class="message-body" style="color: #98282e">
        <b>We are unable to process your STEP/STP file.</b>
        <br />
        <br />
        You can fix this issue by exporting your file with <b><a href="https://sendcutsend.com/guidelines/3d-files/#invalid-file class="is-default target="_blank">valid structure</a></b>
        or using a <b><a href="https://sendcutsend.com/faq/what-file-formats-do-you-accept/" class="is-default" target="_blank">different format.</a></b>
      </div>
    </article>
  `,

  step_import_timeout: `
    <article class="message is-danger">
      <div class="message-body" style="color: #98282e">
        <b>We are unable to process your STEP/STP file.</b>
        <br />
        <br />
        You can fix this issue by exporting your file with <b><a href="https://sendcutsend.com/guidelines/3d-files/#invalid-file class="is-default target="_blank">valid structure</a></b>
        or using a <b><a href="https://sendcutsend.com/faq/what-file-formats-do-you-accept/" class="is-default" target="_blank">different format.</a></b>
      </div>
    </article>
  `,

  '3d_solid': `
    <h3><b>This STEP/STP file contains a non-sheet metal part and cannot be manufactured.</b></h3>
    <br />
    <div style="width: 90%"><img src=https://cdn.sendcutsend.com/images/dfm/sheetmetal-body-dfm.png" alt="3D Solid" /></div>
    <br />
    <br />
    <article class="message is-danger">
      <div class="message-body" style="color: #98282e">
        <b>You can fix this issue by converting your design to a sheet metal body in your CAD software. <a href="https://sendcutsend.com/guidelines/3d-files/#sheet-metal" class="is-default target="_blank">Learn more.</a></b>
      </div>
    </article>
  `,

  invalid_sheet_thickness: `
    <h3><b>This STEP/STP file has an inconsistent thickness and cannot be manufactured.</b></h3>
    <br />
    <div style="width: 90%"><img src="https://cdn.sendcutsend.com/images/dfm/uniform-thickness.png" alt="Invalid Sheet Thickness" /></div>
    <br />
    <br />
    <article class="message is-danger">
      <div class="message-body" style="color: #98282e">
        <b>You can fix this issue by ensuring your design is using the same sheet metal thickness as your intended part. <a href="https://sendcutsend.com/guidelines/3d-files/#material-thickness" class="is-default target="_blank">Learn more.</a></b>
      </div>
    </article>
  `,

  malformed_part: `
    <h3><b>This STEP/STP file contains a non-sheet metal part and cannot be manufactured.</b></h3>
    <br />
    <div style="width: 90%"><img src="https://cdn.sendcutsend.com/images/dfm/sheetmetal-body-dfm.png" alt="Malformed Part" /></div>
    <br />
    <br />
    <article class="message is-danger">
      <div class="message-body" style="color: #98282e">
        <b>You can fix this issue by converting your design to a sheet metal body in your CAD software. <a href="https://sendcutsend.com/guidelines/3d-files/#sheet-metal" class="is-default target="_blank">Learn more.</a></b>
      </div>
    </article>
  `,
}
