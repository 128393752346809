async function request(method, path, data = {}) {
  // Default options are marked with *
  const response = await fetch(`${appData.drawing_api_host}${path}`, {
    method: method,
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  })

  return response.json()
}

export default {
  async checkDFM(model, units, material) {
    const data = {
      "model": model,
      "units": units,
      "material": { "min_bridge": Number(material.min_bridge_distance_soft) }
    }

    const resp = await request('POST', `/model/dfm`, data)

    return resp.data
  }
}
