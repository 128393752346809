import Vue from 'vue'
// auto focus when loaded
const focus = {
  inserted: function (el, binding) {
    if (el.tagName !== 'INPUT') {
      el = el.querySelector('input') // if not the input element, drill down and get it (like for beufy inputs)
    }

    Vue.nextTick(() => {
      if (binding.value) {
        el.focus()
      }
      else {
        el.blur()
      }
    })
  },

  componentUpdated: function (el, binding) {
    if (binding.modifiers.lazy) {
      if (Boolean(binding.value) === Boolean(binding.oldValue)) {
        return
      }
    }

    if (el.tagName !== 'INPUT') {
      el = el.querySelector('input')
    }

    Vue.nextTick(() => {
      if (binding.value) {
        el.focus()
      }
      else {
        el.blur()
      }
    })
  },
}

export default {
  directives: {
    focus: focus,
  }
}
