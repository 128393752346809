export default {
  props: {
    zoomable: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      mousedown: false,
      viewBox: { minX: 0, minY: 0, width: 0, height: 0 },
      viewport: { minX: 0, minY: 0, width: 0, height: 0, dx: 0, dy: 0 }
    }
  },

  methods: {
    setViewBox() {
      const $svgContainer = this.$refs.svgContainer
      if ($svgContainer) {
        const $svg = this.$refs.svgContainer.querySelector('svg')
        const [minx, miny, width, height] = $svg.getAttribute('viewBox').split(' ').map(x => parseFloat(x))
        this.viewBox = { minX: minx, minY: miny, width, height }
        this.viewport = { minX: minx, minY: miny, width, height }
      }
    },

    setZoom(event) {
      event.preventDefault()

      const rect = this.$refs.svgContainer.querySelector('svg .base').getBoundingClientRect()
      const direction = event.wheelDeltaY > 0 ? 1 : -1
      const inchesPerPixel = this.viewBox.width / rect.width
      const stepPixels = 15
      const step = inchesPerPixel * stepPixels

      const oldWidth = this.viewport.width
      const oldHeight = this.viewport.height

      // Zoom viewbox
      this.viewport.width = Math.max(Math.min(this.viewBox.width * 1.4, this.viewport.width + step * direction), 0.1)
      this.viewport.height = Math.max(Math.min(this.viewBox.height * 1.4, this.viewport.height + step * direction), 0.1)

      // Keep zoom centered
      this.viewport.minX += (oldWidth - this.viewport.width) / 2
      this.viewport.minY += (oldHeight - this.viewport.height) / 2

      this.updateViewBox(this.viewport)

      // Debounce zoom events
      clearTimeout(this.zoomTimeout)
      this.zoomTimeout = setTimeout(() => {
        // Resize nodes after zoom
        if (typeof this.resizeNodes === 'function') this.resizeNodes()
      }, 750)
    },

    startPan({ pageX, pageY, button, buttons }) {
      // Only start pan if left mouse click is used
      if (button === 0 && buttons === 1) {
        this.mousedown = true
        this.start = { x: pageX, y: pageY }
      }
    },

    stopPan() {
      this.mousedown = false
      // "Save" the the change to the pan back to the viewport.
      this.viewport.minX += this.viewport.dx || 0
      this.viewport.minY += this.viewport.dy || 0
      this.viewport.dx = 0
      this.viewport.dy = 0
    },

    setPan(event) {
      const { pageX, pageY } = event
      if (this.mousedown) {
        const { minX, minY, width, height } = this.viewport
        const rect = this.$refs.svgContainer.querySelector('svg .base').getBoundingClientRect()
        const inchesPerPixelX = this.viewBox.width / rect.width
        const inchesPerPixelY = this.viewBox.height / rect.height

        const reversed = this.showBottom ? -1 : 1

        const pixelChangeX = (this.start.x - pageX) * reversed
        const pixelChangeY = (this.start.y - pageY)

        // Store how for we have panned since startPan happened.
        this.viewport.dx = inchesPerPixelX * pixelChangeX
        this.viewport.dy = inchesPerPixelY * pixelChangeY

        // Update the viewbox with this change.
        this.updateViewBox({
          minX: minX + this.viewport.dx,
          minY: minY + this.viewport.dy,
          width,
          height
        })
      }
    },

    // This function is called externally by the parent component.
    resetViewport() {
      const { minX, minY, height, width } = this.viewBox
      this.viewport = { minX, minY, height, width }
      this.updateViewBox(this.viewport)
    },

    updateViewBox({ minX, minY, width, height }) {
      const $svg = this.$refs.svgContainer.querySelector('svg')
      $svg.setAttribute('viewBox', `${minX} ${minY} ${width} ${height}`)
    },
  }
}
