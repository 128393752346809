import scs from 'shared/scs'

// initial state
const state = {
}

// getters
const getters = {
}

// actions
const actions = {
  async delete(context, data) {
    return scs.deleteAttachment(data)
  },
  upload(context, data) {
    return new Promise((resolve) => {
      scs.attachmentUploadUrl(data.file)
        .then((response) => (
          scs.uploadFileToS3(response.data.url, data.file)
            .then(() => {
              if (data.resourceType && data.resourceId && !data.deferred) {
                scs.createAttachment(response.data.url, data.resourceType, data.resourceId, data.category)
                  .then(() => {
                    resolve(response.data.url)
                  })
              }
              else {
                resolve(response.data.url)
              }
            })
        ))
    })
  },
}

// mutations
const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
