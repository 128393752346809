export default {
  namespaced: true,

  state: {
    filter_list_active: false,

    // Dragging Status Variables
    highlight_folders: false,
    hover_folder: null,
    dragging: null,
    folder_success: null,
    folder_error: null
  },

  getters: {
    currentFolder(state, _, rootState, rootGetters) {
      const resourceType = 'drawing'
      const currentFolderId = rootState.folders.current_folder_ids[resourceType]
      const foldersForType = rootGetters['folders/getFoldersForType'](resourceType)
      return foldersForType[currentFolderId] || {}
    },
    uncategorizedFolder(state, _, __, rootGetters) {
      return rootGetters['folders/uncategorized_folder']
    },
    uploadFolderID(state, getters) {
      const currentFolderId = getters.currentFolder.id
      const uncategorized = getters.uncategorizedFolder.id === currentFolderId
      return uncategorized ? undefined : currentFolderId
    }
  },

  actions: {
    flashFolderError({ commit }, folderID) {
      commit('setFolderError', folderID)
      setTimeout(() => commit('setFolderError', null), 50)
    },

    flashFolderSuccess({ commit }, folderID) {
      commit('setFolderSuccess', folderID)
      setTimeout(() => commit('setFolderSuccess', null), 50)
    },

    highlightFolders({ commit }) {
      commit('setHighlightFolders', true)
      setTimeout(() => commit('setHighlightFolders', false), 50)
    }
  },

  mutations: {
    setDragging(state, drawingID) {
      state.dragging = drawingID
    },

    setFilterListActive(state, active) {
      state.filter_list_active = active
    },

    setFolderError(state, folderID) {
      state.folder_error = folderID
    },

    setFolderSuccess(state, folderID) {
      state.folder_success = folderID
    },

    setHighlightFolders(state, on) {
      state.highlight_folders = on
    },

    setHoverFolder(state, folderID) {
      state.hover_folder = folderID
    }
  }
}
