import makerjs from 'makerjs'
import { GearModel, CenterHole, SpokedWheel } from 'shared/components/PartBuilder/parts/utilities/gears_common'

function SpokedGear(
  pitch,
  numTeeth,
  innerRadius,
  addKeyHole,
  keyHoleLength,
  keyHoleWidth,
  outerRadius,
  ringWidth,
  count,
  spokeWidth,
  flareWidth,
  innerFillet,
  outerFillet,
  units
) {
  this.models = {
    gear_group: makerjs.model.simplify(new GearModel(pitch, numTeeth, units)),
    spokes_group: new SpokedWheel(
      outerRadius,
      innerRadius + ringWidth,
      count,
      spokeWidth,
      flareWidth,
      innerFillet,
      outerFillet
      ),
    hole_group: new CenterHole(innerRadius, addKeyHole, keyHoleLength, keyHoleWidth)
  }
}

SpokedGear.metaParameters = [
  {
    title: "Diametral Pitch",
    type: "select",
    options: {
      "6 (Large Gears)": 6,
      "12 (Medium Gears)": 12,
      "32 (Small Gears)": 32
    },
    value: 32,
    default: 32,
    group: 'gear_group',
    group_title: 'Gear'
  },
  // The teeth start to get wonky when pitch and num teeth are both too low
  {
    title: "Number of Teeth",
    type: "range",
    units: "none",
    step: 1,
    min: 25,
    max: 120,
    value: 110,
    default: 110,
    tooltip_text: "Number of teeth determine the total diameter of the gear. Any gear made from this part will have a 12 in diametrical pitch",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/spur_gear_numTeethParams2.png",
    group: 'gear_group',
    group_title: 'Gear'
  },
  {
    title: "Inner Radius",
    type: "range",
    min: 0,
    max: 2,
    value: 0.45,
    default: 0.45,
    tooltip_text: "Radius of the inner circle",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/spoked_gear_innerRadius3.png",
    group: 'hole_group',
    group_title: 'Inner Hole'
  },

  { title: "Add Key Seat", type: "bool", value: "Yes", default: "Yes", control: "hole_group" },
  {
    title: "Key Seat Height",
    type: "range",
    min: 0.0635,
    max: 0.375,
    value: 0.064,
    default: 0.064,
    tooltip_text: "Distance from the top of the center hole to the top of the key seat",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/spur_gear_keyHeightParams2.png",
    group: 'hole_group',
    group_title: 'Inner Hole'
  },
  {
    title: "Key Seat Width",
    type: "range",
    min: 0.0635,
    max: 0.375,
    value: 0.255,
    default: 0.255,
    tooltip_text: "Width of the key seat",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/spur_gear_keyWidthParams2.png",
    group: 'hole_group',
    group_title: 'Inner Hole'
  },

  {
    title: "Outer Radius",
    type: "range",
    min: 0,
    max: 5,
    value: 1.5,
    default: 1.5,
    tooltip_text: "Outside radius of the wheel",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/spoked_gear_outerRadius2.png",
    group: 'spokes_group',
    group_title: 'Spokes'
  },

  {
    title: "Ring Width",
    type: "range",
    min: 0,
    max: 1,
    value: 0.3,
    default: 0.3,
    tooltip_text: "Radius of the inner circle",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/spoked_gear_ringWidth2.png",
    group: 'spokes_group',
    group_title: 'Spokes'
  },
  {
    title: "Spoke Count",
    type: "range",
    units: "none",
    step: 1,
    min: 0,
    max: 20,
    value: 5,
    default: 5,
    tooltip_text: "Number of spokes in wheel",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/spoke_spokecount.gif",
    group: 'spokes_group',
    group_title: 'Spokes'
  },
  {
    title: "Spoke Width",
    type: "range",
    min: 0,
    max: 2,
    value: 0.6,
    default: 0.6,
    tooltip_text: "Width of the spokes at the base before fillets and flare are added",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/spoked_gear_spokeWidth2.png",
    group: 'spokes_group',
    group_title: 'Spokes'
  },
  {
    title: "Flare Width",
    type: "range",
    min: 0,
    max: 2,
    value: 0.45,
    default: 0.45,
    tooltip_text: "",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/spoked_gear_flareWidth2.png",
    group: 'spokes_group',
    group_title: 'Spokes'
  },
  {
    title: "Inner fillet",
    type: "range",
    min: 0,
    max: 1,
    value: 0.15,
    default: 0.15,
    tooltip_text: "",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/spoked_gear_innerFillet2.png",
    group: 'spokes_group',
    group_title: 'Spokes'
  },
  {
    title: "Outer fillet",
    type: "range",
    min: 0,
    max: 1,
    value: 0.15,
    default: 0.15,
    tooltip_text: "",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/spoked_gear_outerFillet2.png",
    group: 'spokes_group',
    group_title: 'Spokes'
  },

  { type: "units", hidden: true }
]

export default SpokedGear
