import makerjs from 'makerjs'

function RollCageTab(height, topDiameter, bottomRadius, cornerRadius, holeDiameter, holeHeight) {
  const topCircle = new makerjs.paths.Circle([0, height - topDiameter / 2], topDiameter / 2)
  const bottomCircle = new makerjs.paths.Circle([0, 0], bottomRadius)

  const angles = makerjs.solvers.circleTangentAngles(bottomCircle, topCircle)

  const point = makerjs.point.fromAngleOnCircle(angles[0], bottomCircle)
  const point2 = makerjs.point.fromAngleOnCircle(angles[1], bottomCircle)
  const point3 = makerjs.point.fromAngleOnCircle(angles[0], topCircle)
  const point4 = makerjs.point.fromAngleOnCircle(angles[1], topCircle)
  const topOfBottom = makerjs.point.fromAngleOnCircle(90, bottomCircle)

  const leftLine = new makerjs.paths.Line(point, point3)
  const rightLine = new makerjs.paths.Line(point2, point4)

  const startAngle = (topDiameter <= bottomRadius * 2) ? angles[1] : angles[0]
  const endAngle = (topDiameter <= bottomRadius * 2) ? angles[0] : angles[1]

  const topArc = new makerjs.paths.Arc([0, height - topDiameter / 2], topDiameter / 2, startAngle, endAngle)
  const bottomArc = new makerjs.paths.Arc([0, 0], bottomRadius, startAngle, endAngle)

  const hole = new makerjs.paths.Circle([0, topOfBottom[1] + holeHeight], holeDiameter / 2)

  const leftFillet = makerjs.path.fillet(leftLine, bottomArc, cornerRadius)
  const rightFillet = makerjs.path.fillet(rightLine, bottomArc, cornerRadius)

  this.paths = {
    hole
  }

  this.models = {
    tab_group: {
      paths: {
        left_line: leftLine,
        right_line: rightLine,
        top_arc: topArc,
        bottom_arc: bottomArc,
        left_fillet: leftFillet,
        right_fillet: rightFillet
      }
    }
  }
}

RollCageTab.metaParameters = [
  {
    title: "Height",
    type: "range",
    min: 0,
    max: 10,
    value: 4,
    default: 4,
    tooltip_text: "Total height from the center of the bottom radius to the top",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rollcage_height2.png",
    group: 'tab_group',
    group_title: 'Plate'
  },
  {
    title: "Top Diameter",
    type: "range",
    min: 0,
    max: 10,
    value: 2,
    default: 2,
    tooltip_text: "Diameter of the top arc",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rollcage_top.png",
    group: 'tab_group',
    group_title: 'Plate'
  },
  {
    title: "Bottom Radius",
    type: "range",
    min: 0,
    max: 5,
    value: 1.25,
    default: 1.25,
    tooltip_text: "Radius of the bottom arc",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rollcage_bottom2.png",
    group: 'tab_group',
    group_title: 'Plate'
  },
  {
    title: "Corner Radius",
    type: "range",
    min: 0,
    max: 1,
    value: 0.1,
    default: 0.1,
    tooltip_text: "Diameter for bottom fillets",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rollcage_fillet.png",
    group: 'tab_group',
    group_title: 'Plate'
  },
  {
    title: "Hole Diameter",
    type: "range",
    min: 0,
    max: 5,
    value: 1,
    default: 1,
    tooltip_text: "Diameter of the top hole",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rollcage_hole.png",
    group: 'hole',
    group_title: 'Hole'
  },
  {
    title: "Hole Height",
    type: "range",
    min: 0,
    max: 10,
    value: 1.75,
    default: 1.75,
    tooltip_text: "Height from bottom diameter to center of hole",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rollcage_hole_height.png",
    group: 'hole',
    group_title: 'Hole'
  }
]

export default RollCageTab
