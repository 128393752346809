import Decimal from 'decimal.js'
import isEqual from 'lodash/isEqual'

export default {
  namespaced: true,

  state: {
    id: null,
    saved_cart_id: null,
    title: '',
    description: '',
    short_description: '',
    file_attachments: [],
    preview_attachments: [],
    markup: '20',
    markup_basis: 'percentage',
    server_errors: [],

    // These are stored here for convenience for the preview page.
    seller_email: '',
    seller_name: '',
    seller_logo: '',

    marketplace_fee_percentage: 0.1,
    base_price: 0,
    est_ship_date: null
  },

  getters: {
    product: (state) => {
      const markup = state.markup || '20'
      let markupPrice = 0
      if (state.markup_basis === 'flat') {
        markupPrice = Decimal.add(state.base_price, markup)
      } else {
        const markupMultiplier = Decimal.add(1, Decimal.div(markup, 100))
        markupPrice = Decimal.mul(state.base_price, markupMultiplier)
      }
      return {
        id: state.id,
        title: state.title,
        description: state.description,
        short_description: state.short_description,
        saved_cart_id: state.saved_cart_id,
        file_attachments: state.file_attachments,
        preview_attachments: state.preview_attachments,
        markup,
        markup_basis: state.markup_basis,
        seller_email: state.seller_email,
        seller_name: state.seller_name,
        seller_logo: state.seller_logo,
        base_price: state.base_price,
        marketplace_fee_percentage: state.marketplace_fee_percentage,
        markup_price: markupPrice.toFixed(2),
        est_ship_date: state.est_ship_date
      }
    },
  },
  actions: {
    loadProduct({ commit }, product) {
      commit('setId', product.id)
      commit('setTitle', product.title)
      commit('setDescription', product.description)
      commit('setShortDescription', product.short_description)
      commit('setSavedCartId', product.saved_cart_id)
      commit('setMarkup', product.markup)
      commit('setMarkupBasis', product.markup_basis || 'percentage')
      commit('setFileAttachments', product.file_attachments)
      commit('setPreviewAttachments', product.preview_attachments)
      commit('setSellerInfo', product)
      commit('setBasePrice', product.base_price)
      commit('setMarketplaceFeePercentage', product.marketplace_fee_percentage)
      commit('setEstimatedShipDate', product.est_ship_date)
    },
    loadProductFromSavedCard({ commit }, savedCart) {
      commit('setId', null)
      commit('setTitle', '')
      commit('setDescription', '')
      commit('setShortDescription', '')
      commit('setSavedCartId', savedCart.id)
      commit('setMarkup', '20')
      commit('setMarkupBasis', 'percentage')
      commit('setFileAttachments', [])
      commit('setPreviewAttachments', [])
    },
    loadQuote({ commit }, product) {
      commit('setBasePrice', product.base_price)
    },
    reset({ commit }) {
      commit('setId', null)
      commit('setTitle', '')
      commit('setDescription', '')
      commit('setShortDescription', '')
      commit('setSavedCartId', null)
      commit('setMarkup', '20')
      commit('setMarkupBasis', 'percentage')
      commit('setFileAttachments', [])
      commit('setPreviewAttachments', [])
    },
    removeAttachmentByUrl({ commit, state }, url) {
      // Url should be unique so the attachment should only exist in one of these lists.
      commit('setFileAttachments', state.file_attachments.filter(item => item.filename_url !== url))
      commit('setPreviewAttachments', state.preview_attachments.filter(item => item.filename_url !== url))
    },
  },
  mutations: {
    setId(state, id) {
      state.id = id
    },
    setTitle(state, title) {
      state.title = title
    },
    setDescription(state, description) {
      state.description = description
    },
    setShortDescription(state, val) {
      state.short_description = val
    },
    setSavedCartId(state, val) {
      state.saved_cart_id = val
    },
    addFileAttachment(state, attachment) {
      state.file_attachments.push(attachment)
    },
    addPreviewAttachment(state, attachment) {
      state.preview_attachments.push(attachment)
    },
    setMarkup(state, markup) {
      state.markup = markup
    },
    setMarkupBasis(state, val) {
      state.markup_basis = val
    },
    setFileAttachments(state, attachments) {
      if (isEqual(state.file_attachments, attachments)) return
      state.file_attachments.splice(0, Infinity, ...attachments)
    },
    setPreviewAttachments(state, attachments) {
      // I'm not sure that checking for equality here is the right solution.
      // The problem I'm trying to solve is that when the ProductMedia component
      // is loaded it is getting it's validation triggered by the changes in
      // this data, when the actual data isn't changing, just the array instance
      // is getting overriden.
      if (isEqual(state.preview_attachments, attachments)) return
      state.preview_attachments.splice(0, Infinity, ...attachments)
    },
    setServerValidationErrors(state, errors) {
      state.server_errors = errors
    },
    setItem(state, lineItem) {
      const existinglineItem = state.saved_cart.cart_items?.find(item => item.id === lineItem.id)
      if (existinglineItem) {
        Object.assign(existinglineItem, lineItem)
      }
    },
    setSellerInfo(state, { seller_email: sellerEmail, seller_name: sellerName, seller_logo: sellerLogo }) {
      state.seller_email = sellerEmail
      state.seller_name = sellerName
      state.seller_logo = sellerLogo
    },
    setMarketplaceFeePercentage(state, val) {
      state.marketplace_fee_percentage = val
    },
    setBasePrice(state, val) {
      state.base_price = val
    },
    setEstimatedShipDate(state, val) {
      state.est_ship_date = val
    }
  }
}
