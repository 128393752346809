import makerjs from 'makerjs'

function Flag(height) {
  const paths = [
    "M6.4262,11.0103 L6.4262,11.5575 L15.0029,11.5575 L15.0029,11.0103 Z",
    "M0.774,5.1393 L0.774,5.7843 L15.0029,5.7843 L15.0029,5.1393 Z",
    "M0.774,6.3865 L0.774,6.9798 L15.0029,6.9798 L15.0029,6.3865 Z",
    "M15.0029,10.4084 L15.0029,9.8686 L6.4262,9.8686 L6.4262,10.4084 Z",
    "M6.4262,8.7273 L6.4262,9.2664 L15.0029,9.2664 L15.0029,8.7273 Z",
    "M15.0029,7.5826 L0.774,7.5826 L0.774,8.1322 L15.0029,8.1253 Z",
    "M1.1335,8.793 L1.1948,8.6047 L1.3921,8.6047 L1.2317,8.489 L1.2939,8.3006 L1.1335,8.4172 L0.974,8.3006 L1.0354,8.489 L0.8749,8.6047 L1.0731,8.6047 Z",
    "M1.1335,9.608 L1.1948,9.4197 L1.3921,9.4205 L1.2317,9.3041 L1.2939,9.1164 L1.1335,9.2331 L0.974,9.1164 L1.0354,9.3041 L0.8749,9.4205 L1.0731,9.4197 Z",
    "M1.1335,10.3873 L1.1948,10.1989 L1.3921,10.1998 L1.2317,10.0831 L1.2939,9.8957 L1.1335,10.0123 L0.974,9.8957 L1.0354,10.0831 L0.8749,10.1998 L1.0731,10.1989 Z",
    "M1.1335,11.198 L1.1948,11.0094 L1.3921,11.0094 L1.2317,10.8939 L1.2939,10.7053 L1.1335,10.822 L0.974,10.7053 L1.0354,10.8939 L0.8749,11.0094 L1.0731,11.0094 Z",
    "M1.2939,11.5117 L1.1335,11.6283 L0.974,11.5117 L1.0354,11.7003 L0.8749,11.8158 L1.0731,11.8158 L1.1335,12.0043 L1.1948,11.8158 L1.3921,11.8158 L1.2317,11.7003 Z",
    "M1.7286,11.2814 L1.7901,11.0936 L1.6295,11.2102 L1.4702,11.0936 L1.5316,11.2814 L1.3712,11.3977 L1.5691,11.3969 L1.6295,11.5854 L1.6911,11.3969 L1.889,11.3977 Z",
    "M2.2537,11.5152 L2.0942,11.6318 L1.9338,11.5152 L1.9951,11.7027 L1.8348,11.8193 L2.0327,11.8185 L2.0942,12.0068 L2.1546,11.8185 L2.3528,11.8193 L2.1924,11.7027 Z",
    "M2.684,11.2864 L2.7452,11.098 L2.5858,11.2147 L2.4263,11.098 L2.4876,11.2864 L2.3272,11.4021 L2.5253,11.4021 L2.5858,11.5906 L2.6462,11.4021 L2.8443,11.4021 Z",
    "M3.2502,11.5239 L3.0907,11.6405 L2.9303,11.5239 L2.9925,11.7125 L2.8321,11.8282 L3.0291,11.8273 L3.0907,12.0157 L3.1511,11.8273 L3.3492,11.8282 L3.1889,11.7125 Z",
    "M3.6322,11.2814 L3.6935,11.0936 L3.5332,11.2102 L3.3736,11.0936 L3.435,11.2814 L3.2746,11.3977 L3.4727,11.3969 L3.5332,11.5854 L3.5945,11.3969 L3.7926,11.3977 Z",
    "M4.2044,11.5197 L4.0441,11.6361 L3.8846,11.5197 L3.9461,11.7071 L3.7857,11.8237 L3.9836,11.8229 L4.0441,12.0112 L4.1056,11.8229 L4.3035,11.8237 L4.1431,11.7071 Z",
    "M4.634,11.2796 L4.6953,11.0909 L4.5358,11.2075 L4.3754,11.0909 L4.4377,11.2796 L4.2773,11.395 L4.4754,11.395 L4.5358,11.5836 L4.5963,11.395 L4.7944,11.395 Z",
    "M5.1494,11.517 L4.9899,11.6336 L4.8295,11.517 L4.8909,11.7044 L4.7304,11.8211 L4.9283,11.8202 L4.9899,12.0086 L5.0503,11.8202 L5.2484,11.8211 L5.0878,11.7044 Z",
    "M5.5805,11.2796 L5.6427,11.0909 L5.4823,11.2075 L5.3228,11.0909 L5.3842,11.2796 L5.2237,11.395 L5.4219,11.395 L5.4823,11.5836 L5.5436,11.395 L5.7409,11.395 Z",
    "M5.9899,11.6265 L5.8301,11.5099 L5.8917,11.6985 L5.7313,11.8142 L5.9293,11.8142 L5.9899,12.0026 L6.0503,11.8142 L6.2482,11.8142 L6.0878,11.6985 L6.1494,11.5099 Z",
    "M5.9899,10.8202 L5.8301,10.7038 L5.8917,10.8921 L5.7313,11.0079 L5.9293,11.0079 L5.9899,11.1962 L6.0503,11.0079 L6.2482,11.0079 L6.0878,10.8921 L6.1494,10.7038 Z",
    "M5.9899,10.0105 L5.8301,9.8939 L5.8917,10.0823 L5.7313,10.198 L5.9293,10.198 L5.9899,10.3864 L6.0503,10.198 L6.2482,10.198 L6.0878,10.0823 L6.1494,9.8939 Z",
    "M5.9899,9.2313 L5.8301,9.1147 L5.8917,9.3031 L5.7313,9.4188 L5.9293,9.4188 L5.9899,9.6074 L6.0503,9.4188 L6.2482,9.4188 L6.0878,9.3031 L6.1494,9.1147 Z",
    "M2.2537,10.708 L2.0942,10.8255 L1.9338,10.708 L1.9951,10.8963 L1.8348,11.0121 L2.0327,11.0121 L2.0942,11.2007 L2.1546,11.0121 L2.3528,11.0121 L2.1924,10.8963 Z",
    "M2.684,10.4959 L2.7452,10.3075 L2.5858,10.4241 L2.4263,10.3075 L2.4876,10.4959 L2.3272,10.6116 L2.5253,10.6116 L2.5858,10.8002 L2.6462,10.6116 L2.8443,10.6116 Z",
    "M3.2502,10.7178 L3.0907,10.8342 L2.9303,10.7178 L2.9925,10.9053 L2.8321,11.0219 L3.0291,11.021 L3.0907,11.2094 L3.1511,11.021 L3.3492,11.0219 L3.1889,10.9053 Z",
    "M3.6322,10.4916 L3.6935,10.3031 L3.5332,10.4197 L3.3736,10.3031 L3.435,10.4916 L3.2746,10.6072 L3.4727,10.6072 L3.5332,10.7957 L3.5945,10.6072 L3.7926,10.6072 Z",
    "M4.2044,10.7133 L4.0441,10.83 L3.8846,10.7133 L3.9461,10.9008 L3.7857,11.0174 L3.9836,11.0165 L4.0441,11.2049 L4.1056,11.0165 L4.3035,11.0174 L4.1431,10.9008 Z",
    "M4.634,10.489 L4.6953,10.3004 L4.5358,10.4179 L4.3754,10.3004 L4.4377,10.489 L4.2773,10.6056 L4.4754,10.6047 L4.5358,10.7931 L4.5963,10.6047 L4.7944,10.6056 Z",
    "M5.1494,10.7098 L4.9899,10.8273 L4.8295,10.7098 L4.8909,10.8981 L4.7304,11.0148 L4.9283,11.0139 L4.9899,11.2025 L5.0503,11.0139 L5.2484,11.0148 L5.0878,10.8981 Z",
    "M4.9899,10.0165 L4.8295,9.8999 L4.8909,10.0885 L4.7304,10.204 L4.9283,10.204 L4.9899,10.3926 L5.0503,10.204 L5.2484,10.204 L5.0878,10.0885 L5.1494,9.8999 Z",
    "M4.0441,10.0192 L3.8846,9.9026 L3.9461,10.0912 L3.7857,10.2067 L3.9836,10.2067 L4.0441,10.3952 L4.1056,10.2067 L4.3035,10.2067 L4.1431,10.0912 L4.2044,9.9026 Z",
    "M3.0907,10.0245 L2.9303,9.9079 L2.9925,10.0954 L2.8321,10.212 L3.0291,10.2111 L3.0907,10.3995 L3.1511,10.2111 L3.3492,10.212 L3.1889,10.0954 L3.2502,9.9079 Z",
    "M2.0327,10.2024 L2.0942,10.3908 L2.1546,10.2024 L2.3528,10.2024 L2.1924,10.0867 L2.2537,9.8984 L2.0942,10.0148 L1.9338,9.8984 L1.9951,10.0867 L1.8348,10.2024 Z",
    "M2.0942,9.2357 L1.9338,9.1191 L1.9951,9.3075 L1.8348,9.4232 L2.0327,9.4232 L2.0942,9.6116 L2.1546,9.4232 L2.3528,9.4232 L2.1924,9.3075 L2.2537,9.1191 Z",
    "M2.5858,9.6092 L2.4263,9.4926 L2.4876,9.6809 L2.3272,9.7966 L2.5253,9.7966 L2.5858,9.985 L2.6462,9.7966 L2.8443,9.7966 L2.684,9.6809 L2.7452,9.4926 Z",
    "M3.0907,9.2453 L2.9303,9.1278 L2.9925,9.3164 L2.8321,9.433 L3.0291,9.4321 L3.0907,9.6204 L3.1511,9.4321 L3.3492,9.433 L3.1889,9.3164 L3.2502,9.1278 Z",
    "M3.5332,9.6047 L3.3736,9.4881 L3.435,9.6764 L3.2746,9.7922 L3.4727,9.7922 L3.5332,9.9805 L3.5945,9.7922 L3.7926,9.7922 L3.6322,9.6764 L3.6935,9.4881 Z",
    "M4.0441,9.24 L3.8846,9.1235 L3.9461,9.312 L3.7857,9.4277 L3.9836,9.4277 L4.0441,9.616 L4.1056,9.4277 L4.3035,9.4277 L4.1431,9.312 L4.2044,9.1235 Z",
    "M4.5358,9.6029 L4.3754,9.4854 L4.4377,9.6738 L4.2773,9.7904 L4.4754,9.7896 L4.5358,9.9779 L4.5963,9.7896 L4.7944,9.7904 L4.634,9.6738 L4.6953,9.4854 Z",
    "M4.9899,9.2375 L4.8295,9.1209 L4.8909,9.3093 L4.7304,9.425 L4.9283,9.425 L4.9899,9.6134 L5.0503,9.425 L5.2484,9.425 L5.0878,9.3093 L5.1494,9.1209 Z",
    "M5.4823,9.6029 L5.3228,9.4854 L5.3842,9.6738 L5.2237,9.7904 L5.4219,9.7896 L5.4823,9.9779 L5.5436,9.7896 L5.7409,9.7904 L5.5805,9.6738 L5.6427,9.4854 Z",
    "M5.4823,10.4179 L5.3228,10.3004 L5.3842,10.489 L5.2237,10.6056 L5.4219,10.6047 L5.4823,10.7931 L5.5436,10.6047 L5.7409,10.6056 L5.5805,10.489 L5.6427,10.3004 Z",
    "M1.5691,10.6072 L1.6295,10.7957 L1.6911,10.6072 L1.889,10.6072 L1.7286,10.4916 L1.7901,10.3031 L1.6295,10.4197 L1.4702,10.3031 L1.5316,10.4916 L1.3712,10.6072 Z",
    "M1.5691,9.7922 L1.6295,9.9805 L1.6911,9.7922 L1.889,9.7922 L1.7286,9.6764 L1.7901,9.4881 L1.6295,9.6047 L1.4702,9.4881 L1.5316,9.6764 L1.3712,9.7922 Z",
    "M1.6295,8.8344 L1.4702,8.7177 L1.5316,8.9052 L1.3712,9.0218 L1.5691,9.0209 L1.6295,9.2094 L1.6911,9.0209 L1.889,9.0218 L1.7286,8.9052 L1.7901,8.7177 Z",
    "M2.0942,8.4205 L1.9338,8.3041 L1.9951,8.4916 L1.8348,8.6083 L2.0327,8.6074 L2.0942,8.7957 L2.1546,8.6074 L2.3528,8.6083 L2.1924,8.4916 L2.2537,8.3041 Z",
    "M2.5858,8.8388 L2.4263,8.7222 L2.4876,8.9105 L2.3272,9.0263 L2.5253,9.0263 L2.5858,9.2138 L2.6462,9.0263 L2.8443,9.0263 L2.684,8.9105 L2.7452,8.7222 Z",
    "M3.0907,8.4295 L2.9303,8.3128 L2.9925,8.5012 L2.8321,8.6169 L3.0291,8.6169 L3.0907,8.8054 L3.1511,8.6169 L3.3492,8.6169 L3.1889,8.5012 L3.2502,8.3128 Z",
    "M3.5332,8.8344 L3.3736,8.7177 L3.435,8.9052 L3.2746,9.0218 L3.4727,9.0209 L3.5332,9.2094 L3.5945,9.0209 L3.7926,9.0218 L3.6322,8.9052 L3.6935,8.7177 Z",
    "M4.0441,8.425 L3.8846,8.3084 L3.9461,8.497 L3.7857,8.6127 L3.9836,8.6118 L4.0441,8.8002 L4.1056,8.6118 L4.3035,8.6127 L4.1431,8.497 L4.2044,8.3084 Z",
    "M4.5358,8.8317 L4.3754,8.7151 L4.4377,8.9035 L4.2773,9.0192 L4.4754,9.0192 L4.5358,9.2075 L4.5963,9.0192 L4.7944,9.0192 L4.634,8.9035 L4.6953,8.7151 Z",
    "M4.9899,8.4223 L4.8295,8.3059 L4.8909,8.4934 L4.7304,8.61 L4.9283,8.6091 L4.9899,8.7975 L5.0503,8.6091 L5.2484,8.61 L5.0878,8.4934 L5.1494,8.3059 Z",
    "M5.4823,8.8317 L5.3228,8.7151 L5.3842,8.9035 L5.2237,9.0192 L5.4219,9.0192 L5.4823,9.2075 L5.5436,9.0192 L5.7409,9.0192 L5.5805,8.9035 L5.6427,8.7151 Z",
    "M5.9899,8.4163 L5.8301,8.2996 L5.8917,8.4872 L5.7313,8.6038 L5.9293,8.6029 L5.9899,8.7912 L6.0503,8.6029 L6.2482,8.6038 L6.0878,8.4872 L6.1494,8.2996 Z",
    "M0.3885,4.4228 L15.3885,4.4228 L15.3885,12.2739 L0.3885,12.2739 Z"
  ]

  const flagModels = { }

  class FlagModels {
    constructor(models) {
      this.models = models
    }
  }

  paths.forEach((p, i) => {
    flagModels[i] = makerjs.model.mirror(makerjs.importer.fromSVGPathData(p), true, false)
  })

  this.models = {
    flag_group: new FlagModels(flagModels)
  }

  // Apply a scaling factor
  const m = makerjs.measure.modelExtents(this)
  makerjs.model.scale(this, height / m.height)
  makerjs.model.rotate(this, 180)
}

Flag.metaParameters = [
  { title: "Height", type: "range", min: 3, max: 24, value: 5, default: 5, group: 'flag_group', group_title: 'Flag' },
]

export default Flag
