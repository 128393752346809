import round from 'lodash/round'
import HardwareCatalog from 'shared/components/HardwareCatalog'
import cskData from 'shared/constants/countersink.js'
import tapSizes from 'shared/constants/tap_sizes.js'
import dimpleSizes from 'shared/constants/dimple_sizes.js'
import { createTooltip, positionTooltip, updateTooltipText } from 'shared/util/svg_tooltips'

export default {
  props: {
    showHoleOperations: {
      type: Boolean,
      default: false
    },

    holeSelect: {
      type: Boolean,
      default: false
    },

    holes: {
      type: Array,
      default() { return [] }
    },

    holesHighlighted: {
      type: Array,
      default() { return [] }
    },

    holesSelected: {
      type: Array,
      default() { return [] }
    },

    // Cart or Line item
    item: {
      type: Object,
      default() { return {} }
    },

    // Should this display CSK and hardware insertion per side, or merged on one side?
    flippable: {
      type: Boolean,
      default: false
    },

    showBottom: {
      type: Boolean,
      default: false
    },

    showHoleErrors: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      radii: {},
      $tooltip: null,
    }
  },

  computed: {
    cskConfig() {
      const direction = this.showBottom ? 'bottom' : 'top'
      const csk = (this.hasCsk ? this.item.csk_config || [] : [])

      if (this.flippable) {
        return csk.filter(c => c.csk_direction === direction)
      }

      return csk
    },

    hardwareConfig() {
      const direction = this.showBottom ? 'bottom' : 'top'
      const config = (this.hasHardware ? this.item.hardware_config || [] : [])

      if (this.flippable) {
        return config.filter(h => h.hardware_direction === direction)
      }

      return config
    },

    dimpleConfig() {
      const direction = this.showBottom ? 'bottom' : 'top'
      const config = (this.hasDimpleForming ? this.item.dimple_config || [] : [])

      if (this.flippable) {
        return config.filter(h => h.dimple_direction === direction)
      }

      return config
    },

    showScaledUnits() {
      // If a drawing has been scaled (as we do for admins), the
      // scaled width will not match the original width
      return this.$scs.pack === 'admin' && this.drawing.width !== this.drawing.original_width
    },

    tapConfig() {
      return this.hasTapping ? this.item.tap_config || [] : []
    },

    hasCsk() {
      return this.operations.includes('countersinking')
    },

    hasDimpleForming() {
      return this.operations.includes('dimple_forming')
    },

    hasHardware() {
      return this.operations.includes('hardware')
    },

    hasTapping() {
      return this.operations.includes('tapping')
    },

    operations() {
      return this.item.operations || []
    }
  },

  watch: {
    cskConfig() {
      this.resetHoleOperations()
    },

    tapConfig() {
      this.resetHoleOperations()
    },

    dimpleConfig() {
      this.resetHoleOperations()
    },

    hardwareConfig() {
      this.resetHoleOperations()
    },

    holesHighlighted(holes) {
      const elements = this.$refs.svgContainer.querySelectorAll(".holes .hole")

      for (let i = 0; i < elements.length; i++) {
        const el = elements[i]
        el.classList.toggle('highlight', holes.includes(el.id))
      }
    },

    holesSelected(holes) {
      const elements = this.$refs.svgContainer.querySelectorAll(".holes .hole")

      for (let i = 0; i < elements.length; i++) {
        const el = elements[i]
        el.classList.toggle('selected', holes.includes(el.id))
      }
    },

    holes() {
      setTimeout(this.makeSelectable, 0)
    }
  },

  methods: {
    getRadius(hole, scale = false) {
      // When drawings are processed, they initially have a path defining the hole,
      // so the attribute will be 'radius'. Once we adjust the holes in the client,
      // we replace all the paths with circles, so the attribute will be 'r'.
      let radius = Number(hole.getAttribute('r') || hole.getAttribute('radius'))
      if (scale && this.drawing.units === 'mm') radius /= 25.4
      return radius
    },

    resetHoleOperations() {
      if (this.holeTimeout) clearTimeout(this.holeTimeout)

      this.holeTimeout = setTimeout(() => {
        this.resetHoleSizes()

        this.showCountersinking()
        this.showTapping()
        this.showHardware()
        this.showDimpleForming()
        this.highlightSmallDiameters()
      }, 100)
    },

    async initHoleOperations() {
      if (this.showHoleOperations) {
        await this.$store.dispatch('hardware/fetchByIDs', this.hardwareConfig.map(c => c.hardware_id))
      }

      if (this.holeSelect) {
        setTimeout(this.makeSelectable, 0)
      }

      setTimeout(this.highlightSmallDiameters, 0)
    },

    resetHoleSizes() {
      const $svgContainer = this.$refs.svgContainer
      if ($svgContainer) {
        Object.keys(this.radii).forEach(id => {
          const $hole = $svgContainer.querySelector(`#${id}`)
          $hole.setAttribute('r', this.radii[id])
        })
      }
    },

    makeSelectable() {
      const { svgContainer } = this.$refs

      if (svgContainer) {
        const ids = this.holes.map(hole => hole.id)
        const elements = Array.from(svgContainer.querySelectorAll('.holes .hole'))
          // Filter elements by selectable holes
          .filter(el => ids.includes(el.id))

        for (let i = 0; i < elements.length; i++) {
          const el = elements[i]
          el.classList.toggle('valid', true)
        }
      }
    },

    showHardwareDetails(id) {
      if (!this.hasMixedHardware) {
        this.$buefy.modal.open({
          parent: this,
          component: HardwareCatalog,
          hasModalCard: true,
          canCancel: true,
          props: {
            isReadOnly: true,
            hardwareID: id
          },
          fullScreen: this.$mq === 'mobile',
        })
      }
    },

    highlightSmallDiameters() {
      if (!this.showHoleErrors) return

      const { svgContainer } = this.$refs

      const minHoleDiameter = Number(this.item?.material?.min_hole_diameter)
      if (minHoleDiameter > 0) {
        const holes = svgContainer.querySelectorAll(`.holes .hole`)

        for (let i = 0; i < holes.length; i++) {
          const hole = holes[i]
          const diameter = this.getRadius(hole, true /* scaled radius */) * 2

          hole.classList.toggle('size-error', diameter < minHoleDiameter)
        }
      }
    },

    holeHandleClick(event) {
      const hardwareID = event.target.getAttribute('hardware-id')
      if (hardwareID) {
        this.showHardwareDetails(parseInt(hardwareID))
      }

      if (event.target.classList.contains('hole')) {
        const { id } = event.target
        this.$emit('update:holes-selected', id ? [id] : [])
      }
    },

    holeHandleMouseover(event) {
      const $svgContainer = this.$refs.svgContainer
      if ($svgContainer) {
        this.$tipTarget = event.target
        const $tip = $svgContainer.querySelector('#hole-tooltip')
        if (this.tipTimeout) clearTimeout(this.tipTimeout)

        // Is this element a hole with an ID
        if (this.$tipTarget?.id && this.$tipTarget.classList.contains('hole')) {
          const { id } = this.$tipTarget
          let config = {}

          // Reset the tooltip
          this.$tooltip = null

          // Tap tip content
          config = this.tapConfig.find(c => c.id === id)
          if (config && config.tap) {
            this.$tooltip = this.createHoleTooltip(this.$tipTarget, config.tap)
          }

          // CSK tip content
          config = this.cskConfig.find(c => c.id === id)
          if (config && config.csk_id && config.csk_direction) {
            const csk = cskData.find(c => c.id === config.csk_id)
            if (csk) {
              const content = `${csk.major} / ${csk.drill} / ${csk.angle}°`
              this.$tooltip = this.createHoleTooltip(this.$tipTarget, content)
            }
          }

          // Hardware tip content
          config = this.hardwareConfig.find(c => c.id === id)
          if (config && config.hardware_id && config.hardware_direction) {
            const content = this.$store.state.hardware.data[config.hardware_id]?.title || '?'
            this.$tooltip = this.createHoleTooltip(
              this.$tipTarget,
              content,
              { underline: true, hardwareID: config.hardware_id }
            )
          }

          // Dimple tip content
          config = this.dimpleConfig.find(c => c.id === id)
          if (config && config.dimple_id && config.dimple_direction) {
            const dmp = dimpleSizes.find(d => d.id === config.dimple_id)
            if (dmp) {
              const content = `${dmp.hole_diameter}" ⌀`
              this.$tooltip = this.createHoleTooltip(this.$tipTarget, content)
            }
          }

          // No operations
          if (!this.$tooltip) {
            const diameter = this.getRadius(this.$tipTarget) * 2
            const scaledDiameter = this.getRadius(this.$tipTarget, true /* scaled radius */) * 2

            // If we're to show hole errors (admin), show the scaled diameter (in) we're
            // comparing against. We want to round this number so it doesn't get too long
            const tooltipDiameter = round(this.showScaledUnits ? scaledDiameter : diameter, 4)

            if (diameter > 0 && scaledDiameter <= 3.1) {
              // We always show the scaled diameter in inches for admins (which also see hole errors)
              const units = this.drawing.units === 'mm' && !this.showScaledUnits ? 'mm' : '"'
              this.$tooltip = this.createHoleTooltip(this.$tipTarget, `${tooltipDiameter}${units}`)
            }
          }
        }

        // Only remove the tip if you're not hovering inside it
        else if (![this.$tipTarget, this.$tipTarget.parentElement].includes($tip)) {
          this.tipTimeout = setTimeout(() => {
            const $t = document.querySelector('#hole-tooltip')
            if ($t) $t.remove()
          }, 750)
        }
      }
    },

    showCountersinking() {
      const $svgContainer = this.$refs.svgContainer
      if ($svgContainer) {
        const $old = $svgContainer.querySelector("#countersinking")
        if ($old) $old.remove()
        const g = document.createElementNS("http://www.w3.org/2000/svg", "g")
        g.id = "countersinking"

        this.cskConfig.forEach(csk => {
          if (csk.id && csk.csk_id && csk.csk_direction) {
            const $holes = $svgContainer.querySelector('.holes')
            let $hole = $svgContainer.querySelector(`#${csk.id}`)

            if ($hole) {
              // Remove hole from base path
              this.removeHoleFromBase($hole)

              // Convert hole into circle
              $hole = this.convertToCircle($hole)

              const cskSize = cskData.find(c => c.id === csk.csk_id) || {}

              const center = {
                x: parseFloat($hole.getAttribute('cx')),
                y: parseFloat($hole.getAttribute('cy'))
              }
              const radius = parseFloat($hole.getAttribute('r'))

              // Find drill size for CSK. If not found, use the current radius
              if (cskSize.drill) {
                let diameter = cskSize.drill
                if (this.drawing.units === 'mm') diameter *= 25.4
                this.setHoleRadius($hole, diameter / 2)
              } else {
                this.setHoleRadius($hole, radius)
              }

              let majorDiameter = cskSize.major || radius * 1.25
              if (this.drawing.units === 'mm') majorDiameter *= 25.4

              g.innerHTML += `<circle cx="${center.x}" cy="${center.y}" r="${majorDiameter / 2}" />`
              $holes.appendChild(g)
            }
          }
        })
      }
    },

    createHoleTooltip(target, content, options = {}) {
      const $tooltip = createTooltip('hole-tooltip')

      const box = target.getBoundingClientRect()

      // Start with the center of the target
      const left = box.left + (box.width / 2)

      // Start with the top of the target
      const top = box.top + this.scrollTop()

      if (options.hardwareID) {
        $tooltip.onclick = () => {
          $tooltip.remove()
          this.showHardwareDetails(parseInt(options.hardwareID))
        }
      }

      if (options.underline) {
        $tooltip.style.textDecoration = 'underline'
        $tooltip.style.cursor = 'pointer'
      }

      updateTooltipText($tooltip, content)
      positionTooltip($tooltip, (tooltipBox) => ({
          left: left - (tooltipBox.width / 2),
          top: top - tooltipBox.height
        }))

      return $tooltip
    },

    removeHoleFromBase($hole) {
      if ($hole.tagName === 'path') {
        const $base = this.$refs.svgContainer.querySelector('.base path')
        const path = $hole.getAttribute('d')

        // First, we need to remove this hole from the base path
        $base.setAttribute('d', $base.getAttribute('d').replace(path, ''))
      }
    },

    convertToCircle($hole) {
      // Return the circle if it has already been converted
      if ($hole.tagName === 'circle') return $hole

      // Pull attributes from hole
      const radius = $hole.getAttribute('radius')
      const cx = $hole.getAttribute('cx')
      const cy = $hole.getAttribute('cy')

      // Create a new circle element
      const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle")
      circle.setAttribute('cx', cx)
      circle.setAttribute('cy', cy)
      circle.setAttribute('r', radius)
      circle.classList = $hole.classList
      circle.id = $hole.id

      // Cache original radius
      this.radii[$hole.id] = radius

      // Replace hole with circle element
      $hole.replaceWith(circle)

      // Return the new circle
      return circle
    },

    setHoleRadius($hole, radius) {
      // Set new hole radius
      $hole.setAttribute('r', radius)
      // The hole was removed from the base path, so give this element hole styling
      $hole.classList.add('resized')
    },

    showTapping() {
      const $svgContainer = this.$refs.svgContainer
      if ($svgContainer) {
        const $old = $svgContainer.querySelector("#tapping")
        if ($old) $old.remove()
        const g = document.createElementNS("http://www.w3.org/2000/svg", "g")
        g.id = "tapping"

        this.tapConfig.forEach(tap => {
          if (tap.id && tap.tap) {
            const $holes = $svgContainer.querySelector('.holes')
            let $hole = $svgContainer.querySelector(`#${tap.id}`)

            if ($hole) {
              // Remove hole from base path
              this.removeHoleFromBase($hole)

              // Convert hole into circle
              $hole = this.convertToCircle($hole)

              const tapSize = tapSizes.find(t => t.tap === tap.tap) || {}
              if (tapSize.drill) {
                let diameter = tapSizes.find(t => t.tap === tap.tap).drill
                if (this.drawing.units === 'mm') diameter *= 25.4
                this.setHoleRadius($hole, diameter / 2)
              } else {
                this.setHoleRadius($hole, this.radii[tap.id])
              }

              const center = {
                x: parseFloat($hole.getAttribute('cx')),
                y: parseFloat($hole.getAttribute('cy'))
              }
              const deg2rad = (deg) => (Math.PI / 180) * deg
              const point = (r, deg) => ({
                x: center.x + (r * Math.sin(deg2rad(deg))),
                y: center.y + (r * Math.cos(deg2rad(deg)))
              })

              const radius = parseFloat($hole.getAttribute('r')) * 1.25
              const arc1 = [point(radius, 140), point(radius, 320)]
              const arc2 = [point(radius, 320), point(radius, 20)]
              const arc3 = [point(radius, 35), point(radius, 70)]

              const path = [
                // Move to start
                `M${arc1[0].x},${arc1[0].y}`,
                `A${radius} ${radius} 0 0 0 ${arc1[1].x} ${arc1[1].y}`,
                `A${radius} ${radius} 0 0 0 ${arc2[1].x} ${arc2[1].y}`,

                `M${arc3[0].x},${arc3[0].y}`,
                `A${radius} ${radius} 0 0 0 ${arc3[1].x} ${arc3[1].y}`,
              ]

              g.innerHTML += `<path d="${path.join('')}" />`
              $holes.appendChild(g)
            }
          }
        })
      }
    },

    showDimpleForming() {
      const $svgContainer = this.$refs.svgContainer
      if ($svgContainer) {
        const $old = $svgContainer.querySelector("#dimple_forming")
        if ($old) $old.remove()
        const g = document.createElementNS("http://www.w3.org/2000/svg", "g")
        g.id = "dimple_forming"

        this.dimpleConfig.forEach(dimple => {
          if (dimple.id && dimple.dimple_id && dimple.dimple_direction) {
            const $holes = $svgContainer.querySelector('.holes')
            let $hole = $svgContainer.querySelector(`#${dimple.id}`)

            if ($hole) {
              this.removeHoleFromBase($hole)

              $hole = this.convertToCircle($hole)

              const dimpleSize = dimpleSizes.find(d => d.id === dimple.dimple_id) || {}

              const center = {
                x: parseFloat($hole.getAttribute('cx')),
                y: parseFloat($hole.getAttribute('cy'))
              }
              const radius = parseFloat($hole.getAttribute('r'))

              if (dimpleSize.hole_diameter) {
                let diameter = dimpleSize.hole_diameter
                if (this.drawing.units === 'mm') diameter *= 25.4
                this.setHoleRadius($hole, diameter / 2)
              } else {
                this.setHoleRadius($hole, radius)
              }

              let majorDiameter = dimpleSize.major || radius * 1.25
              if (this.drawing.units === 'mm') majorDiameter *= 25.4

              let toolingDiameter = dimpleSize.tooling || radius * 1.5
              if (this.drawing.units === 'mm') toolingDiameter *= 25.4

              g.innerHTML += `<circle cx="${center.x}" cy="${center.y}" r="${majorDiameter / 2}" />`
              g.innerHTML += `<circle id="tooling" cx="${center.x}" cy="${center.y}" r="${toolingDiameter / 2}" />`
              $holes.appendChild(g)
            }
          }
        })
      }
    },

    showHardware() {
      function hexCorner(center, size, i) {
        const angleDeg = 60 * i // - 30 // Uncomment for pointy-top
        const angleRad = (Math.PI / 180) * angleDeg
        return {
          x: center.x + size * Math.cos(angleRad),
          y: center.y + size * Math.sin(angleRad)
        }
      }

      const $svgContainer = this.$refs.svgContainer
      if ($svgContainer) {
        const $old = $svgContainer.querySelector("#hardware")
        if ($old) $old.remove()
        const g = document.createElementNS("http://www.w3.org/2000/svg", "g")
        g.id = "hardware"

        this.hardwareConfig.forEach(hw => {
          if (hw.id && hw.hardware_id && hw.hardware_direction) {
            const $holes = $svgContainer.querySelector('.holes')
            let $hole = $svgContainer.querySelector(`#${hw.id}`)

            if ($hole) {
              // Remove hole from base path
              this.removeHoleFromBase($hole)

              // Convert hole into circle
              $hole = this.convertToCircle($hole)

              // Determine the hardware diameter
              let diameter = this.$store.getters['hardware/diameter'](hw.hardware_id)
              if (this.drawing.units === 'mm') diameter *= 25.4

              // If we've pulled HW from the server, we have a diameter. Use the diameter if we
              // have it, otherwise use the original hole size
              this.setHoleRadius($hole, diameter > 0 ? diameter / 2 : this.radii[hw.id])

              const center = {
                x: parseFloat($hole.getAttribute('cx')),
                y: parseFloat($hole.getAttribute('cy'))
              }
              const radius = parseFloat($hole.getAttribute('r')) * 1.35

              const points = []
              for (let i = 0; i < 6; i++) {
                points.push(hexCorner(center, radius, i))
              }

              const pointList = points.map(p => `${p.x},${p.y}`).join(' ')
              g.innerHTML += `<polygon points="${pointList}" />`
              $holes.appendChild(g)
            }
          }
        })
      }
    },

    scrollTop() {
      return document.documentElement.scrollTop
    }
  }
}
