import makerjs from 'makerjs'

function DiamondFlange(
  height,
  width,
  LRRadius,
  TBRadius,
  addCenterHole,
  holeDiameter,
  addLRHole,
  LRDiameter,
  LRDistance,
  addTBHole,
  TBDiameter,
  TBDistance
) {
    const TopLeft = new makerjs.paths.Line([-width / 2, 0], [0, height / 2])
    const BottomLeft = new makerjs.paths.Line([-width / 2, 0], [0, -height / 2])
    const TopRight = new makerjs.paths.Line([width / 2, 0], [0, height / 2])
    const BottomRight = new makerjs.paths.Line([width / 2, 0], [0, -height / 2])

    this.models = {
      diamond_group: {
        paths: {
          TopLeft,
          BottomLeft,
          TopRight,
          BottomRight,
          TopFillet: makerjs.path.fillet(TopLeft, TopRight, TBRadius),
          BottomFillet: makerjs.path.fillet(BottomLeft, BottomRight, TBRadius),
          LeftFillet: makerjs.path.fillet(TopLeft, BottomLeft, LRRadius),
          RightFillet: makerjs.path.fillet(TopRight, BottomRight, LRRadius),
        }
      }
    }

    this.paths = {}

    if (addCenterHole === "Yes") {
      this.paths.center_hole = new makerjs.paths.Circle([0, 0], holeDiameter / 2)
    }

    if (addLRHole === "Yes") {
      this.models.lr_holes = {
        paths: {
          LeftHole: new makerjs.paths.Circle([-LRDistance / 2, 0], LRDiameter / 2),
          RightHole: new makerjs.paths.Circle([LRDistance / 2, 0], LRDiameter / 2)
        }
      }
    }

    if (addTBHole === "Yes") {
      this.models.tb_holes = {
        paths: {
          TopHole: new makerjs.paths.Circle([0, TBDistance / 2], TBDiameter / 2),
          BottomHole: new makerjs.paths.Circle([0, -TBDistance / 2], TBDiameter / 2)
        }
      }
    }
}

DiamondFlange.metaParameters = [
    {
      title: "Height",
      type: "range",
      min: 1,
      max: 6,
      value: 4,
      default: 4,
      tooltip_text: "Overall height of the part",
      tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/diamond_flange_heightParam2.png",
      group: 'diamond_group',
      group_title: 'Diamond'
    },
    {
      title: "Width",
      type: "range",
      min: 1,
      max: 6,
      value: 3,
      default: 3,
      tooltip_text: "Overall width of the part",
      tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/diamond_flange_widthParam2.png",
      group: 'diamond_group',
      group_title: 'Diamond'
    },
    {
      title: "Horizontal Radius",
      type: "range",
      min: 0,
      max: 6,
      value: 0.25,
      default: 0.25,
      tooltip_text: "Radius of the left and right corners",
      tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/diamond_flange_hFillets2.png",
      group: 'diamond_group',
      group_title: 'Diamond'
    },
    {
      title: "Vertical Radius",
      type: "range",
      min: 0,
      max: 6,
      value: 0.25,
      default: 0.25,
      tooltip_text: "Radius of the top and bottom corners",
      tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/diamond_flange_vFillets2.png",
      group: 'diamond_group',
      group_title: 'Diamond'
    },
    { title: "Add Center Hole", type: "bool", value: "Yes", default: "Yes", control: "center_hole" },
    {
      title: "Center Hole Diameter",
      type: "range",
      min: 0.25,
      max: 6,
      value: 1,
      default: 1,
      tooltip_text: "Diameter of the center hole",
      tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/diamond_flange_centerDiameter2.png",
      group: 'center_hole',
      group_title: 'Center Hole'
    },
    { title: "Add Horizontal Holes", type: "bool", value: "Yes", default: "Yes", control: "lr_holes" },
    {
      title: "Horizontal Diameter",
      type: "range",
      min: 0.25,
      max: 6,
      value: 0.5,
      default: 0.5,
      tooltip_text: "Diameter for each of the left and right holes",
      tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/diamond_flange_hDiameter2.png",
      group: 'lr_holes',
      group_title: 'Horizontal Holes'
    },
    {
      title: "Distance Between Horizontal Holes",
      type: "range",
      min: 0.5,
      max: 6,
      value: 2,
      default: 2,
      tooltip_text: "Total distance between the centers of both the left and right holes ",
      tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/diamond_flange_hDistance2.png",
      group: 'lr_holes',
      group_title: 'Horizontal Holes'
    },
    { title: "Add Vertical Holes", type: "bool", value: "Yes", default: "Yes", control: "tb_holes" },
    {
      title: "Vertical Diameter",
      type: "range",
      min: 0.25,
      max: 6,
      value: 0.5,
      default: 0.5,
      tooltip_text: "Diameter for each of the top and bottom holes",
      tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/diamond_flange_vDiameter2.png",
      group: 'tb_holes',
      group_title: 'Vertical Holes'
    },
    {
      title: "Distance Between Vertical Holes",
      type: "range",
      min: 0.5,
      max: 6,
      value: 2.5,
      default: 2.5,
      tooltip_text: "Total distance between the centers of the top and bottom holes",
      tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/diamond_flange_vDistance2.png",
      group: 'tb_holes',
      group_title: 'Vertical Holes'
    },
]

export default DiamondFlange
