import Vue from 'vue'
import scs from 'shared/scs'

// initial state
const initialState = {
  data: {},
  current_folder_ids: {
    'drawing': '', // Default drawings to showing all, because a bug caused us to be doing that anyway.
    'saved_cart': 'uncategorized',
    'customer_product': 'uncategorized'
  }
}

// getters
const storeGetters = {
  all: (state) => Object.keys(state.data)
          .sort((a, b) => b - a)
          .map(id => state.data[id]),
  all_for_type: (_, getters) => type => {
    if (type === 'drawing') type = null
    return getters.all.filter(folder => folder.resource_type === type)
  },
  uncategorized_folder: () => ({
    id: "uncategorized",
    name: "Uncategorized",
    size: 0,
    default: true
  }),
  getFoldersForType: (state, getters) => type => {
    if (type === 'drawing') type = null
    const filteredFolders = Object.entries(state.data).filter(entry => entry[1].resource_type === type)
    return Object.fromEntries([[getters.uncategorized_folder.id, getters.uncategorized_folder], ...filteredFolders])
  }
}

// actions
const actions = {
  create({ commit }, data) {
    return scs.createFolder(data)
      .then((response) => {
        commit('setFolder', response.data)
        return response.data
      })
  },
  delete({ commit, dispatch, getters }, data) {
    return scs.deleteFolder(data)
      .then((resp) => {
        commit('removeFolder', data)

        // When a folder is deleted, drawings get displaced and should update
        // their stats accordingly
        commit('drawings/updateStats', resp.drawing_stats, { root: true })
        dispatch('drawings/clearDrawings', getters.uncategorized_folder.id, { root: true })
      })
  },
  async fetch({ commit }, data = {}) {
    return scs.getFolders(data)
      .then((response) => {
        commit('setFolders', response.data)
      })
  },
  update({ commit }, drawing) {
    return scs.updateFolder(drawing).then(response => {
      commit("setFolder", response.data)
      return response.data
    })
  }
}

// mutations
const mutations = {
  removeFolder(state, folder) {
    Vue.delete(state.data, folder.id)
  },
  setFolder(state, folder) {
    Vue.set(state.data, folder.id, folder)
  },
  setFolders(state, folders) {
    state.data = {}
    for (let i = 0; i < folders.length; i++) {
      const folder = folders[i]
      Vue.set(state.data, folder.id, folder)
    }
  },
  setCurrentFolder(state, { resourceType, folder }) {
    Vue.set(state.current_folder_ids, resourceType, folder ? folder.id : null)
  }
}

const state = initialState
const getters = storeGetters
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
