import find from 'lodash/find'
import map from 'lodash/map'


import { addBends } from 'shared/components/PartBuilder/parts/Bends'
import Dimensions from 'shared/components/PartBuilder/parts/Dimensions'

export default {
  computed: {
    fonts() {
      return this.$store.getters['part_builder_dialog/allFonts']
    },
    stencilFonts() {
      return this.$store.getters['part_builder_dialog/allStencilFonts']
    },
    regularFonts() {
      return this.$store.getters['part_builder_dialog/allRegularFonts']
    },
    isLoaded() {
      return this.$store.state.part_builder_dialog.loaded
    },
    previousUnits() {
      return this.$store.state.part_builder_dialog.previous_units
    },
    units: {
      set(value) {
        this.$store.commit('part_builder_dialog/setUnits', value)
      },
      get() {
        return this.$store.state.part_builder_dialog.units
      }
    },
  },
  methods: {
    unitsAdjustment(param, val) {
      let result = param[val]
      if (param.type == 'range' && param.units == undefined) {
        result = this.units == 'in' ? result : result * 25.4         // Adjust to mm
        result = Math.round((result + Number.EPSILON) * 1000) / 1000 // Round to nearest 0.001
      }
      else if (param.type == 'bends') {
        result = result.map((bend) => {
          bend.offset = this.units == 'in' ? bend.offset : bend.offset * 25.4  // Adjust to mm
          bend.offset = Math.round((new_bend.offset + Number.EPSILON) * 1000) / 1000  // Round to nearest 0.001
          return bend
        })
      }
      return result
    },
    partModel(part, include_dimensions = false) {
      if (part && this.isValidParams(part.params)) {
        let params = map(part.params, (p) => {
          if (p.type == 'range') {
            return parseFloat(p.value)
          }
          else if (p.type == 'font') {
            return this.getFont(p.value)
          }
          else if (p.type == 'units') {
            return this.$store.getters['part_builder_dialog/getUnits']
          }
          else {
            return p.value
          }
        })

        let part_model = new part.model(...params)
        part_model.layer = "model"

        // Add bend lines
        const bends = part.params.find((p) => p.type === 'bends')
        if (bends) {
          addBends(part_model, bends.value)
        }

        // Add dimensions
        if (include_dimensions) {
          let dims = new Dimensions(part_model, this.units, this.dimFont())
          if (part_model.models) {
            part_model.models.dims = dims
          }
          else {
            part_model.models = {
              dims: dims
            }
          }
          part_model.models.dims.layer = "dims"
        }
        return part_model
      }
    },
    getFont(name) {
      return window.fonts[name]
    },
    dimFont() {
      return window.fonts['dimfont']
    },
    isValidParams(params) {
      // Find the first invalid param
      return !find(params, (p) => {
        let result = false
        if (p.type == 'range') {
          let value = parseFloat(p.value)
          result = (isNaN(value) || (value > this.unitsAdjustment(p, 'max') || value < this.unitsAdjustment(p, 'min')))
        }

        return result
      })
    },
  },
}
