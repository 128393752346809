import Vue from 'vue'
import scs from 'shared/scs'

export default {
  namespaced: true,

  state: {
    loading: false,
    data: {
      // [drawing_id]: part
    }
  },

  getters: {},

  actions: {
    async fetch({ commit }, data = {}) {
      commit('setLoading', true)
      try {
        const resp = await scs.getParts(data)
        commit('setParts', resp.data)
      } catch {
        // Don't worry if a part cannot be found. The most likely reason is
        // because a customer is not logged in.
        console.log('Could not find part')
      }
      commit('setLoading', false)
    },
  },

  mutations: {
    setLoading(state, on) {
      state.loading = on
    },

    setPart(state, part) {
      Vue.set(state.data, part.drawing_id, part)
    },

    setParts(state, parts) {
      for (let i = 0; i < parts.length; i++) {
        Vue.set(state.data, parts[i].drawing_id, parts[i])
      }
    }
  }
}
