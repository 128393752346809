export default {
  /**
   * Register all socket events
   */
  mounted() {
    this.registerListeners()
  },

  /**
   * unsubscribe when component unmounting
   */
  beforeDestroy() {
    this.deregisterListeners()
  },

  methods: {
    deregisterListeners() {
      if (this.$options.sockets) {
        Object.keys(this.$options.sockets).forEach(event => {
          this.$vueAbly.emitter.removeListener(event, this)
        })
      }
    },

    registerListeners() {
      if (this.$options.sockets) {
        Object.keys(this.$options.sockets).forEach(event => {
          this.$vueAbly.emitter.addListener(event, this.$options.sockets[event], this)
        })
      }
    },
  }
}
