import { scsFetch } from 'shared/scs/scsFetch'

const generateUrl = (spli) => `/orders/${spli.order_id}/shipped_packages/${spli.shipped_package_id}/shipped_package_line_items/${spli.id || ''}`

export const fetch = async (orderId, shippedPackageId, data = {}) => {
  const resp = await scsFetch(generateUrl({ order_id: orderId, shipped_package_id: shippedPackageId }), 'GET', data)
  return resp
}

export const create = async (shippedPackageLineItem) => {
  const resp = await scsFetch(generateUrl(shippedPackageLineItem), "POST", shippedPackageLineItem)
  return resp.json()
}

export const get = async (orderId, shippedPackageId, id) => {
  const resp = await scsFetch(generateUrl({ order_id: orderId, shipped_package_id: shippedPackageId, id }), "GET")
  return resp.json()
}

export const update = async (shippedPackageLineItem) => {
  const resp = await scsFetch(generateUrl(shippedPackageLineItem), "PUT", shippedPackageLineItem)
  return resp.json()
}

export const destroy = async (shippedPackageLineItem) => {
  const resp = await scsFetch(generateUrl(shippedPackageLineItem), "DELETE")
  return resp.json()
}
