import { getCurrentInstance } from 'vue'

export const useStore = () => {
  // This is a workaround for getting the store.
  // The "correct" way is to use `useStore` from
  // the vue-use-vuex package, but that package
  // does not support version 2.7.X of vue which
  // includes composition support in vue2 without
  // the external package.
  const instance = getCurrentInstance()
  if (!instance) {
    console.error('You must use this function within the "setup()" method')
  }
  return instance.proxy.$store
}
