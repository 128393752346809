import BoltCircle from './BoltCircle'
import { GearModel, CenterHole } from 'shared/components/PartBuilder/parts/utilities/gears_common'

function SpurGear(
  pitch,
  numTeeth,
  addHole,
  addKeyHole,
  holeDiameter,
  keyHoleLength,
  keyHoleWidth,
  addBolt,
  boltCircleDiameter,
  boltDiameter,
  holeCount,
  units
) {
  this.models = {
    gear_group: GearModel(pitch, numTeeth, units)
  }

  if (addHole === "Yes" || addKeyHole === "Yes") {
    this.models.hole_group = CenterHole(holeDiameter / 2, addKeyHole, keyHoleLength, keyHoleWidth)
  }

  if (addBolt === "Yes") {
    this.models.bolts = new BoltCircle(boltCircleDiameter / 2, boltDiameter / 2, holeCount)
  }
}

SpurGear.metaParameters = [
  {
    title: "Diametral Pitch",
    type: "select",
    options: {
      "6 (Large Gears)": 6,
      "12 (Medium Gears)": 12,
      "32 (Small Gears)": 32
    },
    value: 32,
    default: 32,
    group: 'gear_group',
    group_title: 'Gear'
  },

  // The teeth start to get wonky when pitch and num teeth are both too low
  {
    title: "Number of Teeth",
    type: "range",
    units: "none",
    step: 1,
    min: 25,
    max: 120,
    value: 81,
    default: 81,
    tooltip_text: "Total Number of Teeth on Gear (This determines the overall Diameter of the part)",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/spur_gear_numTeethParams2.png",
    group: 'gear_group',
    group_title: 'Gear'
  },

  { title: "Add Center Hole", type: "bool", value: "No", default: "No", control: "no_key_group" },
  { title: "Add Key Seat", type: "bool", value: "Yes", default: "Yes", control: "hole_group" },
  {
    title: "Center Hole Diameter",
    type: "range",
    min: 0.25,
    max: 3,
    value: 0.5,
    default: 0.5,
    tooltip_text: "Diameter of the Hole at the center of the gear",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/spur_gear_centerDiameter2.png",
    group: ['no_key_group', 'hole_group'],
    group_title: 'Center Hole'
  },
  {
    title: "Key Seat Height",
    type: "range",
    min: 0.0635,
    max: 0.375,
    value: 0.064,
    default: 0.064,
    tooltip_text: "Distance from the top of the center hole to the top of the key seat",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/spur_gear_keyHeightParams2.png",
    group: 'hole_group',
    group_title: 'Center Hole'
  },
  {
    title: "Key Seat Width",
    type: "range",
    min: 0.0635,
    max: 0.375,
    value: 0.255,
    default: 0.255,
    tooltip_text: "Width of the key seat",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/spur_gear_keyWidthParams2.png",
    group: 'hole_group',
    group_title: 'Center Hole'
  },

  { title: "Add Bolt Circle", type: "bool", value: "Yes", default: "Yes", control: "bolts" },
  {
    title: "Bolt Circle Diameter",
    type: "range",
    min: 0.25,
    max: 8,
    value: 2,
    default: 2,
    tooltip_text: "Diameter of circle created from the layout of each bolt hole",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/spur_gear_boltDiameterParams2.png",
    group: 'bolts',
    group_title: 'Bolts'
  },
  {
    title: "Bolt Hole Diameter",
    type: "range",
    min: 0.25,
    max: 2,
    value: 0.25,
    default: 0.25,
    tooltip_text: "Diameter of each individual bolt hole",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/spur_gear_boltHoleDiameterParams2.png",
    group: 'bolts',
    group_title: 'Bolts'
  },
  { title: "Bolt Hole Count", type: "range", units: "none", step: 1, min: 1, max: 24, value: 12, default: 12, group: 'bolts', group_title: 'Bolts' },
  { type: "units", hidden: true }
]

export default SpurGear
