import makerjs from 'makerjs'
import BoltCircle from './BoltCircle'
import { adjustMetric } from 'shared/components/PartBuilder/parts/utilities/common'

// Definition of a circle object
function Circle(a, b, r) {
  this.a = a
  this.b = b
  this.r = r
}

function solveY(c, x) {
  var y1 = c.a + Math.sqrt(Math.pow(c.r, 2) - Math.pow(x - c.b, 2))
  return y1
}

// Create Gear Base
function gearModel(N, OD, ST, combineTeeth, units) {
  // Outside circle origin [0,0], radius OD/2
  let temp

  if (units === 'mm') {
    temp = OD / 25.4
  } else {
    temp = OD
  }

  if (temp < N / 5 && N / temp <= 15) {
    OD *= 5
  } else if (temp < N / 5) {
    N = Math.round(temp * 5)
  }

  const P = (N + 1.6) / OD

  const dedendum = 1.250 / P

  // Pitch circle orign [0, 0] radius PR
  const D = N / P
  // Root circle origin [0,0] radius RD/2
  const RD = D - (2 * dedendum)

  // Reference points and intersections
  const c3 = new Circle(0, 0, OD / 2)

  // Putting the tooth together
  const W = adjustMetric(units, 0.118)
  const c4 = new Circle(0, 0, RD / 2)
  const A = 360 / N
  const C = Math.PI * RD
  const spacing = C / N
  const W2 = (W + ((ST - 1) * spacing) / 2) + (spacing / 4)
  const yCoords = solveY(c4, W + (spacing / 4))
  const yCoords1 = solveY(c4, W2)
  const yCoords2 = solveY(c3, W)
  const yCoords3 = solveY(c3, W + (((ST - 1) * spacing) / 2))
  const right = [[W, yCoords2], [W, yCoords], [W + (spacing / 4), yCoords]]
  const left = [[-W, yCoords2], [-W, yCoords], [-(W + (spacing / 4)), yCoords]]

  // Defining a single tooth
  const Tooth = {
    paths: {
      Top: new makerjs.paths.Arc([-W, yCoords2], [W, yCoords2], OD / 2, false, true),
      Bottom: new makerjs.paths.Line([W + (spacing / 4), yCoords], [-W - (spacing / 4), yCoords])
    },
    models: {
      Curve1: new makerjs.models.BezierCurve(right),
      Curve2: new makerjs.models.BezierCurve(left)
    }
  }

  const bigToothOrigin1 = [-(W + (((ST - 1) * spacing) / 2)), yCoords3]
  const bigToothOrigin2 = [W + (((ST - 1) * spacing) / 2), yCoords3]
  const bigToothCurveEnd1 = [W + (((ST - 1) * spacing) / 2), yCoords1]
  const bigToothCurveEnd2 = [-(W + (((ST - 1) * spacing) / 2)), yCoords1]

  const bigTooth = {
    paths: {
      Top: new makerjs.paths.Arc(bigToothOrigin1, bigToothOrigin2, OD / 2, false, true),
      Bottom: new makerjs.paths.Line([W2, yCoords1], [-W2, yCoords1])
    },
    models: {
      Curve1: new makerjs.models.BezierCurve(bigToothOrigin2, bigToothCurveEnd1, [W2, yCoords1]),
      Curve2: new makerjs.models.BezierCurve(bigToothOrigin1, bigToothCurveEnd2, [-W2, yCoords1])
    }
  }

  const Teeth = {
    models: {}
  }

  // Rotate the teeth around the center and add them to the a teeth model
  for (var i = 0; i <= N - ST; i++) {
    if (i < N - ST) {
      const itooth = makerjs.cloneObject(Tooth)
      Teeth.models[`Tooth${i}`] = makerjs.model.rotate(itooth, i * A, [0, 0])
    }

    else if (i === (N - ST) && combineTeeth === "Yes" && ST > 0) {
      const itoothLarge = makerjs.cloneObject(bigTooth)
      Teeth.models[`Tooth${i}`] = makerjs.model.rotate(itoothLarge, (((N - 1) + (N - ST)) / 2) * A, [0, 0])
    }
  }

  const gearBody = new makerjs.models.Ring(RD / 2 + (adjustMetric(units, N / (N * 1000))))

  // The gear body needs to extend into the tooth a little
  const { combineUnion: CombineUnion } = makerjs.model
  const finalGear = new CombineUnion(Teeth, gearBody)

  if (temp < N / 5 && N / temp <= 15) {
    return makerjs.model.scale(finalGear, 1 / 5)
  }
  return finalGear
}

function TriggerWheel(
  Diameter,
  NumTeeth,
  SubTeeth,
  combineTeeth,
  addCenterHole,
  centerHoleDiameter,
  addBolt,
  boltCircleDiameter,
  boltDiameter,
  holeCount,
  units
) {
  this.models = {
    gear_group: gearModel(NumTeeth, Diameter, SubTeeth, combineTeeth, units)
  }

  this.paths = {}

  if (addCenterHole === "Yes") {
    this.paths.hole_group = new makerjs.paths.Circle([0, 0], centerHoleDiameter / 2)
  }
  if (addBolt === "Yes") {
    this.models.bolts = new BoltCircle(boltCircleDiameter / 2, boltDiameter / 2, holeCount)
  }
}

TriggerWheel.metaParameters = [
  {
    title: "Diameter",
    type: "range",
    min: 1.8,
    max: 15,
    value: 8.25,
    default: 8.25,
    tooltip_text: "Diameter of the Wheel",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/trigger_wheel_diameter.png",
    group: 'gear_group',
    group_title: 'Gear'
  },
  {
    title: "Number of Teeth",
    type: "range",
    units: "none",
    step: 1,
    min: 10,
    max: 60,
    value: 36,
    default: 36,
    tooltip_text: "Total number of teeth before taking away the missing teeth.",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/trigger_wheel_numTeeth.png",
    group: 'gear_group',
    group_title: 'Gear'
  },
  {
    title: "Number of Missing Teeth",
    type: "range",
    units: "none",
    step: 1,
    min: 1,
    max: 5,
    value: 1,
    default: 1,
    tooltip_text: "Number of Teeth to take away from the wheel",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/trigger_wheel_missingTeeth.png",
    group: 'gear_group',
    group_title: 'Gear'
  },
  {
    title: "Combine Missing Teeth",
    type: "bool",
    value: "No",
    default: "No",
    tooltip_text: "Checked will combine missing teeth into one large tooth, unchecked will remain missing from gear altogether.",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/trigger_wheel_combineTeeth.png",
    group: 'gear_group',
    group_title: 'Gear'
  },

  { title: "Add Center Hole", type: "bool", value: "Yes", default: "Yes", control: 'hole_group' },
  {
    title: "Center Hole Diameter",
    type: "range",
    min: 0.25,
    max: 3,
    value: 0.5,
    default: 0.5,
    tooltip_text: "Diameter of the hole",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/trigger_wheel_centerDiameter.png",
    group: 'hole_group',
    group_title: 'Center Hole'
  },

  { title: "Add Bolt Circle", type: "bool", value: "Yes", default: "Yes", control: 'bolts' },
  {
    title: "Bolt Circle Diameter",
    type: "range",
    min: 0.25,
    max: 8,
    value: 2,
    default: 2,
    tooltip_text: "Diameter of circle created from the layout of each bolt hole",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/trigger_wheel_boltCircleDiameter.png",
    group: 'bolts',
    group_title: 'Holes'
  },
  {
    title: "Bolt Hole Diameter",
    type: "range",
    min: 0.25,
    max: 2,
    value: 0.25,
    default: 0.25,
    tooltip_text: "Diameter of each individual bolt hole",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/trigger_wheel_boltHoleDiameter.png",
    group: 'bolts',
    group_title: 'Holes'
  },
  { title: "Bolt Hole Count", type: "range", units: "none", step: 1, min: 1, max: 24, value: 4, default: 4, group: 'bolts', group_title: 'Holes' },

  { type: "units", hidden: true }
]

export default TriggerWheel
