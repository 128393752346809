import Vue from "vue"

import { bendData } from 'shared/util/bend_math'

export default {
  data() {
    return {
      bendErrorData: {}
    }
  },

  computed: {
    engineBendErrors() {
      return this.$store.state.bending.bendErrors
    },

    bendErrors() {
      return Object
        .keys(this.bendErrorData)
        .map(id => this.bendErrorData[id])
        .sort(a => ((a?.code || '').includes('hard') ? -1 : 1))
    },
    maxBendWidth() {
      let maxWidth = 0
      if (this.bendingOperation && this.bendingOperation?.configuration?.max_bend_width) {
        maxWidth = Number(this.bendingOperation.configuration.max_bend_width)
        if (this.drawing.units === "mm") maxWidth *= 25.4
      }

      return maxWidth
    },
    bendingOperation() {
      return this.material?.operations?.find(o => o.operation === 'bending')
    },
    material() {
      return this.item.material || {}
    },
  },

  methods: {
    onBendErrors(errors) {
      errors.forEach((error) => {
        Vue.set(this.bendErrorData, `${error.id}_${error.code}`, error)
      })
    },
    reportBendErrors() {
      let errors = []
      // Report an error for bend lines that are too long
      if (this.maxBendWidth > 0.0) {
        const widthErrors = this.bendLines
          .filter(bendLine => bendLine.length > this.maxBendWidth)
          .map(bendLine => ({ id: bendLine.id, code: 'hard_bend_too_wide', disabled: true }))
        errors = errors.concat(widthErrors)
      }
      // Get bend data for this material/units
      const data = bendData(this.material, this.drawing.units)
      // If we have a value for bottomDie, use it to check for intersections
      if (data.bottomDie > 0) {
        this.bendLines.forEach(bendLine => {
          // Get intersections for this bend line in this drawing, given the bottomDie size
          const intersections = this.$store.getters['drawing_models/bendIntersections'](this.drawing.id, bendLine.id, data.bottomDie)
          // If we found any intersections between the die lines and internal geometry,
          // report an error. This bend will deform something.
          if (intersections.length > 0) {
            errors.push({ id: bendLine.id, code: 'soft_deformed_geometry', disabled: true })
          }
          const minDieContact = this.$store.getters['drawing_models/minDieContact'](this.drawing.id, bendLine.id, data.minFlangeLength)
          if (!data.minFlangeLength) {
            // Do nothing
          }
          // When bend contact is 0, the flange length is clearly too short to bend. It won't even
          // span the full die width at any point along the part's edge.
          else if (minDieContact <= data.minFlangeContact) {
            errors.push({ id: bendLine.id, code: 'hard_min_flange_contact', disabled: true })
          }
          // When bend contact to die contact is below XX%, return a hard error. There is likely
          // not enough contact with the die to bend without severely deforming the part.
          else if (minDieContact < data.minDieContactHard) {
            errors.push({ id: bendLine.id, code: 'soft_insufficient_contact', disabled: true })
          }
          // When bend contact to die contact is > XX% and < YY%, return a soft error. There may
          // be some deforming, but it should work ok.
          else if (minDieContact < data.minDieContactSoft) {
            errors.push({ id: bendLine.id, code: 'soft_insufficient_contact', disabled: true })
          }
        })
      }
      // Report bend errors if we found any
      if (errors.length > 0) this.onBendErrors(errors)

      return errors
    },
  },
  watch: {
    engineBendErrors() {
      this.onBendErrors(this.engineBendErrors)
    }
  }
}
