var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-main ml-6 pr-4",class:_vm.$mq},[_c('div',{staticClass:"is-flex is-justify-content-flex-end mt-4"},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('View product setup tips'),expression:"'View product setup tips'"}],staticClass:"is-icon is-size-5 mr-2",attrs:{"href":"https://sendcutsend.com/marketplace/sellers/tips/","target":"_blank"}},[_c('scs-icon',{attrs:{"icon":"scs-icon-lightbulb"}})],1),_vm._v(" "),_c('button',{staticClass:"button is-small is-sale is-outlined",on:{"click":function($event){return _vm.$emit('preview')}}},[_vm._v("Preview")])]),_vm._v(" "),_c('div',{staticClass:"updated__form product-section mt-4"},[(_vm.baseErrors)?_c('div',{staticClass:"notification is-danger"},[_vm._v(_vm._s(_vm.baseErrors))]):_vm._e(),_vm._v(" "),_c('b-field',{class:_vm.fieldClass('title'),attrs:{"type":_vm.errorType('title'),"message":_vm.errorMessage('title')},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n        Title"),_c('span',{staticClass:"is-danger super"},[_vm._v("*")])]},proxy:true}])},[_vm._v(" "),_c('b-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"title"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('b-field',{staticClass:"mt-2 mb-0",class:{filled: !!_vm.fieldClass('description'), ..._vm.errorType('description')},attrs:{"type":_vm.errorType('description'),"message":_vm.errorMessage('description')}},[_c('Editor',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"control",attrs:{"name":"description","init":{
          height: 500,
          menubar: 'edit insert view format table tools help',
          promotion: false,
          elementpath: false,
          plugins: [
            'lists', 'link', 'image', 'emoticons', 'charmap', 'table'
          ],
          toolbar: 'undo redo | bold italic underline |  styles fontsize | link table | alignment | bullist numlist | image emoticons charmap | indent outdent | strikethrough removeformat',
          toolbar_mode: 'floating',
          link_target_list: [{ title: 'New window', value: '_blank' }],
          setup: (editor) => {
            /* example, adding a group toolbar button */
            editor.ui.registry.addGroupToolbarButton('alignment', {
              icon: 'accordion',
              tooltip: 'Alignment',
              items: 'alignleft aligncenter alignright | alignjustify'
            })
          },
        }},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_vm._v(" "),_c('b-field',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.shortDescriptionTooltip),expression:"shortDescriptionTooltip"}],staticClass:"mt-3 mb-0",class:_vm.fieldClass('shortDescription'),attrs:{"type":_vm.errorType('description excerpt', 'shortDescription'),"message":_vm.errorMessage('description excerpt', 'shortDescription')},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n        Short description"),_c('span',{staticClass:"is-danger super"},[_vm._v("*")])]},proxy:true}])},[_vm._v(" "),_c('b-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"short description"},model:{value:(_vm.shortDescription),callback:function ($$v) {_vm.shortDescription=$$v},expression:"shortDescription"}})],1),_vm._v(" "),_c('span',{staticClass:"is-italic is-size-7"},[_vm._v("Appears on product tiles, search results, and when shared socially.")]),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_c('AttachmentGroup',{staticClass:"is-6 pl-0",attrs:{"attachments":_vm.fileAttachments,"resourceType":"customer_product","resourceId":0,"deferred":true,"label":"Add PDF","icon":"scs-icon-plus-circle"},on:{"refresh":_vm.addFileAttachment,"delete":_vm.deleteAttachment}})],1),_vm._v(" "),_c('ProductMedia',{staticClass:"product-section"}),_vm._v(" "),_c('ProductPricingDetails',{staticClass:"product-section"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-3"},[_c('span',{staticClass:"is-size-6 has-text-weight-semibold"},[_vm._v("Long description")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_c('span',{staticClass:"is-size-6 has-text-weight-semibold"},[_vm._v("Downloadable PDFs")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"is-size-7"},[_vm._v("Provide assembly instructions, parts lists, and more.  Customers will receive\n        download links in their confirmation email once they purchase your product.\n      ")])])
}]

export { render, staticRenderFns }