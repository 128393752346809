function isIE() {
  return /(msie|trident)/i.test(window.navigator.userAgent)
}

function findStrokeWidth(svg) {
  const scaleWidth = 600
  const scaleHeight = 340
  const viewbox = svg.match(/viewbox="([0-9\-. ]*)/i)

  if (viewbox) {
    const dimensions = viewbox[1].split(' ')
    const width = parseFloat(dimensions[2])
    const height = parseFloat(dimensions[3])
    const scale = 1.2
    return Math.max((width / scaleWidth) * scale, (height / scaleHeight) * scale).toFixed(4)
  }

  return 0.5
}

function defineBold(strokeWidth) {
  const existing = document.getElementById('svg-bold')

  if (!existing) {
    const bendWidth = strokeWidth * 1.5
    const node = document.createElement('style')
    const css = `
      .drawing-image-container:not(.dynamic-scale):not(.black) svg .bend:not(.bold) {
        stroke-width: ${strokeWidth * 1.7} !important;
      }
      .drawing-image-container svg g .bold {
        stroke-width: ${strokeWidth * 6} !important;
      }
      .drawing-image-container svg g .bend.clickable:hover + .bend:not(.disabled) {
        stroke-width: ${bendWidth * 3} !important;
      }
      .drawing-image-container svg g .bend.clickable { stroke-width: ${bendWidth * 15} !important; }
    `

    node.id = "svg-bold"
    node.type = 'text/css'
    node.appendChild(document.createTextNode(css))
    document.head.appendChild(node)
  }
}

function fixIE(svg) {
  const width = findStrokeWidth(svg)
  defineBold(width)

  return svg
    .replace(/stroke-width:0.5/g, `stroke-width: ${width}`)
    .replace(/stroke-dasharray:\d/g, `stroke-dasharray:${8 * width}`)
    .replace(/vector-effect="non-scaling-stroke"/g, '')
}

function svgHTML(html) {
  let svg = html

  if (isIE()) {
    svg = fixIE(svg)
  }

  return svg
}

/*
 * Exported functions
 */

export async function fetchSvg(url) {
  const response = await fetch(url)
  return svgHTML(await response.text())
}

export function getHoles(svgContent) {
  const svgContainer = document.createElement('div')
  svgContainer.innerHTML = svgContent

  const elements = Array.from(svgContainer.querySelectorAll(".holes .hole"))

  return elements.map(el => {
    const radius = parseFloat(el.getAttribute('radius'))
    return { id: el.id, radius, diameter: radius * 2 }
  })
}

export function addBendsByColor(svgContent) {
  const svgContainer = document.createElement('div')
  svgContainer.innerHTML = svgContent

  if (svgContainer.querySelectorAll('.bends').length === 0) {
    const bends = Array.from(svgContainer.querySelectorAll('.bend'))
    const bendLayer = document.createElementNS("http://www.w3.org/2000/svg", 'g')
    bendLayer.setAttribute('class', 'bends')
    bendLayer.setAttribute('transform', 'scale(1,-1)')

    for (let i = 0; i < bends.length; i++) {
      const bend = bends[i]
      const className = bend.getAttribute('class') || ''
      const match = className.match(/c[0-9a-fA-F]{6}/)
      const id = `${(match || [])[0]}-${i}`
      if (id) bend.id = id
      bend.parentNode.removeChild(bend)
      bendLayer.appendChild(bend)
    }
    svgContainer.querySelector('svg')?.appendChild(bendLayer)
    return svgContainer.innerHTML
  }

  return svgContent
}

export function assignEmptyBendIds(svgContent) {
  const svgContainer = document.createElement('div')
  svgContainer.innerHTML = svgContent

  const elements = svgContainer.querySelectorAll('.bends .bend')
  for (let i = 0; i < elements.length; i++) {
    elements[i].id = elements[i].id ? elements[i].id : `c${i + 1}`
  }

  return svgContainer.innerHTML
}

export function getBends(svgContent) {
  const svgContainer = document.createElement('div')
  svgContainer.innerHTML = svgContent

  const elements = svgContainer.querySelectorAll(".bends .bend")

  return Array.from(elements).map((el, idx) => {
    const className = el.getAttribute('class') || ''
    const match = className.match(/c[0-9a-fA-F]{6}/)
    const color = (match || [])[0]
    const length = parseFloat(el.getAttribute('l') || '0.0')

    return {
      id: el.id,
      index: idx + 1,
      color,
      length
    }
  })
}
