export default {
  data() {
    return {
      currentPage: 1
    }
  },

  computed: {
    totalCount() {
      return this.$store.state[this.type].totalCount
    },

    totalPages() {
      return this.$store.state[this.type].totalPages
    },

    perPage() {
      return this.$store.state[this.type].perPage
    },

    lastPage() {
      return this.$store.state[this.type].lastPage
    },

    showPagination() {
      return this.totalCount > this.perPage
    }
  }
}
