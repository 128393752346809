export default {
    data() {
      return {
        iframeLoaded: false,
        iframeErrorSrc: []
      }
    },

    methods: {
      hasIframeError(src) {
        this.iframeErrorSrc.includes(src)
      },
      handleIframeErrors(error) {
        let msg = error.msg.toLowerCase()
        let testCases = ['permission denied', 'referenceerror', 'typeerror', 'syntaxerror']
        let hardError = testCases.some(errorStr => msg.includes(errorStr))
        if (hardError) this.iframeErrorSrc(error.src)
      },
      receivePostMessage(e) {
        if (e.origin !== "https://sendcutsend.com") {
          return
        } else {
          if(e.data.type == 'error') {
            this.handleIframeErrors(e.data)
          } else {
            this.iframeLoaded = true
          }
        }
      },

    }
  }
