export default {
  data(){
    return {
      serverErrors: ''
    }
  },
  computed: {
    baseErrors() {
      return this.serverFieldErrors && this.serverFieldErrors['base'] ? this.serverFieldErrors['base'] : ''
    },
    isValid() {
      // Use errors.count here because it takes into account the instance of the validator that created
      // the errors.  This corrects an issue where the errors from the parent component validator were
      // transfered to the child component errorbag and being counted as validation errors in that child.
      return this.errors.count() === 0
    },
    serverFieldErrors() {
      try {
        return JSON.parse(this.serverErrors)
      } catch(e) {
        return this.serverErrors ? { 'base': this.serverErrors } : { }
      }
    }
  },
  methods: {
    errorType(field, server_field = '') {
      let check = (f) => {
        return this.errors.has(f) || this.serverFieldErrors.hasOwnProperty(f)
      }
      return {'is-danger': check(field) || check(server_field)}
    },
    errorMessage(field, server_field = '') {
      // There's some times where the server field name doesn't match our client field name (e.g. Login vs Username).
      // In this case we're looking for a server field, then replacing the error text from the server with our client field name.
      let message = (f, a = '') => {
        if (this.serverFieldErrors.hasOwnProperty(f)) {
          let alias_field = a.charAt(0).toUpperCase() + a.slice(1)
          let field = f.charAt(0).toUpperCase() + f.slice(1)
          return this.serverFieldErrors[f].replace(field, alias_field)
        }
        return ''
      }

      return message(field, field) || message(server_field, field) || this.errors.first(field)
    },

  }
}
