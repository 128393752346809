import makerjs from 'makerjs'
import BoltCircle from './BoltCircle'

function DoubleSquareFlange(
  sideLength,
  cornerRadius,
  innerDiameter,
  innerBoltDiameter,
  innerBoltCircleDiameter,
  innerBoltCount,
  outerBoltDiameter,
  outerBoltCircleDiameter,
  outerBoltCount,
  outerBoltAngle
) {
  this.paths = {
    circle: new makerjs.paths.Circle([0, 0], innerDiameter / 2)
  }

  this.models = {
    square_group: makerjs.model.center(new makerjs.models.RoundRectangle(sideLength, sideLength, cornerRadius)),
    outer_bolt: new BoltCircle(outerBoltDiameter / 2, outerBoltCircleDiameter / 2, outerBoltCount, outerBoltAngle),
    inner_bolt: new BoltCircle(innerBoltDiameter / 2, innerBoltCircleDiameter / 2, innerBoltCount, 0, innerBoltCount)
  }
}

DoubleSquareFlange.metaParameters = [
  {
    title: "Side Length",
    type: "range",
    min: 0,
    max: 24,
    value: 6,
    default: 6,
    tooltip_text: "Overall part height and length",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/double_square_flange_sideLength.png",
    group: 'square_group',
    group_title: 'Square'
  },
  {
    title: "Corner Radius",
    type: "range",
    min: 0,
    max: 3,
    value: 0.5,
    default: 0.5,
    tooltip_text: "Radius of the outside corners",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/double_square_flange_cornerRadius.png",
    group: 'square_group',
    group_title: 'Square'
  },
  {
    title: "Center Hole Diameter",
    type: "range",
    min: 0,
    max: 24,
    value: 2,
    default: 2,
    tooltip_text: "Diameter of the Center Hole",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/double_square_flange_centerDiameter.png",
    group: 'circle',
    group_title: 'Center Hole'
  },
  {
    title: "Inner Bolt Diameter",
    type: "range",
    min: 0,
    max: 24,
    value: 3,
    default: 3,
    tooltip_text: "Diameter of the Entire Inner Bolt Circle",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/double_square_flange_innerBoltDiameter.png",
    group: 'inner_bolt',
    group_title: 'Inner Holes'
  },
  {
    title: "Inner Hole Diameter",
    type: "range",
    min: 0,
    max: 6,
    value: 0.5,
    default: 0.5,
    tooltip_text: "Diameter of Each Individual Hole of Inner Bolt Circle",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/double_square_flange_innerHoleDiameter.png",
    group: 'inner_bolt',
    group_title: 'Inner Holes'
  },
  {
    title: "Inner Hole Count",
    type: "range",
    units: "none",
    step: 1,
    min: 1,
    max: 24,
    value: 8,
    default: 8,
    tooltip_text: "Number of Holes in Inner Bolt Circle",
    group: 'inner_bolt',
    group_title: 'Inner Holes'
  },
  {
    title: "Outer Bolt Diameter",
    type: "range",
    min: 0,
    max: 24,
    value: 5,
    default: 5,
    tooltip_text: "Diameter of the Entire Outer Bolt Circle",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/double_square_flange_outerBoltDiameter.png",
    group: 'outer_bolt',
    group_title: 'Outer Holes'
  },
  {
    title: "Outer Hole Diameter",
    type: "range",
    min: 0,
    max: 6,
    value: 0.5,
    default: 0.5,
    tooltip_text: "Diameter of Each Individual Hole of Outer Bolt Circle",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/double_square_flange_outerHoleDiameter.png",
    group: 'outer_bolt',
    group_title: 'Outer Holes'
  },
  {
    title: "Outer Hole Count",
    type: "range",
    units: "none",
    step: 1,
    min: 1,
    max: 24,
    value: 4,
    default: 4,
    tooltip_text: "Number of Holes in Outer Bolt Circle",
    group: 'outer_bolt',
    group_title: 'Outer Holes'
  },
  {
    title: "First Bolt Angle",
    type: "range",
    units: "none",
    min: 0,
    max: 180,
    value: 45,
    default: 45,
    tooltip_text: "Angle of First Outer Bolt Hole in Relation to Inner Bolt Hole on the Horizontal Axis",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/double_square_flange_angle.png",
    group: 'outer_bolt',
    group_title: 'Outer Holes'
  },
  { type: "bends", value: [], default: [], hidden: true, group: ['bend0', 'bend1', 'bend2', 'bend3'] }
]

export default DoubleSquareFlange
