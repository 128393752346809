import makerjs from 'makerjs'
import BoltCircle from './BoltCircle'

function RoundFlange(flangeDiameter, innerHoleDiameter, holeCircleDiameter, holeDiameter, holeCount) {
  this.paths = {
    flange: new makerjs.paths.Circle([0, 0], flangeDiameter / 2),
    inner: new makerjs.paths.Circle([0, 0], innerHoleDiameter / 2)
  }
  this.models = {
    bolts: new BoltCircle(holeCircleDiameter / 2, holeDiameter / 2, holeCount)
  }
}

RoundFlange.metaParameters = [
  {
    title: "Flange Diameter",
    type: "range",
    min: 0,
    max: 24,
    value: 6,
    default: 6,
    tooltip_text: "Overall diameter of the flange",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/round_flange_dia.png",
    group: 'flange',
    group_title: 'Plate'
  },
  {
    title: "Inner Hole Diameter",
    type: "range",
    min: 0,
    max: 24,
    value: 3,
    default: 3,
    tooltip_text: "Diameter of the center hole",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/round_flange_innerdia.png",
    group: 'inner',
    group_title: 'Inner Hole'
  },
  {
    title: "Hole Circle Diameter",
    type: "range",
    min: 0,
    max: 24,
    value: 4.5,
    default: 4.5,
    tooltip_text: "Diameter of the path of the bolt holes",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/round_flange_pathdia.gif",
    group: 'bolts',
    group_title: 'Bolts'
  },
  {
    title: "Hole Diameter",
    type: "range",
    min: 0,
    max: 6,
    value: 0.5,
    default: 0.5,
    tooltip_text: "Diameter of the bolt holes",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/round_flange_ohole_dia.png",
    group: 'bolts',
    group_title: 'Bolts'
  },
  {
    title: "Hole Count",
    type: "range",
    units: "none",
    step: 1,
    min: 1,
    max: 24,
    value: 12,
    default: 12,
    tooltip_text: "Number of evenly spaced bolt holes in the flange",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/round_flange_holes.gif",
    group: 'bolts',
    group_title: 'Bolts'
  }
]

export default RoundFlange
