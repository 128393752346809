import makerjs from 'makerjs'

function MountainTwo(height) {
  const paths = [
    "M12.8023,7.0336 A0.0119062,0.0119062 0 0 0 12.7879,7.0154 L11.2103,7.7677 A0.0116817,0.0116817 0 0 0 11.2041,7.7747 L10.5279,9.8488 A0.0117916,0.0117916 0 0 0 10.5483,9.8599 Z",
    "M8.5442,9.7946 A0.0118602,0.0118602 0 0 1 8.5444,9.8009 L8.202,11.2793 A0.0118369,0.0118369 0 0 0 8.2228,11.2894 L10.3499,8.6224 A0.0132418,0.0132418 0 0 0 10.3512,8.6205 A0.00966133,0.00966133 0 0 0 10.3519,8.6187 L10.87,7.0311 A0.0118625,0.0118625 0 0 0 10.8513,7.0182 L8.3148,9.0522 A0.0117055,0.0117055 0 0 0 8.3108,9.0651 Z",
    "M5.8932,9.8488 A0.0118653,0.0118653 0 0 0 5.9138,9.8599 L8.1727,7.0275 A0.011856,0.011856 0 0 0 8.1591,7.009 L6.6318,7.5981 A0.011715,0.011715 0 0 0 6.6248,7.6055 Z",
    "M9.9239,9.8403 A0.0119118,0.0119118 0 0 0 9.9053,9.8403 L8.1994,11.9795 A0.0118886,0.0118886 0 0 1 8.1808,11.9795 L6.4748,9.8404 A0.0117912,0.0117912 0 0 0 6.4563,9.8403 L5.8822,10.5604 A0.0119578,0.0119578 0 0 1 5.8636,10.5604 L2.7055,6.6004 A0.0118899,0.0118899 0 0 1 2.7148,6.5811 L13.6654,6.5811 A0.0118384,0.0118384 0 0 1 13.6747,6.6004 L10.5167,10.5604 A0.011989,0.011989 0 0 1 10.4981,10.5604 Z"
  ]

  const mountainModels = { }

  paths.forEach((p, i) => {
    mountainModels[i] = makerjs.model.mirror(makerjs.importer.fromSVGPathData(p), true, false)
  })

  class MountainTwoGroup {
    constructor(models) {
      this.models = models
    }
  }

  this.models = {
    mountain_group: new MountainTwoGroup(mountainModels)
  }

  // Apply a scaling factor
  const m = makerjs.measure.modelExtents(this)
  makerjs.model.scale(this, height / m.height)
  makerjs.model.rotate(this, 180)
}

MountainTwo.metaParameters = [
  { title: "Height", type: "range", min: 3, max: 24, value: 5, default: 5, group: 'mountain_group', group_title: 'Plate' },
]

export default MountainTwo
