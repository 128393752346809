import makerjs from 'makerjs'
import { SpokedWheel } from './utilities/gears_common'

function SpokedDisc(outerRadius, innerRadius, ringWidth, count, spokeWidth, flareWidth, innerFillet, outerFillet) {
  const radii = {
    ring_outer: outerRadius + ringWidth,
    ring_inner: innerRadius - ringWidth
  }

  this.paths = {}

  Object.keys(radii).forEach(id => {
    const r = radii[id]
    if (r !== undefined && r > 0) {
      this.paths[id] = new makerjs.paths.Circle(makerjs.point.zero(), r)
    }
  })

  this.models = {
    spokes_group: new SpokedWheel(outerRadius, innerRadius, count, spokeWidth, flareWidth, innerFillet, outerFillet)
  }
}

SpokedDisc.metaParameters = [
  {
    title: "Outer Radius",
    type: "range",
    min: 0,
    max: 30,
    value: 10,
    default: 10,
    tooltip_text: "Outside radius of the wheel",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/spoke_radius.png",
    group: 'ring_outer',
    group_title: 'Disc'
  },
  {
    title: "Inner Radius",
    type: "range",
    min: 0,
    max: 30,
    value: 5,
    default: 5,
    tooltip_text: "Radius of the inner circle",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/spoke_innerradius.png",
    group: 'ring_inner',
    group_title: 'Inner Hole'
  },
  {
    title: "Ring Width",
    type: "range",
    min: 0,
    max: 10,
    value: 2,
    default: 2,
    tooltip_text: "Radius of the inner circle",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/spoke_ringwidth.png",
    group: 'spokes_group',
    group_title: 'Spokes'
  },
  {
    title: "Spoke Count",
    type: "range",
    units: "none",
    step: 1,
    min: 0,
    max: 20,
    value: 5,
    default: 5,
    tooltip_text: "Number of spokes in wheel",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/spoke_spokecount.gif",
    group: 'spokes_group',
    group_title: 'Spokes'
  },
  {
    title: "Spoke Width",
    type: "range",
    min: 0,
    max: 20,
    value: 4,
    default: 4,
    tooltip_text: "Width of the spokes at the base before fillets and flare are added",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/spoke_spokewidth.png",
    group: 'spokes_group',
    group_title: 'Spokes'
  },
  {
    title: "Flare Width",
    type: "range",
    min: 0,
    max: 20,
    value: 3,
    default: 3,
    tooltip_text: "",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/spoke_flarewidth.png",
    group: 'spokes_group',
    group_title: 'Spokes'
  },
  {
    title: "Inner fillet",
    type: "range",
    min: 0,
    max: 6,
    value: 1,
    default: 1,
    tooltip_text: "",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/spoke_innerfillet.png",
    group: 'spokes_group',
    group_title: 'Spokes'
  },
  {
    title: "Outer fillet",
    type: "range",
    min: 0,
    max: 6,
    value: 1,
    default: 1,
    tooltip_text: "",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/spoke_outerfillet.png",
    group: 'spokes_group',
    group_title: 'Spokes'
  }
]

export default SpokedDisc
