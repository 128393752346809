import Vue from 'vue'
import capitalize from 'lodash/capitalize'
import each from 'lodash/each'
import find from 'lodash/find'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import uniqBy from 'lodash/uniqBy'

import { firstBy } from 'thenby'

const resetOperations = (state) => {
  state.bends = undefined
  state.bendConfig = undefined
  state.tapConfig = undefined
  state.cskConfig = undefined
  state.dimpleConfig = undefined
  state.hardwareConfig = undefined
  state.anodizingConfig = undefined
  state.platingConfig = undefined
  state.powderConfig = undefined
  state.operations.anodizing = false
  state.operations.bending = false
  state.operations.countersinking = false
  state.operations.dimple_forming = false
  state.operations.deburring = true
  state.operations.etching = false
  state.operations.hardware = false
  state.operations.plating = false
  state.operations.powder = false
  state.operations.tapping = false
  state.operations.tumbling = false
}

const buildBendConfig = (state) => ({
    ...state.bendConfig,
    agreed_to_deformation: !isEmpty(state.agreedToBendDeformation)
  })

export default {
  namespaced: true,
  state: {
    id: null,
    loading: false,
    showing: false,
    currentMode: 'tile',
    currentStep: 'material-group',
    drawings: [], // Which drawings are being quoted
    quantity: 1,
    discountList: [],
    state: 'quote',
    selectedSku: '',

    shownPanel: '',

    attachments: [],
    materialTitle: "Select your Material",
    allOperationsCost: 0,

    bends: undefined,
    bendConfig: undefined,
    tapConfig: undefined,
    cskConfig: undefined,
    dimpleConfig: undefined,
    hardwareConfig: undefined,
    anodizingConfig: undefined,
    platingConfig: undefined,
    powderConfig: undefined,
    operations: {
      anodizing: false,
      bending: false,
      countersinking: false,
      dimple_forming: false,
      deburring: true,
      etching: false,
      hardware: false,
      plating: false,
      powder: false,
      tapping: false,
      tumbling: false,
    },

    agreedToBendDeformation: {},

    tile: {
      loading: false,
      containerHeight: 0,

      selectedGroup: '',
      selectedCategory: '',
      selectedType: '',

      // This is used for radio button selection of the quantity. We do this
      // because "Custom" is an option.
      selectedQuantity: 'Custom',
    }
  },

  getters: {
    canShowTileListSelector(state) {
      return state.currentMode === 'list' || state.currentStep === 'material-group'
    },

    drawings(state, _, rootState) {
      return state.drawings.map(id => rootState.drawings.data[id])
    },

    item(state, getters) {
      const quote = getters.selectedQuote || {}

      const item = {
        attachments: state.attachments,
        quantity: state.quantity,
        operations: getters.enabledOperations,
        material_id: quote?.material?.id,
        bends: state.operations.bending ? state.bends : [],
        bend_config: buildBendConfig(state),
        tap_config: state.operations.tapping ? state.tapConfig : [],
        csk_config: state.operations.countersinking ? state.cskConfig : [],
        dimple_config: state.operations.dimple_forming ? state.dimpleConfig : [],
        hardware_config: state.operations.hardware ? state.hardwareConfig : [],
        anodizing_config: state.operations.anodizing ? state.anodizingConfig : undefined,
        plating_config: state.operations.plating ? state.platingConfig : undefined,
        powder_config: state.operations.powder ? state.powderConfig : undefined,
      }

      if (state.drawings.length > 1) {
        item.drawing_ids = state.drawings
      } else {
        [item.drawing_id] = state.drawings
      }

      return item
    },

    materialOperations: () => (material) => {
      // This is a list of all available operations regardless of whether they are public.
      // This component relies on every operation that can be on a line item being exposed
      // in this list.
      const ALL_OPERATIONS = [
        {
          operation: 'bending',
          learn_more: 'https://sendcutsend.com/guidelines/bending/',
        },
        {
          operation: 'countersinking',
          learn_more: 'https://sendcutsend.com/guidelines/countersinking/'
        },
        {
          title: 'dimple forming',
          operation: 'dimple_forming',
          learn_more: 'https://sendcutsend.com/guidelines/dimple-forming'
        },
        {
          operation: 'hardware',
          learn_more: 'https://sendcutsend.com/guidelines/hardware/'
        },
        {
          operation: 'tapping',
          learn_more: 'https://sendcutsend.com/guidelines/tapping/'
        },
        {
          operation: 'deburring',
          learn_more: 'https://sendcutsend.com/services/deburring/'
        },
        {
          operation: 'tumbling',
          learn_more: 'https://sendcutsend.com/services/tumbling/'
        },
        {
          operation: 'anodizing',
          learn_more: 'https://sendcutsend.com/guidelines/anodizing/'
        },
        {
          operation: 'plating',
          learn_more: 'https://sendcutsend.com/guidelines/plating/'
        },
        {
          title: 'powder coating',
          operation: 'powder',
          learn_more: 'https://sendcutsend.com/guidelines/powder-coating/'
        },
        {
          operation: 'etching',
          learn_more: 'https://sendcutsend.com/faq/do-you-offer-laser-etching-or-laser-engraving/'
        }
      ]

      const operations = material && material.operations ? material.operations : []

      // Use the information from the material to update any overlapping material
      // in the superset(ALL_OPERATIONS).
      return ALL_OPERATIONS
        .map(o => {
          const opData = operations.find(op => op.operation === o.operation) || {}
          return {
            ...o,
            ...opData,
            unavailable: !opData.operation,
            public: opData && opData.public,
            title: o.title || capitalize(o.operation)
          }
        })
    },

    enabledOperations(state) {
      return Object.keys(state.operations)
        .filter(key => state.operations[key])
    },

    materialSkus: (state, _getters, _rootState, _rootGetters) => (selectedCategory, selectedType) => {
      let result = false
      const materials = _rootGetters['materials/all']

      return filter(materials, (m) => {
        if (selectedType) {
          result = m.name === selectedType
        }
        else if (selectedCategory) {
          result = m.category === selectedCategory
        }
        return result
      }).sort(firstBy('thickness'))
    },

    materialGroups: (state, _getters, _rootState, _rootGetters) => {
      const materials = _rootGetters['materials/all']

      const sortOrder = ['Metals', 'Composites', 'Plastics', 'Wood and MDF']
      const materialGroups = filter(uniqBy(materials, 'group'), (m) => !!m.group).sort(
        (a, b) => (a.group === b.group ? 0 : sortOrder.indexOf(a.group) - sortOrder.indexOf(b.group))
      )

      const materialTags = {}
      each(materialGroups, (c) => {
        const materialsForGroup = filter(materials, (m) => m.group === c.group)
        materialTags[c.group] = {
          new_material: !find(materialsForGroup, (m) => !m.new_material),
          limited_material: !find(materialsForGroup, (m) => !m.limited_material)
        }
      })

      return map(materialGroups, (m) => ({ ...m, ...materialTags[m.group] }))
    },

    materialCategories: (state, _getters, _rootState, rootGetters) => (group) => {
      const materials = rootGetters['materials/all']

      const allForGroup = filter(materials, (m) => m.group === group)
      const materialCategories = uniqBy(allForGroup, 'category').sort(firstBy('category'))

      const materialTags = {}
      each(materialCategories, (c) => {
        const materialsForCategory = filter(materials, (m) => m.category === c.category)
        materialTags[c.category] = {
          new_material: !find(materialsForCategory, (m) => !m.new_material),
          limited_material: !find(materialsForCategory, (m) => !m.limited_material)
        }
      })

      return map(materialCategories, (m) => ({ ...m, ...materialTags[m.category] }))
    },

    materialTypes: (state, _getters, _rootState, rootGetters) => (category) => {
      let types = []

      if (category) {
        const materials = rootGetters['materials/all']

        const allForCategory = filter(materials, (m) => m.category === category)

        const materialTags = {}
        each(allForCategory, (n) => {
          const materialsForName = filter(materials, (m) => m.name === n.name)
          materialTags[n.name] = {
            new_material: !find(materialsForName, (m) => !m.new_material),
            limited_material: !find(materialsForName, (m) => !m.limited_material)
          }
        })

        types = uniqBy(map(allForCategory, (m) => ({ ...m, ...materialTags[m.name] })), 'name')
          .sort(firstBy((v1, v2) => v2.priority - v1.priority).thenBy('name'))
      }

      return types
    },

    operationCount(state, _getters) {
      const counts = {}

      _getters.enabledOperations.forEach((op) => {
        let count = 0
        switch (op) {
          case 'deburring':
          case 'tumbling':
          case 'powder':
          case 'plating':
          case 'anodizing':
            count = 1
            break
          case 'bending':
            count = (state.bends || []).filter(b => !b.joined_to).length
            break
          case 'tapping':
            count = state.tapConfig.filter((t) => !!t.tap).length
            break
          case 'hardware':
            count = state.hardwareConfig.filter((h) => !!h.hardware_id).length
            break
          case 'countersinking':
            count = state.cskConfig.filter((h) => !!h.csk_id).length
            break
          case 'dimple_forming':
            count = state.dimpleConfig.filter((h) => !!h.dimple_id).length
            break
        }
        if (count > 0) counts[op] = count
      })

      return counts
    },

    selectedIsCustom(state, _getters) {
      const selectedQuote = _getters.selectedQuote || {}
      const operations = Object.keys(state.operations).filter(op => state.operations[op])
      const validOperations = operations.every((operation) => {
        let valid = true
        if (operation === 'tapping') {
          valid = (state.tapConfig || []).length > 0
        }
        else if (operation === 'countersinking') {
          valid = (state.cskConfig || []).length > 0
        }
        else if (operation === 'dimple_forming') {
          valid = (state.dimpleConfig || []).length > 0
        }
        else if (operation === 'hardware') {
          valid = (state.hardwareConfig || []).length > 0
        }
        return valid
      })

      return selectedQuote.unit_cost === 0
        || selectedQuote.custom
        || selectedQuote.errors?.length > 0
        || !validOperations
    },

    selectedHasHardErrors(state, _getters) {
      const selectedQuote = _getters.selectedQuote || {}
      return find(selectedQuote.errors, (e) => e.code.includes('hard'))
    },

    selectedQuote(state, _getters, _rootState, _rootGetters) {
      const quotes = _rootGetters['quotes/all']
      return find(quotes, (q) => q.material_sku === state.selectedSku)
    },

    showing: (state) => state.showing,
  },

  actions: {
    clearOperations({ commit, state }) {
      const ops = Object.keys(state.operations)
      for (let i = 0; i < ops.length; i++) {
        commit('setOperation', { operation: ops[i], on: ops[i] === 'deburring' })
      }
      state.allOperationsCost = 0
    },

    clearOperationConfigs({ commit }) {
      // Clear configurations
      commit('setBendConfig', undefined)
      commit('setTapConfig', undefined)
      commit('setCskConfig', undefined)
      commit('setDimpleConfig', undefined)
      commit('setHardwareConfig', undefined)

      // Clear finish configurations
      commit('setPowderConfig', '')
      commit('setAnodizingConfig', '')
      commit('setPlatingConfig', '')
    },

    clearFinishOperation({ commit }, { operation }) {
      commit('setOperation', { operation, on: false })
      commit(`set${operation.charAt(0).toUpperCase() + operation.slice(1)}Config`, '')
    },

    clearFinishOperations({ dispatch }) {
      // Clear all other operations
      ['anodizing', 'plating', 'powder'].forEach((op) => {
        dispatch('clearFinishOperation', { operation: op })
      })
    },

    clearDeburringOperations({ commit }) {
      ['deburring', 'tumbling'].forEach((op) => {
        commit('setOperation', { operation: op, on: false })
      })
    },

    loadPart({ dispatch }, part) {
      return dispatch('loadItem', {
        material: { id: part.default_material },
        quantity: part.default_quantity,
        operations: part.operations,
        bends: part.bending,
        bend_config: part.bend_config,
        tap_config: part.tapping,
        csk_config: part.countersinking,
        dimple_config: part.dimple_forming,
        hardware_config: part.hardware,
        anodizing_config: part.anodizing,
        plating_config: part.plating,
        powder_config: part.powder,
        drawing_id: part.drawing_id
      })
    },

    async loadItem({ commit, dispatch, rootGetters }, item) {
      // Select the quote
      const material = rootGetters['materials/get'](item.material?.id)
      if (!isEmpty(material)) {
        commit('selectGroup', material.group)
        commit('selectCategory', material.category)
        commit('selectType', material.name)
        commit('selectSku', material.sku)
      }

      // Select quantity
      commit('setQuantity', item.quantity || 1)
      commit('setID', item.id)

      // Select operations and set operation data
      item.operations.forEach(operation => {
        commit('setOperation', { operation, on: true })
      })

      // Set operation configurations
      if (item.bends) commit('setBends', item.bends)
      if (item.bend_config) commit('setBendConfig', item.bend_config)
      if (item.tap_config) commit('setTapConfig', item.tap_config)
      if (item.csk_config) commit('setCskConfig', item.csk_config)
      if (item.dimple_config) commit('setDimpleConfig', item.dimple_config)
      if (item.hardware_config && item.material?.id) {
        await dispatch("hardware/fetch", { material_id: item.material?.id }, { root: true })
        commit('setHardwareConfig', item.hardware_config)
      }

      // Set finishing options
      if (item.anodizing_config) commit('setAnodizingConfig', item.anodizing_config)
      if (item.plating_config) commit('setPlatingConfig', item.plating_config)
      if (item.powder_config) commit('setPowderConfig', item.powder_config)

      // Return format for adding a quoted item to the cart
      return { ...item, material_id: item.material.id }
    },

    selectGroup({ commit }, material) {
      commit('selectGroup', material.group)
      commit('selectCategory', '')
      commit('selectType', '')
      commit('selectSku', '')
    },

    selectCategory({ commit, dispatch, getters }, material) {
      commit('selectCategory', material.category)
      commit('selectType', '')
      commit('selectSku', '')

      // We normally advance to material types here - however if there is only
      // one, we go straight to thickness
      if (getters.materialTypes(material.category).length <= 1) {
        dispatch('selectType', material)
      }
    },

    selectType({ commit }, material) {
      commit('selectType', material.name)
    },

    selectQuantity({ commit }, value) {
      commit('setQuantity', value === 'Custom' ? 1 : value)
      commit('selectQuantity', value)
    },

    setFinishOperation({ commit, dispatch }, { operation, on, color }) {
      // Clear all finish operations
      dispatch('clearFinishOperations')

      // Set this finish operation
      commit('setOperation', { operation, on })
      commit(`set${operation.charAt(0).toUpperCase() + operation.slice(1)}Config`, color)
    },
    setHoleOperationConfig({ commit }, { operation, on, config }) {
      commit('setOperation', { operation, on })
      const operations = {
        'hardware': 'setHardwareConfig',
        'countersinking': 'setCskConfig',
        'tapping': 'setTapConfig',
        'dimple_forming': 'setDimpleConfig'
      }
      commit(operations[operation], on ? config : [])
    },
  },

  mutations: {
    reset(state) {
      state.tile.selectedCategory = ''
      state.tile.selectedGroup = ''
      state.tile.selectedType = ''
      state.tile.selectedQuantity = 'Custom'
      state.id = null
      state.quantity = 1
      state.attachments = []
      resetOperations(state)
      state.selectedSku = ''
      state.allOperationsCost = 0
      state.discountList = []
      state.currentStep = 'material-group'
      state.agreedToBendDeformation = {}
      state.previewFinish = {
        operation: undefined,
        value: undefined
      }
    },

    setID(state, id) {
      state.id = id
    },

    setDrawingIDs(state, ids) {
      state.drawings = ids
    },

    selectCategory(state, cat) {
      state.tile.selectedCategory = cat || ''
    },

    selectGroup(state, group) {
      state.tile.selectedGroup = group || ''
    },

    selectSku(state, sku) {
      // Select SKU
      state.selectedSku = sku || ''

      state.tile.selectedQuantity = 'Custom'

      // If we are making changes to a cart item, such as material, we should
      // reset the operations. Otherwise, we should leave them alone. This allows
      // preconfigured operations from step files to be populated.
      if (state.id) {
        resetOperations(state)
      }
    },

    selectType(state, type) {
      state.tile.selectedType = type
    },

    selectQuantity(state, quantity) {
      state.tile.selectedQuantity = quantity
    },

    setAllOperationsCost(state, cost) {
      state.allOperationsCost = cost
    },

    setBends(state, bends) {
      state.bends = bends.filter(b => b.angle > 0)
    },

    setBendConfig(state, config) {
      state.bendConfig = config
    },

    setCurrentMode(state, mode) {
      state.currentMode = mode
    },

    setCurrentStep(state, step) {
      state.currentStep = step
    },

    setDiscountList(state, list) {
      state.discountList = list
    },

    setLoading(state, loading) {
      state.loading = loading
    },

    setOperation(state, { operation, on }) {
      Vue.set(state.operations, operation, on)
    },

    setQuantity(state, value) {
      state.quantity = parseInt(value)
    },

    setAnodizingConfig(state, config) {
      state.anodizingConfig = config
    },

    setPlatingConfig(state, config) {
      state.platingConfig = config
    },

    setPowderConfig(state, config) {
      state.powderConfig = config
    },

    setCskConfig(state, config) {
      state.cskConfig = config
    },

    setDimpleConfig(state, config) {
      state.dimpleConfig = config
    },

    setHardwareConfig(state, config) {
      state.hardwareConfig = config
    },

    setTapConfig(state, config) {
      state.tapConfig = config
    },

    setTileLoading(state, loading) {
      state.tile.loading = loading
    },

    setShowing(state, showing) {
      state.showing = showing
    },

    setState(state, val) {
      state.state = val
    },

    setShownPanel(state, val) {
      state.shownPanel = val
    },

    setAgreedToBendDeformation(state, bendId) {
      state.agreedToBendDeformation[bendId] = true
    }
  }
}
