import makerjs from 'makerjs'

function Polygon(sides, outerDiamter, innerDiameter) {
  this.paths = {
    inner: new makerjs.paths.Circle([0, 0], innerDiameter / 2)
  }

  this.models = {
    polygon_group: new makerjs.models.Polygon(sides, outerDiamter / 2, 0, false)
  }
}

Polygon.metaParameters = [
  { title: "Number of Sides", type: "range", units: 'none', step: 1, min: 3, max: 24, value: 6, default: 6, group: 'polygon_group', group_title: 'Plate' },
  { title: "Outer Diameter", type: "range", min: 0, max: 24, value: 6, default: 6, group: 'polygon_group', group_title: 'Plate' },
  { title: "Inner Diameter", type: "range", min: 0, max: 24, value: 3, default: 3, group: 'inner', group_title: 'Inner Hole' },
]

export default Polygon
