import Vue from 'vue'
import scs from 'shared/scs'
import * as common from 'shared/store/common.js'
import omit from 'lodash/omit'
import cloneDeep from 'lodash/cloneDeep'

function fixupEmptyArray(obj, key) {
  // Rails will remove empty arrays from the params, but sometimes
  // we want to keep them.  By adding an array elements that is an
  // empty string(but doesn't match the params allowed in the array)
  // we can keep the array in the params.
  if (key in obj && obj[key].length === 0) {
    obj[key].push('')
  }
}

// initial state
const initialState = {
  currentFolder: 'uncategorized',
  products: [],
}

// getters
const storeGetters = {
  all: (state) => Object.keys(state.products)
    .filter(productId => {
      const product = state.products[productId]
      const folder = state.currentFolder === 'uncategorized' ? null : state.currentFolder || 'all'
      return folder === 'all' || product.folder === folder
    })
    .map(id => state.products[id])
    .sort((a, b) => b.id - a.id),
  get: (state) => id => state.products[id],
  isValid: (state, getters) => id => {
    const product = getters.get(id)
    if (!product) return false

    return product.title
      && product.description
      && product.short_description
      && product.markup
      && product.preview_attachments && product.preview_attachments.length
  }
}

// actions
const actions = {
  clear({ commit }) {
    return commit('clearData')
  },
  async create({ commit }, data) {
    data = cloneDeep(data)
    fixupEmptyArray(data, 'file_attachments')
    fixupEmptyArray(data, 'preview_attachments')
    const options = omit(data, 'saved_cart')
    return scs.createCustomerProduct(options)
      .then((response) => {
        commit('setCustomerProduct', response.data)
        commit('setStats', response.stats)
        return response.data
      })
  },
  async delete({ commit }, data) {
    const response = await scs.deleteCustomerProduct(data)
    commit('removeCustomerProduct', data)
    commit('setStats', response.stats)
  },
  /**
   * Fetches Products from server
   * @param {*} data - Query data for the fetch
   */
  async fetch({ commit }, data) {
    const response = await scs.getCustomerProducts(data)
    const { stats, data: products } = await response.json()

    commit('setPagination', response.headers)
    commit('setCustomerProducts', products)
    commit('setStats', stats)

    return products
  },
  async fetchProduct({ commit }, id) {
    const response = await scs.getCustomerProduct({ id })
    const product = response.data
    commit('setCustomerProducts', [product])
    return product
  },

  refreshSingleProduct({ commit }, product) {
    return scs.getCustomerProduct({ id: product.id })
      .then((response) => {
        commit('setCustomerProduct', response.data)
        commit('setStats', response.stats)
      })
  },
  async filter({ state, commit }, data = {}) {
    if (state.currentFolder !== data.folderId) {
      commit('clearData')
      commit('setCurrentFolder', data.folderId ? data.folderId : undefined)
    }
  },
  update({ commit }, product) {
    product = cloneDeep(product)
    fixupEmptyArray(product, 'file_attachments')
    fixupEmptyArray(product, 'preview_attachments')
    product = cloneDeep(product)
    return scs.updateCustomerProduct(product)
      .then((response) => {
        commit('setCustomerProduct', response.data)
        commit('setStats', response.stats)
        return response.data
      })
  },
}

// mutations
const mutations = {
  clearData(state) {
    state.products = {}
  },
  setCurrentFolder(state, folderId) {
    state.currentFolder = folderId
  },
  setLoading(state, loading) {
    state.loading = loading
  },
  removeCustomerProduct(state, product) {
    Vue.delete(state.products, product.id)
  },
  setCustomerProducts(state, products) {
    products.forEach(product => {
      Vue.set(state.products, product.id, product)
    })
  },
  setCustomerProduct(state, product) {
    Vue.set(state.products, product.id, product)
  },
}

export default {
  namespaced: true,
  state: { ...common.state, ...initialState },
  mutations: { ...common.mutations, ...mutations },
  actions: { ...common.actions, ...actions },
  getters: { ...common.getters, ...storeGetters }
}
