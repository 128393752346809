import LoginRegisterDialog from 'customer/components/shared/LoginRegisterDialog'

export default {
  computed: {
    currentUser: function() {
      return this.$store.getters['app/currentUser']
    },
  },
  methods: {
    requireCurrentUser: function(default_on_reject = false, options = {}) {
      return new Promise((resolve, reject) => {
        if (this.currentUser) {
          resolve()
        }
        else {
          this.loginOrRegister('choose', options)
            .then(() => {
              resolve()
            })
            .catch(() => {
              if (default_on_reject) {
                this.$router.replace({ path: "/" })
              }
              else {
                reject()
              }
            })
        }
      })
    },
    loginOrRegister: function(mode, options = {}) {
      return new Promise((resolve, reject) => {
        this.$buefy.modal.open({
          parent: this,
          component: LoginRegisterDialog,
          hasModalCard: true,
          canCancel: true,
          fullScreen: this.$mq == 'mobile',
          onCancel: function() {
            reject()
          },
          events: {
            user_logged_in: function() {
              resolve()
            }
          },
          props: {
            mode: mode,
            options: options
          }
        })
      })
    },
  },
}
