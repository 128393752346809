import makerjs from 'makerjs'

function Star(points, outerDiameter, innerDiameter) {
  this.models = {
    star_group: new makerjs.models.Star(points, outerDiameter / 2, innerDiameter / 2)
  }
}

Star.metaParameters = [
  { title: "Number of Points", type: "range", units: 'none', step: 1, min: 3, max: 24, value: 8, default: 8, group: 'star_group', group_title: 'Plate' },
  { title: "Outer Diameter", type: "range", min: 0, max: 24, value: 6, default: 6, group: 'star_group', group_title: 'Plate' },
  { title: "Inner Diameter", type: "range", min: 0, max: 24, value: 3, default: 3, group: 'star_group', group_title: 'Plate' },
  { value: 2, default: 2, hidden: true } // skip points (when inner radius is zero)
]

export default Star
