import Vue from 'vue'
import scs from 'shared/scs'

function resourceParam(data) {
  const { resource_id, resource } = data
  return { id: resource_id, type: resource }
}

// initial state
const state = {
  data: {},
  showHidden: false,
  editing: null
}

// getters
const getters = {
  all(state) {
    return Object.keys(state.data)
      .map(key => state.data[key])
      .filter(note => state.showHidden ? true : note.archived === false)
      .sort((a, b) => new Date(a.created) - new Date(b.created))
  },

  hidden(state) {
    return Object.keys(state.data)
      .map(key => state.data[key])
      .filter(n => n.archived).length
  }
}

// actions
const actions = {
  fetch({ commit }, data = {}) {
    if (!data.append) commit('clearNotes')

    return scs
      .getNotes(resourceParam(data), data)
      .then(resp => {
        commit('setNotes', resp.data)
      })
  },

  addNote({ commit }, data = {}) {
    return scs
      .addNote(resourceParam(data), data)
      .then(resp => commit('appendNote', resp.data))
  },

  updateNote({ commit }, { resource, resource_id, note }) {
    commit('setNote', note)

    return scs
      .updateNote(resourceParam({ resource, resource_id }), note)
      .then(resp => commit('setNote', resp.data))
  }
}

// mutations
const mutations = {
  appendNote(state, note) {
    Vue.set(state.data, note.id, note)
  },

  clearNotes(state) {
    Vue.set(state, 'data', {})
  },

  setEditing(state, noteID) {
    state.editing = noteID
  },

  setNotes(state, notes) {
    for(let i = 0; i < notes.length; i++) {
      Vue.set(state.data, notes[i].id, notes[i])
    }
  },

  setNote(state, note) {
    Vue.set(state.data, note.id, note)
  },

  setShowHidden(state, show) {
    state.showHidden = show
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
