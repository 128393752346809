import login from 'customer/components/shared/mixins/login'
import ConfirmDialog from 'shared/components/ConfirmDialog'
import RequestQuoteDialog from 'customer/components/shared/RequestQuoteDialog'
import { EventBus } from 'shared/services/event_bus'

const MAX_FILE_UPLOAD = 10
const MAX_FILE_SIZE_MB = 5
const MAX_GUEST_UPLOADS = 10

function ios() {
  return navigator.platform && /ipad|iphone|ipod/i.test(navigator.platform)
}

function extension(filename) {
  const parts = filename.split('.')
  return parts[parts.length - 1]
}

export default {
  mixins: [login],
  data() {
    return {
      options: {},
      uploads: [],
      uploading: false,
      dropping: false,
      allowedExtensions: this.$store.state.app.allowedExtensions || '.ai,.dxf,.dwg,.eps',
    }
  },

  computed: {
    // Value used only for b-upload accept field. Do not use directly.
    acceptedExtentions() {
      // make sure to return lower and uppercase for the accept= field
      return ios() ? undefined : `${this.allowedExtensions},${this.allowedExtensions.toUpperCase()}`
    },

    // Array of valid file extensions.
    validExtensions() {
      return `${this.allowedExtensions},${this.allowedExtensions.toUpperCase()}`.split(',')
    },

    folderID() {
      return this.options && this.options.folderId ? this.options.folderId : undefined
    },

    currentUserExists() {
      return !!this.$store.getters['app/currentUser']
    },

    newDrawing: {
      get() { return this.uploads },
      async set(fileData) {
        this.uploads = []
        // Wait for the customer to log in (if required)
        if (this.requireLogin) {
          await this.loginOrRegister('choose')
        }

        this.$emit('uploadStarted', fileData)

        if (fileData.length > 0) {
          await this.setFiles(fileData)
        }
      }
    },

    readableAllowedExtensions() {
      return this.allowedExtensions.replace(/[.\s]/g, ' ')
    },

    requireLogin() {
      const userPresent = this.$store.getters['app/currentUser']
      return !userPresent && this.$store.getters['drawings/count'] >= MAX_GUEST_UPLOADS
    },

    showLoading() {
      return this.uploading || this.dropping
    },
  },

  methods: {
    initUploadDrawing(options) {
      this.options = options
    },
    validFileType(file) {
      if (file && file.name && file.name.length) {
        return this.validExtensions.includes(`.${extension(file.name).toLowerCase()}`)
      }
      return false
    },
    allowUpload(files) {
      // All files must have valid extensions
      return files.length > 0 && files.every(file => this.validFileType(file))
    },

    confirmUploadLimit(files) {
      return new Promise((resolve, reject) => {
        const modal = this.$buefy.modal.open({
          parent: this,
          component: ConfirmDialog,
          hasModalCard: true,
          canCancel: true,
          props: {
            title: `Only ${MAX_FILE_UPLOAD} drawings may be uploaded at once`,
            buttonMessage: 'Upload',
            message: `
              <p class="content">
                <ul>${files.map(f => `<li>${f.name}</li>`).join('')}</ul>
              </p>
            `
          },
          events: {
            confirm: resolve
          }
        })

        modal.$on('close', reject)
      })
    },

    warnFileSizeLimit() {
      this.$buefy.modal.open({
        parent: this,
        component: ConfirmDialog,
        hasModalCard: true,
        canCancel: true,
        props: {
          title: `File size too large`,
          buttonMessage: 'Request Custom Quote',
          message: `The file you've uploaded is larger than our accepted maximum of
            <strong>${MAX_FILE_SIZE_MB}MB</strong>. Please reach out to us for a custom quote.`,
          buttonClass: 'is-sale'
        },
        events: {
          confirm: () => {
            this.requestQuote()
          }
        }
      })
    },

    requestQuote() {
      this.$buefy.modal.open({
        parent: this,
        component: RequestQuoteDialog,
        fullScreen: this.$mq === 'mobile',
        hasModalCard: true,
        canCancel: ['x'],
      })
    },

    dragenter() {
      // Only set the dropping flag when a file is the target. Otherwise, this
      // can interfere with other drag/drop functionality
      if (this.$store.state.quote_ui.dragging === null) {
        this.dropping = true
      }
    },

    dragleave() {
      this.dropping = false
    },

    drop(event) {
      if (!this.uploading && event && event.dataTransfer) {
        const files = Array.from(event.dataTransfer.files || [])

        if (this.allowUpload(files)) {
          this.newDrawing = files
        } else {
          const invalidFiles = files.filter((file) => !this.validFileType(file))
          this.$emit('drop-failed', { files, invalid: invalidFiles })
        }
      }

      this.dropping = false
    },

    async setFiles(files) {
      // If the number of files set is too large, limit to max file size allowed
      if (files.length > MAX_FILE_UPLOAD) {
        files.splice(MAX_FILE_UPLOAD, Infinity)
        await this.confirmUploadLimit(files)
      }

      // If the filesize is too large reject it
      for (var i = files.length - 1; i >= 0; i--) {
        if (files[i].size > (MAX_FILE_SIZE_MB * 1048576)) {
          this.warnFileSizeLimit()
          files.splice(i, 1)
        }
      }

      await this.uploadFiles(files)
    },

    async uploadFiles(files) {
      // If iOS, we clear the allowed attribute because mobile safari does not
      // support file extensions in the accept property and the mimetypes for
      // dxf and dwg are also not supported (they show up as an empty string).
      // With the allowed attribute cleared, we need to validate the file
      // extensions manually.
      if (ios()) {
        if (!files.some(f => this.validateFiletype(f))) return
      }

      const data = await this.$store.dispatch('drawings/batchUploadKeys', files)
      const promises = []
      const totalUploading = Math.min(data.length, MAX_FILE_UPLOAD)

      this.uploading = true

      for (let i = 0; i < totalUploading; i++) {
        const file = {
          file: files.filter(f => f.name === data[i].filename)[0],
          url: data[i].url,

          // Drawing should now how many other drawings are being uploaded
          quote: totalUploading === 1
        }

        promises.push(this.processDrawing(file))
      }

      const drawings = await Promise.all(promises)

      // v6+ will wait for websocket messages to pop open the quote dialog
      if (this.$store.state.app.drawingApiVersion < 6) {
        if (drawings.length === 1) {
          EventBus.$emit('quote', { id: drawings[0].id, verify: true })
        }
      }

      // If we're uploading a batch, or adding a drawing to a batch upload, select the uploaded drawings
      if (drawings.length > 1 || this.$store.state.drawings.batch_select?.length > 0) {
        drawings.forEach(drawing => this.$store.commit('drawings/addBatchSelect', drawing.id))
      }

      if (!this.isAdmin && this.currentUserExists) {
        this.$store.dispatch('folders/fetch')
      }
      this.uploading = false
      this.emitUpload(drawings[0].id)
    },

    emitUpload(id) {
      this.$emit('upload-complete', id)
    },

    async processDrawing(fileData) {
      try {
        // Upload the file
        await this.$store.dispatch('drawings/uploadFile', fileData)

        const { file } = fileData
        this.$store.commit('drawings/setUploading', { ...file, id: file.name, state: 'processing' })

        // Create the drawing
        const drawingData = {
          ...fileData,

          // Assign a folder id if we have one
          folder_id: this.folderID || undefined,

          // If the units are predetermined...
          units: this.options?.units || undefined,

          // Indicates this is a generated drawing from parts builder...
          builder: this.options?.builder || undefined,

          processor_version: this.$store.state.app.drawingApiVersion,

          // Link this drawing to the resource if one was provided
          add_resource_type: this.resourceType ? this.resourceType : undefined,
          add_resource_id: this.resourceId ? this.resourceId : undefined
        }

        if (this.$store.state.app.drawingApiVersion < 6) {
          // Convert the file to dxf
          const resp = await this.$store.dispatch('drawings/convertDrawing', fileData)
          drawingData.conversion = resp.token
        }

        const drawing = await this.$store.dispatch('drawings/create', drawingData)

        if (this.$store.state.app.drawingApiVersion < 6) {
          this.$emit('refreshResource')
        }

        return drawing
      } catch (e) {
        // Remove uploading (non-created) file from store
        this.$store.commit('drawings/removeUploading', { id: fileData.file.name })

        // Log error thrown by processing the drawing and let the UI recover
        console.error(e)
        return null
      }
    },

    validateFiletype(file) {
      // Check if the file extension exists in the set of allowed extensions
      const allowed = this.validFileType(file)

      // If this file type is not allowed, alert the user
      if (!allowed) {
        const ext = extension(file.name)
        this.$buefy.toast.open({
          message: `${ext || file.type} is not a valid file type`,
          duration: 5000,
          type: 'is-danger',
          queue: false
        })
      }

      return allowed
    }
  }
}
