import makerjs from 'makerjs'

function Dimensions(model, units, font) {
  const extents = makerjs.measure.modelExtents(model)
  const eWidth = extents.width
  const eHeight = extents.height

  const scale = 20
  const scaleWidth = 600
  const scaleHeight = 340

  const offset = Math.max((eWidth / scaleWidth) * scale, (eHeight / scaleHeight) * scale)
  const windowScale = 1400 / (window.innerWidth * 4.285714285)
  const fontSize = window.innerWidth > 768
                   ? windowScale * offset * 4.285714285
                   : offset * 1.5

  const heightLineCoords = [[extents.low[0] - offset, extents.low[1]], [extents.low[0] - offset, extents.high[1]]]
  const hLC00 = heightLineCoords[0][0]
  const hLC01 = heightLineCoords[0][1]
  const hLC10 = heightLineCoords[1][0]
  const hLC11 = heightLineCoords[1][1]

  const widthLineCoords = [[extents.low[0], extents.high[1] + offset], [extents.high[0], extents.high[1] + offset]]
  const wLC00 = widthLineCoords[0][0]
  const wLC01 = widthLineCoords[0][1]
  const wLC10 = widthLineCoords[1][0]
  const wLC11 = widthLineCoords[1][1]

  const tickLength = offset / 4

  this.paths = {
    height: new makerjs.paths.Line(heightLineCoords[0], heightLineCoords[1]),
    height_tick_top: new makerjs.paths.Line([hLC10 - tickLength, hLC11], [hLC10 + tickLength, hLC11]),
    height_tick_bottom: new makerjs.paths.Line([hLC00 - tickLength, hLC01], [hLC00 + tickLength, hLC01]),
    width_tick_left: new makerjs.paths.Line([wLC00, wLC01 + tickLength], [wLC00, wLC01 - tickLength]),
    width_tick_right: new makerjs.paths.Line([wLC10, wLC11 + tickLength], [wLC10, wLC11 - tickLength]),
    width: new makerjs.paths.Line(widthLineCoords[0], widthLineCoords[1]),
  }

  if (font) {
    const widthStr = parseFloat(eWidth).toFixed(3)
    const heightStr = parseFloat(eHeight).toFixed(3)
    const widthText = new makerjs.models.Text(font, `${widthStr} ${units}`, fontSize, false, false, 4)
    const heightText = new makerjs.models.Text(font, `${heightStr} ${units}`, fontSize, false, false, 4)

    const widthTextDims = makerjs.measure.modelExtents(widthText)
    const heightTextDims = makerjs.measure.modelExtents(heightText)

    const textOffset = widthTextDims.height / 1.25 // Text hight is the same for both

    const widthTextX = extents.center[0] - (widthTextDims.width / 2)
    const widthTextY = extents.high[1] + offset + textOffset
    makerjs.model.move(widthText, [widthTextX, widthTextY])

    const heightTextX = extents.low[0] - offset - textOffset
    const heightTextY = extents.center[1] - (heightTextDims.width / 2)
    makerjs.model.rotate(heightText, 90)
    makerjs.model.move(heightText, [heightTextX, heightTextY])

    this.models = {
      width_text: widthText,
      height_text: heightText
    }
    this.original_extents = {
      width: eWidth,
      height: eHeight
    }
  }
}

export default Dimensions
