import makerjs from 'makerjs'

function CornerFlatGusset(height, width, xOffset, yOffset, cornerLength, centerHole, holeDiameter) {
  const points = [
    [0, 0], // going clockwise
    [0, yOffset],
    [width - xOffset, height],
    [width, height],
    [width, cornerLength],
    [width - cornerLength, 0]
  ]

  this.models = {
    gusset_group: new makerjs.models.ConnectTheDots(true, points)
  }

  function LineMidPoint(x1, x2, y1, y2) {
    return [(x1 + x2) / 2, (y1 + y2) / 2]
  }

  if (centerHole === "Yes") {
    const lineAMidpoint = LineMidPoint(width - cornerLength, width, 0, cornerLength)
    const lineBMidpoint = LineMidPoint(0, width - xOffset, yOffset, height)
    const midpoint = LineMidPoint(lineAMidpoint[0], lineBMidpoint[0], lineAMidpoint[1], lineBMidpoint[1])
    this.paths = {
      hole: new makerjs.paths.Circle(midpoint, holeDiameter / 2)
    }
  }
}

CornerFlatGusset.metaParameters = [
  {
    title: "Height",
    type: "range",
    min: 0,
    max: 24,
    value: 6,
    default: 6,
    tooltip_text: "Overall height of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/fcrngus_height.png",
    group: 'gusset_group',
    group_title: 'Gusset'
  },
  {
    title: "Width",
    type: "range",
    min: 0,
    max: 24,
    value: 6,
    default: 6,
    tooltip_text: "Overall width of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/fcrngus_width.png",
    group: 'gusset_group',
    group_title: 'Gusset'
  },
  {
    title: "Left side height",
    type: "range",
    min: 0,
    max: 12,
    value: 2,
    default: 2,
    tooltip_text: "Height of the left flat side",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/fcrngus_yoffset.png",
    group: 'gusset_group',
    group_title: 'Gusset'
  },
  {
    title: "Right side width",
    type: "range",
    min: 0,
    max: 12,
    value: 2,
    default: 2,
    tooltip_text: "Width of the upper right flat side",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/fcrngus_xoffset.png",
    group: 'gusset_group',
    group_title: 'Gusset'
  },
  {
    title: "Corner width",
    type: "range",
    min: 0,
    max: 12,
    value: 1,
    default: 1,
    tooltip_text: "Length and width of the triangle cut off the corner",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/fcrngus_corner.png",
    group: 'gusset_group',
    group_title: 'Gusset'
  },
  { title: "Add Center Hole", type: "bool", value: "Yes", default: "Yes", control: "hole" },
  {
    title: "Center Hole Diameter",
    type: "range",
    min: 0,
    max: 10,
    value: 1.5,
    default: 1.5,
    tooltip_text: "Diameter of the Hole at the center of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/fcrngus_center.png",
    group: 'hole',
    group_title: 'Center Hole'
  },
  { type: "bends", value: [], default: [], hidden: true, group: ['bend0', 'bend1', 'bend2', 'bend3'] }
]

export default CornerFlatGusset
