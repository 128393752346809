import makerjs from 'makerjs'

function Triangle(sideLength, topCornerRadius, bottomCornerRadius, addHole, holeDiameter, holeHeight) {
  let sideOneCoords = [0, sideLength]
  const rads = 60 * (Math.PI / 180)
  sideOneCoords = [Math.cos(rads) * sideLength, (Math.sin(rads) * sideLength)]

  const sideOne = new makerjs.paths.Line([0, 0], sideOneCoords)
  const sideTwo = new makerjs.paths.Line([0, 0], [sideLength, 0])
  const sideThree = new makerjs.paths.Line([sideLength, 0], sideOneCoords)

  this.models = {
    triangle_group: {
      paths: {
        side_one: sideOne,
        side_two: sideTwo,
        side_three: sideThree,
        fillet_one: makerjs.path.fillet(sideOne, sideTwo, bottomCornerRadius),
        fillet_two: makerjs.path.fillet(sideOne, sideThree, topCornerRadius),
        fillet_three: makerjs.path.fillet(sideTwo, sideThree, bottomCornerRadius),
      }
    }
  }

  if (addHole === "Yes") {
    this.paths = {
      hole: new makerjs.paths.Circle([sideLength / 2, 0 + holeHeight], holeDiameter / 2)
    }
  }
}

Triangle.metaParameters = [
  { title: "Side Length", type: "range", min: 0, max: 24, value: 6, default: 6, group: 'triangle_group', group_title: 'Plate' },
  { title: "Top Corner Radius", type: "range", min: 0, max: 6, value: 0.25, default: 0.25, group: 'triangle_group', group_title: 'Plate' },
  { title: "Bottom Corners' Radius", type: "range", min: 0, max: 5, value: 0.25, default: 0.25, group: 'triangle_group', group_title: 'Plate' },
  { title: "Add Hole", type: "bool", value: "No", default: "No" },
  { title: "Hole Diameter", type: "range", min: 0.25, max: 6, value: 1, default: 1, group: 'hole', group_title: 'Hole' },
  { title: "Hole Height", type: "range", min: 0, max: 6, value: 3, default: 3, group: 'hole', group_title: 'Hole' }
]

export default Triangle
