import { computed } from 'vue'
import { useStore } from 'customer/components/composables/store'
import { useBuefy } from 'customer/components/composables/buefy'
import { useRouter } from 'customer/components/composables/router'

import CustomerProductIntro from 'customer/components/customer_products/CustomerProductIntro'

export const useCustomerProducts = () => {
  const store = useStore()
  const buefy = useBuefy()
  const router = useRouter()

  const currentUser = computed(() => store.getters['app/currentUser'])
  const currentCustomer = computed(() => currentUser.value.customer)
  const hasCustomerProductEnabled = computed(
    () => currentUser.value && currentUser.value.customer_products_enabled
  )
  const shouldShowIntro = computed(
    () => !currentCustomer.value || !currentCustomer.value.seller_email
  )

  const newCustomerProductFromSavedCart = async (savedCart) => {
    try {
      const result = await store.dispatch('customer_products/create', { saved_cart_id: savedCart.id })
      router.replace({ name: "product", params: { productId: result.id } })
    }
    catch (error) {
      buefy.toast.open({
        message: 'Product Not Created. No Eligible Items Were Found.',
        type: 'is-danger'
      })
    }
  }

  const newCustomerProductFromCart = async () => {
    try {
      const savedCart = await store.dispatch('saved_carts/create', { kind: 'product' })
      newCustomerProductFromSavedCart(savedCart)
    }
    catch (error) {
      buefy.toast.open({
        message: 'Product Not Created. No Eligible Items Were Found.',
        type: 'is-danger'
      })
    }
  }

  const newCustomerProductFromOrder = async (order) => {
    try {
      const result = await store.dispatch('customer_products/create', { order_id: order.id })
      router.replace({ name: "product", params: { productId: result.id } })
    }
    catch (error) {
      buefy.toast.open({
        message: 'Product Not Created. No Eligible Items Were Found.',
        type: 'is-danger'
      })
    }
  }

  const startProductFlowFromCart = async (parent) => {
    if (shouldShowIntro.value) {
      buefy.modal.open({
        parent,
        component: CustomerProductIntro,
        hasModalCard: true,
        canCancel: false,
        events: {
          saved: newCustomerProductFromCart
        }
      })
    } else {
      newCustomerProductFromCart()
    }
  }

  const startProductFlowFromSavedCart = async (savedCart, parent) => {
    if (shouldShowIntro.value) {
      buefy.modal.open({
        parent,
        component: CustomerProductIntro,
        hasModalCard: true,
        canCancel: false,
        events: {
          saved: () => newCustomerProductFromSavedCart({ id: savedCart.id })
        }
      })
    } else {
      newCustomerProductFromSavedCart({ id: savedCart.id })
    }
  }

  const startProductFlowFromOrder = async (order, parent) => {
    if (shouldShowIntro.value) {
      buefy.modal.open({
        parent,
        component: CustomerProductIntro,
        hasModalCard: true,
        canCancel: false,
        events: {
          saved: () => newCustomerProductFromOrder(order)
        }
      })
    } else {
      newCustomerProductFromOrder(order)
    }
  }

  const fillDefaults = (product) => {
    const previewAttachments = product.preview_attachments && product.preview_attachments.length > 0
      ? product.preview_attachments
      : [{ filename_url: 'https://cdn.sendcutsend.com/images/product-pages/product-part-blank.svg' }]
    return {
      ...product,
      description: product.description || `This is where your product description should go.
      You can format text and create lists.  Your customer will want to see a good description
      here.`,
      short_description: product.short_description || `My Product's Description`,
      title: product.title || `My Product`,
      preview_attachments: previewAttachments,
    }
  }

  return {
    hasCustomerProductEnabled,
    startProductFlowFromCart,
    startProductFlowFromSavedCart,
    startProductFlowFromOrder,
    fillDefaults
  }
}
