import makerjs from 'makerjs'

function Washer(outerDiameter, innerDiameter) {
  const radii = {
    ring_outer: outerDiameter / 2,
    ring_inner: innerDiameter / 2
  }

  this.paths = {}

  Object.keys(radii).forEach(id => {
    const r = radii[id]
    if (r !== undefined && r > 0) {
      this.paths[id] = new makerjs.paths.Circle(makerjs.point.zero(), r)
    }
  })
}

Washer.metaParameters = [
  { title: "Diameter", type: "range", min: 0, max: 24, value: 6, default: 6, group: 'ring_outer', group_title: 'Ring Outer' },
  { title: "Diameter", type: "range", min: 0, max: 24, value: 3, default: 3, group: 'ring_inner', group_title: 'Ring Inner' }
]

export default Washer
