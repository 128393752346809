import makerjs from 'makerjs'

function GameSystem(height) {
  const paths = [
    "M4.3849,7.3313 L4.3849,13.915 L11.6091,13.915 L11.6091,7.3313 Z",
    "M10.5163,3.8308 L10.5163,4.7026 L11.3882,4.7026 L11.3882,3.8308 Z",
    "M9.3671,2.705 L9.3671,3.5947 L10.2568,3.5947 L10.2568,2.705 Z",
    "M6.3422,3.2922 L6.3422,2.3491 L5.4169,2.3491 L5.4169,3.2922 L4.4739,3.2922 L4.4739,4.2174 L5.4169,4.2174 L5.4169,5.1605 L6.3422,5.1605 L6.3422,4.2174 L7.2853,4.2174 L7.2853,3.2922 Z",
    "M4.3185,15.6232 A1.32166,1.32166 0 0 1 2.997,14.3015 L2.997,1.7133 A1.32153,1.32153 0 0 1 4.3185,0.3918 L11.6755,0.3918 A1.3215,1.3215 0 0 1 12.997,1.7133 L12.9969,14.3015 A1.32143,1.32143 0 0 1 11.6755,15.6231 Z"
  ]

  const gameModels = { }
  paths.forEach((p, i) => {
    gameModels[i] = makerjs.model.mirror(makerjs.importer.fromSVGPathData(p), true, false)
  })

  class GameGroup {
    constructor(models) {
      this.models = models
    }
  }

  this.models = {
    game_group: new GameGroup(gameModels)
  }

  // Apply a scaling factor
  const m = makerjs.measure.modelExtents(this)
  makerjs.model.scale(this, height / m.height)

  makerjs.model.rotate(this, 180)
}

GameSystem.metaParameters = [
  { title: "Height", type: "range", min: 3, max: 24, value: 5, default: 5, group: 'game_group', group_title: 'Plate' },
]

export default GameSystem
