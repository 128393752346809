import Decimal from 'decimal.js'
import moment from 'moment-timezone'

// Round to the nearest 2 decimal places
const roundAmount = (amount) => Decimal(amount).mul(100).ceil().div(100)
  .toNumber()

// https://www.30secondsofcode.org/js/s/hash-sha-256/
const calculateSha256 = (val) => crypto.subtle
    .digest('SHA-256', new TextEncoder('utf-8').encode(val))
    .then(h => {
      const hexes = []
        const view = new DataView(h)
      for (let i = 0; i < view.byteLength; i += 4) hexes.push((`00000000${view.getUint32(i).toString(16)}`).slice(-8))
      return hexes.join('')
    })

// Build a current cart object for use in analytics
const buildItemsResponse = (getters, items) => (items || []).map((item) => {
    const {
      operation_costs: operationCosts,
      total_cost: totalCost,
      total_price: totalPrice,
      quantity
    } = item

    const operationTotalUnit = Object.keys(operationCosts).map((operation) => operationCosts[operation].total)
      .reduce((total, opPrice) => new Decimal(opPrice).add(total), new Decimal(0)).div(quantity)

    const response = {
      price: roundAmount(Decimal(totalCost || totalPrice).add(operationTotalUnit)),
      quantity,
      item_id: null,
      item_name: null,
      item_variant: null,
      item_category: null,
      item_category2: null
    }

    if (item.material) {
      response.item_id = item.material.sku || ''
      response.item_name = item.material.name || ''
      response.item_variant = item.material.thickness || ''

      // If the material is non-public, it's possible this additional information does not exist,
      // so we'll only add it if we have it. Public invoice view doesn't this store, so we'll skip it.
      if (getters['materials/get']) {
        const materialDetails = getters['materials/get'](item.material.id)
        if (materialDetails) {
          response.item_category = materialDetails.category || ''
          response.item_category2 = materialDetails.subcategory || ''
        }
      }
    }
    return response
  })

const buildUserResponse = async (user, includeDetails = false) => new Promise((resolve) => {
    calculateSha256(user.email.toLowerCase()).then((emailHash) => {
      const response = {
        scs_guest_user: user.guest,
        user_id: user.id.toString(),
        sha256_email_address: emailHash,
        scs_user_order_count: user?.summary?.total_order_count || 0
      }

      if (includeDetails) {
        response.first_name = user.name.split(' ').slice(0, -1).join(' ')
        response.last_name = user.name.split(' ').slice(-1).join(' ')
        response.company_name = user.organization?.name || user.customer?.company || ''
        response.email = user.email
        response.scs_days_since_last_session = user?.summary?.days_since_last_session
        response.scs_days_since_last_order = user?.summary?.days_since_last_order
        response.scs_days_since_first_order = user?.summary?.days_since_first_order

        /*
          FUTURE METRICS
          scs_ytd_order_count: 123',  //sum orders placed year to date, number w/o commas or decimals
          scs_ttm_order_count: 123',  //sum orders placed rolling 12 mos, a number w/o commas or decimals
          scs_ytd_aov: '345.00',  //average order value year to date, number w/o commas
          scs_ttm_aov: '345.00',  //average order value for rolling 12 mos, number w/o commas
          scs_ytd_ltv: '1234.00',  //sum of product revenue year to date, number w/o commas
          scs_ttm_ltv: '1234.00'  //sum of product revenue rolling 12 mos, number w/o commas
        */
      }
      resolve(response)
    })
    .catch(() => {
      resolve({})
    })
  })

const actions = {
  login: ({ rootGetters }) => new Promise((resolve) => {
    try {
      const user = rootGetters['app/currentUser']
      if (user && window.dataLayer) {
        buildUserResponse(user, true /* include details */)
          .then((userResponse) => {
            window.dataLayer.push({
              event: 'login',
              ...userResponse
            })
            resolve()
          })
      }
      resolve()
    }
    catch (error) {
      resolve()
    }
  }),
  // Each step in the checkout flow triggers this event
  checkout: ({ rootGetters }, step) => new Promise((resolve) => {
    try {
      if (step && window.dataLayer) {
        const stepToEvent = {
          'billing': 'begin_checkout',
          'shipping': 'add_shipping_info',
          'payment': 'add_payment_info'
        }

        // Only track the step if we have an associated event
        if (stepToEvent[step]) {
          const cartItems = rootGetters['cart/items']
          const cartTotals = rootGetters['cart/totals']
          const discountCode = rootGetters['cart/discount_code']

          const itemsResponse = buildItemsResponse(rootGetters, cartItems)
          buildUserResponse(rootGetters['app/currentUser'])
            .then((userResponse) => {
              // GA4
              window.dataLayer.push({ ecommerce: null }) // Clear the ecommerce object
              window.dataLayer.push({
                'event': stepToEvent[step],
                'ecommerce': {
                  ...userResponse,
                  'affiliation': 'standard_cart',
                  'value': roundAmount(cartTotals?.subtotal || 0),
                  'currency': "USD",
                  'coupon': discountCode,
                  'items': itemsResponse
                }
              })
              resolve()
            })
        }
      }
      else {
        resolve()
      }
    } catch (error) {
      // We don't really care if this completes or not.
    }
    resolve()
  }),

  // This is called when the user creates a formal quote
  create_quote: ({ rootGetters }, quote) => new Promise((resolve) => {
    try {
      if (quote && window.dataLayer) {
        const {
          discount_code: discountCode,
          expires_at: expiresAt,
          reference_number: referenceNumber,
          total_price: totalPrice
        } = quote

        buildUserResponse(rootGetters['app/currentUser'])
          .then((userResponse) => {
            // GA4
            window.dataLayer.push({ ecommerce: null }) // Clear the ecommerce object
            window.dataLayer.push({
              'event': 'formal_quote_created',
              ...userResponse,
              formal_quote_id: referenceNumber,
              coupon: discountCode,
              expiration_date: expiresAt ? moment(expiresAt).format('YYYYMMDD') : '',
              quote_price: roundAmount(totalPrice || 0)
            })
            resolve()
          })
      }
      else {
        resolve()
      }
    } catch (error) {
      // We don't really care if this completes or not.
    }
    resolve()
  }),
  // This is called when the user pays for an order, be it terms or otherwise.
  // We also call it when the user pays for a formal quote, thus converting
  // their quote into an order.
  create_order: ({ rootGetters }, { order, affiliation }) => new Promise((resolve) => {
    try {
      if (order) {
        const {
            discount_code: discountCode,
            line_items: lineItems,
            reference_number: referenceNumber,
            total_price: totalPrice,
            total_tax: totalTax,
            total_shipping: totalShipping
        } = order

        // Google Tag Manager
        if (window.dataLayer) {
          // GA4
          const itemsResponse = buildItemsResponse(rootGetters, lineItems)
          buildUserResponse(order.user)
            .then((userResponse) => {
              window.dataLayer.push({ ecommerce: null }) // Clear the ecommerce object
              window.dataLayer.push({
                'event': 'purchase',
                'ecommerce': {
                  'transaction_id': referenceNumber,
                  'affiliation': affiliation || 'standard_cart',
                  'value': roundAmount(totalPrice || 0),
                  'tax': roundAmount(totalTax || 0),
                  'shipping': roundAmount(totalShipping || 0),
                  'currency': "USD",
                  'coupon': discountCode,
                  ...userResponse,
                  scs_user_order_count: order?.user?.order_count,
                  'items': itemsResponse
                }
              })
              resolve()
            })
        }
      }
      else {
        resolve()
      }
    } catch (error) {
      // We don't really care if this completes or not.
    }
    resolve()
  }),
  registerUser: (_, user) => new Promise((resolve) => {
    try {
      if (window.dataLayer) {
        buildUserResponse(user, /* include details */ true)
          .then((userResponse) => {
            // GA4
            window.dataLayer.push({
              'event': 'new_user',
              ...userResponse,
            })
            resolve()
          })
      }
      else {
        resolve()
      }
    } catch (error) {
      // We don't really care if this completes or not.
    }
    resolve()
  }),
  convertGuest: (_, user) => new Promise((resolve) => {
    try {
      if (window.dataLayer) {
        buildUserResponse(user, /* include details */ true)
          .then((userResponse) => {
            // GA4
            window.dataLayer.push({
              'event': 'new_user_guest_converted',
              ...userResponse,
            })
            resolve()
          })
      }
      else {
        resolve()
      }
    } catch (error) {
      // We don't really care if this completes or not.
    }
    resolve()
  })
}

export default {
  namespaced: true,
  state: {},
  getters: {},
  actions,
  mutations: {}
}
