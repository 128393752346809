export default {
  namespaced: true,

  state: {
    errors: []
  },

  getters: {
  },

  actions: {
  },

  mutations: {
    setFinishOperationErrors(state, errors) {
      state.errors = errors
    },
  }
}
