import makerjs from 'makerjs'

function Ellipse(width, height) {
  this.models = {
    ellipse_group: new makerjs.models.Ellipse(width / 2, height / 2)
  }
}

Ellipse.metaParameters = [
  { title: "Width", type: "range", min: 0, max: 24, value: 4, default: 4, group: 'ellipse_group', group_title: 'Ellipse' },
  { title: "Height", type: "range", min: 0, max: 24, value: 2, default: 2, group: 'ellipse_group', group_title: 'Ellipse' }
]

export default Ellipse
