import scs from 'shared/scs'
import uniq from 'lodash/uniq'
import Vue from 'vue'

// Return hard errors unique by code, but only if every quote has a hard error.
const hardErrors = (quotes) => {
  // Determine error using most permissive limit for the given code.
  const mostPermissive = (errors, code, minLimit) => {
    // extract all limit values for this error code
    const codeLimits = errors.filter(e => e.code === code).map(e => Number(e.limit))
    // For each error limit, find the one in the list that is most permissive.
    return { code, limit: minLimit ? Math.min(...codeLimits, 9999999) : Math.max(...codeLimits, 0) }
  }

  // This should only return hard errors if every quote in the list has a hard error.
  const hasErrors = quotes.every(q => q.errors?.some(e => e.code.includes('hard')))

  if (!hasErrors) return []

  // create one list of all hard errors across quotes.
  const errors = quotes.flatMap(q => q.errors.filter(e => e.code.includes('hard')))
  // For each unique error code, find the most permissive limit.
  return uniq(errors.map(e => e.code))
    .map(code => mostPermissive(errors, code, code.includes('min_')))
}

// initial state
const quoteState = {
  quotes: [],
  est_ship_date: null
}

const getters = {
  all: (state, _getters, _rootState, _rootGetters) => {
    const materials = _rootGetters['materials/all']
    return state.quotes.map((q) => ({
      ...q, material: q.material ?? materials.find((m) => m.sku === q.material_sku)
    }))
  },

  est_ship_date: (state) => state.est_ship_date,

  quotesForSku: (state, _getters) => (sku) => _getters.all.filter((q) => q.material?.sku === sku),
  quotesForType: (state, _getters) => (type) => _getters.all.filter((q) => q.material?.name === type),
  quotesForCategory: (state, _getters) => (category) => _getters.all.filter((q) => q.material?.category === category),
  quotesForGroup: (state, _getters) => (group) => _getters.all.filter((q) => q.material?.group === group),

  hardErrorsForSku: (state, _getters) => (sku) => hardErrors(_getters.quotesForSku(sku)),
  hardErrorsForType: (state, _getters) => (type) => hardErrors(_getters.quotesForType(type)),
  hardErrorsForCategory: (state, _getters) => (category) => hardErrors(_getters.quotesForCategory(category)),
  hardErrorsForGroup: (state, _getters) => (group) => hardErrors(_getters.quotesForGroup(group)),
}

const actions = {
  clear({ commit }) {
    commit('setQuotes', [])
  },

  fetch({ commit, rootGetters }, data = {}) {
    data.user_token = rootGetters["app/userToken"]
    return scs.getQuotes(data)
      .then((response) => {
        if (data.material_sku) {
          commit('setQuote', response.data[0])
          commit('setEstShipDate', response.meta.est_ship_date)
        }
        else {
          commit('setQuotes', response.data)
          commit('setEstShipDate', null)
        }
      })
  },
}

const mutations = {
  setEstShipDate(state, shipDate) {
    state.est_ship_date = shipDate
  },

  setQuotes(state, quotes) {
    state.quotes = quotes
  },

  setQuote(state, quote) {
    if (quote) {
      const index = state.quotes.findIndex((q) => quote.material_sku === q.material_sku)
      if (index >= 0) { Vue.set(state.quotes, index, quote) }
    }
  }
}

export default {
  namespaced: true,
  state: quoteState,
  mutations,
  actions,
  getters
}
