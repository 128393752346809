import { computed } from 'vue'
import { useStore } from 'customer/components/composables/store'

export function useSavedCartFolderService() {
  const store = useStore()

  const itemStats = computed(() => store.getters['saved_carts/stats'])
  const fetchFolders = (options) => store.dispatch('folders/fetch', options)
  const updateItem = async (options) => store.dispatch('saved_carts/update', options)
  const filterItemsForFolder = async (folder_id) => store.dispatch('saved_carts/filter', { resource_type: 'saved_cart', folder_id })
  const itemName = 'Carts'
  const generateFolder = (data) => ({ resource_type: 'saved_cart', ...data })
  const itemResourceType = 'saved_cart'

  return {
    itemStats,
    fetchFolders,
    updateItem,
    filterItemsForFolder,
    itemName,
    generateFolder,
    itemResourceType
  }
}
