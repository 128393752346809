import scs from 'shared/scs'

// actions
const actions = {
  create ({}, data) {
    return scs.createTicket(data)
  },
}

export default {
  namespaced: true,
  state:     {},
  mutations: {},
  actions:   actions,
  getters:   {}
}
