export default {
  computed:  {
    notifications() {
      return this.$store.getters['notifications/all'] || []
    },

    hasNotifications() {
      return this.notifications.length > 0
    },

    hasUnread() {
      return this.notifications.some(notification => !notification.viewed_at)
    },
  },

  methods: {
    deleteAll() {
      this.$store.dispatch('notifications/deleteAll')
    },

    displayFilename(url) {
      return url.split('\\').pop().split('/').pop()
    },

    downloadPath(url, override_filename = '') {
      fetch(url, {
        headers: new Headers({
          'Origin': location.origin
        }),
        mode: 'cors'
      })
      .then(response => response.blob())
      .then(blob => {
        let blobUrl = window.URL.createObjectURL(blob)
        let filename = override_filename || this.displayFilename(url)
        this.forceDownload(blobUrl, filename)
      })
      .catch(() => {
        this.forceDownload(url)
      })
    },

    fetchNotifications() {
      return this.$store.dispatch('notifications/fetch')
    },

    forceDownload(blob, filename = '') {
      let a = document.createElement('a')
      a.download = filename
      a.href = blob
      // For Firefox https://stackoverflow.com/a/32226068
      document.body.appendChild(a)
      a.click()
      a.remove()
    },

    markRead(notification) {
      this.$store.dispatch('notifications/read', notification)
    },

    notificationAction(notification) {
      this.markRead(notification)

      switch (notification.category) {
        case 'export':
          return this.downloadPath(notification.data.export_url, notification.data.export_filename)
        case 'report':
          return this.downloadPath(notification.data.report_url, notification.data.export_filename)
        default:
      }
    },

    notificationActionText(notification) {
      switch (notification.category) {
        case 'export':
          return 'Download'
        case 'report':
          return 'Download'
        default:
      }
    },

    readAll() {
      this.$store.dispatch('notifications/readAll')
    },

  }
}
