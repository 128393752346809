import Vue from 'vue'
import * as mjsutil from 'shared/util/makerjs'

const largestTapSize = Number(window.appData.max_tap_size)

function toInches(value, drawingUnits) {
  if (drawingUnits === 'mm') {
    return value / 25.4
  }

  return value
}

export default {
  namespaced: true,

  state: {
    data: {
      // [drawingID]: { model }
    }
  },

  getters: {
    bendLines: (state) => (id) => {
      const bendLines = state.data[id]?.bendLines || []
      return bendLines.map((line, idx) => {
        return { id: line.id, index: idx + 1 }
      })
    },

    // Does this bend intersect with other geometry. There are 2 ways this can happen:
    //   - Area in die lines intersects some geometry
    //   - Geometry exists entirely within the area between die lines
    bendIntersections: (state, getters) => (id, bendLineID, bottomDie) => {
      const dwgModel = state.data[id] || {}
      const interior = mjsutil.interiorGeometry(dwgModel)
      const bendLines = dwgModel.bendLines || []
      const bendLine = bendLines.filter(l => l.id == bendLineID)[0]

      if (bendLine) {
        // Create a model from the interior geometry
        let model = mjsutil.toMakerJS(interior, 'cut')

        // Create a model for the box defined by the die lines
        const dieModel = mjsutil.expandBendLine(bendLine, bottomDie / 2)

        // Return the intersections between the model and the die line
        const ints = mjsutil.intersections(model, dieModel)

        // Check all interior polylines for containment within a die line
        interior.cut.forEach(poly => {
          // Add an intersection if the model contains the dieModel
          if (mjsutil.containsPoly(dieModel, poly)) ints.push({})
        })

        // Return the number of intersections
        return ints
      }

      return []
    },

    // If a bend were to split a model in half, what are the flange lengths
    minDieContact: (state, getters) => (id, bendLineID, minFlangeLength) => {
      const dwgModel = state.data[id] || {}
      const exterior = mjsutil.exteriorGeometry(dwgModel)
      const bendLines = dwgModel.bendLines || []
      const bendLine = bendLines.filter(l => l.id == bendLineID)[0]

      // Start with a value of 1, this would disable this check if we can't find
      // a bend line for some reason
      let minDieContact = 1

      if (bendLine) {
        // Create a model from the exterior geometry
        const exteriorModel = mjsutil.toMakerJS(exterior, 'cut')

        // Create vectors that describe a box around the bend and die lines
        const { dieA, dieB, bend } = mjsutil.bendVectors(bendLine, minFlangeLength)

        // Determine how much contact the die and punch will make with the part
        const bendContactLength = mjsutil.contact(exteriorModel, bend)
        const dieAContactLength = mjsutil.contact(exteriorModel, dieA)
        const dieBContactLength = mjsutil.contact(exteriorModel, dieB)

        // The Die contact must be near or greater than the bend line contact
        minDieContact = Math.min(dieAContactLength / bendContactLength, dieBContactLength / bendContactLength)
      }

      return minDieContact
    },

    holeDiameters: (state, getters) => (id, units) => {
      const holes = getters.holes(id)
      return Object.keys(holes)
        .map(id => holes[id])
        .map(h => h.diameter)
        .filter(d => toInches(d, units) <= largestTapSize)
        .map(d => ({ diameter: d }))
    },

    holes: (state) => (id) => {
      const entities = (state.data[id]?.cut || [])
      const holes = entities
        .filter(poly => poly.circular && poly.closed)
        .map(poly => {
          return {
            id: poly.id,
            radius: poly.entities[0].radius,
            diameter: poly.entities[0].radius * 2,
            operation: null
          }
        })

      return holes.reduce((data, hole) => {
        data[hole.id] = hole
        return data
      }, {})
    },
  },

  actions: {
    async fetch({ commit }, drawing) {
      // Load && parse drawing.model_url
      const resp = await fetch(drawing.model_url)
      const model = await resp.json()

      // Store drawing model
      commit('setDrawingModel', { drawingID: drawing.id, model })
    },

    // If a model has not been fetched yet, fetch it. Otherwise, do nothing
    async sync({ state, dispatch }, drawing) {
      if (!state.data[drawing.id]) {
        await dispatch('fetch', drawing)
      }
    }
  },

  mutations: {
    setDrawingModel(state, { drawingID, model }) {
      Vue.set(state.data, drawingID, model)
    }
  }
}
