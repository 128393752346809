import { Decimal } from 'decimal.js'
import intersection from 'lodash'
import { useQuoteErrors } from 'shared/composables/quote_errors'

export default {
  setup() {
    const { hasHardErrors, hasSoftErrors, quoteErrorTooltip, filterMostPermissive } = useQuoteErrors()

    return {
      hasHardErrors,
      hasSoftErrors,
      quoteErrorTooltip,
      filterMostPermissive
    }
  },
  computed: {
    materialOperations() {
      return this.$store.getters['quote_dialog/materialOperations'](this.material)
    },

    material() {
      return this.quote ? this.quote.material || {} : {}
    },

    hasBendLines() {
      return this.$store.getters['bending/bendLines'].length !== 0
    },

    hasOperationErrors() {
      return this.operationErrors(this.operation).length > 0
    },

    hasEligibleHoles() {
      return this.$store.getters['hole_operations/tappableHoles'].length > 0
    },

    hasInteriorGeometry() {
      function hasHoles(drawing) {
        return (drawing.contours || drawing.calculated_entities) > 1
      }

      if (this.drawing.nested_drawings.length <= 1) {
        return hasHoles(this.drawing)
      }

      return this.drawing.nested_drawings.every(hasHoles)
    },

    errorsForTooltip() {
      const errors = this.operationErrors(this.operation)
      // op_unavailable_private_hard isn't a real error because a private operation *can*
      // be on a part(vs an error which would not allow the operation).  In order for it
      // to have a consistent tooltip(which makes it look like the operation is unavailable)
      // we add the fake error here.
      if (errors.length === 0 && !this.operation.public) {
        errors.push({
          name: this.operation.operation,
          code: 'op_unavailable_private_hard'
        })
      }
      return errors
    },

    operationTooltip() {
      return this.quoteErrorTooltip(this.errorsForTooltip, "operation")
    },

    partDimensions() {
      if (this.drawing.nested_drawings.length === 0) {
        return [{ width: this.drawing.width, height: this.drawing.height }]
      }
      return this.drawing.nested_drawings.map(nestedDrawing => (
        { width: nestedDrawing.width, height: nestedDrawing.height }))
    },

    quote() {
      return this.$store.getters['quote_dialog/selectedQuote']
    },
  },

  methods: {
    hasOperation(operation) {
      return this.$store.getters['quote_dialog/enabledOperations'].includes(operation)
    },
    hasNoVendor(op) {
      return this.quote?.restricted_operations && this.quote.restricted_operations[op.operation]
        && this.quote.restricted_operations[op.operation].includes('no_vendor')
    },
    hasAnyOperations(operations) {
      return intersection(this.$store.getters['quote_dialog/enabledOperations'], Set(operations))
        .length > 0
    },
    operationErrors(op) {
      if (!op) return []

      if (op.unavailable) {
        return [{
          name: op.operation,
          code: 'op_unavailable_hard'
        }]
      }

      if (this.hasNoVendor(op)) {
        return [{
          name: op.operation,
          code: 'op_no_vendor'
        }]
      }

      const errors = []

      const opMinLength = new Decimal(op.min_length)
      const opMinLengthMax = new Decimal(op.min_length_max)
      const opMaxLengthMin = new Decimal(op.max_length_min)
      const opMaxLength = new Decimal(op.max_length)

      const nestedDimensions = this.partDimensions
      for (let i = 0; i < nestedDimensions.length; i++) {
        const partDimension = nestedDimensions[i]
        const smallestDim = new Decimal(Math.min(partDimension.height, partDimension.width))
        const largestDim = new Decimal(Math.max(partDimension.height, partDimension.width))

        if (opMinLength.gt(0)) {
          if (smallestDim.lt(opMinLength)) {
            errors.push({
              name: op.operation,
              code: 'op_min_length_short_side_hard',
              limit: opMinLength
            })
          }
        }

        if (opMinLengthMax.gt(0)) {
          if (largestDim.lt(opMinLengthMax)) {
            errors.push({
              name: op.operation,
              code: 'op_min_length_long_side_hard',
              limit: opMinLengthMax
            })
          }
        }

        if (opMaxLengthMin.gt(0)) {
          if (smallestDim.gt(opMaxLengthMin)) {
            errors.push({
              name: op.operation,
              code: 'op_max_length_short_side_hard',
              limit: opMaxLengthMin
            })
          }
        }

        if (opMaxLength.gt(0)) {
          if (largestDim.gt(opMaxLength)) {
            errors.push({
              name: op.operation,
              code: 'op_max_length_max_side_hard',
              limit: opMaxLength
            })
          }
        }
      }

      const operationName = op.operation.toLowerCase()
      if (operationName === "bending" && !this.hasBendLines) {
        errors.push({
          name: op.operation,
          code: 'op_bending_no_lines'
        })
      }

      if (['anodizing', 'plating', 'powder'].includes(operationName) && !this.hasInteriorGeometry) {
        errors.push({
          name: op.operation,
          code: 'op_finish_no_holes'
        })
      }

      if (["tapping", "countersinking", "hardware", "dimple_forming"].includes(operationName) && !this.hasEligibleHoles) {
        errors.push({
          name: op.operation,
          code: 'op_no_holes'
        })
      }

      if ((operationName === 'etching'
        && this.hasAnyOperations(['tumbling', 'deburring', 'anodizing', 'plating', 'powder']))) {
        errors.push({
          name: op.operation,
          code: 'op_etching_wiped_by_finishing'
        })
      }

      if (['tumbling', 'deburring', 'anodizing', 'plating', 'powder'].includes(operationName)
        && this.hasOperation('etching')) {
        errors.push({
          name: op.operation,
          code: 'op_finish_wipes_etching'
        })
      }

      return errors
    },
  }
}
