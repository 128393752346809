// custom validations

export const emailListValidator = {
  getMessage: (field) => `The ${field} field must contain valid emails.`,
  validate: value => {
    const emails = value.split(',')
    let valid = true
    var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    for (var i = 0; i < emails.length; i++) {
      if (emails[i] === "" || !regex.test(emails[i].replace(/\s/g, ""))) {
        valid = false
      }
    }
    return valid
  }
}

// Validates only the username portion of an email
export const emailUsernameValidator = {
  getMessage: (field) => `The ${field} field must be valid.`,
  validate: value => {
    var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))$/
    return value && regex.test(value.trim())
  }
}

// This validates north american phone numbers. We'll to extend this for internation numbers later
export const phoneValidator = {
  getMessage: (field) => `The ${field} field must contain a valid phone number (XXX-XXX-XXXX)`,
  validate: value => {
    var regex = /^([2-9][0-8][0-9])-([2-9][0-9]{2})-([0-9]{4})$/
    return value && regex.test(value)
  }
}

export const maxFilesValidator = (maxFiles) => ({
  validate: files => files.length <= maxFiles,
  getMessage: () => `You can upload a maximum of ${maxFiles} files`,
})

export const fileTypeValidator = (allowedTypes) => ({
  validate: files => files.every(file => allowedTypes.includes(file.name.split('.').pop().toLowerCase())),
  getMessage: () => `All files must be ${allowedTypes.join(', ')}`,
})

export const fileSizeValidator = (maxSize) => ({
  validate: files => files.every(file => file.size <= maxSize),
  getMessage: () => `Each file size must not exceed ${maxSize / (1024 * 1024)} MB`,
})
