export default {
  props: {
    showNodes: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    totalNodes() {
      if (!this.loading && this.isSvg && this.$refs.svgContainer) {
        const $svg = this.$refs.svgContainer.querySelector('svg')
        const $path = $svg.querySelector('.base path')
        const d = $path?.getAttribute('d') || ''

        // Return the number of Line and Arc commands (node count)
        return d.match(/[LA]/g)?.length || 0
      }
      return 0
    },
  },

  methods: {
    addNodes() {
      const $svg = this.$refs.svgContainer.querySelector('svg')
      const $base = $svg.querySelector('.base')
      const $path = $svg.querySelector('.base path')
      const d = $path.getAttribute('d')

      // Determine the node radius
      const [, , width, height] = $svg.getAttribute('viewBox').split(' ').map(x => parseFloat(x))
      const radius = 3 * (Math.max(width / $svg.clientWidth, height / $svg.clientHeight))

      // Collect start points
      const move = (d.match(/M[\d .-]*/g) || [])
        .map(m => {
          const [x, y] = m.replace('M', '').split(' ')
          return [parseFloat(x), parseFloat(y)]
        })

      // Collect line endpoints
      const lines = (d.match(/L[\d .-]*/g) || [])
        .map(l => {
          const [x, y] = l.replace('L', '').split(' ')
          return [parseFloat(x), parseFloat(y)]
        })

      // Collect arc endpoints
      const arcs = (d.match(/A[\d .-]*/g) || [])
        .map(a => {
          const [, , , , , x, y] = a.replace('A', '').split(' ')
          return [parseFloat(x), parseFloat(y)]
        })

      // Create a list of start/end points
      const verts = [...move, ...lines, ...arcs]

      // Create a node group to store nodes in
      const nodes = document.createElementNS("http://www.w3.org/2000/svg", "g")
      nodes.id = "nodes"

      // Build SVG elements to display nodes
      let html = ''
      for (let i = 0; i < verts.length; i++) {
        const [x, y] = verts[i]
        if (x !== null && y != null) {
          html += `<circle cx="${x}" cy="${y}" r="${radius}" fill="green" />`
        }
      }

      // Add nodes to html
      nodes.innerHTML = html
      $base.insertBefore(nodes, $base.firstChild)
    },

    resizeNodes() {
      // Determine the node radius
      const $svg = this.$refs.svgContainer?.querySelector('svg')
      if (!$svg) return

      const [, , width, height] = $svg.getAttribute('viewBox').split(' ').map(x => parseFloat(x))
      const radius = 3 * (Math.max(width / $svg.clientWidth, height / $svg.clientHeight))

      const nodes = $svg.querySelectorAll('#nodes circle')
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i]
        node.setAttribute('r', radius)
      }
    },

    removeNodes() {
      const $svg = this.$refs.svgContainer.querySelector('svg')
      const $el = $svg.querySelector('#nodes')
      if ($el) $el.remove()
    },
  },
  watch: {
    showNodes() {
      if (this.showNodes) {
        this.addNodes()
      } else {
        this.removeNodes()
      }
    },
  }
}
