import makerjs from 'makerjs'

function RoundLink(
  leftOuterDiameter,
  rightOuterDiameter,
  leftInnerDiameter,
  centerHoleDiameter,
  rightInnerDiameter,
  centerDistance,
) {
  this.paths = {
    left: new makerjs.paths.Circle([0, 0], leftInnerDiameter / 2),
    right: new makerjs.paths.Circle([centerDistance, 0], rightInnerDiameter / 2),
    center: new makerjs.paths.Circle([centerDistance / 2, 0], centerHoleDiameter / 2)
  }

  this.models = {
    belt_group: new makerjs.models.Belt(leftOuterDiameter / 2, centerDistance, rightOuterDiameter / 2)
  }
}

RoundLink.metaParameters = [
  {
    title: "Left Outer Diameter",
    type: "range",
    min: 0,
    max: 6,
    value: 1,
    default: 1,
    tooltip_text: "Outer diameter of the left side of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/roundlink_left_outer.png",
    group: 'belt_group',
    group_title: 'Plate'
  },
  {
    title: "Right Outer Diameter",
    type: "range",
    min: 0,
    max: 6,
    value: 1,
    default: 1,
    tooltip_text: "Outer diameter of the right side of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/roundlink_right_outer.png",
    group: 'belt_group',
    group_title: 'Plate'
  },
  {
    title: "Left Hole Diameter",
    type: "range",
    min: 0,
    max: 6,
    value: 0.5,
    default: 0.5,
    tooltip_text: "Diameter of the hole on the left side of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/roundlink_left_hole.png",
    group: 'left',
    group_title: 'Left Hole'
  },
  {
    title: "Center Hole Diameter",
    type: "range",
    min: 0,
    max: 6,
    value: 0.5,
    default: 0.5,
    tooltip_text: "Diameter of the hole in the center of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/roundlink_center_hole.png",
    group: 'center',
    group_title: 'Center Hole'
  },
  {
    title: "Right Hole Diameter",
    type: "range",
    min: 0,
    max: 6,
    value: 0.5,
    default: 0.5,
    tooltip_text: "Diameter of the hole on the right side of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/roundlink_right_hole.png",
    group: 'right',
    group_title: 'Right Hole'
  },
  {
    title: "Distance Between Centers",
    type: "range",
    min: 0,
    max: 12,
    value: 2,
    default: 2,
    tooltip_text: "Distance between the centers of the left and right holes",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/roundlink_centers.png",
    group: ['left', 'right'],
    group_title: 'Holes'
  }
]

export default RoundLink
