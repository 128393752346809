import { render, staticRenderFns } from "./LayoutCustomer.vue?vue&type=template&id=4c469afb"
import script from "./LayoutCustomer.vue?vue&type=script&lang=js"
export * from "./LayoutCustomer.vue?vue&type=script&lang=js"
import style0 from "./LayoutCustomer.vue?vue&type=style&index=0&id=4c469afb&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports