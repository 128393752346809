import makerjs from 'makerjs'
import { adjustFontSize, adjustMetric } from 'shared/components/PartBuilder/parts/utilities/common'

const STATE_DEFINITIONS = {
  "AL": "M-1.912 2.5706L-1.8308 2.5184L-1.7959 0.1393L-1.7843 -1.1663L-1.4884 -2.9536L-1.4652 -2.9478L-1.2041 -3.0L-1.1344 -2.4836L-1.0184 -2.7737L-0.8211 -2.9536L-0.4439 -2.7331L-0.4845 -2.6983L-0.4729 -2.7041L-0.734 -2.1064L1.7727 -1.7002L1.941 -1.6712L1.941 -1.6654L1.7843 -1.3694L1.7727 -0.9458L1.6451 -0.7137L1.7089 -0.2611L1.8134 -0.1451L1.3839 0.6209L0.6064 2.9884L0.6006 3.0L0.4265 2.971L-0.5019 2.8201L-0.9662 2.7621L-1.941 2.6286L-1.9352 2.617L-1.912 2.5706Z",
  "AK": "M-0.4747 2.2957L-0.5754 2.2343L-0.8937 2.5247L-1.1449 2.48L-1.575 2.3181L-1.3796 2.1003L-1.4075 1.9663L-1.2791 1.8098L-0.8546 1.6423L-0.5473 1.7986L-0.4972 1.6814L-0.5808 1.3296L-0.8378 1.3296L-1.1395 1.1956L-1.1561 1.2905L-1.3126 1.2291L-1.3852 1.067L-1.642 0.8716L-1.642 0.5868L-1.5415 0.542L-1.6197 0.3019L-1.2845 0.0114L-1.0723 0.1007L-1.117 -0.2566L-1.0556 -0.3348L-0.7987 -0.3907L-0.7038 -0.4969L-0.4803 -0.5135L-0.3742 -0.6253L-0.1453 -0.5583L-0.2514 -0.7035L-0.3351 -0.9994L-1.0165 -1.4407L-1.106 -1.53L-1.2344 -1.4798L-1.441 -1.53L-1.7371 -1.7255L-1.2735 -1.5915L-1.2065 -1.692L-0.6089 -1.4965L-0.5921 -1.5747L-0.3575 -1.3402L0.1339 -0.9994L0.173 -1.0218L0.6031 -0.681L0.4914 -0.547L0.6366 -0.3069L1.0164 0.1455L1.3068 0.2014L1.3963 0.1398L1.0444 0.0393L0.9494 -0.3348L0.9606 -0.536L1.0835 -0.5247L1.6755 -0.1673L1.7202 -0.0333L1.6699 0.1733L1.8486 0.1288L1.977 0.2125L2.3457 -0.0108L2.3178 -0.0724L3.1555 -0.1785L3.3511 -0.117L3.4069 -0.2008L4.1329 -0.547L4.2558 -0.4744L4.8311 -0.5861L4.8702 -0.7258L5.2779 -0.9548L5.384 -1.2507L5.4845 -1.0218L5.6911 -1.4239L5.8309 -1.195L5.7192 -0.9101L5.2891 -0.8654L4.63 -0.2343L4.2726 0.0281L3.9989 -0.2399L3.837 -0.2399L3.4627 0.0058L3.0941 0.0227L2.5692 3.3346L2.4797 3.491L2.2675 3.6194L1.9491 3.558L1.7034 3.6082L1.3907 3.7311L1.0611 3.7087L0.9774 3.8652L0.7148 3.9043L0.6701 3.9992L0.5529 3.8652L0.5194 4.1165L0.2904 3.9265L0.1508 3.9657L-0.1732 3.7813L-0.2346 3.8204L-0.6033 3.4072L-0.9774 3.3234L-0.9439 3.1223L-0.7764 2.8934L-0.4637 2.5918L-0.5529 2.469L-0.4747 2.2957Z",
  "AZ": "M0.8717 2.6708L0.0325 2.7913L-1.1638 2.9815L-1.2844 3.0L-1.2844 2.9907L-1.4884 2.0912L-1.5951 2.0958L-1.7573 2.2396L-1.9614 2.068L-1.966 1.3308L-2.0402 1.1731L-2.0402 1.1546L-2.0495 1.0386L-1.8872 0.6028L-1.7434 0.408L-2.0031 0.255L-2.1051 0.0649L-2.272 -0.3431L-2.3555 -0.3617L-2.3601 -0.7002L-2.2164 -0.8068L-2.2906 -1.0108L-2.4575 -1.0201L-2.5549 -1.2009L-2.221 -1.4096L-1.5904 -1.762L0.357 -2.796L2.0077 -3.0L2.0263 -3.0L2.1561 -1.6321L2.1886 -1.2937L2.5363 2.2952L2.5549 2.4621L2.4343 2.476L0.8717 2.6708Z",
  "AR": "M2.3511 2.1679L2.687 2.7023L2.542 3.0L-0.3664 2.7176L-2.8244 2.5191L-3.1832 2.4962L-3.1374 2.2824L-2.8397 0.7405L-2.8321 0.3969L-2.7481 -1.6794L-2.7404 -2.0229L-2.7175 -2.0305L-2.603 -2.1527L-2.145 -2.1374L-2.0992 -2.8092L-2.084 -3.0L-1.8473 -2.9771L-1.1221 -2.9237L1.7557 -2.6565L1.771 -2.6565L1.7634 -2.4886L1.8397 -2.4351L1.7023 -2.1756L1.9924 -0.9542L1.8779 -0.8168L2.313 -0.3359L2.2824 0.0763L2.6031 0.6107L2.6031 0.6183L2.5878 0.6336L2.8626 0.8855L2.7863 1.1908L3.0 1.5267L2.9542 1.8397L3.1832 2.2824L3.1832 2.2901L3.1756 2.2901L2.3511 2.1679Z",
  "CA": "M-1.4604 1.3674L-1.4233 1.0846L-1.2753 0.9022L-1.3731 0.7753L-1.3573 0.6141L-1.2515 0.4502L-1.0665 -0.0573L-1.0084 -0.089L-1.0269 -0.4405L-0.9978 -0.4828L-0.6013 -0.6256L-0.4797 -0.7551L-0.4374 -0.8396L-0.3211 -0.9057L-0.1943 -0.9295L-0.1414 -1.0749L-0.0357 -1.1251L0.1229 -1.3128L0.2709 -1.5586L0.2604 -1.7859L0.3423 -1.8943L1.4233 -2.0L1.5185 -1.9947L1.5608 -1.8784L1.4789 -1.8176L1.4815 -1.6247L1.5291 -1.6141L1.6242 -1.3815L1.6824 -1.2731L1.8304 -1.1859L1.7485 -1.0749L1.6559 -0.8264L1.6612 -0.7604L1.6612 -0.7577L1.5925 -0.6652L1.3811 -0.3824L0.9714 0.1938L0.4004 1.0132L0.078 1.4599L-0.2339 1.9093L0.1256 3.4344L0.1493 3.5348L0.0463 3.5612L-1.085 3.8731L-1.5053 4.0L-1.5053 3.9894L-1.5291 3.7278L-1.6401 3.4185L-1.8251 3.1885L-1.8304 3.0722L-1.7405 2.911L-1.6956 2.6996L-1.759 2.5278L-1.7485 2.3559L-1.7802 2.2846L-1.6718 2.0493L-1.6031 1.9595L-1.6084 1.7031L-1.4445 1.5471L-1.4604 1.3674Z",
  "CO": "M3.9122 2.506L3.596 2.5192L1.989 2.5785L1.8309 2.5851L1.6992 2.5917L-0.2569 2.7168L-1.5609 2.8222L-3.1877 2.9802L-3.3458 3.0L-3.3655 2.8288L-3.7212 -0.6026L-3.8463 -1.8013L-3.899 -2.3216L-3.9122 -2.4929L-3.708 -2.5126L-0.3886 -2.8024L1.0735 -2.8946L2.5291 -2.9605L2.7398 -2.9671L2.8716 -2.9737L3.1482 -2.9802L3.2799 -2.9868L3.6948 -3.0L3.8002 -3.0L3.8002 -2.8683L3.8727 0.6158L3.8793 1.0044L3.8793 1.1295L3.8858 1.2151L3.9122 2.506Z",
  "CT": "M-0.86 1.9L-0.9 2.0L-2.52 1.52L-2.88 1.42L-2.84 1.22L-2.08 -1.72L-1.84 -2.02L-2.36 -2.66L-2.0 -3.0L-0.84 -1.88L-0.22 -1.02L-0.1 -1.26L2.82 0.04L2.88 0.1L2.86 0.58L2.74 1.0L2.12 2.74L2.04 2.96L2.02 3.0L-0.86 1.9Z",
  "DE": "M-1.8881 1.972L-0.1468 -3.0L1.8462 -2.4126L1.8252 -2.3706L1.8671 -2.3706L1.9301 -2.3916L1.972 -2.3706L1.9301 -2.2867L1.4266 -0.9441L-1.028 1.3846L-0.9231 3.0L-1.4266 3.0L-1.8042 2.7063L-1.972 2.2867L-1.8881 1.972Z",
  "DC": "M-2.3437 0.5626L-1.9688 0.375L-1.5937 0.5626L-1.2187 0.375L0.0938 -0.375L0.4688 -0.9375L0.4688 -2.25L0.8438 -3.0L3.2813 1.5L0.6563 3.0L-2.5312 2.8124L-3.2813 1.3126L-2.3437 0.5626Z",
  "FL": "M2.0943 2.7862L1.9517 3.0115L1.7632 3.3931L1.6345 3.7149L1.5333 3.8299L1.5103 3.977L1.5057 3.9816L1.1885 4.0L1.0644 3.9034L0.9954 3.5494L0.931 3.7149L-1.2391 3.4437L-1.3954 3.6874L-1.3908 3.692L-1.5241 3.669L-3.5103 3.3471L-3.3035 2.8736L-3.2897 2.869L-3.3402 2.6805L-3.1931 2.7448L-3.0322 2.8874L-2.7563 2.8966L-2.4391 3.0115L-2.223 2.9793L-2.2736 2.9011L-1.8782 2.7678L-1.5379 2.6023L-1.4598 2.4184L-1.2069 2.4736L-0.6092 2.9609L-0.3885 2.9517L0.5126 2.2713L0.6965 2.308L0.8897 2.0506L0.9264 1.6414L0.8667 1.3839L0.8942 1.2552L1.0552 0.9333L0.9816 1.1862L1.1885 1.1908L1.2069 1.0069L1.0965 0.7218L1.4782 0.1977L1.6207 0.1931L1.5011 0.354L1.6483 0.3356L1.7356 0.3908L1.6988 0.0966L1.8965 -0.0598L2.0299 -0.4184L2.2092 -0.5287L2.4391 -0.5885L2.7563 -0.9931L2.9218 -1.0805L2.7011 -1.0943L2.8023 -1.2092L3.1655 -1.0897L3.3448 -0.9885L3.5103 -0.4092L3.4276 0.492L3.4092 0.5839L2.9678 1.3195L2.5908 1.8529L2.3793 2.2897L2.5586 2.1379L2.5366 2.2479L2.0943 2.7862Z",
  "GA": "M-0.3252 3.9809L-0.5675 3.9299L-1.4602 3.745L-1.543 3.7258L-1.6323 3.7131L-2.1297 3.6174L-2.2636 3.5919L-2.8055 3.4899L-2.9203 3.4772L-2.9139 3.4644L-2.0595 0.8629L-1.5877 0.0213L-1.7024 -0.1063L-1.7726 -0.6036L-1.6323 -0.8587L-1.6196 -1.3241L-1.4474 -1.6493L-1.4474 -1.6557L-1.4538 -1.6621L-1.237 -2.0L1.7726 -1.6238L1.8619 -1.8533L1.9575 -1.3624L2.1297 -1.2285L2.5696 -1.254L2.5314 -0.5654L2.6142 -0.4187L2.5696 -0.1445L2.6589 0.1169L2.7928 0.2635L2.6971 0.3528L2.9203 0.627L2.9139 0.627L2.5314 0.9841L2.0085 1.7301L1.7216 1.864L0.491 2.8969L0.1913 3.2795L-0.3889 3.5218L-0.4208 3.6493L-0.2423 4.0L-0.3252 3.9809Z",
  "HI": "M3.2277 -0.1381L3.2685 -0.5188L3.017 -0.8312L3.2345 -1.3273L3.2957 -1.8369L3.5471 -2.0L3.7032 -1.7485L3.9819 -1.5448L4.3218 -1.4565L4.6954 -1.137L4.3286 -0.8244L4.3218 -0.6343L3.6219 -0.2469L3.4112 -0.0905L3.2277 -0.1381ZM2.2696 0.6977L2.4463 0.6841L2.5007 0.4598L2.691 0.3782L3.1121 0.5753L2.7182 0.9016L2.3036 1.0101L2.188 0.9559L2.2696 0.6977ZM0.1225 1.7441L0.3125 1.3907L0.4485 1.5334L0.8359 1.4519L0.8019 1.615L0.5776 1.8257L0.4213 1.975L0.1225 1.7441ZM-1.9093 2.4372L-1.719 2.3013L-1.4542 2.2333L-1.2639 2.6071L-1.3862 2.7428L-1.8618 2.5663L-1.9093 2.4372ZM1.6649 1.112L1.9365 1.0441L1.794 1.2684L1.4338 1.2888L1.3726 1.1052L1.6649 1.112Z",
  "ID": "M-0.5347 2.9625L-0.6435 2.9887L-0.6923 3.0L-1.1276 1.0413L-1.1088 0.8799L-1.1238 0.7111L-1.1163 0.6735L-1.1163 0.6698L-1.1126 0.5985L-0.985 0.4597L-1.1689 -0.0019L-1.5591 -0.5122L-1.5704 -0.606L-1.469 -0.8462L-1.8255 -2.3246L-1.8405 -2.4034L-1.7355 -2.4259L-0.6735 -2.636L-0.2495 -2.7148L-0.1407 -2.7336L-0.0356 -2.7523L0.5047 -2.8386L1.4728 -2.985L1.5816 -3.0L1.5966 -2.8762L1.6304 -2.6323L1.758 -1.6567L1.8255 -1.1726L1.8405 -1.0488L1.818 -1.0375L1.7017 -0.8274L1.6041 -0.955L1.2101 -0.9137L0.8987 -0.9175L0.8612 -0.9475L0.7148 -0.6473L0.6773 -0.6173L0.4672 -0.1032L0.4371 0.0694L0.0657 0.0957L0.137 0.3021L0.1895 0.3171L0.3208 0.9325L0.2083 0.925L0.182 1.0037L-0.0957 1.469L-0.1932 1.5516L-0.2645 1.7467L-0.2308 1.7767L-0.2871 1.9906L-0.3583 2.1295L-0.197 2.8949L-0.2008 2.8949L-0.5347 2.9625Z",
  "IL": "M-1.2945 2.6987L-1.1004 2.5762L-1.0391 2.4536L-0.8349 2.2596L-0.84 2.0962L-0.9523 1.7745L-1.2077 1.5906L-1.4374 1.5447L-1.4834 1.2792L-1.4068 1.2434L-1.4834 0.7226L-1.6723 0.6051L-1.6366 0.406L-1.6826 0.3855L-1.7234 0.1609L-1.56 -0.2936L-0.9779 -0.7838L-0.9268 -1.0187L-0.8145 -1.1362L-0.4468 -1.1157L-0.5132 -1.4579L-0.5387 -1.8255L-0.3191 -2.0043L0.0281 -2.1574L0.2119 -2.3004L0.314 -2.5915L0.2579 -2.6987L0.6204 -2.9949L0.6357 -3.0L0.6051 -2.9694L0.7072 -2.7294L1.1004 -2.8366L1.1974 -2.7243L1.1209 -2.4996L1.4426 -2.3106L1.3557 -2.1881L1.4323 -2.0145L1.4323 -1.9991L1.4119 -1.994L1.417 -1.5396L1.5294 -1.4579L1.7234 -1.0034L1.6826 -0.8043L1.5549 -0.5643L1.6264 -0.2936L1.2689 2.2238L1.2179 2.28L1.0851 2.5813L0.9779 2.6936L0.9319 2.9898L0.9319 3.0L0.2426 2.903L-0.1711 2.8519L-1.2843 2.7243L-1.2945 2.6987Z",
  "IN": "M0.0133 2.8208L-0.219 2.7743L-1.042 2.6416L-1.2146 2.5022L-1.4734 2.3827L-1.573 2.3894L-1.6925 2.4491L-1.7323 2.4889L-1.2677 -0.7832L-1.3606 -1.135L-1.1947 -1.4469L-1.1416 -1.7058L-1.3938 -2.2965L-1.5398 -2.4027L-1.5465 -2.9934L-1.5199 -3.0L-1.4934 -2.9934L-1.3938 -2.8075L-1.1482 -2.8075L-0.8496 -2.708L-0.4779 -2.8473L-0.4381 -2.6947L-0.2257 -2.5354L0.0929 -2.5819L0.2655 -2.2434L0.7367 -2.2832L0.7301 -2.0907L1.1482 -1.5398L1.0819 -1.2876L1.3739 -1.281L1.7323 -0.9956L1.646 -0.6704L1.6593 -0.6571L1.646 -0.5442L1.2478 1.8053L1.0686 2.8075L1.0487 2.9137L1.0288 3.0L0.0133 2.8208Z",
  "IA": "M3.3041 1.5295L3.0318 1.9017L2.941 2.4372L3.0136 2.6823L2.7504 3.0L2.3056 2.9546L-2.1604 2.5825L-3.9486 2.4826L-4.4024 2.4554L-4.593 2.4463L-4.4387 1.4387L-4.5658 0.9576L-4.3661 0.6581L-4.3661 0.5946L-4.3026 0.5855L-4.0121 -0.2678L-3.7307 -0.7126L-3.7125 -1.2209L-3.4039 -1.6475L-3.4584 -1.829L-3.177 -2.9728L-3.177 -3.0L-1.3162 -2.9455L0.6717 -2.7731L2.6233 -2.528L3.0681 -2.9183L3.0862 -2.9365L3.1679 -2.9002L3.1044 -2.5461L3.4402 -2.3374L3.5764 -1.4115L3.4402 -1.348L3.5219 -0.8759L3.9304 -0.7943L4.3843 -0.4675L4.584 0.1044L4.593 0.3949L4.2299 0.7398L4.121 0.9576L3.7761 1.1755L3.7942 1.2209L3.8033 1.2663L3.3041 1.5295Z",
  "KS": "M5.5139 1.4259L5.0972 1.6667L5.0602 1.8519L4.7269 2.1482L5.0509 2.5463L4.9213 2.7778L4.6806 2.7685L4.4583 2.9259L4.3657 3.0L4.0509 2.9815L0.6343 2.8519L-0.9306 2.8148L-4.0417 2.8148L-5.2917 2.8333L-5.6435 2.8426L-5.6435 2.6667L-5.6528 2.1204L-5.7546 -2.7778L-5.7546 -2.963L-5.3565 -2.9722L-3.2083 -3.0L1.8194 -2.9259L5.4028 -2.7593L5.7546 -2.7315L5.7454 -2.4907L5.5139 1.4259Z",
  "KY": "M0.7723 3.8571L0.8884 3.4196L0.4063 3.0357L0.0134 3.0268L0.1027 2.6875L-0.4598 1.9464L-0.4509 1.6875L-1.0848 1.7411L-1.317 1.2857L-1.7455 1.3482L-2.0312 1.1339L-2.0848 0.9286L-2.5848 1.1161L-2.9866 0.9821L-3.317 0.9821L-3.4509 0.7321L-3.4866 0.7232L-3.4866 0.6964L-3.6205 0.3929L-3.4687 0.1786L-4.0312 -0.1518L-3.8973 -0.5446L-4.067 -0.7411L-4.7545 -0.5536L-4.933 -0.9732L-4.8795 -1.0268L-4.8616 -1.0625L-4.942 -1.7946L-4.9777 -1.8125L-5.2098 -1.9554L-5.2187 -1.9821L-4.9598 -1.9464L-3.1562 -1.7054L-3.2455 -1.3482L-2.942 -1.3393L0.692 -0.8571L3.2455 -0.4286L3.2812 -0.4018L3.3437 -0.3304L4.0134 0.0268L4.5312 0.7054L4.6027 0.9375L4.942 1.2054L5.3705 1.75L5.433 1.8393L5.4062 1.8482L5.183 1.8482L4.8973 2.0089L4.3884 2.625L4.2098 3.25L4.2098 3.2679L4.192 3.2857L3.817 3.5L3.6473 3.7857L3.308 3.4554L3.0937 3.4018L2.8259 3.5089L2.5937 3.3304L2.1116 3.5446L1.9866 3.4911L1.5313 3.8839L1.2991 4.0L0.8616 3.9464L0.7902 3.875L0.7723 3.8571Z",
  "LA": "M0.0882 4.0L-2.6838 3.7426L-3.3823 3.6912L-3.6103 3.6691L-3.6029 3.4926L-3.4559 2.0735L-3.2132 1.7353L-3.2059 1.5368L-2.9706 1.125L-2.7794 0.5221L-2.8456 0.1838L-2.8971 -0.6544L-3.0073 -0.9265L-2.9926 -0.9265L-2.9706 -1.1176L-3.0956 -1.2941L-2.1838 -1.1912L-1.4706 -1.3897L-0.9632 -1.4559L-0.7059 -1.2721L-0.8456 -1.0882L-0.375 -0.9412L-0.3897 -1.1029L-0.1471 -1.0441L0.0515 -1.3529L0.2206 -1.2941L0.6618 -1.5956L0.3897 -1.6912L1.0147 -1.875L1.25 -1.875L1.3897 -1.7059L1.6912 -1.5441L1.9044 -1.8529L2.0515 -1.5735L2.0074 -1.1324L2.4044 -1.2868L2.4485 -1.4191L2.9191 -1.4779L3.1544 -1.6691L3.125 -1.8235L3.4559 -1.5147L3.3309 -1.3529L2.7794 -1.2132L2.5735 -1.0956L2.5662 -0.875L2.7941 -0.9044L2.9706 -0.5441L2.7426 -0.2279L2.6176 -0.5294L2.3309 -0.4779L2.5294 -0.1544L2.3971 -0.1176L2.0735 -0.3456L1.6103 -0.375L1.4779 -0.2721L1.6912 0.1029L1.9412 0.1103L2.3676 -0.0294L2.6765 -0.0588L2.5074 0.1544L2.1838 0.7868L2.2721 1.1397L-0.2647 0.8603L-0.1691 0.9559L-0.2721 1.2206L-0.1176 1.4485L-0.0294 2.0221L0.5294 2.9779L0.2574 3.3088L0.2721 3.5515L0.1029 3.9485L0.1029 4.0L0.0882 4.0Z",
  "ME": "M-0.9217 -1.4096L-0.9096 -1.4518L-0.5301 -1.9157L-0.5301 -1.9096L-0.4699 -1.8916L-0.4578 -1.5482L-0.3614 -1.4277L-0.3012 -1.1084L-0.3554 -1.0542L-0.253 -0.741L0.0964 -0.5783L0.3253 -0.3012L0.4096 0.1807L0.7771 0.2048L0.7289 0.4518L1.1868 0.6265L1.2169 0.8976L1.3253 0.8735L1.6265 1.1205L1.759 1.4398L1.4518 1.747L1.1265 1.8133L1.006 2.0602L1.006 2.2048L0.8072 2.1446L0.5843 2.2229L0.5843 2.4699L-0.0181 3.7711L-0.4578 4.0L-0.9458 3.5904L-1.0783 3.6145L-1.1928 3.8253L-1.3494 3.7651L-1.5723 2.6566L-1.494 2.2952L-1.4277 1.5602L-1.6024 0.994L-1.4819 0.9036L-1.6265 0.6265L-1.7831 0.6506L-1.7952 0.6386L-0.9217 -1.4096Z",
  "MD": "M2.4 -0.9644L2.137 0.5315M2.137 0.5315L2.2192 1.2055M2.2192 1.2055L1.874 1.9452M1.874 1.9452L2.2356 2.2411M2.2356 2.2411L2.6794 1.3041M2.6794 1.3041L2.9425 1.863M2.9425 1.863L3.0904 0.9425M3.0904 0.9425L2.811 0.2685M2.811 0.2685L3.6164 -0.0274M3.6164 -0.0274L2.926 -0.2575M2.926 -0.2575L3.2877 -0.9315M3.2877 -0.9315L3.8794 -1.1288M3.8794 -1.1288L4.2082 -0.5534M4.2082 -0.5534L4.1753 -1.3589M4.1753 -1.3589L4.7342 -1.3425M4.7342 -1.3425L5.0959 -1.7534M5.0959 -1.7534L5.1123 -1.7534M5.1123 -1.7534L5.2274 -1.6055M5.2274 -1.6055L5.5562 -1.4575M5.5562 -1.4575L5.7699 -1.3753M5.7699 -1.3753L5.7534 -1.3425M5.7534 -1.3425L6.1808 -0.0767M6.1808 -0.0767L6.1808 -0.0274M6.1808 -0.0274L6.0986 0.3014M6.0986 0.3014L6.0986 0.3178M6.0986 0.3178L4.537 -0.1425M4.537 -0.1425L3.1726 3.7534M3.1726 3.7534L3.1068 4.0M3.1068 4.0L2.8274 3.9178M2.8274 3.9178L1.6603 3.5562M1.6603 3.5562L-4.7671 1.7644M-4.7671 1.7644L-5.9342 1.4521M-5.9342 1.4521L-6.2301 1.3699M-6.2301 1.3699L-5.852 -0.3562M-5.852 -0.3562L-5.0959 0.7123M-5.0959 0.7123L-4.7178 0.7945M-4.7178 0.7945L-3.9945 1.3041M-3.9945 1.3041L-3.5014 1.4192M-3.5014 1.4192L-3.5014 1.7479M-3.5014 1.7479L-1.463 1.2548M-1.463 1.2548L-1.4137 1.2383M-1.4137 1.2383L-0.6575 1.0411M-0.6575 1.0411L-0.1151 0.6466M-0.1151 0.6466L0.411 0.4164M0.411 0.4164L0.4603 0.3671M0.4603 0.3671L0.526 0.4986M0.526 0.4986L0.8055 0.5151M0.8055 0.5151L1.0356 0.3836M1.0356 0.3836L0.8219 -0.011M0.8219 -0.011L0.8219 -0.0274M0.8219 -0.0274L0.5096 -0.9808M0.5096 -0.9808L1.5288 -1.6384M1.5288 -1.6384L2.5479 -1.6877M2.5479 -1.6877L3.2219 -2.0M3.2219 -2.0L2.9425 -1.211M2.9425 -1.211L2.4 -0.9644M2.4 -0.9644L2.9425 -1.211",
  "MA": "M1.9325 -0.8037L1.9877 -1.0245L2.1534 -1.3374L3.0184 -0.2883L3.7362 -0.5276L3.9018 -0.1595L5.0798 0.4663L4.5828 1.4417L4.7117 0.816L4.1043 0.3558L3.4233 0.3006L2.0614 1.7546L1.454 2.6748L2.0614 3.319L1.4356 3.3558L1.1779 3.9632L1.1595 4.0L0.1104 2.8589L-2.2086 2.0859L-2.9632 1.8466L-3.092 1.7914L-4.1411 1.4785L-4.5092 1.3497L-4.9325 1.2209L-5.0798 1.1656L-5.0798 1.1104L-5.0245 0.1534L-4.9693 -0.7301L-4.9141 -1.5399L-4.8221 -1.5951L-4.4908 -1.5031L-3.0 -1.0613L-2.9632 -1.1534L-0.3129 -0.1411L-0.2945 -0.1779L-0.0552 -0.0859L0.7914 0.2454L1.7485 -0.8037L1.9325 -0.8037Z",
  "MI": "M0.6798 -1.9072L0.8422 -1.8747L1.5522 -1.7494L1.5661 -1.8097L1.6125 -1.7958L1.9188 -1.7309L2.2111 -1.6613L2.5081 -1.587L2.5035 -1.5731L2.6566 -1.2715L2.6427 -1.0209L2.8144 -0.5893L2.949 -0.6682L3.0 -0.5708L2.9861 -0.1995L2.8608 0.0325L2.6752 0.5244L2.5128 0.7053L2.4292 0.7285L2.1276 0.5197L2.1601 0.478L2.0023 0.181L1.8492 0.2088L1.8028 0.5197L1.8678 0.5429L2.0023 0.8538L2.0162 1.4014L1.8074 1.8654L1.3434 1.9443L1.297 2.0139L0.8654 2.1392L0.6705 1.8979L0.6891 1.6891L0.5406 1.5035L0.5731 1.2158L0.4292 1.1137L0.4292 1.4756L0.2993 1.2019L0.1462 0.9606L0.0441 0.8817L0.0951 0.6265L0.0302 0.362L0.0673 0.0325L0.0209 -0.1299L0.355 -0.7703L0.3968 -1.0162L0.355 -1.471L0.1322 -1.9814L0.1044 -2.0L0.6798 -1.9072ZM-2.8515 2.2274L-2.0905 1.9954L-1.7378 1.9026L-1.3944 1.8794L-1.1439 1.6752L-1.1439 1.3596L-0.9211 1.1369L-0.9211 1.1415L-0.7401 1.6659L-0.522 1.819L-0.355 2.0046L-0.3271 1.8376L-0.1554 2.1021L0.2575 2.2599L0.29 2.3248L0.7077 2.2738L0.8701 2.1903L0.949 2.3944L1.0232 2.348L1.348 2.3991L1.2691 2.529L1.1253 2.6125L1.1531 2.9142L0.8701 2.7564L0.6102 2.7889L0.5267 2.9884L0.5545 3.0534L0.2019 2.8863L-0.0255 2.8724L-0.4339 2.6218L-0.4803 2.529L-0.7309 2.5893L-0.8886 2.5197L-1.2042 2.7935L-1.9049 3.007L-1.9188 3.0905L-2.3689 2.6589L-2.6241 2.594L-2.9861 2.3202L-3.0 2.3109L-2.8515 2.2274Z",
  "MN": "M-2.5008 2.2052L-2.2973 0.9645L-2.2003 0.4701L-2.1712 0.0339L-2.0598 -0.2278L-2.0355 -0.4507L-2.0162 -0.5234L-2.021 -0.538L-2.2149 -0.8578L-2.0889 -1.0711L-1.8465 -2.7771L-1.8368 -3.0L-1.5945 -2.9855L-0.6397 -2.9321L1.7447 -2.7334L1.9822 -2.7092L1.9822 -2.685L1.9144 -2.3118L1.6187 -2.1616L1.3861 -1.9192L1.0178 -1.6963L0.9015 -1.6866L0.7221 -1.5509L0.7706 -0.8821L0.601 -0.727L0.6591 -0.4556L0.9693 -0.1745L0.916 0.4071L1.0275 0.5331L1.4055 0.9305L1.8368 1.483L2.0016 1.5994L2.2924 1.7399L2.5977 1.9677L2.3942 1.9289L2.273 2.021L1.8078 1.958L1.7351 2.0452L1.3183 1.8756L0.9838 2.0162L0.8918 2.1276L0.6252 2.1082L0.4459 2.2003L0.504 2.2682L0.2763 2.3409L0.0727 2.3409L-0.252 2.2003L-0.315 2.2973L-0.8288 2.37L-1.0032 2.8061L-1.0226 2.937L-1.2456 3.0L-1.2262 2.6268L-1.8174 2.5832L-2.5493 2.5541L-2.5977 2.5493L-2.5008 2.2052Z",
  "MS": "M-0.815 3.7254L-1.0604 3.3164L-1.037 3.001L-1.37 2.6329L-1.2824 2.5277L-1.5044 1.593L-1.3992 1.3943L-1.4576 1.3535L-1.4518 1.2249L-1.4518 1.184L-1.3174 0.8685L-1.3291 0.6758L-1.1129 0.4128L-1.557 -0.3466L-1.6271 -0.8023L-1.7498 -0.9834L-1.668 -1.1938L-1.7439 -1.2697L0.2717 -1.0477L0.2016 -1.3281L0.4586 -1.8306L0.593 -2.0L0.593 -1.9942L0.8092 -1.7488L1.2648 -1.6495L1.6855 -1.6962L1.7498 -1.6144L1.7498 -1.6086L1.4518 0.1909L1.4401 1.5054L1.4051 3.9007L1.3233 3.9533L1.2999 4.0L1.2882 4.0L0.9961 3.9591L0.482 3.889L-0.6865 3.7429L-0.815 3.7312L-0.815 3.7254Z",
  "MO": "M0.424 3.0L-0.9469 2.8278L-2.3433 2.7067L-3.6504 2.6684L-3.6567 2.6302L-3.4973 2.3241L-3.3507 2.2221L-3.1785 1.916L-3.153 1.8905L-3.0893 1.8395L-2.9362 1.7311L-2.7705 1.7375L-2.6812 1.5781L-2.9044 1.3039L-2.6748 1.0999L-2.6493 0.9724L-2.3624 0.8066L-2.203 -1.8905L-2.1966 -2.0563L-2.1838 -2.2221L-2.1775 -2.3879L-2.1647 -2.56L-2.1583 -2.7258L-1.8587 -2.7067L0.1945 -2.5409L2.6238 -2.305L2.745 -2.5537L2.4644 -3.0L3.153 -2.898L3.1594 -2.898L3.1594 -2.847L3.1722 -2.5537L3.2997 -2.3943L3.2614 -2.2157L3.2551 -2.2093L3.2487 -2.203L3.2742 -2.1201L3.3124 -2.1328L3.3316 -2.1838L3.3252 -2.203L3.3252 -2.2604L3.3507 -2.2667L3.4017 -2.203L3.4017 -2.1966L3.4081 -2.1775L3.5739 -2.0754L3.5994 -2.0627L3.6567 -1.5398L3.644 -1.5143L3.6249 -1.508L3.1722 -1.1381L3.2423 -1.0042L3.1148 -0.6408L2.8852 -0.4623L2.4516 -0.271L2.1775 -0.0478L2.2094 0.4113L2.2922 0.8385L1.8332 0.813L1.6929 0.9596L1.6291 1.2529L0.9022 1.865L0.6982 2.4325L0.7492 2.7131L0.7365 2.7258L0.424 3.0Z",
  "MT": "M2.7802 1.9439L1.4044 2.0752L0.0342 2.235L-0.8164 2.3492L-2.1751 2.5604L-2.8544 2.6746L-4.373 2.9657L-4.5328 3.0L-4.7783 1.8354L-4.6698 1.6242L-4.5842 1.2988L-4.6356 1.2531L-4.5271 0.9562L-4.3787 0.8306L-3.9562 0.1227L-3.9163 0.0029L-3.745 0.0143L-3.9448 -0.922L-4.0247 -0.9448L-4.1332 -1.2588L-3.568 -1.2988L-3.5224 -1.5614L-3.2027 -2.3435L-3.1456 -2.3892L-2.9229 -2.8459L-2.8658 -2.8002L-2.392 -2.7945L-1.7926 -2.8573L-1.6442 -2.6632L-1.4672 -2.9829L-1.4329 -3.0L-1.4272 -2.9657L-1.3473 -2.4063L0.6051 -2.6461L2.1636 -2.7945L2.9857 -2.863L4.4529 -2.9486L4.5271 -2.9543L4.5442 -2.9543L4.5499 -2.8744L4.5671 -2.4805L4.5899 -1.961L4.5956 -1.8297L4.607 -1.6013L4.6984 0.1912L4.7726 1.5842L4.7783 1.8126L4.6698 1.8183L2.7802 1.9439Z",
  "NE": "M4.0841 2.3364L3.0654 2.6355L2.7383 2.6449L2.243 2.5047L1.6542 2.8785L-0.1869 2.8598L-2.8691 2.8878L-6.0374 2.9907L-6.3177 3.0L-6.3271 2.757L-6.3645 2.028L-6.4486 0.3178L-6.4953 -0.6635L-6.5047 -0.9065L-6.2804 -0.9159L-4.0 -1.0L-3.5514 -1.0187L-3.5888 -2.8505L-3.5981 -2.972L-3.243 -2.9813L-1.9813 -3.0L1.1589 -3.0L2.7383 -2.9626L6.1869 -2.8318L6.5047 -2.8131L6.4673 -2.7757L6.2149 -2.3271L6.0 -2.1776L5.7663 -1.729L5.7757 -1.6729L5.7757 -1.6449L5.486 -0.4673L5.542 -0.2804L5.2243 0.1589L5.2056 0.6822L4.9159 1.1402L4.6168 2.0187L4.5514 2.028L4.3738 2.0467L4.0841 2.3364Z",
  "NV": "M1.718 0.9754L1.9138 2.1724L1.936 2.2906L1.8288 2.3091L1.4113 2.3867L0.3658 2.5936L0.2623 2.6158L0.1589 2.6379L-0.3621 2.7525L-1.2968 2.9741L-1.4002 3.0L-1.4335 2.8596L-1.936 0.7278L-1.5 0.0998L-1.0493 -0.5246L-0.2512 -1.67L0.3214 -2.4754L0.617 -2.8707L0.7131 -3.0L0.7131 -2.9889L0.7722 -2.8633L0.7759 -2.2759L0.9384 -2.1392L1.0677 -2.2537L1.1527 -2.2574L1.3153 -1.5406L1.3153 -1.5333L1.3337 -1.415L1.718 0.9754Z",
  "NH": "M-0.9573 2.8362L-1.1109 2.8669L-1.1928 2.1911L-1.1928 2.1809L-0.9471 1.2594L-0.9676 1.0751L-1.4079 0.4915L-1.2952 -0.041L-1.2952 -0.7065L-1.3771 -1.3208L-0.9164 -3.0L-0.4966 -2.8669L0.7935 -2.4369L1.3771 -1.802L1.3771 -1.6382L1.4079 -1.372L1.3362 -1.4027L1.3259 -1.3413L0.6809 -0.5529L0.6604 -0.4812L-0.8242 3.0L-0.9573 2.8362Z",
  "NJ": "M-0.4294 3.8926L-0.6441 3.9523L-0.8469 4.0L-1.3479 2.8907L-1.1332 2.6282L-1.1451 1.829L-0.9185 1.7932L-0.4533 0.5765L-0.4771 0.5408L-1.0616 -0.3181L-1.002 -0.9742L0.4056 -1.4513L0.4175 -2.0L0.7396 -1.5467L0.7873 -0.8907L1.1213 -0.664L0.9901 -0.1749L1.2406 0.4095L1.2406 1.6859L1.1571 2.1034L0.5964 2.0915L0.7396 2.664L0.8231 3.5825L-0.4294 3.8926Z",
  "NM": "M2.8188 2.3758L2.8238 2.5067L2.8289 2.6376L2.6678 2.6426L1.5554 2.693L0.4379 2.7634L-2.099 2.9849L-2.255 3.0L-2.2752 2.8188L-2.6527 -1.0772L-2.6879 -1.4446L-2.8289 -2.9295L-2.8087 -2.9346L-2.1141 -3.0L-2.0487 -2.6477L-1.9228 -2.547L-0.5134 -2.6728L-0.5034 -2.6728L-0.6292 -2.4262L0.0101 -2.4715L1.6057 -2.5621L2.6023 -2.6074L2.7735 2.1141L2.8087 2.1091L2.8138 2.245L2.8188 2.3758Z",
  "NY": "M2.3072 -1.2182L2.1928 -1.1102L2.3581 -0.9068L2.2818 -0.8114L2.0403 0.1229L2.0275 0.1864L1.9958 0.2055L1.9767 0.4852L1.9576 0.7903L1.9386 1.1208L1.9386 1.1398L1.9258 1.1335L1.6081 2.1758L1.2712 2.7034L1.1758 3.3326L0.8898 4.0L0.8517 3.9809L-0.4767 3.536L-0.7436 3.25L-1.0424 2.678L-1.0297 2.5508L-1.4237 1.9534L-1.1822 1.8708L-1.1123 1.4195L-1.392 1.0508L-2.2309 0.5424L-2.3898 0.6059L-3.0127 0.5169L-3.5911 0.1674L-3.5593 -0.017L-3.3559 -0.1949L-3.4703 -0.7542L-3.928 -1.2881L-3.9343 -1.2945L-3.8898 -1.4852L-3.553 -1.5678L-1.6208 -1.0403L0.3242 -0.4555L0.6801 -0.7288L0.75 -0.8877L1.1631 -1.0657L1.1758 -1.0911L1.2839 -1.1165L1.3983 -1.1483L2.0657 -1.3136L2.053 -1.2818L2.1165 -1.6949L2.142 -1.8856L2.4153 -1.7775L2.8157 -1.4534L3.2606 -1.2182L3.9343 -0.5826L3.4576 -0.9449L3.3051 -1.0085L3.5593 -0.589L3.1335 -0.9831L2.4788 -1.2627L2.393 -1.3358L2.3072 -1.2182Z",
  "NC": "M5.1269 3.9194L1.5448 2.8985L-0.7388 2.3164L-3.0493 1.8866L-3.0582 1.9045L-3.103 1.7701L-3.094 1.3851L-3.1925 1.394L-3.4254 0.9194L-3.6851 0.8925L-4.1687 0.4179L-4.3836 0.4985L-4.6612 0.0866L-5.3955 -0.5761L-5.7537 -0.6657L-6.1298 -1.0149L-6.1478 -1.2478L-6.4791 -1.4269L-6.4701 -1.8209L-6.4701 -1.9104L-6.3537 -1.8836L-5.1 -1.6239L-4.7597 -1.5522L-4.6433 -1.5254L-4.6164 -1.5254L-1.6433 -0.5851L-1.1418 -0.8448L-0.1299 -0.8806L0.6134 -0.7373L2.6104 -2.0L2.6463 -1.9731L3.5687 -1.597L3.5776 -1.1045L3.909 -0.5045L4.2672 -0.1642L5.0104 0.3284L5.109 0.5075L5.306 0.4179L5.3955 1.009L5.2881 0.803L4.9119 0.6776L4.5537 0.6687L4.9925 0.991L4.8582 1.1343L5.0104 1.4567L4.1687 1.591L4.1328 1.6358L4.8493 1.7701L5.0821 1.6537L5.3776 1.6806L5.6194 1.8597L5.7985 2.209L5.9597 2.2537L5.9328 2.7463L5.4134 2.8627L5.297 2.8895L4.8537 2.706L4.8224 3.0597L5.2343 3.1582L5.3328 3.7403L5.1269 3.9194Z",
  "ND": "M0.9387 2.8712L-1.0031 2.8804L-2.6687 2.9172L-4.6196 2.9908L-4.7209 3.0L-4.7301 2.6319L-4.8497 0.3865L-4.9969 -2.5031L-5.0153 -2.8712L-4.6564 -2.8896L-0.5982 -3.0L3.1472 -2.9724L4.7025 -2.9264L5.0153 -2.908L4.9785 -2.7699L4.9325 -2.3466L4.7209 -1.8497L4.6656 -1.0215L4.4816 -0.0828L4.0951 2.273L3.911 2.9264L3.7178 2.9264L0.9387 2.8712Z",
  "OH": "M2.3859 1.9206L2.3486 2.0844L2.1402 3.0L1.8722 2.8288L1.299 2.3896L0.9268 1.9355L0.6365 1.8908L0.1005 1.5856L-0.1973 1.7643L-1.098 1.8685L-1.5744 1.7494L-2.0434 1.6377L-2.5347 1.5335L-2.6092 1.5112L-2.5868 1.3921L-2.3859 0.268L-1.9392 -2.3672L-1.9243 -2.4938L-1.8648 -2.4342L-1.5 -2.3896L-1.3065 -2.4864L-0.9268 -2.8139L-0.8226 -2.7692L-0.4206 -2.9479L-0.227 -2.799L-0.0037 -2.8883L0.1749 -2.8437L0.4578 -2.5682L0.5993 -2.8065L0.9119 -2.9851L0.9268 -3.0L1.2469 -2.8437L1.3586 -2.598L1.2692 -2.3449L1.5223 -1.9578L1.7084 -1.928L1.6861 -1.6005L1.9913 -1.1836L2.0434 -1.2432L2.3189 -1.0347L2.5571 -0.6774L2.5943 0.9529L2.6092 0.9603L2.572 1.1166L2.3859 1.9206Z",
  "OK": "M5.7529 2.5568L5.7443 2.7784L5.7273 3.0L5.4034 2.9744L2.1051 2.821L-2.5227 2.7528L-4.5 2.7784L-4.8665 2.7869L-5.0028 2.7869L-5.5398 2.804L-5.7102 2.8125L-6.0682 2.821L-6.2386 2.8296L-6.2642 2.1648L-6.2727 1.9347L-6.2216 1.9347L-4.1591 1.8835L-1.9773 1.858L-1.9517 -1.0568L-1.7983 -1.5682L-1.4574 -1.892L-1.0909 -1.9091L-1.0142 -1.7557L-0.5114 -2.2159L0.1449 -2.2841L0.2642 -2.4205L0.5028 -2.3097L1.0994 -2.5909L1.0994 -2.7528L1.321 -2.7443L1.5511 -2.5483L1.9773 -2.821L2.3778 -2.9659L2.6506 -2.5909L3.5199 -3.0L3.8438 -2.7358L4.108 -2.6335L4.321 -2.6847L5.1222 -2.4545L5.5483 -2.6335L5.9318 -2.8807L6.2642 -2.9403L6.2727 -2.9403L6.2642 -2.5568L6.1705 -0.2386L6.1619 0.1449L5.8296 1.8665L5.7784 2.1051L5.7699 2.3267L5.7529 2.5568Z",
  "OR": "M-2.6297 -1.7459L-0.3162 -2.3838L-0.1054 -2.4378L0.0459 -2.4757L1.4135 -2.8L2.1757 -2.9676L2.327 -3.0L2.3486 -2.8865L2.8622 -0.7568L2.7162 -0.4108L2.7324 -0.2757L3.2946 0.4595L3.5595 1.1243L3.3757 1.3243L3.3703 1.427L3.3703 1.4324L3.2676 1.4541L1.7486 1.8L1.6676 1.7622L1.3703 1.8108L0.7703 1.746L0.4027 1.7946L0.0135 1.8L-0.0189 1.8973L-0.8405 1.973L-1.1324 2.1297L-1.1703 2.2324L-1.1378 2.6378L-1.3324 2.8487L-1.3486 2.8433L-1.3865 2.8162L-1.6568 2.9568L-1.8189 2.9081L-1.927 2.9622L-2.0405 2.4973L-2.1162 2.3676L-2.4514 1.5514L-2.5487 1.1027L-2.6081 1.0973L-2.9216 0.3514L-3.1324 0.0324L-3.2081 -0.0216L-3.4027 -0.4L-3.4405 -0.8L-3.5595 -1.1567L-3.4892 -1.4811L-3.4892 -1.4865L-2.6297 -1.7459ZM-1.527 3.0L-1.5324 2.9622L-1.5108 2.9514L-1.527 3.0Z",
  "PA": "M3.2328 2.4026L3.1362 2.6223L2.6442 3.0L-0.0439 2.1918L-2.7145 1.4627L-3.1801 1.5769L-3.2416 1.8404L-3.3909 1.7262L-3.6281 1.5593L-4.0586 1.1201L-4.1113 1.0761L-3.8653 -0.0044L-3.8214 -0.1977L-3.6018 -1.1464L-3.5578 -1.3309L-3.1713 -3.0L-1.9239 -2.6925L-1.7482 -2.6486L-1.59 -2.6047L-0.9663 -2.4378L2.4685 -1.4802L3.0922 -1.287L3.2416 -1.243L3.3119 -1.0673L3.47 -0.9444L3.6808 -0.9444L3.7072 -0.8653L4.0498 -0.4788L4.0849 -0.4173L4.1113 -0.3997L3.7687 0.4963L3.6018 0.5227L3.6105 1.1113L3.4524 1.3045L3.8214 2.1215L3.8038 2.1567L3.2328 2.4026Z",
  "RI": "M-2.259 2.7711L-1.1386 -0.3735L-0.9217 -1.1325L-0.8855 -2.0L-0.8132 -1.8916L0.9217 -0.5181L0.5964 1.9398L1.3193 2.0843L-0.2711 4.0L-1.9337 3.3494L-2.4036 3.1687L-2.259 2.7711ZM1.8614 1.0361L2.1867 0.4578L2.4036 0.8916L2.0783 1.506L1.9699 1.9398L1.6084 1.9398L1.6084 1.6867L1.8614 1.0361Z",
  "SC": "M-3.9653 1.4858L-3.918 1.2965L-3.0568 0.9369L-2.612 0.3691L-0.7855 -1.164L-0.3596 -1.3628L0.4164 -2.47L0.9842 -3.0L1.0789 -2.5647L0.9274 -2.1483L1.3344 -2.4511L1.5615 -2.2713L1.1735 -1.9495L1.6467 -1.9211L2.3849 -1.2965L2.2997 -1.1356L2.7539 -0.7855L2.7066 -0.6151L3.0379 -0.4069L3.0568 0.0757L3.5773 1.041L3.9274 1.4858L3.9653 1.5047L1.8549 2.8391L1.0694 2.6877L0.0 2.7256L-0.53 3.0L-3.6719 2.0063L-3.7003 2.0063L-3.9653 1.4858Z",
  "SD": "M2.8125 2.9083L-0.5792 2.8833L-4.2541 2.9833L-4.5791 3.0L-4.5875 2.8083L-4.6208 2.05L-4.6458 1.475L-4.6542 1.3583L-4.6792 1.3583L-4.6875 1.1417L-4.7708 -0.6083L-4.8375 -1.9083L-4.8458 -2.1333L-4.5958 -2.1417L-1.7708 -2.2333L0.6208 -2.2583L2.2625 -2.2417L2.7875 -2.575L3.2292 -2.45L3.5208 -2.4583L4.4292 -2.725L4.6875 -2.9833L4.8458 -3.0L4.8458 -2.9417L4.6625 -2.6667L4.7792 -2.225L4.6375 -1.3L4.8125 -1.2917L4.7958 -0.9083L4.3792 2.025L4.1625 2.3917L4.4958 2.9417L4.5042 2.9667L4.2208 2.95L2.8125 2.9083Z",
  "TN": "M0.8215 1.7314L-3.4107 1.1699L-3.7643 1.1595L-3.6603 0.7435L-5.7608 0.4627L-6.0624 0.4212L-6.0624 0.4108L-6.1456 0.3068L-6.1872 0.3172L-6.1872 0.4108L-6.3016 0.4004L-6.2912 0.39L-6.2288 0.0988L-6.4367 -0.1612L-6.4575 -0.6395L-6.4575 -0.7331L-6.7695 -1.3362L-6.7071 -1.7626L-6.9983 -2.2201L-6.8943 -2.636L-7.2686 -2.9792L-7.2478 -3.0L-7.0191 -2.9792L-4.9393 -2.7192L-4.0243 -2.5945L-3.5043 -2.5217L-3.4835 -2.5217L-3.4939 -2.5009L-1.747 -2.2617L-0.9151 -2.1577L0.7487 -1.8873L1.0607 -1.8354L1.2478 -1.8146L2.1317 -1.6482L2.3501 -1.6066L3.1612 -1.4506L3.3068 -1.4298L3.3068 -1.3258L3.2964 -0.8683L3.6811 -0.6603L3.7019 -0.3899L4.1386 0.0156L4.5546 0.1196L5.4073 0.8891L5.7296 1.3674L5.9792 1.2738L6.5407 1.825L6.8423 1.8562L7.1127 2.4073L7.227 2.3969L7.2166 2.844L7.2686 3.0L7.1022 2.9688L6.5095 2.8024L5.8024 2.6568L4.0243 2.2929L3.8371 2.2617L3.7955 2.2305L0.8215 1.7314Z",
  "TX": "M3.0095 0.215L3.0694 0.2177L3.1075 0.3918L3.1184 0.3918L3.1592 0.4925L3.1782 0.8027L3.2027 0.9279L3.132 1.151L3.0449 1.3034L3.0422 1.3769L2.9524 1.502L2.898 2.0272L2.8952 2.0925L2.8898 2.1605L2.8735 2.4L2.7102 2.3946L2.6694 2.4381L2.6612 2.4408L2.6585 2.4408L2.5524 2.4599L2.4299 2.5388L2.2939 2.5959L2.0381 2.5225L1.9701 2.5388L1.8857 2.5061L1.7823 2.4218L1.5048 2.5524L1.4177 2.4327L1.2898 2.4789L1.1537 2.566L1.0803 2.5034L1.0095 2.5007L1.0095 2.5524L0.819 2.6422L0.7429 2.6068L0.7048 2.6503L0.4952 2.6721L0.3347 2.819L0.3102 2.7701L0.1932 2.7755L0.0844 2.8789L0.0354 3.0422L0.0272 3.9728L-0.6694 3.981L-1.3279 3.9973L-1.3442 3.9973L-1.3633 4.0L-1.4558 1.4476L-1.9946 1.4721L-2.8571 1.5211L-3.2027 1.5456L-3.1347 1.4122L-3.132 1.4095L-2.8381 1.1156L-2.6694 0.917L-2.4463 0.751L-2.2884 0.4626L-2.2286 0.1605L-2.1007 0.0762L-2.0 -0.0354L-1.8558 -0.0871L-1.6463 -0.2204L-1.551 -0.2558L-1.4204 -0.1252L-1.3796 0.0136L-1.3116 0.1469L-1.0993 0.2313L-1.0177 0.1905L-0.781 0.1714L-0.566 0.0381L-0.419 0.0109L-0.4599 -0.0653L-0.3755 -0.1197L-0.2966 -0.215L-0.2803 -0.3156L-0.2286 -0.3837L-0.1088 -0.6721L0.0109 -0.7728L0.1034 -0.9252L0.2204 -1.0449L0.2721 -1.0612L0.3156 -1.3714L0.4435 -1.4422L0.449 -1.5401L0.4816 -1.532L0.7048 -1.8041L0.8163 -1.8286L0.9578 -1.9129L1.1755 -1.9129L1.3333 -2.0L1.5211 -1.9592L1.4367 -1.8803L1.3605 -1.6517L1.3306 -1.4531L1.2871 -1.3878L1.3061 -1.2544L1.249 -1.249L1.1728 -1.1238L1.2599 -1.2163L1.3605 -1.181L1.415 -1.0367L1.3061 -0.8898L1.4694 -0.9088L1.5456 -0.7918L1.5347 -0.751L1.6027 -0.6612L1.5864 -0.7401L1.6626 -0.6803L1.6327 -0.5742L1.7306 -0.6259L1.8558 -0.5551L1.7333 -0.4272L1.8095 -0.4571L1.951 -0.4653L2.1361 -0.4245L2.3429 -0.2857L2.468 -0.1823L2.4871 -0.1061L2.5633 -0.0272L3.0095 0.215Z",
  "UT": "M-0.6099 2.854L-1.3606 2.9739L-1.5065 3.0L-1.5378 2.8332L-1.8141 1.1442L-2.3562 -2.2285L-2.3823 -2.3953L-2.2467 -2.4162L-0.9018 -2.6299L0.0417 -2.7654L1.7984 -2.9844L1.934 -3.0L1.9444 -2.8645L1.9861 -2.4526L2.0851 -1.5039L2.3666 1.212L2.3823 1.3475L2.278 1.358L0.9487 1.5195L0.7454 1.5456L0.8758 2.5621L0.8862 2.6299L0.735 2.6507L-0.6099 2.854Z",
  "VT": "M1.0478 -0.7721L1.136 -0.1103L1.136 0.6066L1.0147 1.1801L1.489 1.8088L1.511 2.0074L1.2463 3.0L1.1029 2.9449L0.4963 2.7243L-0.0993 2.4926L-1.4669 1.9963L-1.5441 1.9743L-1.0478 0.8162L-0.8824 -0.2757L-0.2978 -1.1912L0.2537 -3.0L0.2757 -2.989L0.364 -2.9559L0.6176 -2.8787L0.8382 -2.8015L1.4669 -2.614L1.5441 -2.5809L1.0478 -0.7721Z",
  "VA": "M3.7939 2.0076L3.8626 2.1221L3.3435 2.3206L2.8931 2.3511L2.6717 2.5496L2.4885 2.4351L2.4198 2.9618L2.4962 2.9466L2.4809 3.374L2.4656 3.3893L2.4504 3.3817L2.4351 3.3893L2.3969 3.4198L2.374 3.4427L2.1298 3.5496L1.8779 3.7328L1.5267 3.8244L1.5038 3.8321L1.5038 3.8244L1.458 3.5038L0.7481 3.8092L0.7634 3.3664L0.374 2.626L0.1374 2.3664L-0.0153 1.916L-0.4809 2.0763L-0.7099 1.0382L-0.8931 0.687L-0.9237 0.229L-1.0611 0.0076L-1.5725 -0.145L-1.9924 -0.4733L-2.1908 -0.5038L-2.3817 -0.6718L-2.8626 -0.4962L-2.9466 -0.2595L-2.9618 -0.2519L-3.0153 -0.3282L-3.3817 -0.7939L-3.6718 -1.0229L-3.7328 -1.2214L-4.1756 -1.8015L-4.7481 -2.1069L-4.8015 -2.1679L-4.6641 -2.145L-3.3588 -1.8779L-2.8397 -1.771L-2.4046 -1.6489L-2.2824 -1.626L-2.2748 -1.6412L-0.3053 -1.2748L1.6412 -0.7786L4.6946 0.0916L4.8855 0.2214L4.5725 0.7023L4.1832 0.6336L4.0954 0.5611L4.1908 0.8168L3.8473 1.2519L4.0916 1.2366L3.9618 1.5878L3.6565 1.5725L3.7939 2.0076ZM4.85 0.1972L4.6946 0.0916",
  "WA": "M-2.0049 1.8915L-1.8052 2.3354L-1.8718 2.402L-1.9141 2.3476L-1.9827 2.6979L-2.1899 2.7497L-2.2935 2.9273L-2.4192 2.8607L-3.0999 3.1566L-3.6991 3.6227L-3.8619 3.1714L-3.8767 2.979L-3.7509 2.6387L-3.8323 1.5364L-3.8915 1.3662L-3.5586 1.3366L-3.8915 1.0999L-3.7139 1.0111L-3.8545 0.5376L-3.9211 0.9075L-4.0469 0.4784L-3.8767 0.2713L-3.6917 0.2861L-3.4698 0.1529L-3.3588 -0.0616L-3.2256 -0.0617L-2.9593 -0.3502L-3.0037 -0.9051L-2.9519 -1.0456L-2.5524 -1.2602L-1.4279 -1.3637L-1.3835 -1.4969L-0.8508 -1.5043L-0.3477 -1.5709L0.4735 -1.4821L0.8804 -1.5487L0.9914 -1.4969L3.0703 -1.9704L3.2109 -2.0L3.1961 -1.926L3.2257 -1.5931L3.1887 -1.275L4.0469 2.5869L3.9285 2.6091L2.1973 3.016L0.688 3.4007L-1.021 3.8742L-1.5314 4.0L-1.4131 3.3564L-1.524 3.1492L-1.7238 3.2528L-1.5314 2.3576L-1.7386 2.0617L-1.8348 1.7879L-1.8348 1.5068L-2.2713 1.2035L-2.3637 1.2626L-2.0049 1.8915Z",
  "WV": "M-0.7309 1.4739L-1.0843 3.0L-1.1004 2.992L-1.1406 1.2329L-1.3976 0.8474L-1.6948 0.6225L-1.751 0.6867L-2.0803 0.2369L-2.0562 -0.1165L-2.257 -0.1486L-2.5301 -0.5663L-2.4337 -0.8394L-2.5542 -1.1044L-2.8996 -1.2731L-2.8996 -1.2892L-2.739 -1.8514L-2.2811 -2.4056L-2.0241 -2.5502L-1.8233 -2.5502L-1.7992 -2.5582L-1.7831 -2.5663L-1.6948 -2.8153L-1.1888 -3.0L-0.988 -2.8233L-0.7791 -2.7912L-0.3373 -2.4458L0.2008 -2.2851L0.3454 -2.0522L0.3775 -1.5703L0.5703 -1.2008L0.8112 -0.1084L1.3012 -0.2771L1.4618 0.1968L1.7108 0.4699L2.1205 1.249L2.1044 1.7149L2.8514 1.3936L2.8996 1.7309L2.8996 1.739L2.6667 2.1406L2.1847 2.1968L1.9036 1.9799L1.9036 1.8193L1.6626 1.7631L1.3092 1.5141L1.1245 1.4739L0.755 0.9518L0.5703 1.7952L0.4096 1.755L-0.7309 1.4739Z",
  "WI": "M-2.2814 3.2657L-2.2123 2.5126L-2.614 2.1485L-2.6893 1.7971L-2.4697 1.5962L-2.5324 0.7301L-2.3002 0.5544L-2.1496 0.5418L-1.6726 0.2531L-1.3713 -0.0607L-0.9885 -0.2552L-0.9006 -0.7385L-0.9006 -0.7699L-0.7186 -0.9895L-0.7688 -1.159L-0.7061 -1.5293L-0.5178 -1.7866L-0.1726 -1.9686L-0.1789 -2.0L1.1893 -1.8431L1.6977 -1.7803L2.545 -1.6611L2.5199 -1.5669L2.5199 -1.272L2.3504 -0.9582L2.3065 -0.5628L2.3881 -0.1234L2.3441 0.2155L2.4948 0.636L2.4383 0.705L2.4508 1.0941L2.5324 1.3891L2.4383 1.4895L2.2563 1.4268L2.1559 1.1632L1.9174 1.0251L1.9927 1.3577L2.2061 1.6402L2.2186 1.7657L2.2249 1.772L1.9236 2.0732L1.9236 2.5L1.5847 2.7762L1.1203 2.8075L0.6433 2.9331L-0.386 3.2469L-0.5868 3.3598L-0.6057 3.341L-0.9257 3.3849L-1.0324 3.3222L-0.9885 3.7803L-1.5031 3.5167L-2.1308 3.4163L-2.137 3.4289L-2.2814 3.2657Z",
  "WY": "M3.5949 0.8649L3.6592 2.2154L3.6656 2.3826L3.582 2.3891L1.9293 2.4855L1.0032 2.5627L-0.7524 2.7299L-2.9518 3.0L-3.0418 2.3698L-3.0482 2.3312L-3.074 2.119L-3.1897 1.2894L-3.4084 -0.3826L-3.4662 -0.8006L-3.492 -1.0129L-3.5048 -1.0965L-3.6656 -2.3505L-3.4148 -2.3826L-1.7749 -2.582L-1.6463 -2.5949L-1.492 -2.6141L0.0965 -2.7685L1.3698 -2.8714L3.2797 -2.9936L3.4084 -3.0L3.4148 -2.8328L3.4469 -2.1576L3.5048 -0.9807L3.5305 -0.4791L3.537 -0.3119L3.5434 -0.1383L3.5949 0.8649Z"
}

function States(
  state,
  area,
  width,
  height,
  cornerRadius,
  holeHeight,
  holeWidth,
  holeDiameter,
  font,
  text,
  fontSize,
  units
) {
  const path = STATE_DEFINITIONS[state]
  const shape = makerjs.model.mirror(makerjs.importer.fromSVGPathData(path), false, true)
  const m = makerjs.measure.modelExtents(shape)
  makerjs.model.scale(shape, adjustMetric(units, area) / m.height)
  makerjs.model.center(shape)

  const sign = new makerjs.models.RoundRectangle(width, height, cornerRadius)
  makerjs.model.center(sign)

  const bolts = new makerjs.models.BoltRectangle(holeWidth, holeHeight, holeDiameter / 2)
  makerjs.model.center(bolts)

  this.models = {
    state_group: shape,
    sign_group: sign,
    bolts
  }
  if (text !== "") {
    this.models.state_group = makerjs.model.moveRelative(shape, [0, height / 10])
    const textModel = new makerjs.models.Text(font, text, fontSize, false, false, 4)

    adjustFontSize(textModel, fontSize)

    makerjs.model.zero(textModel)
    makerjs.model.center(textModel)
    makerjs.model.moveRelative(textModel, [0, height / -3.3])
    this.models.text_group = textModel
  }
}
States.metaParameters = [
  {
    title: "State",
    type: "select",
    options: {
      "Alabama": "AL",
      "Alaska": "AK",
      "Arizona": "AZ",
      "Arkansas": "AR",
      "California": "CA",
      "Colorado": "CO",
      "Connecticut": "CT",
      "Delaware": "DE",
      "District of Columbia": "DC",
      "Florida": "FL",
      "Georgia": "GA",
      "Hawaii": "HI",
      "Idaho": "ID",
      "Illinois": "IL",
      "Indiana": "IN",
      "Iowa": "IA",
      "Kansas": "KS",
      "Kentucky": "KY",
      "Louisiana": "LA",
      "Maine": "ME",
      "Maryland": "MD",
      "Massachusetts": "MA",
      "Michigan": "MI",
      "Minnesota": "MN",
      "Mississippi": "MS",
      "Missouri": "MO",
      "Montana": "MT",
      "Nebraska": "NE",
      "Nevada": "NV",
      "New Hampshire": "NH",
      "New Jersey": "NJ",
      "New Mexico": "NM",
      "New York": "NY",
      "North Carolina": "NC",
      "North Dakota": "ND",
      "Ohio": "OH",
      "Oklahoma": "OK",
      "Oregon": "OR",
      "Pennsylvania": "PA",
      "Rhode Island": "RI",
      "South Carolina": "SC",
      "South Dakota": "SD",
      "Tennessee": "TN",
      "Texas": "TX",
      "Utah": "UT",
      "Vermont": "VT",
      "Virginia": "VA",
      "Washington": "WA",
      "West Virginia": "WV",
      "Wisconsin": "WI",
      "Wyoming": "WY"
    },
    value: "NV",
    default: "NV",
    group: 'state_group',
    group_title: 'State'
  },
  {
    title: "State Height",
    type: "range",
    min: 1,
    max: 12,
    value: 6,
    default: 6,
    tooltip_text: "Height of the selected state",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/states_stateHeightParams.png",
    group: 'state_group',
    group_title: 'State'
  },
  {
    title: "Sign Width",
    type: "range",
    min: 4,
    max: 24,
    value: 8,
    default: 8,
    tooltip_text: "Total Width of the Sign",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/states_signWidthParams.png",
    group: 'sign_group',
    group_title: 'Plate'
  },
  {
    title: "Sign Height",
    type: "range",
    min: 4,
    max: 24,
    value: 10,
    default: 10,
    tooltip_text: "Total Height of the Sign",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/states_signHeightParams.png",
    group: 'sign_group',
    group_title: 'Plate'
  },
  {
    title: "Corner Radius",
    type: "range",
    min: 0,
    max: 6,
    value: 0.25,
    default: 0.25,
    tooltip_text: "Radius of each corner of the sign",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/states_cornerRadiusParams.png",
    group: 'sign_group',
    group_title: 'Plate'
  },

  {
    title: "Bolt Hole Height",
    type: "range",
    min: 1,
    max: 20,
    value: 9,
    default: 9,
    tooltip_text: "Distance from the top two bolts to the bottom two bolts",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/states_boltsHeightParams.png",
    group: 'bolts',
    group_title: 'Holes'
  },
  {
    title: "Bolt Hole Width",
    type: "range",
    min: 1,
    max: 20,
    value: 7,
    default: 7,
    tooltip_text: "Distance from the left two bolts to the right two bolts",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/states_boltsWidthParams.png",
    group: 'bolts',
    group_title: 'Holes'
  },
  {
    title: "Bolt Hole Diameter",
    type: "range",
    min: 0,
    max: 2,
    value: 0.25,
    default: 0.25,
    tooltip_text: "Diameter of each bolt hole",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/states_boltDiameter.png",
    group: 'bolts',
    group_title: 'Holes'
  },

  { title: "Font", type: "font", stencil: true, value: 'Allerta', default: 'Allerta', group: 'text_group', group_title: 'Text' },
  { title: "Text", type: "text", value: 'DAD', default: 'HOME MEANS NEVADA', group: 'text_group', group_title: 'Text' },
  { title: "Font Size", type: "range", min: 0.25, max: 1.5, value: 0.5, default: 0.5, group: 'text_group', group_title: 'Text' },

  { type: "units", hidden: true }
]

export default States
