export default {
  props: {
    showGridLines: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    scaleThreshold() {
      if (this.displayUnits === 'in') {
        return 300
      }
      return 11 // about 300 mm in inches which feels about right.
    },
  },

  methods: {
    gridPattern(id, x, y, size, options) {
      const pattern = document.createElementNS("http://www.w3.org/2000/svg", "pattern")
      pattern.id = id
      pattern.setAttribute('width', size)
      pattern.setAttribute('height', size)
      pattern.setAttribute('x', x)
      pattern.setAttribute('y', y)
      pattern.setAttribute('patternUnits', 'userSpaceOnUse')
      pattern.innerHTML = `
        <path
          d="M ${size} 0 L 0 0 0 ${size}"
          fill="none"
          stroke-width="${options.strokeWidth}"
          vector-effect="non-scaling-stroke"
        />
      `

      return pattern
    },

    gridMarkers($svg, x, y, width, height, scale) {
      const g = document.createElementNS("http://www.w3.org/2000/svg", "g")
      g.id = "markers"

      const unitsText = this.displayUnitsSymbol

      const scaledWidth = this.convertUnits(width)
      const scaledHeight = this.convertUnits(height)

      function createText(xpos, ypos, val) {
        const padding = 2 * (Math.max(scaledWidth / $svg.clientWidth, scaledHeight / $svg.clientHeight))
        const fontSize = 10 * (Math.max(scaledWidth / $svg.clientWidth, scaledHeight / $svg.clientHeight))

        const text = document.createElementNS("http://www.w3.org/2000/svg", "text")
        text.setAttribute('fill', '#999')
        text.setAttribute('x', xpos + padding)
        text.setAttribute('y', ypos + padding + fontSize)
        text.style.font = `${fontSize}px sans-serif`
        text.innerHTML = `${val}${unitsText}`
        return text
      }

      const unitScale = Math.max(this.drawing.height, this.drawing.width) > this.scaleThreshold ? 100 : 10
      let units = 0
      // Add X markers
      for (let i = x; i <= x + width; i += scale) {
        if (units >= 1) {
          g.appendChild(createText(i, y, units))
        }
        units += unitScale
      }

      units = 0
      // Add Y markers
      for (let i = y; i <= y + height; i += scale) {
        if (units >= 1) {
          g.appendChild(createText(x, i, units))
        }
        units += unitScale
      }

      // If we haven't drawn any markers then add one at the end.
      const lastXLineOffset = Math.floor(width)
      if (lastXLineOffset < unitScale) {
        g.appendChild(createText(x + lastXLineOffset, y, lastXLineOffset))
      }

      const lastYLineOffset = Math.floor(height)
      if (lastYLineOffset < unitScale) {
        g.appendChild(createText(x, y + lastYLineOffset, lastYLineOffset))
      }

      return g
    },

    buildGrid($svg, x, y, width, height) {
      // The boxes are too small if the total is over 300 so we should display
      // a grid where each small square is 10 and each large square is 100
      let scale = Math.max(this.drawing.height, this.drawing.width) > this.scaleThreshold ? 10 : 1
      scale = this.convertUnits(scale)

      return [
        this.gridPattern('smgrid', x, y, 1 * scale, { strokeWidth: 0.5 }),
        this.gridPattern('grid', x, y, 10 * scale, { strokeWidth: 1 }),
        this.gridMarkers($svg, x, y, width, height, 10 * scale)
      ]
    },

    createGridlines(x, y) {
      const g = document.createElementNS("http://www.w3.org/2000/svg", "g")
      const rect = this.$refs.svgContainer.querySelector('svg .base').getBBox()
      g.id = "gridlines"
      g.innerHTML = `
        <rect x="${x}" y="${y}" width="${rect.width * 1.1}" height="${rect.height * 1.1}" fill="url(#smgrid)" />
        <rect x="${x}" y="${y}" width="${rect.width * 1.1}" height="${rect.height * 1.1}" fill="url(#grid)" />
      `
      return g
    },

    removeGridLines() {
      const $svg = this.$refs.svgContainer.querySelector('svg')
      function removeEl(el) {
        const $el = $svg.querySelector(el)
        if ($el) $el.remove()
      }
      removeEl('#gridlines')
      removeEl('#grid')
      removeEl('#smgrid')
      removeEl('#markers')
    },

    addGridLines() {
      const $svg = this.$refs.svgContainer.querySelector('svg')
      const { minX, minY, width, height } = this.viewBox

      if (!$svg.querySelector('defs #grid')) {
        const [smgrid, lggrid, markers] = this.buildGrid($svg, minX, minY, width, height)
        const $defs = $svg.querySelector('defs')

        $defs.appendChild(smgrid)
        $defs.appendChild(lggrid)

        $svg.insertBefore(markers, $svg.firstChild)
        $svg.insertBefore(this.createGridlines(minX, minY), $svg.firstChild)
      }
    }
  },
  watch: {
    showGridLines() {
      if (this.showGridLines) {
        this.addGridLines()
      } else {
        this.removeGridLines()
      }
    }
  }
}
