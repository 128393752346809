import { EventBus } from 'shared/services/event_bus.js'
import { nextTick } from "vue"

// If the user does not have permission to the route - this is the one we go to
const defaultRoute = 'quote'

//
// Any new paths added, also need to be added to ROUTE_TO_PERMISSION in utilities.js
//
const ROUTE_TO_PERMISSION = {
  "orders": "",
  "order": "account",
  "quote": "",
  "carts": "account",
  "referrals": "account",
  "settings": "account"
}

function getJsonFromUrl(url) {
  if (!url) url = window.location.search
  const query = url.substr(1)
  const result = {}
  query.split("&").forEach((part) => {
    const item = part.split("=")
    result[item[0]] = decodeURIComponent(item[1])
  })
  return result
}

// Extracts the affiliate ID from the URL
function getAffiliateID(route) {
  // Read affiliate from session storage
  let affiliate = sessionStorage.getItem('affiliate')

  // Read affiliate from parameters and override session storage
  const params = getJsonFromUrl()
  affiliate = params.aff || route.query.aff || affiliate

  // Store affiliate id in session storage. This survives refreshes and domain
  // changes, but is cleared on tab close
  if (affiliate) sessionStorage.setItem('affiliate', affiliate)

  return affiliate
}

// Checks whether the user has permission to the route, and if not it will return the new default
export function routeCheck(store, to) {
  const permission = ROUTE_TO_PERMISSION[to.name]

  // If the user has permission to this route, then let them access it
  if (!permission || (permission && store.getters["app/hasPermission"](permission))) {
    return undefined
  }

  // If they do not have permission to the "to" route, then find the first route they do have access to and redirect
  const route = Object.keys(ROUTE_TO_PERMISSION).find((r) => store.getters["app/hasPermission"](ROUTE_TO_PERMISSION[r]))
  return { name: route ?? defaultRoute }
}

function initialize(store, route) {
  const affiliate = getAffiliateID(route)
  return new Promise((resolve) => {
    if (affiliate) store.commit('app/setAffiliate', affiliate)
    store.dispatch('app/getCurrentUser')
      .then(() => Promise.all([
          store.dispatch('cart/fetch'),
          store.dispatch("intercom/bootstrap"),
          store.dispatch("tracking/login")
        ]))
      .catch(() => {
        store.dispatch("intercom/bootstrap")
      })
      .finally(() => {
        resolve()
      })
  })
}

export function initApp(store, route) {
  EventBus.$on('user_logged_in', () => {
    initialize(store, route)
  })

  EventBus.$on('user_logout', () => {
    nextTick(() => {
      window.location = "/logout"
    })
  })

  EventBus.$on('user_unauthorized', () => {
    store.dispatch('app/remoteLogout')
  })

  return initialize(store, route)
}
