import scs from 'shared/scs'
import Vue from 'vue'
import uniq from 'lodash/uniq'
import * as common from 'shared/store/common.js'

export default {
  namespaced: true,

  state: {
    ...common.state,

    data: {},
    current: null,
    materialID: null,

    // Dialog state
    state: 'category',
    selectedCategory: {}
  },

  getters: {
    ...common.getters,

    all(state) {
      return Object
        .keys(state.data)
        .map(id => state.data[id])
        .sort((a, b) => parseFloat(a.hole_diameter) - parseFloat(b.hole_diameter))
    },

    categories(state, getters) {
      const categories = {}
      getters.all.forEach(hardware => {
        categories[hardware.category] = categories[hardware.category] || {
          category: hardware.category,
          preview_url: hardware.preview_url,
          ids: [] // Initialize with no hardware
        }

        categories[hardware.category].ids.push(hardware.id)
      })

      return Object.keys(categories).map(name => categories[name])
    },

    current(state) {
      return state.data[state.current] || {}
    },

    diameter: (state) => (id) => parseFloat(state.data[id]?.hole_diameter || '0'),

    minClToEdgeDistance: (state) => (id) => parseFloat(state.data[id]?.min_cl_to_edge_distance || '0'),

    hardwareFields: () => (hardwares) => {
      const fields = {}

      hardwares.forEach(hardware => {
        hardware.descriptions.forEach(desc => {
          fields[desc.field] = fields[desc.field] || { field: desc.field }

          // Keep track of unique values with this field name
          fields[desc.field].values = uniq([...(fields[desc.field].values || []), desc.value])

          // If this field on one hardware is filterable, they all are
          fields[desc.field].filterable = fields[desc.field].filterable || desc.filterable
        })
      })

      return Object.keys(fields).map(key => fields[key])
    },

    // TODO sort
    get: (state) => (ids) => ids.map(id => state.data[id]),
  },

  actions: {
    ...common.actions,

    create({ commit }, data) {
      return scs.createHardware(data)
        .then((response) => {
          commit('setHardware', response.data)
        })
    },

    delete({ commit }, data) {
      return scs.deleteHardware(data)
        .then(() => {
          commit('removeHardware', data)
        })
    },

    async fetch({ commit, state }, data = {}) {
      // Only refetch if we're changing materials
      if (!data.material_id || Number(state.materialID) !== Number(data.material_id)) {
        const resp = await scs.fetchHardware(data)
        const json = await resp.json()

        commit('setPagination', resp.headers)
        commit('setHardwares', json.data)
        commit('setMaterialID', data.material_id)
      }
    },

    async fetchByIDs({ state, commit }, hardwareIDs) {
      // Remove ids we already have in the store
      const ids = uniq(hardwareIDs.filter(id => !state.data[id]))

      // Retrieve the remaining ids
      if (ids.length > 0) {
        const resp = await scs.fetchHardware({ ids }).then(r => r.json())
        resp.data.forEach(h => commit('setHardware', h))
      }
    },

    refreshHardware({ commit }, data) {
      return scs.getHardware(data)
        .then((response) => {
          commit('setHardware', response.data)
          return response.data
        })
    },

    reset({ commit }) {
      commit('setState', 'category')
      commit('setSelectedCategory', {})
      commit('setData', {})
      commit('setCurrent', null)
      commit('setMaterialID', null)
    },

    selectCategory({ commit }, category) {
      commit('setState', 'hardware')
      commit('setSelectedCategory', category)
    },

    update({ commit }, data) {
      return scs.updateHardware(data)
        .then((response) => {
          commit('setHardware', response.data)
        })
    },
  },

  mutations: {
    ...common.mutations,

    removeHardware(state, hardware) {
      Vue.delete(state.data, hardware.id)
    },

    setSelectedCategory(state, category) {
      state.selectedCategory = category
    },

    setData(state, val) {
      state.data = val
    },

    setCurrent(state, id) {
      state.current = id
    },

    setHardware(state, hardware) {
      Vue.set(state.data, hardware.id, hardware)
    },

    setHardwares(state, hardwares) {
      Vue.set(state, 'data', {})
      if (hardwares) {
        hardwares.forEach(hardware => {
          Vue.set(state.data, hardware.id, hardware)
        })
      }
    },

    setMaterialID(state, materialID) {
      state.materialID = materialID
    },

    setState(state, val) {
      state.state = val
    }
  }
}
