import makerjs from 'makerjs'

function DogEarTab(topHeight, bottomWidth, topWidth, diameter, bottomHeight) {
  const lineOne = new makerjs.paths.Line([-bottomWidth / 2, 0], [bottomWidth / 2, 0])
  const lineTwo = new makerjs.paths.Line([-bottomWidth / 2, 0], [-bottomWidth / 2, bottomHeight])
  const lineThree = new makerjs.paths.Line([bottomWidth / 2, 0], [bottomWidth / 2, bottomHeight])
  const lineFour = new makerjs.paths.Line([-topWidth / 2, topHeight], [topWidth / 2, topHeight])
  const hole = new makerjs.paths.Circle([0, bottomHeight], diameter / 2)

  this.paths = {
    hole
  }

  this.models = {
    tab_group: {
      paths: {
        lineOne,
        lineTwo,
        lineThree,
        lineFour
      }
    }
  }

  if (bottomWidth !== topWidth) {
    const lineFive = new makerjs.paths.Line([-bottomWidth / 2, bottomHeight], [-topWidth / 2, topHeight])
    const lineSix = new makerjs.paths.Line([topWidth / 2, topHeight], [bottomWidth / 2, bottomHeight])
    this.models.tab_group.paths.lineFive = lineFive
    this.models.tab_group.paths.lineSix = lineSix
  }
}

DogEarTab.metaParameters = [
    {
      title: "Overall Height",
      type: "range",
      min: 0,
      max: 6,
      value: 1.75,
      default: 1.75,
      tooltip_text: "Distance between the top and bottom of the part",
      tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/dogear_oheight.png",
      group: 'tab_group',
      group_title: 'Tab'
    },
    {
      title: "Bottom Width",
      type: "range",
      min: 0,
      max: 6,
      value: 1,
      default: 1,
      tooltip_text: "Width of the base of the part",
      tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/dogear_bwidth.png",
      group: 'tab_group',
      group_title: 'Tab'
    },
    {
      title: "Top Width",
      type: "range",
      min: 0,
      max: 6,
      value: 0.75,
      default: 0.75,
      tooltip_text: "Width of the top of tab",
      tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/dogear_twidth.png",
      group: 'tab_group',
      group_title: 'Tab'
    },
    {
      title: "Hole Diameter",
      type: "range",
      min: 0,
      max: 6,
      value: 0.5,
      default: 0.5,
      tooltip_text: "Diameter of the hole in the part",
      tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/dogear_diameter.png",
      group: 'hole',
      group_title: 'Hole'
    },
    {
      title: "Hole Height",
      type: "range",
      min: 0,
      max: 12,
      value: 1.25,
      default: 1.25,
      tooltip_text: "Distance from the center of the hole to the bottom of the part",
      tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/dogear_hheight.png",
      group: 'hole',
      group_title: 'Hole'
    },
    { type: "bends", value: [], default: [], hidden: true, group: ['bend0', 'bend1', 'bend2', 'bend3'] }
]

export default DogEarTab
