import makerjs from 'makerjs'

function OvalFlange(flangeWidth, flangeHeight, innerWidth, innerHeight, holeDiameter, holeCount) {
  const outerOval = new makerjs.models.Oval(flangeWidth, flangeHeight)
  const innerOval = new makerjs.models.Oval(innerWidth, innerHeight)
  const boltWidth = (flangeWidth + innerWidth) / 2
  const boltHeight = (flangeHeight + innerHeight) / 2
  const boltOval = new makerjs.models.Oval(boltWidth, boltHeight)

  makerjs.model.center(outerOval)
  makerjs.model.center(innerOval)
  makerjs.model.center(boltOval)

  const chain = makerjs.model.findSingleChain(boltOval)
  const spacing = chain.pathLength / holeCount
  const keyPoints = makerjs.chain.toPoints(chain, spacing)
  const holes = new makerjs.models.Holes(holeDiameter / 2, keyPoints)

  this.paths = { }

  this.models = {
    outer_oval_group: outerOval,
    inner_oval_group: innerOval,
    holes
  }
}

OvalFlange.metaParameters = [
  {
    title: "Flange Width",
    type: "range",
    min: 0,
    max: 24,
    value: 6,
    default: 6,
    tooltip_text: "Overall width of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/ovalflange_width.png",
    group: 'outer_oval_group',
    group_title: 'Plate'
  },
  {
    title: "Flange Height",
    type: "range",
    min: 0,
    max: 24,
    value: 4,
    default: 4,
    tooltip_text: "Overall height of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/ovalflange_height.png",
    group: 'outer_oval_group',
    group_title: 'Plate'
  },
  {
    title: "Inner Width",
    type: "range",
    min: 0,
    max: 24,
    value: 3,
    default: 3,
    tooltip_text: "Width of the inner oval cut out",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/ovalflange_iwidth.png",
    group: 'inner_oval_group',
    group_title: 'Inner Oval'
  },
  {
    title: "Inner Height",
    type: "range",
    min: 0,
    max: 24,
    value: 2,
    default: 2,
     tooltip_text: "Height of the inner oval cut out",
     tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/ovalflange_iheight.png",
     group: 'inner_oval_group',
     group_title: 'Inner Oval'
   },
  {
    title: "Hole Diameter",
    type: "range",
    min: 0,
    max: 6,
    value: 0.5,
    default: 0.5,
    tooltip_text: "Diameter of the bolt holes",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/ovalflange_boltdia.png",
    group: 'holes',
    group_title: 'Holes'
  },
  {
    title: "Hole Count",
    type: "range",
    units: "none",
    step: 1,
    min: 1,
    max: 24,
    value: 10,
    default: 10,
    tooltip_text: "Number of bolt holes in a oval pattern equally spaced",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/ovalflange_boltnum2.gif",
    group: 'holes',
    group_title: 'Holes'
  },
]

export default OvalFlange
