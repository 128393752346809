import makerjs from 'makerjs'

function CornerNotchGusset(height, width, notchY, notchX, xOffset, yOffset) {
  const points = [
    [0, notchY], // going clockwise
    [0, notchY + yOffset],
    [width - xOffset, height],
    [width, height],
    [width, 0],
    [notchX, 0],
    [notchX, notchY]
  ]

  this.models = {
    gusset_group: new makerjs.models.ConnectTheDots(true, points)
  }
}

CornerNotchGusset.metaParameters = [
  {
    title: "Height",
    type: "range",
    min: 0,
    max: 24,
    value: 6,
    default: 6,
    tooltip_text: "Overall height of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/corner_notch_height.png",
    group: 'gusset_group',
    group_title: 'Gusset'
  },
  {
    title: "Width",
    type: "range",
    min: 0,
    max: 24,
    value: 6,
    default: 6,
    tooltip_text: "Overall width of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/corner_notch_width.png",
    group: 'gusset_group',
    group_title: 'Gusset'
  },
  {
    title: "Notch Height",
    type: "range",
    min: 0,
    max: 12,
    value: 1,
    default: 1,
    tooltip_text: "Height of the notch on the left side of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/corner_notch_nheight.png",
    group: 'gusset_group',
    group_title: 'Gusset'
  },
  {
    title: "Notch Width",
    type: "range",
    min: 0,
    max: 12,
    value: 2,
    default: 2,
    tooltip_text: "Width of the notch on the left side of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/corner_notch_nwidth.png",
    group: 'gusset_group',
    group_title: 'Gusset'
  },
  {
    title: "Right side width",
    type: "range",
    min: 0,
    max: 12,
    value: 1,
    default: 1,
    tooltip_text: "Width of the right flat side",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/corner_notch_xoffset.png",
    group: 'gusset_group',
    group_title: 'Gusset'
  },
  {
    title: "Left side height",
    type: "range",
    min: 0,
    max: 12,
    value: 1,
    default: 1,
    tooltip_text: "Height of the lower left flat side",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/corner_notch_yoffset.png",
    group: 'gusset_group',
    group_title: 'Gusset'
  },
  { type: "bends", value: [], default: [], hidden: true, group: ['bend0', 'bend1', 'bend2', 'bend3'] }
]

export default CornerNotchGusset
