import makerjs from 'makerjs'

function CornerRoundGusset(height, width, yOffset, radius, arcWidth, addSlot, slotLength, slotWidth, slotHeight) {
  const prearcHeight = height - 0.5
  const points = [
    [width, prearcHeight], // height - arc height
    [width, 0], // Corner will be cut out
    [0, 0],
    [0, yOffset],
    [width - arcWidth, prearcHeight]
  ]

  const cutout = { models: {}, paths: {} }
  let base = new makerjs.models.ConnectTheDots(false, points)
  const arcX = (width * 2 - arcWidth) / 2
  base.paths.top_arc = new makerjs.paths.Arc([width, prearcHeight], [arcX, height], [width - arcWidth, prearcHeight])
  cutout.paths.punch = new makerjs.paths.Circle([width, 0], radius)
  base = makerjs.model.combineSubtraction(base, cutout)

  this.models = {
    gusset_group: base
  }

  if (addSlot === "Yes") {
    const startY = slotHeight + (slotWidth / 2)
    const endY = startY + slotLength - slotWidth
    this.models.slot_group = new makerjs.models.Slot([arcX, startY], [arcX, endY], slotWidth / 2)
  }
}

CornerRoundGusset.metaParameters = [
  {
    title: "Height",
    type: "range",
    min: 0,
    max: 24,
    value: 6,
    default: 6,
    tooltip_text: "Overall height of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/corner_round_height.png",
    group: 'gusset_group',
    group_title: 'Gusset'
  },
  {
    title: "Width",
    type: "range",
    min: 0,
    max: 24,
    value: 6,
    default: 6,
    tooltip_text: "Overall width of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/corner_round_width.png",
    group: 'gusset_group',
    group_title: 'Gusset'
  },
  {
    title: "Left side height",
    type: "range",
    min: 0,
    max: 12,
    value: 1,
    default: 1,
    tooltip_text: "Height of the lower left flat side",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/corner_round_left.png",
    group: 'gusset_group',
    group_title: 'Gusset'
  },
  {
    title: "Corner radius",
    type: "range",
    min: 0,
    max: 12,
    value: 1,
    default: 1,
    tooltip_text: "Radius of the corner on the bottom right",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/corner_round_radius.png",
    group: 'gusset_group',
    group_title: 'Gusset'
  },
  {
    title: "Top arc width",
    type: "range",
    min: 0,
    max: 12,
    value: 2,
    default: 2,
    tooltip_text: "Width of the arc on the top of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/corner_round_arcwidth.png",
    group: 'gusset_group',
    group_title: 'Gusset'
  },
  { title: "Add Slot", type: "bool", value: "Yes", default: "Yes", control: "slot_group" },
  {
    title: "Slot length",
    type: "range",
    min: 0,
    max: 15,
    value: 2.75,
    default: 2.75,
    tooltip_text: "Total length of the slot",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/corner_round_slotlen.png",
    group: 'slot_group',
    group_title: 'Slot'
  },
  {
    title: "Slot width",
    type: "range",
    min: 0,
    max: 15,
    value: 0.75,
    default: 0.75,
    tooltip_text: "Width of the slot",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/corner_round_slotwidth.png",
    group: 'slot_group',
    group_title: 'Slot'
  },
  {
    title: "Slot height",
    type: "range",
    min: 0,
    max: 24,
    value: 1.5,
    default: 1.5,
    tooltip_text: "Height of the slot off the bottom of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/corner_round_slotheight.png",
    group: 'slot_group',
    group_title: 'Slot'
  },
  { type: "bends", value: [], default: [], hidden: true, group: ['bend0', 'bend1', 'bend2', 'bend3'] }
]

export default CornerRoundGusset
