import scs from 'shared/scs'
import * as common from 'shared/store/common.js'

// initial state
const state = {
  path: 'organizations'
}

const actions = {
  accept(_, data = {}) {
    return scs.acceptOrganization(data)
    // Handled in the caller
  },
  // Invite others to the org this current user is a part of
  invite(_, data = {}) {
    return scs.inviteOrganization(data)
    // Handled in the caller
  },
  // Join the organization that matches the email domain the current user has (if they don't have a current org)
  join(_, data = {}) {
    return scs.joinOrganization(data)
    // Handled in the caller
  },
  // Leave an organization
  leave(_, data = {}) {
    return scs.leaveOrganization(data)
    // Handled in the caller
  }
}

export default {
  namespaced: true,
  state: { ...common.state, ...state },
  mutations: { ...common.mutations },
  actions: { ...common.actions, ...actions },
  getters: { ...common.getters }
}
