import makerjs from 'makerjs'
import filter from 'lodash/filter'
import { adjustMetric, adjustFontSize } from 'shared/components/PartBuilder/parts/utilities/common'

function PositiveSign(font, text, fontSize, letterSpacing, alignment, width, height, units) {
  const Y_CORRECTION = adjustMetric(units, -0.015) // This addresses an error introduced by addModel
  const Y_DROP = adjustMetric(units, -0.05)        // Used to combine base and lettering
  const Q_ADJUSTMENT = 0.00                        // per unit
  const PERMITTED_CHARS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789 "

  const textArray = filter(text.toUpperCase().split(""), (c) => PERMITTED_CHARS.includes(c))
  const leadingSpaces = text.length - text.trimLeft().length

  const textModelArray = []
  textArray.forEach(element => {
    const charModel = new makerjs.models.Text(font, element, fontSize, false, false, 4)
    if (charModel.paths) { // skip spaces
      adjustFontSize(charModel, fontSize)
    }
    textModelArray.push(charModel)
  })

  const ids = textModelArray.map((p, i) => `letter ${i}`)

  // Recombine text in to a single model
  const finalTextModel = new makerjs.models.Text(font, " ", fontSize, false, false, 4)
  for (var i = 0; i < textModelArray.length; i++) {
    if (textArray !== ' ') {
      makerjs.model.moveRelative(textModelArray[i], [letterSpacing * i, Y_CORRECTION])

      if (textArray[i] === 'Q') {
        makerjs.model.moveRelative(textModelArray[i], [0, Q_ADJUSTMENT * fontSize])
      }
      makerjs.model.addModel(finalTextModel, textModelArray[i], ids[i])
    }
  }

  const textBoundingBox = makerjs.measure.modelExtents(finalTextModel)

  if (width < textBoundingBox.width) {
    width = textBoundingBox.width
  }
  const base = new makerjs.models.Rectangle(width, height)
  makerjs.model.zero(base)

  // Adjust for leading spaces and drop letters into sign base slightly
  makerjs.model.moveRelative(finalTextModel, [leadingSpaces * letterSpacing, height + Y_DROP])

  switch (alignment) {
    case "Right":
      makerjs.model.moveRelative(finalTextModel, [width - textBoundingBox.width, 0])
      break
    case "Center":
      makerjs.model.moveRelative(finalTextModel, [width / 2 - textBoundingBox.width / 2, 0])
      break
  }

  this.models = {
    final_group: makerjs.model.combineUnion(base, finalTextModel)
  }
}

PositiveSign.metaParameters = [
  { title: "Font", type: "font", stencil: false, value: 'Hack', default: 'Hack', group: 'final_group', group_title: 'Sign' },
  { title: "Text", type: "text", value: 'SENDCUTSEND', default: 'SENDCUTSEND', group: 'final_group', group_title: 'Sign' },
  { title: "Font Size", type: "range", min: 0.75, max: 12, value: 1.5, default: 1.5, group: 'final_group', group_title: 'Sign' },
  { title: "Letter Spacing", type: "range", min: 0, max: 5, value: 0.875, default: 0.875, group: 'final_group', group_title: 'Sign' },
  { title: "Text Alignment",
    type: "select",
    options: {
      "Left": "Left",
      "Center": "Center",
      "Right": "Right",
    },
    value: "Left",
    default: "Left",
    group: 'final_group',
    group_title: 'Sign'
  },
  { title: "Base Width", type: "range", min: 0, max: 47, value: 13, default: 13, group: 'final_group', group_title: 'Sign' },
  { title: "Base Height", type: "range", min: 0, max: 12, value: 0.75, default: 0.75, group: 'final_group', group_title: 'Sign' },
  { type: "units", hidden: true }
]

export default PositiveSign
