import 'whatwg-fetch'
import { EventBus } from 'shared/services/event_bus.js'

const { prefix_url: prefixUrl } = window.appData

const serializeParams = function (obj) {
  const str = []
  Object.getOwnPropertyNames(obj).forEach((p) => {
    str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`)
  })
  return str.join("&")
}

const scsFetch = function (url, method, data = {}) {
  const options = {
    method: method || 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
    },
    credentials: 'same-origin'
  }

  // TODO: actually make a public scsFetch/store stuff
  if (prefixUrl) {
    url = `${prefixUrl}/${url}`
  }

  if (options.method === 'GET') {
    url += `${/\?/.test(url) ? '&' : '?'}${serializeParams(data)}`
  }
  else {
    options.body = JSON.stringify(data)
  }

  return fetch(url, options).then((response) => {
    if (!response.ok) {
      if (response.status === 401) {
        EventBus.$emit('user_unauthorized')
      }

      // Right now our error responses are plain text
      return response.text().then(error => { throw error })
    }

    return response
  })
}

export {
  scsFetch
}
