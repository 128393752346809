import scs from 'shared/scs'

const CARRIERS = [
  { name: 'USPS', value: 'usps', image: 'https://cdn.sendcutsend.com/images/usps-icon.png' },
  { name: 'UPS', value: 'ups', image: 'https://cdn.sendcutsend.com/images/ups-icon.png' },
  { name: 'FEDEX', value: 'fedex', image: 'https://cdn.sendcutsend.com/images/fedex-icon.png' }
]

/**
 * Returns a fresh initial state object.
 * Using a function ensures that a new object is created each time,
 * preventing accidental mutations of a shared state object across
 * multiple instances or resets of the store module.
 * This is particularly important for nested objects and arrays.
 */
const getInitialState = () => ({
  designRequests: [],
  requestType: '',
  loading: false,
  userDetails: {
    firstName: '',
    lastName: '',
    email: '',
    zipcode: '',
    agreeToTerms: false,
    currentCarrier: '',
  },
  projectDetails: {
    projectTitle: '',
    projectDescription: '',
    longestSide: '',
    unit: 'in',
    templateMaterial: '',
  },
  attachments: [],
  materialId: null,
  reviewChoices: [],
  carriers: CARRIERS,
})

const getters = {
  isSketchService: state => state.requestType === 'sketch-service',
  isTemplateService: state => state.requestType === 'template-service',
  loading: state => state.loading,
}

const actions = {
  async uploadAttachments({ dispatch, rootState }, { files = [] } = {}) {
    const { currentUser } = rootState.app
    const uploadPromises = files.map(fileInfo => dispatch('uploadSingleAttachment', { fileInfo, currentUser }))
    return Promise.all(uploadPromises)
  },
  async uploadSingleAttachment({ dispatch }, { fileInfo, currentUser }) {
    const uploadUrl = await dispatch('attachments/upload', {
      file: fileInfo.file,
      resource_type: 'design_request',
      resource_id: null
    }, { root: true })

    return {
      resource_type: 'design_request',
      resource_id: null,
      filename_url: uploadUrl.split('?')[0],
      name: fileInfo.file.name,
      type: fileInfo.type,
      size: fileInfo.file.size,
      category: 'image',
      user_id: currentUser?.id || null,
    }
  },
  async create({ state, commit, dispatch }) {
    try {
      const designRequestData = {
        design_request: {
          request_type: state.requestType,
          first_name: state.userDetails.firstName,
          last_name: state.userDetails.lastName,
          email: state.userDetails.email,
          zipcode: state.userDetails.zipcode,
          agree_to_terms: state.userDetails.agreeToTerms,
          project_title: state.projectDetails.projectTitle,
          project_description: state.projectDetails.projectDescription,
          attachments: state.attachments.map(attachment => ({
            filename_url: attachment.filename_url,
            user_id: attachment.user_id
          })),
          ...(getters.isSketchService && { material_id: state.materialId }),
          ...(getters.isTemplateService && {
            longest_side: state.projectDetails.longestSide,
            units: state.projectDetails.unit,
            template_material: state.projectDetails.templateMaterial,
            current_carrier: state.userDetails.currentCarrier,
          }),
        }
      }
      const response = await scs.createDesignRequest(designRequestData)
      commit('addDesignRequest', response)

      // Create ticket with correct parameters
      await dispatch('tickets/create', {
        type: 'design-service',
        design_request_id: response.id
      }, { root: true })

      return response
    } catch (error) {
      console.error('Error in create action:', error)
      throw error
    }
  },
  updateUserDetails({ commit }, details) {
    const updatedDetails = Object.entries(details).reduce((acc, [key, value]) => {
      switch (key) {
        case 'agreeToTerms':
          acc[key] = value
          break
        case 'firstName':
        case 'lastName':
        case 'zipcode':
          acc[key] = value.trim()
          break
        case 'email':
          acc[key] = value.trim().toLowerCase()
          break
        default:
          acc[key] = value
      }
      return acc
    }, {})

    commit('setUserDetails', updatedDetails)
  },
  updateProjectDetails({ commit }, details) {
    commit('setProjectDetails', details)
  },
  updateMaterialId({ commit }, id) {
    commit('setMaterialId', id)
  },
  updateUploadedImages({ commit }, images) {
    commit('setUploadedImages', images)
  },
}

const mutations = {
  resetFormData(state) {
    Object.assign(state, getInitialState())
  },
  addDesignRequest(state, data) {
    state.designRequests.unshift(data)
  },
  setRequestType(state, val) {
    state.requestType = val
  },
  setLoading(state, loading) {
    state.loading = loading
  },
  setUploadedImages(state, images) {
    state.attachments = images
  },
  setUserDetails(state, details) {
    state.userDetails = {
      ...state.userDetails,
      ...details,
    }
  },
  setProjectDetails(state, details) {
    state.projectDetails = { ...state.projectDetails, ...details }
  },
  setMaterialId(state, id) {
    state.materialId = id
  },
  setReviewChoices(state, choices) {
    state.reviewChoices = choices
  },
}

export default {
  namespaced: true,
  state: getInitialState(),
  mutations,
  actions,
  getters,
}
