import Decimal from "decimal.js"

export function processTaxLineItems(lineItems, total) {
  // Zonos returns tax/duty items for each line item.  It
  // also returns a zero amount for taxes that do not apply.
  // We need to consolidate the taxes returned because we
  // are not interested in a per item breakdown.  We also
  // need to remove any zero value taxes.
  // Finally, we may have a small discrepancy between the
  // sum of the rounded line items(zonos appears to round
  // at 4 decimal places).  Modify the first line item to
  // to make sure our customers do not get confused by the
  // difference between the total we charge them and the
  // line item tax sum.
  lineItems = lineItems.filter(item => item.amount > 0)

  const consolidatedLineItems = lineItems.reduce((prev, curr) => {
    const existingItem = prev.find(item => item.description === curr.description)
    if (existingItem) {
      existingItem.amount = existingItem.amount.plus(Decimal(curr.amount).toDecimalPlaces(2))
    } else {
      prev.push(
        {
          description: curr.description,
          amount: Decimal(curr.amount).toDecimalPlaces(2),
          formula: curr.formula
        }
      )
    }
    return prev
  }, [])
  const sum = consolidatedLineItems.reduce((prev, curr) => prev.plus(curr.amount), Decimal(0))
  const diff = sum.minus(Decimal(total).toDecimalPlaces(2))
  // Sanity check to make sure we're not adjusting the value too much.
  if (diff < Decimal("0.05") && consolidatedLineItems.length >= 1) {
    consolidatedLineItems[0].amount = consolidatedLineItems[0].amount.minus(diff)
  }
  return consolidatedLineItems
}
