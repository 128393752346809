export default class Vector {
  constructor(x, y) {
    this.x = x
    this.y = y
  }

  add(v) {
    return new Vector(this.x + v.x, this.y + v.y)
  }

  cross(v) {
    return new Vector(this.x * v.y - this.y * v.x)
  }

  dot(vec) {
    return this.x * vec.x + this.y * vec.y
  }

  divide(v) {
    if (v instanceof Vector) {
      return new Vector(this.x / v.x, this.y / v.y)
    }

    // Divide by scalar
    return new Vector(this.x / v, this.y / v)
  }

  magnitude() {
    return Math.sqrt(this.dot(this))
  }

  multiply(v) {
    if(v instanceof Vector) {
      return new Vector(this.x * v.x, this.y * v.y);
    }

    // Multiply by scalar
    return new Vector(this.x * v, this.y * v);
  }

  normalize() {
    return this.divide(this.magnitude())
  }

  subtract(v) {
    return new Vector(this.x - v.x, this.y - v.y)
  }
}
