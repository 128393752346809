import makerjs from 'makerjs'

function CornerGusset(height, width, xOffset, yOffset, radius) {
  const points = [
    [0, 0], // going clockwise
    [0, yOffset],
    [width - xOffset, height],
    [width, height],
    [width, 0]
  ]
  var cutout = { models: {}, paths: {} }
  let base = new makerjs.models.ConnectTheDots(true, points)

  cutout.paths.punch = new makerjs.paths.Circle([width, 0], radius)
  base = makerjs.model.combineSubtraction(base, cutout)

  this.models = {
    gusset_group: base
  }
}

CornerGusset.metaParameters = [
  {
    title: "Height",
    type: "range",
    min: 0,
    max: 24,
    value: 6,
    default: 6,
    tooltip_text: "Overall height of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/cornergus_height.png",
    group: 'gusset_group',
    group_title: 'Gusset'
  },
  {
    title: "Width",
    type: "range",
    min: 0,
    max: 24,
    value: 6,
    default: 6,
    tooltip_text: "Overall width of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/cornergus_width.png",
    group: 'gusset_group',
    group_title: 'Gusset'
  },
  {
    title: "Right side width",
    type: "range",
    min: 0,
    max: 12,
    value: 2,
    default: 2,
    tooltip_text: "Width of the right flat side",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/cornergus_xoffset.png",
    group: 'gusset_group',
    group_title: 'Gusset'
  },
  {
    title: "Left side height",
    type: "range",
    min: 0,
    max: 12,
    value: 2,
    default: 2,
    tooltip_text: "Height of the lower left flat side",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/cornergus_yoffset.png",
    group: 'gusset_group',
    group_title: 'Gusset'
  },
  {
    title: "Corner radius",
    type: "range",
    min: 0,
    max: 12,
    value: 1.5,
    default: 1.5,
    tooltip_text: "Radius of the corner on the bottom right",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/cornergus_radius.png",
    group: 'gusset_group',
    group_title: 'Gusset'
  },
  { type: "bends", value: [], default: [], hidden: true, group: ['bend0', 'bend1', 'bend2', 'bend3'] }
]

export default CornerGusset
