import makerjs from 'makerjs'

function SpokedArcDisc(outerRadius, innerRadius, count, slotdiameter, spokeWidth) {
  const a = 360 / count
  const sweep = (outerRadius + innerRadius) / 2
  const offsetLength = slotdiameter / 2 + spokeWidth / 2
  const offsetAngle = 360 * (offsetLength / (2 * sweep * 3.1415))
  const ovalPunch = new makerjs.models.OvalArc(0 + offsetAngle, a - offsetAngle, sweep, slotdiameter / 2, false, true)

  const wedgeModels = { }

  for (let i = 0; i < count; i++) {
    const iovalPunch = makerjs.cloneObject(ovalPunch)
    wedgeModels[`wedge${i}`] = makerjs.model.rotate(iovalPunch, i * a, [0, 0])
  }

  const radii = {
    ring_outer: outerRadius,
    ring_inner: innerRadius
  }

  this.paths = {}

  Object.keys(radii).forEach(id => {
    const r = radii[id]
    if (r !== undefined && r > 0) {
      this.paths[id] = new makerjs.paths.Circle(makerjs.point.zero(), r)
    }
  })

  class SpokeGroup {
    constructor(models) {
      this.models = models
    }
  }

  this.models = {
    spoke_group: new SpokeGroup(wedgeModels)
  }
}

SpokedArcDisc.metaParameters = [
  {
    title: "Outer Radius",
    type: "range",
    min: 0,
    max: 30,
    value: 6,
    default: 6,
    tooltip_text: "Outside radius of the wheel",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/arcspoke_radius.png",
    group: 'ring_outer',
    group_title: 'Disc'
  },
  {
    title: "Inner Radius",
    type: "range",
    min: 0,
    max: 30,
    value: 2,
    default: 2,
    tooltip_text: "Radius of the inner circle",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/arcspoke_innerradius.png",
    group: 'ring_inner',
    group_title: 'Inner Hole'
  },
  {
    title: "Spoke Count",
    type: "range",
    units: "none",
    step: 1,
    min: 0,
    max: 20,
    value: 5,
    default: 5,
    tooltip_text: "Number of spokes in wheel",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/arcspoke_spokecount.gif",
    group: 'spoke_group',
    group_title: 'Spokes'
  },
  {
    title: "Slot Width",
    type: "range",
    min: 0,
    max: 20,
    value: 1.5,
    default: 1.5,
    tooltip_text: "Width of the slot cutout",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/arcspoke_slotwidth.png",
    group: 'spoke_group',
    group_title: 'Spokes'
  },
  {
    title: "Spoke Width",
    type: "range",
    min: 0,
    max: 20,
    value: 1,
    default: 1,
    tooltip_text: "Width of the spoke",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/arcspoke_spokewidth.png",
    group: 'spoke_group',
    group_title: 'Spokes'
  }
]

export default SpokedArcDisc
