import flatten from 'lodash/flatten'
import find from 'lodash/find'
import uniq from 'lodash/uniq'

const OPERATION_SORT_ORDER = [
  'ETCHING', 'TAPPING', 'COUNTERSINKING', 'DEBURRING', 'TUMBLING', 'DIMPLE FORMING',
  'BENDING', 'ANODIZING', 'PLATING', 'HARDWARE', 'POWDER']

// Sorting method is designed to strip the ops down to their base type so we can sort on that component only
const sortOperations = (a, b) => {
  a = OPERATION_SORT_ORDER.find((op) => a.includes(op)) || a
  b = OPERATION_SORT_ORDER.find((op) => b.includes(op)) || b
  return a === b ? 0 : OPERATION_SORT_ORDER.indexOf(a) - OPERATION_SORT_ORDER.indexOf(b)
}

export default {
  computed: {
    anodizingColors() {
      return this.$store.getters['finish_options/allForType']('anodizing').filter(item => !item.deleted)
    },
    platingColors() {
      return this.$store.getters['finish_options/allForType']('plating').filter(item => !item.deleted)
    },
    powderColors() {
      return this.$store.getters['finish_options/allForType']('powder').filter(item => !item.deleted)
    },
  },
  methods: {
    vendorsForLineItem(lineItem, vendorsByColor) {
      const {
        anodizing_config: anodizingConfig,
        operation_costs: operationCosts,
        plating_config: platingConfig,
        powder_config: powderConfig
      } = lineItem
      const operations = Object.keys(operationCosts)
      const vendors = []
      for (let i = 0; i < operations.length; i++) {
        const operation = operations[i]
        if (operation === 'powder') {
          const color = find(this.powderColors, (p) => p.value === platingConfig)
          const vendor = vendorsByColor[color?.value]
          if (vendor) {
            vendors.push(vendor.name)
          }
        }
        else if (operation === 'anodizing') {
          const color = find(this.anodizingColors, (p) => p.value === anodizingConfig)
          const vendor = vendorsByColor[color?.value]
          if (vendor) {
            vendors.push(vendor.name)
          }
        }
        else if (operation === 'plating') {
          const color = find(this.platingColors, (p) => p.value === powderConfig)
          const vendor = vendorsByColor[color?.value]
          if (vendor) {
            vendors.push(vendor.name)
          }
        }
      }
      return vendors
    },

    // Returns a flattened list of operations and sub operations for a line item
    lineItemOperations(lineItem, options = {}) {
      const includeOpDetails = options.includeOpDetails || false
      const includeOpCounts = options.includeOpCounts || false
      const vendorsByColor = options.vendorsByColor || {}
      const addDeburringIfNeeded = options.addDeburringIfNeeded || false

      const {
        anodizing_config: anodizingConfig,
        csk_config: cskConfig,
        dimple_config: dimpleConfig,
        hardware_config: hardwareConfig,
        operation_costs: operationCosts,
        plating_config: platingConfig,
        powder_config: powderConfig,
        tap_config: tapConfig,
        bends } = lineItem
      const material = lineItem.material || {}
      const operations = Object.keys(operationCosts)
      const result = []

      for (let i = 0; i < operations.length; i++) {
        const operation = operations[i].toUpperCase()
        let opTitle = ''
        if (operation === 'TAPPING') {
          const noun = tapConfig?.length > 1 ? 'holes' : 'hole'
          if ((tapConfig || []).some(hole => !!hole.tap)) opTitle += 'TAPPING'
          if (includeOpCounts) opTitle += ` (${tapConfig.length} ${noun})`
        }
        else if (operation === 'COUNTERSINKING') {
          const noun = cskConfig?.length > 1 ? 'holes' : 'hole'
          if ((cskConfig || []).some(hole => !!hole.csk_id)) opTitle += 'COUNTERSINKING'
          if (includeOpCounts) opTitle += ` (${cskConfig.length} ${noun})`
        }
        else if (operation === 'DIMPLE_FORMING') {
          const noun = dimpleConfig?.length > 1 ? 'holes' : 'hole'
          if ((dimpleConfig || []).some(hole => !!hole.dimple_id)) opTitle += 'DIMPLE FORMING'
          if (includeOpCounts) opTitle += ` (${dimpleConfig.length} ${noun})`
        }
        else if (operation === 'HARDWARE') {
          const noun = hardwareConfig?.length > 1 ? 'holes' : 'hole'
          if ((hardwareConfig || []).some(hole => !!hole.hardware_id)) opTitle += 'HARDWARE'
          if (includeOpCounts) opTitle += ` (${hardwareConfig.length} ${noun})`
        }
        else if (operation === 'POWDER') {
          const color = find(this.powderColors, (p) => p.value === powderConfig)
          opTitle = this.generateFinishStringForColor(operation, color, includeOpDetails, vendorsByColor)
        }
        else if (operation === 'BENDING' && includeOpCounts) {
          const filteredBends = (bends || []).filter(b => !b.joined_to)
          const noun = filteredBends.length > 1 ? 'bends' : 'bend'
          opTitle += `BENDING (${filteredBends.length} ${noun})`
        }
        else if (operation === 'ANODIZING') {
          const color = find(this.anodizingColors, (p) => p.value === anodizingConfig)
          opTitle = this.generateFinishStringForColor(operation, color, includeOpDetails, vendorsByColor)
        }
        else if (operation === 'PLATING') {
          const color = find(this.platingColors, (p) => p.value === platingConfig)
          opTitle = this.generateFinishStringForColor(operation, color, includeOpDetails, vendorsByColor)
        }
        else {
          opTitle = operation
        }

        result.push(opTitle)
      }

      // If requested add deburring if it is needed by the finishing operation.  This
      // is opt-in becuase certains areas this function are used(client facing) should not
      // show the deburring operation if it was not selected.

      // The finish operations that require deburring
      const canAddDeburring = operations.filter(op => ['powder', 'anodizing', 'plating'].includes(op)).map(op => {
        // Check whether the op has indicated we should skip deburring...
        if (!material?.operations) return true
        return !(material.operations?.find(o => o.operation === op)?.configuration?.skip_deburring)
      })

      if (addDeburringIfNeeded
        && canAddDeburring.length > 0
        // We only check the first because the finish operations are mutually exclusive (there will be only one)
        && canAddDeburring[0]
        && !operations.includes('deburring')) {
        result.push('DEBURRING')
      }

      return uniq(result).sort(sortOperations)
    },
    generateFinishStringForColor(operation, color, includeOpDetails, vendorsByColor) {
      if (!color) {
        return operation
      }
      let opTitle = operation
      if (includeOpDetails) {
        opTitle = `${color.color.toUpperCase()} ${opTitle}`
      }
      const vendor = vendorsByColor[color.value]
      if (vendor) {
        opTitle += ` - ${vendor.name}`
      }
      return opTitle
    },
    // Returns a flattened list of operations for a list of order line items
    orderOperations(lineItems, options = {}) {
      const result = flatten(lineItems.map((l) => this.lineItemOperations(l, options)))
      return uniq(result).sort(sortOperations)
    },
    // Returns a flattened list of operations for a list of order line items
    orderVendors(lineItems, vendorsByColor) {
      const result = flatten(lineItems.map((l) => this.vendorsForLineItem(l, vendorsByColor)))
      return uniq(result).sort()
    }
  }
}
