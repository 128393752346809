export function createTooltip(id) {
  const $oldTooltip = document.body.querySelector(`#${id}`)
  if ($oldTooltip) $oldTooltip.remove()

  const $tooltip = document.createElement('div')

  $tooltip.id = id
  $tooltip.className = `svg-tooltip tooltip vue-tooltip-theme ${id}`
  $tooltip.innerHTML = `
        <div class="tooltip-inner"></div>
        <div class="tooltip-arrow"></div>
      `
  $tooltip.setAttribute('x-placement', 'top')
  $tooltip.style.cssText = `
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      `
  document.body.appendChild($tooltip)

  return $tooltip
}

export function positionTooltip($tooltip, getAttrs) {
  setTimeout(() => {
    const tooltipBox = $tooltip.getBoundingClientRect()
    const { left, top } = getAttrs(tooltipBox)

    $tooltip.style.left = `${left}px`
    $tooltip.style.top = `${top}px`
    $tooltip.style.opacity = 1
  }, 0)
}

export function updateTooltipText($tooltip, content) {
  const $inner = $tooltip.getElementsByClassName('tooltip-inner')[0]
  const $arrow = $tooltip.getElementsByClassName('tooltip-arrow')[0]

  $inner.innerHTML = content

  setTimeout(() => {
    // Reposition tooltip arrow
    const tooltipBox = $tooltip.getBoundingClientRect()
    $arrow.style.left = `${tooltipBox.width / 2 - 8}px`
  }, 0)
}
