import makerjs from 'makerjs'

function Trapezoid(height, bottomWidth, topWidth) {
  this.models = {
    trapezoid_group: {
      paths: {
        lineOne: new makerjs.paths.Line([-bottomWidth / 2, 0], [bottomWidth / 2, 0]),
        lineTwo: new makerjs.paths.Line([-topWidth / 2, height], [topWidth / 2, height]),
        lineThree: new makerjs.paths.Line([-bottomWidth / 2, 0], [-topWidth / 2, height]),
        lineFour: new makerjs.paths.Line([bottomWidth / 2, 0], [topWidth / 2, height])
      }
    }
  }
}

Trapezoid.metaParameters = [
    { title: "Height", type: "range", min: 0, max: 12, value: 1, default: 1, group: 'trapezoid_group', group_title: 'Plate' },
    { title: "Bottom Width", type: "range", min: 0, max: 12, value: 2, default: 2, group: 'trapezoid_group', group_title: 'Plate' },
    { title: "Top Width", type: "range", min: 0, max: 12, value: 1, default: 1, group: 'trapezoid_group', group_title: 'Plate' },
    { type: "bends", value: [], default: [], hidden: true, group: ['bend0', 'bend1', 'bend2', 'bend3'] }
]

export default Trapezoid
