import { computed } from 'vue'
import { useStore } from 'customer/components/composables/store'

export function useCustomerProductFolderService() {
  const store = useStore()

  const itemStats = computed(() => store.getters['customer_products/stats'])
  const fetchFolders = (options) => store.dispatch('folders/fetch', { resource_type: 'customer_product', ...options })
  const updateItem = async (options) => store.dispatch('customer_products/update', options)
  const filterItemsForFolder = async (folderId) => store.dispatch('customer_products/filter', { resource_type: 'customer_product', folderId })
  const itemName = 'Products'
  const generateFolder = (data) => ({ resource_type: 'customer_product', ...data })
  const itemResourceType = 'customer_product'

  return {
    itemStats,
    fetchFolders,
    updateItem,
    filterItemsForFolder,
    itemName,
    generateFolder,
    itemResourceType
  }
}
