import makerjs from 'makerjs'

function RoundRectangle(plateWidth, plateHeight, cornerRadius) {
  this.models = {
    flange_group: new makerjs.models.RoundRectangle(plateWidth, plateHeight, cornerRadius),
  }
}

RoundRectangle.metaParameters = [
    { title: "Width", type: "range", min: 0, max: 24, value: 6, default: 6, group: 'flange_group', group_title: 'Plate' },
    { title: "Height", type: "range", min: 0, max: 24, value: 4, default: 4, group: 'flange_group', group_title: 'Plate' },
    { title: "Corner Radius", type: "range", min: 0, max: 6, value: 0.25, default: 0.25, group: 'flange_group', group_title: 'Plate' },
    { type: "bends", value: [], default: [], hidden: true, group: 'bend0' }
]

export default RoundRectangle
