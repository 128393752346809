import makerjs from 'makerjs'
import BoltCircle from './BoltCircle'

function DoubleRoundBoltFlange(
  outerDiameter,
  innerDiameter,
  innerBoltDiameter,
  innerBoltCirDiameter,
  innerBoltCount,
  outerBoltDiameter,
  outerBoltCirDiameter,
  outerBoltCount,
  outerBoltAngle
) {
  this.paths = {
    outer: new makerjs.paths.Circle([0, 0], outerDiameter / 2),
    inner: new makerjs.paths.Circle([0, 0], innerDiameter / 2)
  }

  this.models = {
    outside_holes: new BoltCircle(outerBoltDiameter / 2, outerBoltCirDiameter / 2, outerBoltCount, outerBoltAngle),
    inside_holes: new BoltCircle(innerBoltDiameter / 2, innerBoltCirDiameter / 2, innerBoltCount, 0, innerBoltCount)
  }
}

DoubleRoundBoltFlange.metaParameters = [
  {
    title: "Flange Diameter",
    type: "range",
    min: 0,
    max: 24,
    value: 6,
    default: 6,
    tooltip_text: "Overall Diameter of the Flange",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/double_round_flange_Diameter.png",
    group: 'outer',
    group_title: 'Plate'
  },
  {
    title: "Center Hole Diameter",
    type: "range",
    min: 0,
    max: 24,
    value: 2,
    default: 2,
    tooltip_text: "Diameter of the Center Hole",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/double_round_flange_centerDiameter.png",
    group: 'inner',
    group_title: 'Center Hole'
  },
  {
    title: "Inner Bolt Diameter",
    type: "range",
    min: 0,
    max: 24,
    value: 3,
    default: 3,
    tooltip_text: "Diameter of the Entire Inner Bolt Circle",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/double_round_flange_innerBoltDiameter.png",
    group: 'inside_holes',
    group_title: 'Inner Holes'
  },
  {
    title: "Inner Hole Diameter",
    type: "range",
    min: 0,
    max: 6,
    value: 0.5,
    default: 0.5,
    tooltip_text: "Diameter of Each Individual Hole of Inner Bolt Circle",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/double_round_flange_innerHoleDiameter.png",
    group: 'inside_holes',
    group_title: 'Inner Holes'
  },
  {
    title: "Inner Hole Count",
    type: "range",
    units: "none",
    step: 1,
    min: 1,
    max: 24,
    value: 8,
    default: 8,
    tooltip_text: "Number of Holes in Inner Bolt Circle",
    group: 'inside_holes',
    group_title: 'Inner Holes'
  },
  {
    title: "Outer Bolt Diameter",
    type: "range",
    min: 0,
    max: 24,
    value: 5,
    default: 5,
    tooltip_text: "Diameter of the Entire Outer Bolt Circle",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/double_round_flange_outerBoltDiameter.png",
    group: 'outside_holes',
    group_title: 'Outer Holes'
  },
  {
    title: "Outer Hole Diameter",
    type: "range",
    min: 0,
    max: 6,
    value: 0.5,
    default: 0.5,
    tooltip_text: "Diameter of Each Individual Hole of Outer Bolt Circle",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/double_round_flange_outerHoleDiameter.png",
    group: 'outside_holes',
    group_title: 'Outer Holes'
  },
  {
    title: "Outer Hole Count",
    type: "range",
    units: "none",
    step: 1,
    min: 1,
    max: 24,
    value: 4,
    default: 4,
    tooltip_text: "Number of Holes in Outer Bolt Circle",
    group: 'outside_holes',
    group_title: 'Outer Holes'
  },
  {
    title: "First Bolt Angle",
    type: "range",
    units: "none",
    min: 0,
    max: 180,
    value: 45,
    default: 45,
    tooltip_text: "Angle of First Outer Bolt Hole in Relation to Inner Bolt Hole on the Horizontal Axis",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/double_round_flange_Angle.png",
    group: 'outside_holes',
    group_title: 'Outer Holes'
  }
]

export default DoubleRoundBoltFlange
