export default [
  /*
   * Standard
   */

  { tap: "4-40"   , drill: 0.091, threshold: { low: 0.030, high: 0.030 } },
  { tap: "6-32"   , drill: 0.112, threshold: { low: 0.030, high: 0.030 } },
  { tap: "8-32"   , drill: 0.138, threshold: { low: 0.030, high: 0.030 } },
  { tap: "10-32"  , drill: 0.164, threshold: { low: 0.030, high: 0.030 } },
  { tap: "1/4-20" , drill: 0.209, threshold: { low: 0.030, high: 0.030 } },
  { tap: "1/4-28" , drill: 0.221, threshold: { low: 0.030, high: 0.030 } },
  { tap: "5/16-18", drill: 0.266, threshold: { low: 0.030, high: 0.030 } },
  { tap: "5/16-24", drill: 0.278, threshold: { low: 0.030, high: 0.030 } },
  { tap: "3/8-16" , drill: 0.323, threshold: { low: 0.030, high: 0.030 } },
  { tap: "3/8-24" , drill: 0.341, threshold: { low: 0.030, high: 0.030 } },
  { tap: "1/2-13" , drill: 0.435, threshold: { low: 0.030, high: 0.030 } },
  { tap: "1/2-20" , drill: 0.459, threshold: { low: 0.030, high: 0.030 } },



  /*
   * Metric
   */

  { tap: "M2 x 0.4"   , drill: 0.065, threshold: { low: 0.030, high: 0.030 } },
  { tap: "M2.5 x 0.45", drill: 0.083, threshold: { low: 0.030, high: 0.030 } },
  { tap: "M3 x 0.5"   , drill: 0.102, threshold: { low: 0.030, high: 0.030 } },
  { tap: "M4 x 0.7"   , drill: 0.134, threshold: { low: 0.030, high: 0.030 } },
  { tap: "M5 x 0.8"   , drill: 0.170, threshold: { low: 0.030, high: 0.030 } },
  { tap: "M6 x 1"     , drill: 0.203, threshold: { low: 0.030, high: 0.030 } },
  { tap: "M8 x 1.25"  , drill: 0.274, threshold: { low: 0.030, high: 0.030 } },
  { tap: "M10 x 1.5"  , drill: 0.344, threshold: { low: 0.030, high: 0.030 } },
]
