import { render, staticRenderFns } from "./ThankYouPage.vue?vue&type=template&id=4e2cc8bc"
import script from "./ThankYouPage.vue?vue&type=script&lang=js"
export * from "./ThankYouPage.vue?vue&type=script&lang=js"
import style0 from "./ThankYouPage.vue?vue&type=style&index=0&id=4e2cc8bc&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports