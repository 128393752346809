import makerjs from 'makerjs'

function RectangularVent(outerWidth, outerHeight, cornerRadius, holeMargin, holeDiameter, spacing) {
  const holePaths = {}
  const holeRadius = holeDiameter / 2
  const width = (outerWidth / 2) - holeMargin
  const height = (outerHeight / 2) - holeMargin
  let alternate = false
  const xDistance = 2 * holeRadius * (1 + spacing / 100)
  const countX = Math.ceil(width / xDistance)
  const yDistance = makerjs.solvers.solveTriangleASA(60, xDistance / 2, 90)
  const countY = Math.ceil(height / yDistance) + 1

  function checkBoundary(x, y) {
    return y - holeRadius < height && x - holeRadius < width
  }

  const row = (iy) => {
    let total = countX
    if (!alternate) {
        total++
    }
    for (let i = 0; i < total; i++) {
      let x = i * xDistance
      const y = iy * yDistance
      if (alternate) {
        x += xDistance / 2
      }
      if (checkBoundary(Math.abs(x), Math.abs(y))) {
        const id = `hole_${i}_${iy}`
        holePaths[id] = new makerjs.paths.Circle([x, y], holeRadius)
        if (alternate || (!alternate && i > 0)) {
          holePaths[`${id}_alt`] = new makerjs.paths.Circle([-x, y], holeRadius)
        }
      }
    }
  }
  for (let i = 0; i < countY; i++) {
    row(i)
    if (i > 0) {
      row(-i)
    }
    alternate = !alternate
  }

  this.models = {
    rectangle_group: makerjs.model.center(new makerjs.models.RoundRectangle(outerWidth, outerHeight, cornerRadius)),
    holes_group: {
      paths: holePaths
    }
  }
}

RectangularVent.metaParameters = [
  {
    title: "Width",
    type: "range",
    min: 0,
    max: 24,
    value: 12,
    default: 12,
    group: 'rectangle_group',
    group_title: 'Plate',
    tooltip_text: "Overall width of the vent",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectangular_vent_width.png"
  },
  {
    title: "Height",
    type: "range",
    min: 0,
    max: 24,
    value: 6,
    default: 6,
    group: 'rectangle_group',
    group_title: 'Plate',
    tooltip_text: "Overall height of the vent",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectangular_vent_height.png"
  },
  {
    title: "Corner Radius",
    type: "range",
    min: 0,
    max: 6,
    value: 0.25,
    default: 0.25,
    group: 'rectangle_group',
    group_title: 'Plate',
    tooltip_text: "Radius of the outside corners",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectangular_vent_corner.png"
  },
  {
    title: "Hole Margin",
    type: "range",
    min: 0,
    max: 24,
    value: 1,
    default: 1,
    group: 'holes_group',
    group_title: 'Holes',
    tooltip_text: "Minimum distance between vent edge to hole",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectangular_vent_margin.png"
  },
  {
    title: "Hole Diameter",
    type: "range",
    min: 0.062,
    max: 6,
    value: 0.5,
    default: 0.5,
    group: 'holes_group',
    group_title: 'Holes',
    tooltip_text: "Diameter of vent holes",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectangular_vent_diameter.png"
  },
  {
    title: "Hole Spacing",
    type: "range",
    units: '%',
    min: 10,
    max: 100,
    value: 49,
    step: 1,
    default: 49,
    group: 'holes_group',
    group_title: 'Holes',
    tooltip_text: "Space between holes as a percentage of the vent hole diameter",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectangular_vent_spacing.png"
  },
  { type: "bends", value: [], default: [], hidden: true }
]

export default RectangularVent
