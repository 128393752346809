import makerjs from 'makerjs'
import { toRadians, circle, solveY, distance, twoCirclesIntersection } from 'shared/components/PartBuilder/parts/utilities/common'

//Create Gear Base
export function GearModel(P, N, units){

    //outside circle origin [0,0], radius OD/2
    if(units == 'mm'){
      if(P == 6){
          P = .24
      } else if(P == 12){
          P = .48
      } else if(P == 32){
          P = 1.26
      }
    }
    let OD = (N+1.6)/P
    let dedendum = 1.250/P

    //pitch circle orign [0,0] radius PR
    let D = N/P
    let PR =  D/2 //pitch radius
    //root circle origin [0,0] radius RD/2
    let RD = D - (2 * dedendum)
    //circular thickness angle
    let CTA = (360/N)/2

     //base circle origin [0,0], radius r2
     //and pressure line y2 = (m2 * x2) + PR
    let m2 = Math.tan(toRadians(20))
    let r2 = PR/(Math.sqrt((Math.pow(m2,2)+1)))

    //bisector line
    let BA = CTA/2
    let m3 = Math.tan(toRadians(90-BA))

    //reference Circle origin [coords1[0], coords1[1]] radius D/8
    let c1 = new circle(0,PR,D/8)
    let c2 = new circle(0,0,r2)
    let i1 = twoCirclesIntersection(c1, c2)
    var coords1
    if(i1[0] < 0){
        coords1 = [i1[0], i1[1]]
    } else{
        coords1 = [i1[2], i1[3]]
    }

    //reference points and intersections
    let c3 = new circle(coords1[0], coords1[1], D/8)
    let c4 = new circle(0, 0, OD/2)
    let i2 = twoCirclesIntersection(c3, c4)
    var coords2
    if(i2[0] < i2[2]){
        coords2 = [i2[2], i2[3]]
    } else{
        coords2 = [i2[0], i2[1]]
    }

    let x4 = coords2[1] / -m3
    let d1 = distance(coords2[0], x4)
    let x6 = PR/-m3
    let x7 = (RD/2)/-m3
    let d2 = distance(0, x6)
    let d3 = distance(0, x7)
    let x8 = x6 - d2
    let x9 = x7 - d3

    // Putting the tooth together
    let A = 360/N
    let C = Math.PI * RD
    let spacing = C/N
    let c5 = new circle(0,0,RD/2)
    let y1 = solveY(c5, -(x9/2)+(spacing/4))
    let right = [[d1, coords2[1]], [-(x8/2),PR], [-(x9/2),y1], [-(x9/2)+(spacing/4),y1]]
    let left = [[-d1, coords2[1]], [x8/2,PR], [x9/2, y1],[x9/2 -(spacing/4),y1]]

    // Define a single tooth
    let tooth = {
      paths: {
        top: new makerjs.paths.Line([d1, coords2[1]], [-d1, coords2[1]]),
      },
      models: {
        curve_one: new makerjs.models.BezierCurve(right),
        curve_two: new makerjs.models.BezierCurve(left)
      }
    }

    // Rotate the teeth around the center and add them to the a teeth model
    let teeth = { models: {} }
    for (var i = 0; i < N; i++) {
      let itooth = makerjs.cloneObject(tooth)
      teeth.models['Tooth' + i] = makerjs.model.rotate(itooth, i * A, [0, 0]);
    }

    return makerjs.model.simplify(teeth)
};

export function CenterHole(centerHoleRadius, addKeyHole, keyHoleLength, keyHoleWidth){
  // Hole has to be a model for combine union to work
  let Hole = {
    paths: {
      Hole: new makerjs.paths.Circle([0,0], centerHoleRadius)
    }
  }
  if(addKeyHole == "Yes"){
    let keyHole = {
      paths: {
        Line1: new makerjs.paths.Line([-keyHoleWidth/2,0], [keyHoleWidth/2, 0]),
        Line2: new makerjs.paths.Line([keyHoleWidth/2,0],[keyHoleWidth/2, keyHoleLength + (centerHoleRadius)]),
        Line3: new makerjs.paths.Line([-keyHoleWidth/2,0],[-keyHoleWidth/2, keyHoleLength + (centerHoleRadius)]),
        Line4: new makerjs.paths.Line([-keyHoleWidth/2, keyHoleLength + (centerHoleRadius)], [keyHoleWidth/2, keyHoleLength + (centerHoleRadius)])
      }
    }

  return makerjs.model.combineUnion(keyHole, Hole)
  } else{
    return Hole
  }
}

export function SpokeWedge(spoke, outerRadius, innerRadius, count) {
  var _this = this;
  this.paths = {};
  var ring = new makerjs.models.Ring(outerRadius, innerRadius);
  //punch the spoke out from the ring
  makerjs.model.combine(ring, spoke, false, true, true, false);
  var punch1 = {
      models: {
          ring: ring,
          spoke: spoke
      }
  };
  //clone the punch and rotate it for one spoke's rotation
  var origin = [0, 0];
  var punch2 = makerjs.model.rotate(makerjs.cloneObject(punch1), 360 / count, origin);
  //combine both punches
  makerjs.model.combine(punch1, punch2, true, false, true, false);
  //we now have a wedge separated from the ring.
  var wedgeAndRing = {
      models: {
          punch1: punch1,
          punch2: punch2
      }
  };
  //to eliminate the ring, we can "discontiguate" it by removing a shape from it, leaving dead ends.
  var corner = [outerRadius, -outerRadius];
  var oneDegree = makerjs.point.rotate(corner, 1, origin);
  var knife = new makerjs.models.ConnectTheDots(true, [origin, corner, oneDegree]);
  //when combined, the dead-ended lines will be removed, leaving only the wedge.
  makerjs.model.combine(wedgeAndRing, knife, false, true, false, false);
  //the wedge is a deep tree of models with many pruned nodes, so flatten it and keep only the needed paths.
  makerjs.model.walkPaths(wedgeAndRing, function (modelContext, pathId, path) {
      var id = makerjs.model.getSimilarPathId(_this, pathId);
      _this.paths[id] = path;
  });
}

export function SpokedWheel(outerRadius, innerRadius, count, spokeWidth, flareWidth, innerFillet, outerFillet) {
  var a = 360 / count;
  var halfSpokeWidth = spokeWidth / 2;
  var halfFlareWidth = flareWidth / 2;
  var flareLine = new makerjs.paths.Line([halfFlareWidth, 0], [halfFlareWidth, outerRadius]);
  var outerCircle = new makerjs.paths.Circle([0, 0], outerRadius);
  var int = makerjs.path.intersection(flareLine, outerCircle);
  var flareY = int.intersectionPoints[0][1];
  var points = [
          [halfSpokeWidth, 0],
          [halfFlareWidth, flareY],
          [halfFlareWidth, outerRadius + 1],
          [-halfFlareWidth, outerRadius + 1],
          [-halfFlareWidth, flareY],
          [-halfSpokeWidth, 0]
      ];
  var spoke = new makerjs.models.ConnectTheDots(true, points);

  var wedge = new SpokeWedge(spoke, outerRadius, innerRadius, count);

  if (innerFillet) {
      var innerFilletArc = makerjs.path.fillet(wedge.paths.ShapeLine1, wedge.paths.ShapeLine5, innerFillet);
      if (innerFilletArc) {
          wedge.paths.innerFillet = innerFilletArc;
      } else {
          wedge.paths.innerFillet1 = makerjs.path.fillet(wedge.paths.ShapeLine1, wedge.paths.Ring_inner, innerFillet);
          wedge.paths.innerFillet2 = makerjs.path.fillet(wedge.paths.ShapeLine5, wedge.paths.Ring_inner, innerFillet);
      }
  }

  if (outerFillet) {
      wedge.paths.outerFillet1 = makerjs.path.fillet(wedge.paths.ShapeLine1, wedge.paths.Ring_outer, outerFillet);
      wedge.paths.outerFillet2 = makerjs.path.fillet(wedge.paths.ShapeLine5, wedge.paths.Ring_outer, outerFillet);
  }

  this.models = {};

  for (var i = 0; i < count; i++) {
    var iwedge = makerjs.cloneObject(wedge);
    this.models['wedge' + i] = makerjs.model.rotate(iwedge, i * a, [0, 0]);
  }
}



