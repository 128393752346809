import makerjs from 'makerjs'

function RectangleConcaveSide(width, height, bottomArcOffset, topArcOffset, arcDepth) {
  const topEndAngle = [topArcOffset, height]
  const topArc = new makerjs.paths.Arc([width - topArcOffset, height], [width / 2, height - arcDepth], topEndAngle)
  const bottomArc = new makerjs.paths.Arc([bottomArcOffset, 0], [width / 2, arcDepth], [width - bottomArcOffset, 0])
  const bottomLeftOffset = new makerjs.paths.Line([0, 0], [bottomArcOffset, 0])
  const bottomRightOffset = new makerjs.paths.Line([width - bottomArcOffset, 0], [width, 0])
  const leftWall = new makerjs.paths.Line([0, 0], [0, height])
  const rightWall = new makerjs.paths.Line([width, 0], [width, height])
  const topLeftOffset = new makerjs.paths.Line([0, height], [topArcOffset, height])
  const topRightOffset = new makerjs.paths.Line([width, height], [width - topArcOffset, height])

  this.models = {
    rectangle_group: {
      paths: {
        top_arc: topArc,
        bottom_arc: bottomArc,
        blo: bottomLeftOffset,
        bro: bottomRightOffset,
        left_wall: leftWall,
        right_wall: rightWall,
        top_left_offset: topLeftOffset,
        top_right_offset: topRightOffset
      }
    }
  }
}

RectangleConcaveSide.metaParameters = [
  {
    title: "Part Width",
    type: "range",
    min: 0,
    max: 12,
    value: 6,
    default: 6,
    tooltip_text: "Overall width of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectconcave_width.png",
    group: 'rectangle_group',
    group_title: 'Plate'
  },
  {
    title: "Part Height",
    type: "range",
    min: 0,
    max: 12,
    value: 3,
    default: 3,
    tooltip_text: "Overall height of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectconcave_height.png",
    group: 'rectangle_group',
    group_title: 'Plate'
  },
  {
    title: "Bottom Arc Offset",
    type: "range",
    min: 0,
    max: 6,
    value: 1,
    default: 1,
    tooltip_text: "The distance from the side of the part to the beginning of the bottom arc",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectconcave_boffset.png",
    group: 'rectangle_group',
    group_title: 'Plate'
  },
  {
    title: "Top Arc Offset",
    type: "range",
    min: 0,
    max: 6,
    value: 1,
    default: 1,
    tooltip_text: "The distance from the side of the part to the beginning of the top arc",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectconcave_toffset.png",
    group: 'rectangle_group',
    group_title: 'Plate'
  },
  {
    title: "Arc depth",
    type: "range",
    min: 0,
    max: 6,
    value: 0.5,
    default: 0.5,
    tooltip_text: "The distance from the edge of the part to the apex of the arc",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectconcave_depth.png",
    group: 'rectangle_group',
    group_title: 'Plate'
  },
  { type: "bends", value: [], default: [], hidden: true, group: ['bend0', 'bend1', 'bend2', 'bend3'] }
]

export default RectangleConcaveSide
