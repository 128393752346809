export default new class Logger {
  constructor() {
    this.debug = false
    this.prefix = '%cVue-Ably: '
  }

  info(text, data = '') {
    if (this.debug) {
      window.console.info(`${this.prefix}%c${text}`, 'color: blue; font-weight: bold', 'color: inherit', data)
    }
  }

  error(...args) {
    if (this.debug) {
      window.console.error(this.prefix, ...args)
    }
  }

  warn(...args) {
    if (this.debug) {
      window.console.warn(this.prefix, ...args)
    }
  }

  event(text, data = '') {
    if (this.debug) {
      window.console.info(`${this.prefix}%c${text}`, 'color: blue; font-weight: bold', 'color: inherit', data)
    }
  }
}()
