import makerjs from 'makerjs'

function SquareLink(outerWidth, outerHeight, cornerRadius, leftHoleDiameter, rightHoleDiameter, centerDistance) {
  this.paths = {
    left: new makerjs.paths.Circle([0, 0], leftHoleDiameter / 2),
    right: new makerjs.paths.Circle([centerDistance, 0], rightHoleDiameter / 2)
  }

  const rectangle = new makerjs.models.RoundRectangle(outerWidth, outerHeight, cornerRadius)
  makerjs.model.move(rectangle, [-(outerWidth / 2) + (centerDistance / 2), -(outerHeight / 2)])

  this.models = {
    rectangle_group: rectangle
  }
}

SquareLink.metaParameters = [
  {
    title: "Outer Width",
    type: "range",
    min: 0,
    max: 24,
    value: 3,
    default: 3,
    tooltip_text: "Overall width of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/squarelink_width.png",
    group: 'rectangle_group',
    group_title: 'Plate'
  },
  {
    title: "Outer Height",
    type: "range",
    min: 0,
    max: 24,
    value: 1,
    default: 1,
    tooltip_text: "Overall height of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/squarelink_height.png",
    group: 'rectangle_group',
    group_title: 'Plate'
  },
  {
    title: "Corner Radius",
    type: "range",
    min: 0,
    max: 6,
    value: 0.25,
    default: 0.25,
    tooltip_text: "Radius of the outside corners",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/squarelink_corner.png",
    group: 'rectangle_group',
    group_title: 'Plate'
  },
  {
    title: "Left Hole Diameter",
    type: "range",
    min: 0,
    max: 6,
    value: 0.5,
    default: 0.5,
    tooltip_text: "Diameter of the left hole",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/squarelink_left.png",
    group: 'left',
    group_title: 'Left Hole'
  },
  {
    title: "Right Hole Diameter",
    type: "range",
    min: 0,
    max: 6,
    value: 0.5,
    default: 0.5,
    tooltip_text: "Diameter of the right hole",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/squarelink_right.png",
    group: 'right',
    group_title: 'Right Hole'
  },
  {
    title: "Distance Between Centers",
    type: "range",
    min: 0,
    max: 24,
    value: 2,
    default: 2,
    tooltip_text: "Distance between the center of the left and right holes",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/squarelink_dist.png",
    group: ['left', 'right'],
    group_title: 'Holes'
  },
  { type: "bends", value: [], default: [], hidden: true, group: ['bend0', 'bend1', 'bend2', 'bend3'] }
]

export default SquareLink
