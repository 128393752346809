import makerjs from 'makerjs'
import { adjustFontSize } from 'shared/components/PartBuilder/parts/utilities/common'

function PositiveLetter(font, letter, fontSize) {
  const model = new makerjs.models.Text(font, letter, fontSize, false, false, 4)
  adjustFontSize(model, fontSize)

  this.models = {
    text_group: model
  }
}

PositiveLetter.metaParameters = [
    { title: "Font", type: "font", stencil: false, value: 'Hack', default: 'Hack', group: 'text_group', group_title: 'Letter' },
    {
      title: "Letter",
      type: "select",
      options: {
        "A": "A",
        "B": "B",
        "C": "C",
        "D": "D",
        "E": "E",
        "F": "F",
        "G": "G",
        "H": "H",
        "I": "I",
        "J": "J",
        "K": "K",
        "L": "L",
        "M": "M",
        "N": "N",
        "O": "O",
        "P": "P",
        "Q": "Q",
        "R": "R",
        "S": "S",
        "T": "T",
        "U": "U",
        "V": "V",
        "W": "W",
        "X": "X",
        "Y": "Y",
        "Z": "Z",
        "a": "a",
        "b": "b",
        "c": "c",
        "d": "d",
        "e": "e",
        "f": "f",
        "g": "g",
        "h": "h",
        "i": "i",
        "j": "j",
        "k": "k",
        "l": "l",
        "m": "m",
        "n": "n",
        "o": "o",
        "p": "p",
        "q": "q",
        "r": "r",
        "s": "s",
        "t": "t",
        "u": "u",
        "v": "v",
        "w": "w",
        "x": "x",
        "y": "y",
        "z": "z",
        "0": "0",
        "1": "1",
        "2": "2",
        "3": "3",
        "4": "4",
        "5": "5",
        "6": "6",
        "7": "7",
        "8": "8",
        "9": "9"
      },
      value: "A",
      default: "A",
      group: 'text_group',
      group_title: 'Letter'
    },
    { title: "Font Size", type: "range", min: 0.75, max: 12, value: 1.5, default: 1.5, group: 'text_group', group_title: 'Letter' },
]

export default PositiveLetter
