import Vue from 'vue'
import Buefy from 'buefy'
import VTooltip from 'v-tooltip'
import VeeValidate, { Validator } from 'vee-validate'

// Custom Components
import DrawingImage from 'shared/components/DrawingImage'
import ScsIcon from 'shared/components/ScsIcon'
import DrawingUploadButton from 'customer/components/shared/DrawingUploadButton'
import AttachmentItem from 'shared/components/AttachmentItem'
import AttachmentUploadButton from 'shared/components/AttachmentUploadButton'
import LayoutCustomer from 'customer/components/core/LayoutCustomer'
import CopyLink from 'customer/components/shared/CopyLink'
import VerificationBadges from 'customer/components/shared/VerificationBadges'
import Currency from 'customer/components/shared/Currency'
import GatedFeature from 'customer/components/shared/GatedFeature'
import PhoneInput from 'shared/components/PhoneInput'

// Custom Validators
import { emailListValidator, emailUsernameValidator, phoneValidator } from '../shared/validators'

// Shared Customer Validators
import { poBoxValidator, passwordValidator } from 'shared/components/shared/validators'

// https://github.com/wangdahoo/vue-progress
import VueProgress from 'vue-progress'

export default () => {
  Vue.use(Buefy)
  Vue.use(VTooltip)
  Vue.use(VeeValidate)
  Vue.use(VueProgress)

  Vue.component('LayoutCustomer', LayoutCustomer)
  Vue.component('AttachmentItem', AttachmentItem)
  Vue.component('AttachmentUploadButton', AttachmentUploadButton)
  Vue.component('CopyLink', CopyLink)
  Vue.component('DrawingImage', DrawingImage)
  Vue.component('DrawingUploadButton', DrawingUploadButton)
  Vue.component('ScsIcon', ScsIcon)
  Vue.component('VerificationBadges', VerificationBadges)
  Vue.component('Currency', Currency)
  Vue.component('GatedFeature', GatedFeature)
  Vue.component('PhoneInput', PhoneInput)

  // Custom validation
  Validator.extend('email_list', emailListValidator)
  Validator.extend('email_username', emailUsernameValidator)
  Validator.extend('password', passwordValidator)
  Validator.extend('po_box_address', poBoxValidator)
  Validator.extend('phone_number', phoneValidator)

  // SCS Globals
  Vue.prototype.$scs = {
    pack: 'public'
  }
}
