export default {
    data: () => {
      return {
        filterListActive: false,
      }
    },
    computed: {
      filterListOpen: function() {
        return this.filterListActive ? 'is-active' : ''
      },
    },
    methods: {
      toggleFilterMenu: function() {
        this.filterListActive = !this.filterListActive
      }
    },
  }
  