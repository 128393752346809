import moment from "moment-timezone"
import { EventBus } from 'shared/services/event_bus.js'
import scs from 'shared/scs'

// For Reference:
// https://developers.intercom.com/installing-intercom/docs/intercom-javascript
// https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects

const intercomState = {
  // true if the intercom app has been bootstrapped.
  initialized: false,
  // true if the intercom app is currently showing.
  showing: false,
  // Count of unread messages in the intercom app.
  unreadCount: 0,
}

const intercomGetters = {
  // Returns true if the Intercom app is enabled.
  enabled() {
    return !!window.Intercom
  },
  // Returns true if Intercom is available for use.
  available(state, getters) {
    return state.initialized && getters.enabled
  },
  // Returns true if the Intercom app is showing.
  showing(state) {
    return state.showing
  },
  // Returns the unread message count for Intercom.
  unreadCount(state) {
    return state.unreadCount
  },
  // Returns configuration settings for the Intercom app.
  settings(state, getters, rootState) {
    // https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects
    const settings = {
      api_base: rootState.app.intercomApiBase,
      app_id: rootState.app.intercomAppId,
      custom_launcher_selector: '.show-intercom',
      vertical_padding: 20,
      horizontal_padding: 20,
    }

    const { currentUser } = rootState.app
    if (currentUser) {
      settings.user_id = currentUser.id
      settings.user_hash = currentUser.user_hash
      settings.created_at = moment(currentUser.created).unix()

      settings.guest = currentUser.guest
      if (!currentUser.guest) {
        settings.name = currentUser.name
        settings.email = currentUser.email
      }
    }
    return settings
  }
}

const actions = {
  bootstrap({ state, commit, dispatch, getters, rootGetters }) {
    // If intercom is not available, or already enabled.
    if (!getters.enabled || state.initialized) return
    // Do not activate intercom for admin logins.
    if (!rootGetters["app/isCustomer"]) return

    // Intercom Events
    window.Intercom("boot", getters.settings)
    window.Intercom('onShow', () => commit("setShowing", true))
    window.Intercom('onHide', () => commit("setShowing", false))
    window.Intercom('onUnreadCountChange', (unreadCount) => {
      commit("setUnreadCount", unreadCount)
    })

    // Global event handlers to update Intercom state.
    EventBus.$on('user_logged_in', () => dispatch('update'))
    EventBus.$on('user_logout', () => dispatch("shutdown"))
    // If page changes update intercom.
    EventBus.$on('route_changed', ({ from, to }) => {
      if (from.name && from.name !== to.name) dispatch("update")
    })

    commit('setInitialized', true)
  },
  // Update Intercom when user changes.
  update({ getters, rootGetters }) {
    if (getters.available) {
      // Only activate intercom for customers.
      if (rootGetters["app/isCustomer"]) {
        window.Intercom("update", getters.settings)
      } else {
        window.Intercom("shutdown")
      }
    }
  },
  // Shutdown the intercom app. For use when a user logs out.
  shutdown({ getters, commit }) {
    if (getters.available) {
      window.Intercom("shutdown")
      commit('setInitialized', false)
    }
  },
  // Request the intercom app be shown.
  show({ getters }, options = {}) {
    if (getters.available) {
      if (Object.hasOwn(options, 'ticketId')) {
        // This shouldn't be necessary, but doesn't seem to work without
        window.Intercom("show")
        window.Intercom("showConversation", options.ticketId)
      } else if (Object.hasOwn(options, 'message')) {
        window.Intercom("showNewMessage", options.message)
      } else {
        window.Intercom("show")
      }
    }
  },
  // Request the intercom app be hidden.
  hide({ getters }) {
    if (getters.available) window.Intercom("hide")
  },
  support({ rootGetters }, { token } = {}) {
    const clientId = rootGetters["app/clientGuid"]
    if (clientId) {
      return scs.intercomSupport({ client_id: clientId, token })
    }
    return null
  }
}

const mutations = {
  // Set the initialized flag.
  setInitialized(state, value) {
    state.initialized = value
  },
  // Set the showing flag.
  setShowing(state, showing) {
    state.showing = showing
  },
  // Set the intercom unread message count.
  setUnreadCount(state, count) {
    state.unreadCount = count
  }
}

export default {
  namespaced: true,
  state: intercomState,
  getters: intercomGetters,
  actions,
  mutations
}
