export default [
  // Standard

  // added 0.015 to hole diameter for hole resizing
  { id: 1, label: '1/2" diameter', hole_diameter: 0.515, major: 0.850, tooling: 1.190, extrusion: 0.135 },
  { id: 2, label: '3/4" diameter', hole_diameter: 0.765, major: 1.150, tooling: 1.440, extrusion: 0.135 },
  { id: 3, label: '1" diameter', hole_diameter: 1.015, major: 1.575, tooling: 1.940, extrusion: 0.17 },
  { id: 4, label: '1 1/4" diameter', hole_diameter: 1.265, major: 1.875, tooling: 2.440, extrusion: 0.19 },
  { id: 5, label: '1 1/2" diameter', hole_diameter: 1.515, major: 2.200, tooling: 2.690, extrusion: 0.205 },
  { id: 6, label: '1 3/4" diameter', hole_diameter: 1.765, major: 2.500, tooling: 3.190, extrusion: 0.210 },
  { id: 7, label: '2" diameter', hole_diameter: 2.015, major: 2.900, tooling: 3.460, extrusion: 0.225 },
  { id: 8, label: '2 1/2" diameter', hole_diameter: 2.515, major: 3.600, tooling: 4.440, extrusion: 0.275 },
  { id: 9, label: '3" diameter', hole_diameter: 3.015, major: 4.375, tooling: 5.190, extrusion: 0.350 },
]
