import makerjs from 'makerjs'

function SquareWithRoundHole(sideLength, cornerRadius, holeDiameter) {
  this.paths = {
    inner: new makerjs.paths.Circle([0, 0], holeDiameter / 2)
  }
  this.models = {
    square_group: makerjs.model.center(new makerjs.models.RoundRectangle(sideLength, sideLength, cornerRadius))
  }
}

SquareWithRoundHole.metaParameters = [
  {
    title: "Side Length",
    type: "range",
    min: 0,
    max: 24,
    value: 6,
    default: 6,
    tooltip_text: "Overall part height and length",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/sqrnd_length.png",
    group: 'square_group',
    group_title: 'Plate'
  },
  {
    title: "Corner Radius",
    type: "range",
    min: 0,
    max: 3,
    value: 0.5,
    default: 0.5,
    tooltip_text: "Radius of the outside corners",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/sqrnd_corner.png",
    group: 'square_group',
    group_title: 'Plate'
  },
  {
    title: "Inner Hole Diameter",
    type: "range",
    min: 0,
    max: 24,
    value: 3,
    default: 3,
    tooltip_text: "Diameter of the hole",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/sqrnd_hole.png",
    group: 'inner',
    group_title: 'Inner Hole'
  },
  { type: "bends", value: [], default: [], hidden: true, group: ['bend0', 'bend1', 'bend2', 'bend3'] }
]

export default SquareWithRoundHole
