import scs from 'shared/scs'

// initial state
const customerState = {
  customer: null,
  referrals: {},
  affiliate_urls: []
}

// getters
const getters = {
  get: (state) => state.customer,
  hasCompleteProfile: ({ customer }) => !!(customer
    && (customer.first_name && customer.last_name && customer.address1
      && customer.city && customer.province && customer.zip))
}

// actions
const actions = {
  fetch({ commit }, data) {
    return scs.getCustomer(data)
      .then((response) => {
        commit('setCustomer', response.data)
      })
  },

  async fetchReferrals({ commit }, affiliateID) {
    const resp = await scs.getReferralStats(affiliateID)
    commit('setReferralStats', resp)
  },

  async fetchAffiliate({ commit }, affiliateID) {
    const resp = await scs.getAffiliate(affiliateID)
    commit('setAffiliateLinks', resp.data.affiliate_urls)
  },

  update({ commit }, data) {
    return scs.updateCustomer(data)
      .then((response) => {
        commit('setCustomer', response.data)
      })
  },
}

// mutations
const mutations = {
  setCustomer(state, customer) {
    state.customer = customer
  },

  setReferralStats(state, stats) {
    state.referrals = stats
  },

  setAffiliateLinks(state, urls) {
    state.affiliate_urls = urls
  }
}

export default {
  namespaced: true,
  state: customerState,
  mutations,
  actions,
  getters
}
