import makerjs from 'makerjs'

function TriangularGusset(sideOneLength, sideTwoLength, angle, outerCornerRadius, innerCornerRadius, holeDiameter) {
  let sideOneCoords = [0, sideOneLength]
  if (angle !== 90) {
    if (angle > 90) {
      const complimentaryRads = (180 - angle) * (Math.PI / 180)
      sideOneCoords = [-Math.cos(complimentaryRads) * sideOneLength, Math.sin(complimentaryRads) * sideOneLength]
    }
    else {
      const rads = angle * (Math.PI / 180)
      sideOneCoords = [Math.cos(rads) * sideOneLength, (Math.sin(rads) * sideOneLength)]
    }
  }

  const sideOne = new makerjs.paths.Line([0, 0], sideOneCoords)
  const sideTwo = new makerjs.paths.Line([0, 0], [sideTwoLength, 0])
  const sideThree = new makerjs.paths.Line([sideTwoLength, 0], sideOneCoords)
  const sideThreeMP = [sideTwoLength / 2, (sideOneCoords[1]) / 2]
  const medianMP = [sideThreeMP[0] / 2, sideThreeMP[1] / 2]

  const hole = angle >= 90
    ? new makerjs.paths.Circle([medianMP[0], medianMP[1]], holeDiameter / 2)
    : new makerjs.paths.Circle([(sideOneCoords[0] + sideTwoLength) / 3, (sideOneCoords[1] / 3)], holeDiameter / 2)

  this.paths = {
    hole
  }

  this.models = {
    triangle_group: {
      paths: {
        side_one: sideOne,
        side_two: sideTwo,
        side_three: sideThree,
        fillet_one: makerjs.path.fillet(sideOne, sideTwo, innerCornerRadius),
        fillet_two: makerjs.path.fillet(sideOne, sideThree, outerCornerRadius),
        fillet_three: makerjs.path.fillet(sideTwo, sideThree, outerCornerRadius)
      }
    }
  }
}

TriangularGusset.metaParameters = [
  {
    title: "Side One Length",
    type: "range",
    min: 0,
    max: 24,
    value: 6,
    default: 6,
    tooltip_text: "Length of the part vertically before corners are rounded",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/trigusset_lenone.png",
    group: 'triangle_group',
    group_title: 'Plate'
  },
  {
    title: "Side Two Length",
    type: "range",
    min: 0,
    max: 24,
    value: 6,
    default: 6,
    tooltip_text: "Length of the part horzontially before corners are rounded",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/trigusset_lentwo.png",
    group: 'triangle_group',
    group_title: 'Plate'
  },
  {
    title: "Angle",
    type: "range",
    units: '°',
    min: 1,
    max: 180,
    value: 90,
    default: 90,
    tooltip_text: "Angle of the triangle",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/trigusset_angle.png",
    group: 'triangle_group',
    group_title: 'Plate'
  },
  {
    title: "Outer Corner Radius",
    type: "range",
    min: 0,
    max: 6,
    value: 0.25,
    default: 0.25,
    tooltip_text: "Radius of the two outer corners",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/trigusset_corners.png",
    group: 'triangle_group',
    group_title: 'Plate'
  },
  {
    title: "Inner Corner Radius",
    type: "range",
    min: 0,
    max: 6,
    value: 0.25,
    default: 0.25,
    tooltip_text: "Radius of the inner corners",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/trigusset_innercorner.png",
    group: 'triangle_group',
    group_title: 'Plate'
  },
  {
    title: "Hole Diameter",
    type: "range",
    min: 0,
    max: 24,
    value: 1.5,
    default: 1.5,
    tooltip_text: "Diameter of the hole",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/trigusset_hole.png",
    group: 'hole',
    group_title: 'Hole'
  }
]

export default TriangularGusset
