import Vue from 'vue'
import scs from 'shared/scs'

function timeInt(date) {
  return new Date(date).getTime()
}

// initial state
const state = {
  notifications: {}
}

// getters
const getters = {
  all(state) {
    return Object.keys(state.notifications)
      .map(key => state.notifications[key])
      .sort((a, b) => timeInt(b.created_at) - timeInt(a.created_at))
  }
}

// actions
const actions = {
  // A notification was received via websocket--notify the user
  alert({ commit }, notification) {
    commit('setNotification', notification)
  },

  fetch ({ commit }) {
    return scs.getNotifications()
      .then((response) => commit('setNotifications', response.data))
  },

  read ({ commit }, notification) {
    return scs.readNotification(notification.id)
      .then((response) => commit('setNotification', response.data))
  },

  deleteAll({ commit }) {
    commit('clearNotifications')
    scs.deleteAllNotifications()
  },

  readAll({ commit }) {
    commit('markAllRead')
    scs.readAllNotifications()
  },
}

// mutations
const mutations = {
  clearNotifications(state) {
    state.notifications = {}
  },

  markAllRead(state) {
    const ids = Object.keys(state.notifications)
    for (let i = 0; i < ids.length; i++) {
      Vue.set(state.notifications[ids[i]], 'viewed_at', new Date().toString())
    }
  },

  setNotification(state, notification) {
    Vue.set(state.notifications, notification.id, notification)
  },

  setNotifications(state, notifications) {
    for (let i = 0; i < notifications.length; i++) {
      const notification = notifications[i]
      Vue.set(state.notifications, notification.id, notification)
    }
  },
}

export default {
  namespaced: true,
  state:     state,
  mutations: mutations,
  actions:   actions,
  getters:   getters
}
