import makerjs from 'makerjs'

function RectangleBoltFlangeWithCenter(
  plateWidth,
  plateHeight,
  cornerRadius,
  holeWidth,
  holeHeight,
  holeDiameter,
  innerHoleDiameter
) {
  const bolts = new makerjs.models.BoltRectangle(holeWidth, holeHeight, holeDiameter / 2)
  const flange = new makerjs.models.RoundRectangle(plateWidth, plateHeight, cornerRadius)
  const centerHole = new makerjs.paths.Circle([0, 0], innerHoleDiameter / 2)

  makerjs.model.center(bolts)
  makerjs.model.center(flange)

  this.models = {
    flange_group: flange,
    bolts
  }

  this.paths = {
    center_hole: centerHole
  }
}

RectangleBoltFlangeWithCenter.metaParameters = [
  {
    title: "Plate Width",
    type: "range",
    min: 0,
    max: 47,
    value: 6,
    default: 6,
    group: 'flange_group',
    group_title: 'Plate',
    tooltip_text: "Overall width of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectflangeo_width.png"
  },
  {
    title: "Plate Height",
    type: "range",
    min: 0,
    max: 47,
    value: 4,
    default: 4,
    group: 'flange_group',
    group_title: 'Plate',
    tooltip_text: "Overall height of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectflangeo_height.png"
  },
  {
    title: "Corner Radius",
    type: "range",
    min: 0,
    max: 6,
    value: 0.25,
    default: 0.25,
    group: 'flange_group',
    group_title: 'Plate',
    tooltip_text: "Radius of outside corners",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectflangeo_corner.png"
  },
  {
    title: "Bolt Hole Width",
    type: "range",
    min: 0,
    max: 47,
    value: 5,
    default: 5,
    group: 'bolts',
    group_title: 'Bolts',
    tooltip_text: "Horizontal distance between center of bolt holes",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectflangeo_holewidth.png"
  },
  {
    title: "Bolt Hole Height",
    type: "range",
    min: 0,
    max: 47,
    value: 3,
    default: 3,
    group: 'bolts',
    group_title: 'Bolts',
    tooltip_text: "Vertical distance between center of bolt holes",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectflangeo_holeheight.png"
  },
  {
    title: "Bolt Hole Diameter",
    type: "range",
    min: 0,
    max: 6,
    value: 0.25,
    default: 0.25,
    group: 'bolts',
    group_title: 'Bolts',
    tooltip_text: "Diameter of all bolt holes",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectflangeo_holedia.png"
  },
  {
    title: "Inner Hole Diameter",
    type: "range",
    min: 0,
    max: 24,
    value: 3,
    default: 3,
    group: 'center_hole',
    group_title: 'Center Hole',
    tooltip_text: "Diameter of the center hole",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectflangeo_dia.png"
  },
  { type: "bends", value: [], default: [], hidden: true, group: ['bend0', 'bend1', 'bend2', 'bend3'] }
]

export default RectangleBoltFlangeWithCenter
