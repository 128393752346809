import Mixin from "./mixin"
import Logger from "./logger"
import Emitter from "./emitter"
import { EventBus } from 'shared/services/event_bus.js'
import * as Ably from 'ably'

function serializeParams(obj) {
  const str = []
  Object.getOwnPropertyNames(obj).forEach((p) => {
    str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`)
  })
  return str.join("&")
}

export default class VueAbly {
  /**
   * Construct and connect to Ably
   * @param io
   * @param vuex
   * @param debug
   */
  constructor({ connection, vuex, debug }) {
    this.connection = connection
    this.vuex = vuex
    this.refreshToken = ''

    Logger.debug = debug
    this.emitter = new Emitter(vuex)

    if (debug) {
      // Easy access to the ably object for testing.
      window.$ably = this
    }

    try {
      this.client = this.createClient(this.connection || {})
    }
    catch (error) {
      console.error("AblyClient: Failed to create connection", error)
    }

    if (this.client) {
      // Watch for changes in the tab visibility and attempt to reconnect if our connection has gone stale
      document.addEventListener('visibilitychange', () => {
        if (!document.hidden && this.client.connection.state === 'suspended') {
          this.client.connect()
        }
      })

      // Re-authorize sockets when the user changes.
      EventBus.$on('user_logged_in', () => this.refreshChannels())
    }
  }

  /**
   * Vue.js entry point
   * @param Vue
   */
  install(Vue) {
    Vue.prototype.$ably = Ably
    Vue.prototype.$vueAbly = this

    Vue.mixin(Mixin)
    Logger.info('AblyVue plugin enabled')
  }

  /** Refresh Ably channels.
   * Call when channels are expected to change, such as user login.
   */
  refreshChannels() {
    // Re-authorize sockets when the user changes.
    this.client.connection.close()
    this.client.auth.authorize() // will initiate connect.
  }

  /**
   * Create Ably Realtime Socket client
   * @param connection options to pass to the ably client.
   */
  createClient(connection) {
    const client = new Ably.Realtime({
      ...connection,
      authCallback: (data, callback) => this.authCallback(data, callback)
    })

    // Ensure the necessary channels are subscribed to.
    client.connection.on(["connected", "update"], () => this.subscribeChannels())

    return client
  }

  /**
   * Ensure all necessary channels are subscribed to.
   */
  subscribeChannels() {
    try {
      const availableChannels = Object.keys(JSON.parse(this.client?.auth?.tokenDetails?.capability || '{}'))
      availableChannels.forEach(name => {
        const channel = this.client.channels.get(name)

        // Setup the channel when it is created.
        // These operations should only happen once.
        channel.whenState('initialized', () => {
          // Stale channels that are no longer authorized can build up if not explicitly released.
          //  We do this when the channel reaches a state where it's no longer being used.
          channel.whenState('failed', () => this.client.channels.release(name))
          channel.whenState('detached', () => this.client.channels.release(name))

          channel.subscribe(message => this.emitter.emit(message.name, message.data))
        })

        // If this is the generated client channel, store its id
        if (channel.name.includes('client-')) {
          this.vuex.store.commit('app/setClientGuid', channel.name)
        }
      })
    }
    catch (error) {
      console.warn("AblyClient:", error)
    }
  }

  /**
   * Callback used by the ably client to obtain a fresh token.
   *
   * @param tokenParams values provided by the ably library as part of the token request.
   * @param callback called with the result of the token request (or an error)
   */
  async authCallback(tokenParams, callback) {
    const authMethod = (this.connection.authMethod || 'GET').toUpperCase()
    const authParams = { ...tokenParams, ...(this.connection.authParams || {}), refresh_token: this.refreshToken }

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }

    let authUrl = this.connection.authUrl || "/auth_socket"
    if (authMethod === "GET") {
      authUrl += `${/\?/.test(authUrl) ? '&' : '?'}${serializeParams(authParams)}`
    } else {
      headers['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').content
    }

    try {
      const response = await fetch(authUrl, {
        method: authMethod,
        headers,
        credentials: 'same-origin',
        body: (authMethod !== "GET") ? JSON.stringify(authParams) : null
      })

      if (response.ok) {
        // Store refresh token for the next call.
        this.refreshToken = response.headers.get("x-refresh-token")
        callback(null, await response.json())
      } else {
        const error = await response.text() || "Server error"
        callback(error, null)
      }
    }
    catch (error) {
      callback(error, null)
    }
  }
}
