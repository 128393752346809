import makerjs from 'makerjs'

// Scale the metric for the units
export function adjustMetric(units, value) {
  return units == 'in' ? value : value * 25.4
}

// Scale the given font model to the indicated size
export function adjustFontSize(model, size) {
  let textSize = makerjs.measure.modelExtents(model)
  makerjs.model.scale(model, size / textSize.height)
  makerjs.model.center(model)
}

//convert from degrees to radians
export function toRadians (angle) {
  return angle * (Math.PI / 180);
}

//Definition of a circle object
export function circle(a, b, r) {
  this.a = a; this.b = b; this.r = r;
}

export function solveY(c, x){
  var y1 = c.a + Math.sqrt(Math.pow(c.r, 2) - Math.pow(x - c.b, 2))
  return y1
}

//distance between two x coordinates
export function distance(x1, x2){
  return Math.sqrt(Math.pow((x2-x1), 2))
}

//find points where circles intersect
export function twoCirclesIntersection(c1, c2){
  //**************************************************************
  //Calculating intersection coordinates (x1, y1) and (x2, y2) of
  //two circles of the form (x - c1.a)^2 + (y - c1.b)^2 = c1.r^2
  //                        (x - c2.a)^2 + (y - c2.b)^2 = c2.r^2
  //
  //**************************************************************
  var x1,y1,x2,y2;
  var val1, val2, test;
  // Calculating distance between circles centers
  var D = Math.sqrt((c1.a - c2.a) * (c1.a - c2.a) + (c1.b - c2.b) * (c1.b - c2.b));
  // Two circles intersects or tangent
  // Area according to Heron's formula
  //----------------------------------
  var a1 = D + c1.r + c2.r;
  var a2 = D + c1.r - c2.r;
  var a3 = D - c1.r + c2.r;
  var a4 = -D + c1.r + c2.r;
  var area = Math.sqrt(a1 * a2 * a3 * a4) / 4;
  // Calculating x axis intersection values
  //---------------------------------------
  val1 = (c1.a + c2.a) / 2 + (c2.a - c1.a) * (c1.r * c1.r - c2.r * c2.r) / (2 * D * D);
  val2 = 2 * (c1.b - c2.b) * area / (D * D);
  x1 = val1 + val2;
  x2 = val1 - val2;
  // Calculating y axis intersection values 
  //---------------------------------------
  val1 = (c1.b + c2.b) / 2 + (c2.b - c1.b) * (c1.r * c1.r - c2.r * c2.r) / (2 * D * D);
  val2 = 2 * (c1.a - c2.a) * area / (D * D);
  y1 = val1 - val2;
  y2 = val1 + val2;
  // Intersection pointsare (x1, y1) and (x2, y2)
  // Because for every x we have two values of y, and the same thing for y,
  // we have to verify that the intersection points as chose are on the
  // circle otherwise we have to swap between the points
  test = Math.abs((x1 - c1.a) * (x1 - c1.a) + (y1 - c1.b) * (y1 - c1.b) - c1.r * c1.r);
  if (test > 0.0000001) {
  // point is not on the circle, swap between y1 and y2
  // the value of 0.0000001 is arbitrary chose, smaller values are also OK
  // do not use the value 0 because of computer rounding problems
  var tmp = y1;
  y1 = y2;
  y2 = tmp;
  }
  var coords = [x1, y1, x2, y2];
  return coords;
}

