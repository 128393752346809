import makerjs from 'makerjs'

import Antlers from './Antlers'
import BBQGrill from './BBQGrill'
import Belt from './Belt'
import Butterfly from './Butterfly'
import CornerGusset from './CornerGusset'
import CornerFlatGusset from './CornerFlatGusset'
import CornerNotchGusset from './CornerNotchGusset'
import CornerRoundGusset from './CornerRoundGusset'
import DiamondFlange from './DiamondFlange'
import Disc from './Disc'
import DogEarTab from './DogEarTab'
import DomeTab from './DomeTab'
import DoubleRoundBoltFlange from './DoubleRoundBoltFlange'
import DoubleSquareFlange from './DoubleSquareFlange'
import Ellipse from './Ellipse'
import Flag from './Flag'
import FlagGusset from './FlagGusset'
import GameSystem from './GameSystem'
import LaserGun from './LaserGun'
import LBracket from './LBracket'
import Mountain from './Mountain'
import MountainTwo from './MountainTwo'
import NamePlate from './NamePlate'
import OneUp from './OneUp'
import OvalFlange from './OvalFlange'
import RectangleBoltFlange from './RectangleBoltFlange'
import RectangleBoltFlangeWithCenter from './RectangleBoltFlangeWithCenter'
import RectangleConcaveSide from './RectangleConcaveSide'
import RectangleConvexSide from './RectangleConvexSide'
import RectangularLink from './RectangularLink'
import RectangularVent from './RectangularVent'
import RoundBoltFlange from './RoundBoltFlange'
import RoundLink from './RoundLink'
import RoundRectangle from './RoundRectangle'
import SpurGear from './SpurGear'
import SpokedGear from './SpokedGear'
import Polygon from './Polygon'
import PolygonWithRoundHole from './PolygonWithRoundHole'
import PositiveLetter from './PositiveLetter'
import PositiveSign from './PositiveSign'
import PositiveStates from './PositiveStates'
import RollCageTab from './RollCageTab'
import SpokedDisc from './SpokedDisc'
import SpokedArcDisc from './SpokedArcDisc'
import Square from './Square'
import SquareFlange from './SquareFlange'
import SquareLink from './SquareLink'
import SquareWithRoundHole from './SquareWithRoundHole'
import SquareWithSquareHole from './SquareWithSquareHole'
import Star from './Star'
import States from './States'
import Trapezoid from './Trapezoid'
import Triangle from './Triangle'
import TriangularGusset from './TriangularGusset'
import TriggerWheel from './TriggerWheel'
import VanityPlate from './VanityPlate'
import Washer from './Washer'

// This is the big list of our parts
export default [
  {
    id: 'rectangle_bolt_flange',
    category: "Brackets",
    name: 'Rectangle Bolt Flange',
    preview: "https://cdn.sendcutsend.com/images/parts/rectangle_bolt_flange.svg",
    model: RectangleBoltFlange,
    params: RectangleBoltFlange.metaParameters,
  },
  {
    id: 'rectangle_bolt_flange_with_center',
    category: "Brackets",
    name: 'Rectangle Bolt Flange With Center Hole',
    preview: "https://cdn.sendcutsend.com/images/parts/rectangle_bolt_flange_with_center.svg",
    model: RectangleBoltFlangeWithCenter,
    params: RectangleBoltFlangeWithCenter.metaParameters
  },
  {
    id: 'rectangular_link',
    category: "Brackets",
    name: "Rectangular Link",
    preview: "https://cdn.sendcutsend.com/images/parts/rectangular_link.svg",
    model: RectangularLink,
    params: RectangularLink.metaParameters
  },
  {
    id: 'round_bolt_flange',
    category: "Brackets",
    name: 'Round Bolt Flange',
    preview: "https://cdn.sendcutsend.com/images/parts/round_bolt_flange.svg",
    model: RoundBoltFlange,
    params: RoundBoltFlange.metaParameters
  },
  {
    id: 'oval_flange',
    category: "Brackets",
    name: 'Oval Flange',
    preview: "https://cdn.sendcutsend.com/images/parts/oval_flange.svg",
    model: OvalFlange,
    params: OvalFlange.metaParameters
  },
  {
    id: 'round_link',
    category: "Brackets",
    name: 'Round Link',
    preview: "https://cdn.sendcutsend.com/images/parts/round_link2.svg",
    model: RoundLink,
    params: RoundLink.metaParameters
  },
  {
    id: 'square_flange',
    category: "Brackets",
    name: 'Square Flange',
    preview: "https://cdn.sendcutsend.com/images/parts/square_flange.svg",
    model: SquareFlange,
    params: SquareFlange.metaParameters
  },
  {
    id: 'square_link',
    category: "Brackets",
    name: 'Square Link',
    preview: "https://cdn.sendcutsend.com/images/parts/square_link.svg",
    model: SquareLink,
    params: SquareLink.metaParameters
  },
  {
    id: 'square_with_round_hole',
    category: "Brackets",
    name: 'Square With Round Hole',
    preview: "https://cdn.sendcutsend.com/images/parts/square_with_round_hole.svg",
    model: SquareWithRoundHole,
    params: SquareWithRoundHole.metaParameters
  },
  {
    id: 'square_with_square_hole',
    category: "Brackets",
    name: 'Square With Square Hole',
    preview: "https://cdn.sendcutsend.com/images/parts/square_with_square_hole.svg",
    model: SquareWithSquareHole,
    params: SquareWithSquareHole.metaParameters
  },
  {
    id: 'dome_tab',
    category: "Brackets",
    name: 'Dome Tab',
    preview: "https://cdn.sendcutsend.com/images/parts/dome_tab.svg",
    model: DomeTab,
    params: DomeTab.metaParameters
  },
  {
    id: "double_round_flange",
    category: "Brackets",
    name: "Double Round Flange",
    preview: "https://cdn.sendcutsend.com/images/parts/double_bolt_flange.svg",
    model: DoubleRoundBoltFlange,
    params: DoubleRoundBoltFlange.metaParameters
  },
  {
    id: "double_square_flange",
    category: "Brackets",
    name: "Double Square Flange",
    preview: "https://cdn.sendcutsend.com/images/parts/double_square_flange.svg",
    model: DoubleSquareFlange,
    params: DoubleSquareFlange.metaParameters
  },
  {
    id: 'rollcage_tab',
    category: "Brackets",
    name: 'Roll Cage Tab',
    preview: "https://cdn.sendcutsend.com/images/parts/roll_cage_tab.svg",
    model: RollCageTab,
    params: RollCageTab.metaParameters
  },
  {
    id: 'dog_ear_tab',
    category: "Brackets",
    name: 'Dog Ear Tab',
    preview: "https://cdn.sendcutsend.com/images/parts/dog_ear_tab.svg",
    model: DogEarTab,
    params: DogEarTab.metaParameters
  },
  {
    id: 'triangular_gusset',
    category: "Brackets",
    name: 'Triangular Gusset',
    preview: "https://cdn.sendcutsend.com/images/parts/triangular_gusset.svg",
    model: TriangularGusset,
    params: TriangularGusset.metaParameters
  },
  {
    id: 'l_bracket',
    category: "Brackets",
    name: 'L Bracket',
    preview: "https://cdn.sendcutsend.com/images/parts/l_bracket.svg",
    model: LBracket,
    params: LBracket.metaParameters
  },
  {
    id: 'diamond_flange',
    category: "Brackets",
    name: 'Diamond Flange',
    preview: "https://cdn.sendcutsend.com/images/parts/diamond_flange.svg",
    model: DiamondFlange,
    params: DiamondFlange.metaParameters
  },
  {
    id: 'flag_gusset',
    category: "Brackets",
    name: 'Flag Gusset',
    preview: "https://cdn.sendcutsend.com/images/parts/flag_gusset.svg",
    model: FlagGusset,
    params: FlagGusset.metaParameters
  },
  {
    id: 'corner_gusset',
    category: "Brackets",
    name: 'Corner Gusset',
    preview: "https://cdn.sendcutsend.com/images/parts/corner_gusset.svg",
    model: CornerGusset,
    params: CornerGusset.metaParameters
  },
  {
    id: 'flat_corner_gusset',
    category: "Brackets",
    name: 'Flat Corner Gusset',
    preview: "https://cdn.sendcutsend.com/images/parts/flat_corner_gusset.svg",
    model: CornerFlatGusset,
    params: CornerFlatGusset.metaParameters
  },
  {
    id: 'corner_notch_gusset',
    category: "Brackets",
    name: 'Corner Notch Gusset',
    preview: "https://cdn.sendcutsend.com/images/parts/corner_notch_gusset.svg",
    model: CornerNotchGusset,
    params: CornerNotchGusset.metaParameters
  },
  {
    id: 'corner_round_gusset',
    category: "Brackets",
    name: 'Corner Round Gusset',
    preview: "https://cdn.sendcutsend.com/images/parts/corner_round_gusset.svg",
    model: CornerRoundGusset,
    params: CornerRoundGusset.metaParameters
  },
  {
    id: 'spur_gear',
    category: "Gears",
    name: 'Spur Gear',
    preview: "https://cdn.sendcutsend.com/images/parts/spur_gear.svg",
    model: SpurGear,
    params: SpurGear.metaParameters
  },
  {
    id: 'spoked_gear',
    category: "Gears",
    name: 'Spoked Gear',
    preview: "https://cdn.sendcutsend.com/images/parts/spoked_gear2.svg",
    model: SpokedGear,
    params: SpokedGear.metaParameters
  },
  {
    id: 'trigger_wheel',
    category: "Gears",
    name: "Trigger Wheel",
    preview: "https://cdn.sendcutsend.com/images/parts/trigger_wheel2.svg",
    model: TriggerWheel,
    params: TriggerWheel.metaParameters
  },
  {
    id: 'washer',
    category: "Basic",
    name: 'Washer',
    preview: "https://cdn.sendcutsend.com/images/parts/washer.svg",
    model: Washer,
    params: Washer.metaParameters
  },
  {
    id: 'circle',
    category: "Basic",
    name: 'Disc',
    preview: "https://cdn.sendcutsend.com/images/parts/disc_3.svg",
    model: Disc,
    params: Disc.metaParameters
  },
  {
    id: 'round_rectangle',
    category: "Basic",
    name: 'Rectange',
    preview: "https://cdn.sendcutsend.com/images/parts/round_rectangle.svg",
    model: RoundRectangle,
    params: RoundRectangle.metaParameters
  },
  {
    id: 'round_square',
    category: "Basic",
    name: 'Square',
    preview: "https://cdn.sendcutsend.com/images/parts/round_square.svg",
    model: Square,
    params: Square.metaParameters
  },
  {
    id: 'triangle',
    category: "Basic",
    name: 'Triangle',
    preview: "https://cdn.sendcutsend.com/images/parts/triangle.svg",
    model: Triangle,
    params: Triangle.metaParameters
  },
  {
    id: 'star',
    category: "Basic",
    name: 'Star',
    preview: "https://cdn.sendcutsend.com/images/parts/star.svg",
    model: Star,
    params: Star.metaParameters
  },
  {
    id: 'polygon',
    category: "Basic",
    name: 'Polygon',
    preview: "https://cdn.sendcutsend.com/images/parts/polygon.svg",
    model: Polygon,
    params: Polygon.metaParameters
  },
  {
    id: 'polygon_with_round_hole',
    category: "Basic",
    name: 'Polygon With Round Hole',
    preview: "https://cdn.sendcutsend.com/images/parts/polygon_with_round_hole.svg",
    model: PolygonWithRoundHole,
    params: PolygonWithRoundHole.metaParameters
  },
  {
    id: 'dome',
    category: "Basic",
    name: 'Dome',
    preview: "https://cdn.sendcutsend.com/images/parts/dome.svg",
    model: function Dome(width, height) {
      this.models = { dome_group: new makerjs.models.Dome(width, height, width / 2, false) }
    },
    params: [
      { title: "Width", type: "range", min: 0, max: 6, value: 1, default: 1, group: 'dome_group' },
      { title: "Height", type: "range", min: 0, max: 6, value: 1.5, default: 1.5, group: 'dome_group' },
      { type: "bends", value: [], default: [], hidden: true, group: ['bend0', 'bend1', 'bend2', 'bend3'] }
    ]
  },
  {
    id: 'belt',
    category: "Basic",
    name: 'Belt',
    preview: "https://cdn.sendcutsend.com/images/parts/belt.svg",
    model: Belt,
    params: Belt.metaParameters
  },
  {
    id: 'ellipse',
    category: "Basic",
    name: 'Ellipse',
    preview: "https://cdn.sendcutsend.com/images/parts/ellipse.svg",
    model: Ellipse,
    params: Ellipse.metaParameters
  },
  {
    id: 'trapezoid',
    category: "Basic",
    name: 'Trapezoid',
    preview: "https://cdn.sendcutsend.com/images/parts/trapezoid.svg",
    model: Trapezoid,
    params: Trapezoid.metaParameters
  },
  {
    id: 'rectangle_concave',
    category: "Basic",
    name: 'Rectangle with Concave Sides',
    preview: "https://cdn.sendcutsend.com/images/parts/rect_concave.svg",
    model: RectangleConcaveSide,
    params: RectangleConcaveSide.metaParameters
  },
  {
    id: 'rectangle_convex',
    category: "Basic",
    name: 'Rectangle with Convex Sides',
    preview: "https://cdn.sendcutsend.com/images/parts/rect_convex.svg",
    model: RectangleConvexSide,
    params: RectangleConvexSide.metaParameters
  },
  {
    id: 'spoked_disc',
    category: "Basic",
    name: 'Spoked Disc',
    preview: "https://cdn.sendcutsend.com/images/parts/spoked_wheel.svg",
    model: SpokedDisc,
    params: SpokedDisc.metaParameters
  },
  {
    id: 'arc_spoked_disc',
    category: "Basic",
    name: 'Arc Spoked Disc',
    preview: "https://cdn.sendcutsend.com/images/parts/spoked_wheel_arc.svg",
    model: SpokedArcDisc,
    params: SpokedArcDisc.metaParameters
  },
  {
    id: 'name_plate',
    category: "Decorative",
    name: 'Name Plate',
    preview: "https://cdn.sendcutsend.com/images/parts/name_plate.svg",
    model: NamePlate,
    params: NamePlate.metaParameters
  },
  {
    id: 'positive_letter',
    category: "Decorative",
    name: 'Positive Letter',
    preview: "https://cdn.sendcutsend.com/images/parts/positive_letter.svg",
    model: PositiveLetter,
    params: PositiveLetter.metaParameters
  },
  {
    id: 'positive_sign',
    category: "Decorative",
    name: 'Positive Sign',
    preview: "https://cdn.sendcutsend.com/images/parts/positive_sign.svg",
    model: PositiveSign,
    params: PositiveSign.metaParameters
  },
  {
    id: 'rectangular_vent',
    category: "Decorative",
    name: 'Rectangular Vent',
    preview: "https://cdn.sendcutsend.com/images/parts/rectangular_vent.svg",
    model: RectangularVent,
    params: RectangularVent.metaParameters
  },
  {
    id: 'flag',
    category: "Extras",
    name: 'Flag',
    preview: "https://cdn.sendcutsend.com/images/parts/flag.svg",
    model: Flag,
    params: Flag.metaParameters
  },
  {
    id: 'game_system',
    category: "Extras",
    name: 'Game System',
    preview: "https://cdn.sendcutsend.com/images/parts/game_system.svg",
    model: GameSystem,
    params: GameSystem.metaParameters
  },
  {
    id: 'butterfly',
    category: "Extras",
    name: 'Butterfly',
    preview: "https://cdn.sendcutsend.com/images/parts/butterfly.svg",
    model: Butterfly,
    params: Butterfly.metaParameters
  },
  {
    id: 'mystery',
    category: "Extras",
    name: 'Mystery Box',
    preview: "https://cdn.sendcutsend.com/images/parts/one_up_2.svg",
    model: OneUp,
    params: OneUp.metaParameters
  },
  {
    id: 'mountain',
    category: "Extras",
    name: 'Mountain',
    preview: "https://cdn.sendcutsend.com/images/parts/mountain.svg",
    model: Mountain,
    params: Mountain.metaParameters
  },
  {
    id: 'mountain_two',
    category: "Extras",
    name: 'Mountain Two',
    preview: "https://cdn.sendcutsend.com/images/parts/mountain_two.svg",
    model: MountainTwo,
    params: MountainTwo.metaParameters
  },
  {
    id: 'antlers',
    category: "Extras",
    name: 'Antlers',
    preview: "https://cdn.sendcutsend.com/images/parts/antlers.svg",
    model: Antlers,
    params: Antlers.metaParameters
  },
  {
    id: 'vanity_plate',
    category: "Decorative",
    name: 'Vanity Plate',
    preview: "https://cdn.sendcutsend.com/images/parts/vanity_plate.svg",
    model: VanityPlate,
    params: VanityPlate.metaParameters
  },
  {
    id: 'bbq_grill',
    category: "Extras",
    name: 'BBQ Grill',
    preview: "https://cdn.sendcutsend.com/images/parts/bbq_grill.svg",
    model: BBQGrill,
    params: BBQGrill.metaParameters
  },
  {
    id: 'bottle_opener',
    category: "Extras",
    name: 'Bottle Opener',
    preview: "https://cdn.sendcutsend.com/images/parts/laser_bottle_opener.svg",
    model: LaserGun,
    params: LaserGun.metaParameters
  },
  {
    id: 'states',
    category: "Decorative",
    name: 'State Plate',
    preview: "https://cdn.sendcutsend.com/images/parts/states.svg",
    model: States,
    params: States.metaParameters
  },
  {
    id: 'positive_states',
    category: "Decorative",
    name: "State",
    preview: "https://cdn.sendcutsend.com/images/parts/positive_states.svg",
    model: PositiveStates,
    params: PositiveStates.metaParameters
  }
]
