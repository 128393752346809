export default {
  methods: {
    trackerUrl(order) {
      return order.tracker_path
    },
    trackerShareUrl(order) {
      const appHost = this.$store.getters['app/appHost']
      return `${appHost}${this.trackerUrl(order)}`
    }
  }
}
