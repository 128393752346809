import makerjs from 'makerjs'

function DomeTab(width, height, holeDiameter) {
  this.paths = {
    hole: new makerjs.paths.Circle([0, height - (width / 2)], holeDiameter / 2)
  }
  this.models = {
    tab_group: new makerjs.models.Dome(width, height, width / 2, false),
  }
}

DomeTab.metaParameters = [
  {
    title: "Width",
    type: "range",
    min: 0,
    max: 6,
    value: 1,
    default: 1,
    group: 'tab_group',
    group_title: 'Tab',
    tooltip_text: "Overall width of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/dometab_width.png"
  },
  {
    title: "Height",
    type: "range",
    min: 0,
    max: 6,
    value: 1.5,
    default: 1.5,
    group: 'tab_group',
    group_title: 'Tab',
    tooltip_text: "Overall height of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/dometab_height.png"
  },
  {
    title: "Hole Diameter",
    type: "range",
    min: 0,
    max: 6,
    value: 0.5,
    default: 0.5,
    group: 'hole',
    group_title: 'Hole',
    tooltip_text: "Diameter of the hole at the top of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/dometab_hole.png"
  },
  { type: "bends", value: [], default: [], hidden: true, default_orientation: 'horizontal', group: ['bend0', 'bend1', 'bend2', 'bend3'] }
]

export default DomeTab
