import caProvinceCodes from 'shared/caProvinceCodes'

// Returns true if the province is international for shipping purposes.
// As of 3/16/22, only canadian provinces are internaitonal
export function isInternationalProvince(province) {
  return caProvinceCodes.includes(province)
}

/*
 * Number helpers
 */

export function isNaN(val) {
  // Note: isNaN is not the same as Number.isNaN. isNaN coerces the value to a number before checking if it's NaN.
  // eslint-disable-next-line no-restricted-properties
  return window.isNaN(val)
}
