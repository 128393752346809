import { computed } from 'vue'
import { useStore } from 'customer/components/composables/store'

export function useDrawingFolderService() {
  const store = useStore()

  const itemStats = computed(() => store.getters['drawings/stats'])
  const fetchFolders = (options) => store.dispatch('folders/fetch', options)
  const updateItem = async (options) => store.dispatch('drawings/update', options)
  const filterItemsForFolder = async (folderID) => store.dispatch('drawings/filter', { folderID })
  const itemName = 'Drawings'
  const generateFolder = (data) => data
  const itemResourceType = 'drawing'

  return {
    itemStats,
    fetchFolders,
    updateItem,
    filterItemsForFolder,
    itemName,
    generateFolder,
    itemResourceType
  }
}
