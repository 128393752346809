import Vue from 'vue'
import scs from 'shared/scs'
import * as common from 'shared/store/common.js'
import find from 'lodash/find'
import findIndex from "lodash/findIndex"

const PER_PAGE = 20

// initial state
const defaultState = {
  currentFolder: 'uncategorized',
  loading: false,
  collapsed: {},
  collapseAll: false,
  filter_list_active: false,
  data: {}
}

// getters
const getters = {
  all: (state) => Object.keys(state.data)
    .filter(cartID => {
      const cart = state.data[cartID]
      const folder = state.currentFolder === 'uncategorized' ? null : state.currentFolder || 'all'
      return folder === 'all' || cart.folder === folder
    })
    .filter(cartID => {
      const cart = state.data[cartID]
      return cart.kind === 'saved'
    })
    .map(id => state.data[id])
    .sort((a, b) => b.id - a.id),

  get: (state) => id => state.data[id],

  getCartItem: (state) => (savedCartID, cartItemID) => {
    const cart = state.data[savedCartID]
    return find(cart.cart_items, (d) => d.id === cartItemID)
  },
}

// actions
const actions = {
  clear({ commit }) {
    commit('clearData')
  },

  async create({ commit }, data) {
    const response = await scs.createSavedCart(data)
    commit('setSavedCart', response.data)
    commit('setStats', response.stats)
    return response.data
  },

  async delete({ commit }, data) {
    const response = await scs.deleteSavedCart(data)
    commit('removeSavedCart', data)
    commit('setStats', response.stats)
  },

  async fetch({ commit }, data) {
    data.per_page = data.per_page || PER_PAGE
    data.resource_type = 'saved_carts'

    commit('setLoading', true)
    const response = await scs.getSavedCarts(data)
    const { stats, data: carts } = await response.json()
    commit('setLoading', false)

    commit('setPagination', response.headers)
    commit('setSavedCarts', carts)
    commit('setStats', stats)

    return carts
  },

  async filter({ state, commit }, data = {}) {
    if (state.currentFolder !== data.folder_id) {
      commit('clearData')
      commit('setCurrentFolder', data.folder_id ? data.folder_id : undefined)
    }
  },

  async refreshSingle({ commit }, cart) {
    const response = await scs.getSavedCart({ id: cart.id })
    commit('setSavedCart', response.data)
    return response.data
  },

  async refreshCartItem({ commit }, data) {
    const response = await scs.refreshSavedCartItem(data)
    commit('setCartItem', response.data)
    return response.data
  },

  updateCartItem({ commit }, data) {
    return scs.updateSavedCartItem(data)
      .then((response) => {
        commit('setCartItem', response.data)
        return response.data
      })
  },

  deleteCartItem({ commit }, data) {
    return scs.deleteSavedCartItem(data)
      .then(() => {
        commit('removeCartItem', data)
      })
  },

  async update({ commit }, cart) {
    const response = await scs.updateSavedCart(cart)
    commit('setSavedCart', response.data)
    commit('setStats', response.stats)
    return response.data
  },

  // UI state actions

  setCollapse({ commit }, { cartID, collapse }) {
    commit('setCollapse', { cartID, collapse })
  },

  setCollapseAll({ commit }, collapse) {
    commit('setCollapseAll', collapse)
  }
}

// mutations
const mutations = {
  clearData(state) {
    state.data = {}
  },

  setCollapse(state, { cartID, collapse }) {
    Vue.set(state.collapsed, cartID, collapse)
  },

  setCollapseAll(state, collapse) {
    state.collapseAll = collapse

    Object.keys(state.data)
      .map(id => state.data[id])
      .forEach(cart => {
        Vue.set(state.collapsed, cart.id, collapse)
      })
  },

  setFilterListActive(state, active) {
    state.filter_list_active = active
  },

  removeSavedCart(state, cart) {
    Vue.delete(state.data, cart.id)
  },

  setCartItem(state, cartItem) {
    const cart = state.data[cartItem.saved_cart_id]

    if (cart) {
      const newCartItems = cart.cart_items.slice()
      const itemIndex = findIndex(newCartItems, d => d.id === cartItem.id)

      if (itemIndex >= 0) {
        cart.cart_items.splice(itemIndex, 1, cartItem)
      }
    }
  },

  setCurrentFolder(state, folderID) {
    state.currentFolder = folderID
  },

  setLoading(state, on) {
    state.loading = on
  },

  setSavedCarts(state, carts) {
    carts.forEach(cart => {
      if (state.collapseAll) {
        Vue.set(state.collapsed, cart.id, true)
      }

      Vue.set(state.data, cart.id, cart)
    })
  },

  setSavedCart(state, cart) {
    if (state.collapseAll) {
      Vue.set(state.collapsed, cart.id, true)
    }
    Vue.set(state.data, cart.id, cart)
  },

  removeCartItem(state, cartItem) {
    const cart = state.data[cartItem.saved_cart_id]

    if (cart) {
      const itemIndex = findIndex(cart.cart_items, d => d.id === cartItem.id)
      if (itemIndex !== -1) {
        cart.cart_items.splice(itemIndex, 1)
      }
    }
  },
}

export default {
  namespaced: true,
  state: { ...common.state, ...defaultState },
  mutations: { ...common.mutations, ...mutations },
  actions: { ...common.actions, ...actions },
  getters: { ...common.getters, ...getters }
}
