import makerjs, { path } from 'makerjs'

export function addBends(model, bends) {
  const extents = makerjs.measure.modelExtents(model)

  let new_bends = []

  bends.forEach((b, index) => {
    if (b.orientation == 'vertical') {
      let offset = Math.max(extents.low[0], Math.min(extents.low[0] + b.offset, extents.high[0]))

      // Find the true extents
      let test_model = makerjs.model.clone(model)
      let test_bend = new makerjs.models.RoundRectangle(0.001, extents.height + 0.001, 0)
      makerjs.model.move(test_bend, [offset, extents.low[1] - 0.0005])
      const boundary = makerjs.model.combine(test_bend, test_model, true, false, false, false)
      const boundary_extents = makerjs.measure.modelExtents(boundary)

      // Build the bend line for the new extents
      let bend = new makerjs.models.ConnectTheDots(true, [[offset, boundary_extents.low[1]], [offset, boundary_extents.high[1]]])
      bend.layer = b.layer
      new_bends.push(bend)
    }
    else {
      let offset = Math.max(extents.low[1], Math.min(extents.low[1] + b.offset, extents.high[1]))

      // Find the true extents
      let test_model = makerjs.model.clone(model)
      let test_bend = new makerjs.models.RoundRectangle(extents.width + 0.001, 0.001, 0)
      makerjs.model.move(test_bend, [extents.low[0] - 0.0005, offset])
      const boundary = makerjs.model.combine(test_bend, test_model, true, false, false, false)
      const boundary_extents = makerjs.measure.modelExtents(boundary)

      let bend = new makerjs.models.ConnectTheDots(true, [[boundary_extents.low[0], offset], [boundary_extents.high[0], offset]])
      bend.layer = b.layer
      new_bends.push(bend)
    }
  })

  new_bends.forEach((bend, index) => {
    model.models ||= {}
    model.models[`bend${index}`] = bend
  })
}