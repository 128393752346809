import Vue from 'vue'
import scs from 'shared/scs'
import * as common from 'shared/store/common.js'

const resourceParam = (data) => {
  const { resource_id: resourceId, resource } = data
  return { id: resourceId, type: resource }
}

// initial state
const initialState = {
  addresses: {},
}

// getters
const getters = {
  all: ({ addresses }) => Object.keys(addresses)
          .sort((a, b) => b - a)
          .map(id => addresses[id]),
}

// actions
const actions = {
  create({ commit }, data) {
    return scs.createAddress(resourceParam(data), data)
      .then((response) => {
        commit('setAddress', response.data)
        return response.data
      })
  },
  delete({ commit }, data) {
    return scs.deleteAddress(resourceParam(data), data)
      .then(() => {
        commit('removeAddress', data)
      })
  },
  async fetch({ commit }, data = {}) {
    const response = await scs.getAddresses(resourceParam(data), data)
    const json = await response.json()
    commit('setPagination', response.headers)
    commit('setAddresses', json.data)
  },
  async fetchAddress({ commit }, data) {
    const resp = await scs.getAddress(resourceParam(data), data)
    commit('setAddress', resp.data)
    return resp.data
  },

  update({ commit }, data) {
    return scs.updateAddress(resourceParam(data), data).then(response => {
      commit("setAddress", response.data)
      return response.data
    })
  }
}

// mutations
const mutations = {
  removeAddress(state, address) {
    Vue.delete(state.addresses, address.id)
  },
  setAddress(state, address) {
    Vue.set(state.addresses, address.id, address)
  },
  setAddresses(state, addresses) {
    state.addresses = {}
    for (let i = 0; i < addresses.length; i++) {
      const address = addresses[i]
      Vue.set(state.addresses, address.id, address)
    }
  },
}

export default {
  namespaced: true,
  state: { ...common.state, ...initialState },
  getters: { ...common.getters, ...getters },
  actions: { ...common.actions, ...actions },
  mutations: { ...common.mutations, ...mutations }
}
