import makerjs from 'makerjs'

function SquareWithSquareHole(outerSide, outerCornerRadius, innerSide, innerCornerRadius) {
  const outer = new makerjs.models.RoundRectangle(outerSide, outerSide, outerCornerRadius)
  const inner = new makerjs.models.RoundRectangle(innerSide, innerSide, innerCornerRadius)

  makerjs.model.center(outer)
  makerjs.model.center(inner)

  this.models = {
    outer_group: outer,
    inner_group: inner
  }
}

SquareWithSquareHole.metaParameters = [
  {
    title: "Outer Side Length",
    type: "range",
    min: 0,
    max: 24,
    value: 6,
    default: 6,
    tooltip_text: "Length of outside of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/swsh_outerside.png",
    group: 'outer_group',
    group_title: 'Plate'
  },
  {
    title: "Outer Corner Radius",
    type: "range",
    min: 0,
    max: 6,
    value: 0.5,
    default: 0.5,
    tooltip_text: "Radius of the outside corners of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/swsh_outercorner.png",
    group: 'outer_group',
    group_title: 'Plate'
  },
  {
    title: "Inner Side Length",
    type: "range",
    min: 0,
    max: 24,
    value: 3,
    default: 3,
    tooltip_text: "Length of the side of the square cut out",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/swsh_innerside.png",
    group: 'inner_group',
    group_title: 'Inner Hole'
  },
  {
    title: "Inner Corner Radius",
    type: "range",
    min: 0,
    max: 6,
    value: 0.25,
    default: 0.25,
    tooltip_text: "Radius of the outside corners of the square cut out",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/swsh_innercorner.png",
    group: 'inner_group',
    group_title: 'Inner Hole'
  },
  { type: "bends", value: [], default: [], hidden: true, group: ['bend0', 'bend1', 'bend2', 'bend3'] }
]

export default SquareWithSquareHole
