import { computed } from 'vue'
import { useStore } from 'customer/components/composables/store'

export function useFolders(resourceType) {
  const store = useStore()

  const currentFolder = computed({
    // getter
    get() {
      const currentFolderId = store.state.folders.current_folder_ids[resourceType]
      const foldersForType = store.getters['folders/getFoldersForType'](resourceType)
      return foldersForType[currentFolderId] || {}
    },
    // setter
    set(folder) {
      store.commit('folders/setCurrentFolder', { resourceType, folder })
    }
  })

  return {
    currentFolder
  }
}
