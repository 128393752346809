// These are all the triggers for refreshing a quote. We keep this all together for organization
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'

export default {
  computed: {
    isDesktop() {
      return this.$mq === 'desktop'
    },

    drawing() {
      return this.quoteDrawings[0]
    },

    quoteDrawings() {
      return this.$store.getters['quote_dialog/drawings']
    },

    enabledOperations() {
      return this.$store.getters['quote_dialog/enabledOperations']
    },

    anodizingConfig() {
      return this.$store.state.quote_dialog.anodizingConfig
    },

    loading: {
      get() { return this.$store.state.quote_dialog.loading },
      set(val) { this.$store.commit('quote_dialog/setLoading', val) }
    },

    hardwareConfig() {
      return (this.$store.state.quote_dialog.hardwareConfig || []).map(
        (h) => ({ hardware_id: h.hardware_id })
      )
    },

    platingConfig() {
      return this.$store.state.quote_dialog.platingConfig
    },

    powderConfig() {
      return this.$store.state.quote_dialog.powderConfig
    },

    quantity: {
      get() { return this.$store.state.quote_dialog.quantity },
      set(val) { this.$store.commit('quote_dialog/setQuantity', val) }
    },

    currentStep: {
      get() { return this.$store.state.quote_dialog.currentStep },
      set(currentStep) { this.$store.commit('quote_dialog/setCurrentStep', currentStep) }
    },

    quoteData() {
      const data = {
        cart_item_id: this.$store.state.quote_dialog.id,
        drawing_id: this.drawing.id,
        quantity: this.quantity
      }

      // If we're in the quoting mode, features step, or editing the post processing features of a cart item in the cart
      if (['features', 'finishing'].includes(this.$store.state.quote_dialog.currentStep) || this.$store.state.quote_dialog.state === 'processing') {
        data.operations = this.enabledOperations

        if (!isEmpty(this.hardwareConfig)) {
          data.hardware_config = this.hardwareConfig
        }
        if (!isEmpty(this.operationCount)) {
          data.operation_count = this.operationCount
        }
        if (!isEmpty(this.anodizingConfig)) {
          data.anodizing_config = this.anodizingConfig
        }
        if (!isEmpty(this.platingConfig)) {
          data.plating_config = this.platingConfig
        }
        if (!isEmpty(this.powderConfig)) {
          data.powder_config = this.powderConfig
        }

        // In the post process step, we can forego quoting every material and just focus on the one
        if (this.$store.state.quote_dialog.selectedSku) {
          data.material_sku = this.$store.state.quote_dialog.selectedSku
        }
      }

      return data
    },

    // This is trickery to get a single watcher trigger for all the data
    quoteDataSerialized() {
      return JSON.stringify(this.quoteData)
    },

    operationCount() {
      return this.$store.getters['quote_dialog/operationCount']
    },
  },
  methods: {
    async refreshQuote(refreshAll = false) {
      const data = this.quoteData
      if (refreshAll) { data.material_sku = null }

      await this.$store.dispatch('quotes/fetch', this.quoteData)
    },
    clickCrumb(crumb) {
      switch (crumb.step) {
        case 'material-group':
          this.toGroup()
          break
        case 'material-category':
          this.toCategory()
          break
        case 'material-type':
          this.toType()
          break
        case 'sku':
          this.toSku()
          break
      }
    },

    toGroup() {
      this.$store.commit('quote_dialog/selectGroup', null)
      this.$store.commit('quote_dialog/selectCategory', null)
      this.$store.commit('quote_dialog/selectType', null)
      this.$store.commit('quote_dialog/selectSku', null)
      this.currentStep = 'material-group'
    },
    toCategory() {
      this.$store.commit('quote_dialog/selectCategory', null)
      this.$store.commit('quote_dialog/selectType', null)
      this.$store.commit('quote_dialog/selectSku', null)
      this.currentStep = 'material-category'
    },
    toType() {
      this.$store.commit('quote_dialog/selectType', null)
      this.$store.commit('quote_dialog/selectSku', null)
      this.currentStep = 'material-type'
    },
    toSku() {
      this.currentStep = 'sku'
      this.$store.commit('quote_dialog/selectSku', null)
    },
  },
  watch: {
    quoteDataSerialized: debounce(function _() {
      if (this.quantity > 0) {
        this.refreshQuote()
      }
    }, 250),
  }
}
