// Purpose:  when the browser detects it just got internet access again, it calls onOnlineStatusUpdated
const EVENTS = ['online', 'offline']

export default {
  data() {
    return {
      onlineHandlers: []
    }
  },

  mounted() {
    EVENTS.forEach(e => window.addEventListener(e, this.onlineStatusChanged))
  },

  beforeDestroy() {
    EVENTS.forEach(e => window.removeEventListener(e, this.onlineStatusChanged))
  },

  methods: {
    onlineStatusChanged(...rest) {
      this.onlineHandlers.forEach(handler => handler(rest))
    },

    registerOnlineHandler(handler) {
      this.onlineHandlers.push(handler)
    },
  }
}
