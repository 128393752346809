import every from 'lodash/every'
import find from 'lodash/find'
import filter from 'lodash/filter'
import map from 'lodash/map'
import uniqBy from 'lodash/uniqBy'
import capitalize from 'lodash/capitalize'

import { useStore } from 'customer/components/composables/store'

const TOOLTIP_CONTENT = {
  // Drawing Errors

  empty_hard: "<p class='mb-1 quote-error'><strong>No geometry was found</strong></p>"
    + "<p>This design is unable to be processed as we cannot detect any geometry. Please <a href='https://sendcutsend.com/guidelines/' target='_blank' class='is-default'>click here</a> for tips on how to set up your file</p>",

  missing_material_hard: "<p class='mb-1 quote-error'><strong>No material has been selected</strong></p>"
    + "<p>Please remove this item or contact support for assistance</p>",

  max_density_soft: "<p class='mb-1 quote-error'><strong>Custom quote required due to cutting density</strong></p>"
    + "<p>Please <a href='https://sendcutsend.com/density-guidelines/' target='_blank' class='is-default'>click here</a> to learn more about reducing density in your design.</p>",

  max_length_long_side_soft: "<p class='mb-1 quote-error'><strong>Custom quote required due to design length</strong></p>"
    + "<p>The maximum length for instant quoting in this material is <span class='has-text-weight-bold'>LIMIT UNITS</span>.</p>",

  max_length_long_side_hard: "<p class='mb-1 quote-error'><strong>This design exceeds our maximum length for this material</strong></p>"
    + "<p>The maximum length for this material is <span class='has-text-weight-bold'>LIMIT UNITS.</span></p>",

  max_length_short_side_soft: "<p class='mb-1 quote-error'><strong>Custom quote required due to design width</strong></p>"
    + "<p>The maximum width for instant quoting in this material is <span class='has-text-weight-bold'>LIMIT UNITS</span>.</p>",

  max_length_short_side_hard: "<p class='mb-1 quote-error'><strong>This design exceeds our maximum width for this material</strong></p>"
    + "<p>The maximum length for this material is <span class='has-text-weight-bold'>LIMIT UNITS</span>.</p>",

  min_length_long_side_hard: "<p class='mb-1 quote-error'><strong>This design does not meet our minimum length for this material</strong></p>"
    + "<p>The minimum length in this material is <span class='has-text-weight-bold'>LIMIT UNITS</span>.</p>",

  min_length_short_side_hard: "<p class='mb-1 quote-error'><strong>This design does not meet our minimum width for this material</strong></p>"
    + "<p>The minimum width in this material is <span class='has-text-weight-bold'>LIMIT UNITS</span>.</p>",

  min_node_distance_hard: "<p class='mb-1 quote-error'><strong>This design does not meet our minimum node distance for this material</strong></p>"
    + "<p>The minimum node distance in this material is <span class='has-text-weight-bold'>LIMIT UNITS</span>.</p>"
    + "<p>Please <a href='https://sendcutsend.com/guidelines/nodes/' target='_blank' class='is-default'>click here</a> to learn more about nodes and how they can affect cut quality.</p>",

  max_node_density_soft: "<p class='mb-1 quote-error'><strong>Custom quote required due to design node density</strong></p>"
    + "<p>The maximum node density for instant quoting in this material is <span class='has-text-weight-bold'>LIMIT nodes/sq UNITS</span>.</p>"
    + "<p>Please <a href='https://sendcutsend.com/guidelines/nodes/' target='_blank' class='is-default'>click here</a> to learn more about nodes and how they can affect cut quality.</p>",

  // Operation Errors

  op_unavailable_private_hard: "<p class='mb-1 quote-error'><strong>This operation is unavailable in this material.</strong></p>",

  op_unavailable_hard: "<p class='mb-1 quote-error'><strong>This operation is unavailable in this material.</strong></p>",

  op_unavailable_hard_multiple: "<p class='mb-1 quote-error'><strong>These operations are unavailable in this material.</strong></p>",

  op_no_vendor: "<p class='mb-1 quote-error'><strong>No authorized OPERATION vendors for ORGANIZATION</strong></p>",

  op_no_vendor_hard: "<p class='mb-1 quote-error'><strong>No authorized OPERATION vendors for ORGANIZATION</strong></p>",

  op_bending_no_lines: "<p class='mb-1 quote-error'><strong>No bend lines were detected in your drawing</strong></p>"
    + "<p>Mark bends with colors to customize further.</p>"
    + "<br><p><strong><a href='https://sendcutsend.com/guidelines/bending/' target='_blank' class='is-standalone'>BENDING GUIDELINES</a></strong></p>",

  op_finish_no_holes: "<p class='mb-1 quote-error'><strong>No holes were detected in your drawing</strong></p>"
    + "<p>Drawings must have at least one hole to be finished.</p>",

  op_no_holes: "<p class='mb-1 quote-error'><strong>No eligible holes were detected in your drawing</strong></p>"
    + "<p>Please make sure your holes meet our guidelines.</p>"
    + "<br><p><strong><a href='https://sendcutsend.com/guidelines/countersinking/' target='_blank' class='is-standalone'>COUNTERSINKING GUIDELINES</a></strong></p>"
    + "<p><strong><a href='https://sendcutsend.com/guidelines/hardware/' target='_blank' class='is-standalone'>HARDWARE GUIDELINES</a></strong></p>"
    + "<p><strong><a href='https://sendcutsend.com/guidelines/tapping/' target='_blank' class='is-standalone'>TAPPING GUIDELINES</a></strong></p>"
    + "<p><strong><a href='https://sendcutsend.com/guidelines/dimple-forming/' target='_blank' class='is-standalone'>DIMPLE FORMING GUIDELINES</a><strong></p>",

  op_min_length_short_side_hard: "<p class='mb-1 quote-error'><strong>This design does not meet our minimum width for OPERATION</strong></p>"
    + "<p>The minimum width for OPERATION is <span class='has-text-weight-bold'>LIMIT UNITS</span>.</p>",

  op_min_length_long_side_hard: "<p class='mb-1 quote-error'><strong>This design does not meet our minimum length for OPERATION</strong></p>"
    + "<p>The minimum length for OPERATION is <span class='has-text-weight-bold'>LIMIT UNITS</span>.</p>",

  op_max_length_short_side_hard: "<p class='mb-1 quote-error'><strong>This design exceeds our maximum width for OPERATION</strong></p>"
    + "<p>The maximum width for OPERATION is <span class='has-text-weight-bold'>LIMIT UNITS</span>.</p>",

  op_max_length_max_side_hard: "<p class='mb-1 quote-error'><strong>This design exceeds our maximum length for OPERATION</strong></p>"
    + "<p>The maximum length for OPERATION is <span class='has-text-weight-bold'>LIMIT UNITS</span>.</p>",

  op_min_angle_hard: "<p class='mb-1 quote-error'><strong>This design does not meet our minimum angle for OPERATION</strong></p>"
    + "<p>The minimum angle for OPERATION is <span class='has-text-weight-bold'>LIMIT degrees</span>.</p>",

  op_max_angle_hard: "<p class='mb-1 quote-error'><strong>This design exceeds our maximum angle for OPERATION</strong></p>"
    + "<p>The maximum angle for OPERATION is <span class='has-text-weight-bold'>LIMIT degrees</span>.</p>",

  op_etching_wiped_by_finishing: "<p class='mb-1 quote-error'><strong>Etching is incompatible with all finishing techniques.</strong></p>",
  op_finish_wipes_etching: "<p class='mb-1 quote-error'><strong>OP_NAME_CAP is incompatible with etching.</strong></p>",
}

const HARD_LIMIT_INFO = "<br><p><strong><a href='https://sendcutsend.com/materials/min-max/' target='_blank' class='is-standalone'>FULL LIST OF MATERIAL LIMITS</a></strong></p>"
const OP_HARD_LIMIT_INFO = "<br><p><strong><a href='https://sendcutsend.com/materials/processing-min-max/' target='_blank' class='is-standalone'>FULL LIST OF OPERATION LIMITS</a></strong></p>"

export const useQuoteErrors = () => {
  const store = useStore()

  const removeDuplicateErrors = (errs) => {
    const errsCondensed = uniqBy(errs, 'code')
    if (errsCondensed.length === 1 && errsCondensed[0].code === 'op_unavailable_hard') {
      errsCondensed[0].code = 'op_unavailable_hard_multiple'
    }
    return errsCondensed
  }

  const hasHardErrors = (errors) => errors && find(errors, (e) => e.code.includes('hard'))
  const hasSoftErrors = (errors) => errors?.length > 0 && every(errors, (e) => e.code.includes('soft'))

  const getLimit = (error, units) => {
    const limit = Number(error.limit)

    if (units === 'mm') {
      // Node density is measured in in^2 so we need to convert to mm^2
      if (error.code.includes('max_node_density_soft')) {
        return (limit * 25.4 * 25.4).toFixed(2)
      }
      return (limit * 25.4).toFixed(2)
    }

    return limit
  }

  const filterMostPermissive = (errors) => { // Build a list of the most permissive hard errors
    const permissiveHardErrors = []

    const allHardCodes = map(uniqBy(errors, (e) => e.code), (e) => e.code)

    // For each hard error code, find the one in the list with the most permissive limit and add to our list
    allHardCodes.forEach((code) => {
      const minLimit = code.includes('min_')
      const error = { code, limit: minLimit ? 9999999 : 0 }
      errors.forEach((e) => {
        if (e.code === error.code) {
          const currentErrorLimit = Number(error.limit)
          const codeLimit = Number(e.limit)
          if ((!minLimit && codeLimit > currentErrorLimit) || (minLimit && codeLimit < currentErrorLimit)) {
            error.limit = codeLimit
          }
        }
      })
      permissiveHardErrors.push(error)
    })
    return uniqBy(permissiveHardErrors, (e) => e.code)
  }

  const quoteErrorTooltip = (errors, category = "material", units = 'in') => {
    let content = null

    if (errors?.length > 0) {
      if (errors.length > 1) {
        errors = removeDuplicateErrors(errors)
      }
      const hardErrorsExist = hasHardErrors(errors)

      const currentUser = store.getters['app/currentUser']

      // If we have any hard errors, only show the hard.
      content = map(filter(errors, (e) => (hardErrorsExist ? e.code.includes('hard') : true)), (e) => {
        // Round the limit to 3 decimal places
        const limit = getLimit(e, units)
        if (TOOLTIP_CONTENT[e.code]) {
          return TOOLTIP_CONTENT[e.code]
            .replace(/OPERATION/g, e.name || 'this operation')
            .replace(/OP_NAME_CAP/g, capitalize(e.name || 'this operation'))
            .replace('LIMIT', limit)
            .replace('UNITS', units)
            .replace('ORGANIZATION', currentUser?.organization?.name ?? 'your organization')
        }

        return ""
      }).join('')

      if (hardErrorsExist) {
        content += (category === 'material' ? HARD_LIMIT_INFO : OP_HARD_LIMIT_INFO)
      }
    }

    return content ? {
      content: `<div class="quote-errors-container">${content}</div>`,
      html: true,
      classes: 'quote-errors',
      autoHide: false,
      delay: { show: 250, hide: 50 }
    } : null
  }

  return {
    hasHardErrors,
    hasSoftErrors,
    filterMostPermissive,
    quoteErrorTooltip
  }
}
