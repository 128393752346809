import Vue from 'vue'
import scs from 'shared/scs'
import findIndex from "lodash/findIndex"
import find from 'lodash/find'
import filter from 'lodash/filter'
import * as common from 'shared/store/common.js'

// initial state
const initialState = {
  orders: [],

  // Filters
  currentState: 'all',
  currentSearch: '',
  currentPayment: '',
  loading: false,

  expanded: {},
}

// getters
const getters = {
  all: (state) => state.orders,
  get: (state) => id => find(state.orders, (d) => d.id === id),
  getLineItem: (state) => (orderID, lineItemID) => {
    const order = find(state.orders, (d) => d.id === orderID)
    return find(order.line_items, (d) => d.id === lineItemID)
  },
}

// actions
const actions = {
  clear({ commit }) {
    return commit('setOrders', [])
  },
  async create({ commit, rootState }, data) {
    const options = { ...data }
    if (rootState.app.affiliate) {
      options.affiliate = rootState.app.affiliate
    }

    return scs.createOrder(options)
      .then((response) => {
        commit('addOrder', response.data)
        commit('setStats', response.stats)
        return response
      })
  },

  /**
   * Fetches Orders from server
   * @param {*} data - Query data for the fetch
   * @param {boolean} append -  if true, appends new data to existing data; else replaces data
   */
  async fetch({ commit }, data, append = true) {
    const response = await scs.getOrders(data)
    const { data: orders, stats } = await response.json()

    commit('setPagination', response.headers)
    commit(append ? 'appendOrders' : 'setOrders', orders)
    commit('setStats', stats)

    return orders
  },
  fetchOrder({ commit }, id) {
    return scs.getOrder({ id })
      .then(resp => commit('setOrders', [resp.data]))
  },

  invoice(_, data) {
    return scs.getOrderInvoice(data)
  },

  pay({ commit }, data) {
    return scs.payOrder(data)
      .then((response) => {
        commit('setOrder', response.data)
        commit('setStats', response.stats)
        return response.data
      })
  },
  refreshSingleOrder({ commit }, order) {
    return scs.getOrder({ id: order.id })
      .then((response) => {
        commit('setOrder', response.data)
      })
  },
  refreshLineItem({ commit }, data) {
    return scs.refreshOrderLineItem(data)
      .then((response) => {
        commit('setLineItem', response.data)
      })
  },
  update({ commit }, order) {
    return scs.updateOrder(order)
      .then((response) => {
        commit('setOrder', response.data)
        commit('setStats', response.stats)
        return response.data
      })
  },
}

// mutations
const mutations = {
  addOrder(state, data) {
    const newOrders = state.orders.slice()
    newOrders.unshift(data)
    state.orders = newOrders
  },
  appendOrders(state, newSetofOrders) {
    const orders = state.orders.slice()

    // return all orders not already in our list
    newSetofOrders = filter(newSetofOrders, (newOrder) => !find(orders, (o) => o.id === newOrder.id))

    state.orders = orders.concat(newSetofOrders)
  },
  setCurrentPayment(state, val) {
    state.currentPayment = val
  },
  setCurrentState(state, val) {
    state.currentState = val
  },
  toggleExpanded(state, orderID) {
    Vue.set(state.expanded, orderID, !state.expanded[orderID])
  },
  setLoading(state, loading) {
    state.loading = loading
  },
  setOrders(state, orders) {
    state.orders = orders
  },
  setOrder(state, order) {
    const newOrders = state.orders.slice()
    const index = findIndex(newOrders, d => d.id === order.id)
    if (index !== -1) {
      newOrders[index] = order
      state.orders = newOrders
    } else {
      newOrders.unshift(order)
      state.orders = newOrders
    }
  },
  setLineItem(state, lineItem) {
    const newOrders = state.orders.slice()
    const orderIndex = findIndex(newOrders, d => d.id === lineItem.order_id)
    if (orderIndex !== -1) {
      const newLineItems = newOrders[orderIndex].line_items.slice()
      const itemIndex = findIndex(newLineItems, d => d.id === lineItem.id)
      if (itemIndex !== -1) {
        newOrders[orderIndex].line_items[itemIndex] = lineItem
        state.orders = newOrders
      }
    }
  },
  setStateHistory(state, data) {
    const newOrders = state.orders.slice()
    const orderIndex = findIndex(newOrders, d => d.id === data.order_id)
    if (orderIndex !== -1) {
      newOrders[orderIndex].state_history = data.state_history
      state.orders = newOrders
    }
  }
}

export default {
  namespaced: true,
  state: { ...common.state, ...initialState },
  mutations: { ...common.mutations, ...mutations },
  actions: { ...common.actions, ...actions },
  getters: { ...common.getters, ...getters }
}
