import makerjs from 'makerjs'

function RectangleConvexSide(width, height, arcDepth) {
  const leftWall = new makerjs.paths.Line([0, 0], [0, height])
  const rightWall = new makerjs.paths.Line([width, 0], [width, height])
  const topArc = new makerjs.paths.Arc([width, height], [width / 2, height + arcDepth], [0, height])
  const bottomArc = new makerjs.paths.Arc([0, 0], [width / 2, arcDepth], [width, 0])

  this.models = {
    rectangle_group: {
      paths: {
        top_arc: topArc,
        bottom_arc: bottomArc,
        left_wall: leftWall,
        right_wall: rightWall,
      }
    }
  }
}

RectangleConvexSide.metaParameters = [
  {
    title: "Part Width",
    type: "range",
    min: 0,
    max: 12,
    value: 4,
    default: 4,
    tooltip_text: "Overall width of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectconvex_width.png",
    group: 'rectangle_group',
    group_title: 'Plate'
  },
  {
    title: "Part Height",
    type: "range",
    min: 0,
    max: 12,
    value: 5,
    default: 5,
    tooltip_text: "Overall height of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectconvex_height.png",
    group: 'rectangle_group',
    group_title: 'Plate'
  },
  {
    title: "Arc depth",
    type: "range",
    min: 0,
    max: 6,
    value: 1,
    default: 1,
    tooltip_text: "The distance from the edge of the part to the apex of the arc",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/rectconvex_depth.png",
    group: 'rectangle_group',
    group_title: 'Plate'
  },
]

export default RectangleConvexSide
