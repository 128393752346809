import { createTooltip, positionTooltip, updateTooltipText } from 'shared/util/svg_tooltips'

export default {
  data() {
    return {
      $measurement: null,
      startPoint: null,
      endPoint: null,
      measuring: false
    }
  },

  props: {
    showMeasure: {
      type: Boolean,
      default: false
    },
  },

  watch: {
    showMeasure() {
      if (!this.showMeasure) {
        this.measureReset()
      }
    }
  },

  methods: {
    measureReset() {
      if (this.$measurement) this.$measurement.remove()
      if (this.$measureTooltip) this.$measureTooltip.remove()
      this.startPoint = null
      this.endPoint = null
      this.$measurement = null
      this.$measureTooltip = null
    },

    measureHandleClick(event) {
      if (this.$refs.svgContainer) {
        // Reset measuring tool if there are 2 points present
        if (this.startPoint != null && this.endPoint != null) {
          this.measureReset()
        }

        // First click, set the start point
        if (!this.startPoint) {
          this.startPoint = this.svgCoords(event)
          this.createMeasurement()
        } else {
          // Second click, measure the distance
          this.endPoint = this.svgCoords(event)
          this.drawMeasurement(this.startPoint, this.endPoint)
          this.measuring = false
        }
      }
    },

    measureHandleMousemove(event) {
      if (this.$measurement && !this.endPoint) {
        this.drawMeasurement(this.startPoint, this.svgCoords(event))

        positionTooltip(this.$measureTooltip, (tooltipBox) => ({
            left: event.clientX - (tooltipBox.width / 2),
            top: this.scrollTop() + event.clientY - (tooltipBox.height + 15)
          }))
      }
    },

    createMeasurement() {
      const svg = this.$refs.svgContainer.getElementsByTagName('svg')[0]
      this.$measurement = document.createElementNS("http://www.w3.org/2000/svg", "g")
      this.$measurement.id = 'measurement'

      // Hide a safari bug that occurs because safari doesn't handle the combination of:
      //    stroke-linecap: round|square and vector-effect: non-scaling-stroke correctly.
      // Note: "stroke-linecap: 'butt' doesn't render the line at all if the length is zero.
      this.$measurement.innerHTML = `
        <line x1="0" y1="0" x2="0" y2="0" stroke="black" style="stroke-linecap: butt !important" />
      `
      svg.appendChild(this.$measurement)

      this.$measureTooltip = createTooltip('measure-tooltip')
      this.measuring = true
    },

    drawMeasurement({ x: x1, y: y1 }, { x: x2, y: y2 }) {
      const $line = this.$measurement.getElementsByTagName('line')[0]
      let dist = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
      dist = this.convertUnits(dist)

      // Update line attributes
      $line.setAttribute('x1', x1)
      $line.setAttribute('y1', y1)
      $line.setAttribute('x2', x2)
      $line.setAttribute('y2', y2)

      // Update tooltip text
      updateTooltipText(this.$measureTooltip, `${dist.toFixed(3)}${this.displayUnitsSymbol}`)
    },

    svgCoords(event) {
      const svg = this.$refs.svgContainer.getElementsByTagName('svg')[0]
      const pt = svg.createSVGPoint()

      pt.x = event.clientX
      pt.y = event.clientY

      // The cursor point, translated into svg coordinates
      const cursorPt = pt.matrixTransform(svg.getScreenCTM().inverse())
      return { x: cursorPt.x, y: cursorPt.y }
    },

    scrollTop() {
      return document.documentElement.scrollTop
    }
  }
}
