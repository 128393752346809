import ConfirmDialog from 'shared/components/ConfirmDialog'

export default {
  computed: {
    clientToken() {
      return this.$store.getters['app/clientToken']
    },
    customer() {
      return this.$store.getters['customer/get']
    },
  },

  beforeDestroy() {
    if (this.braintreeInstance) this.braintreeInstance.teardown()
    if (this.hostedFieldsInstance) this.hostedFieldsInstance.teardown()
    if (this.paypalCheckoutInstance) this.paypalCheckoutInstance.teardown()
    if (this.vaultManagerInstance) this.vaultManagerInstance.teardown()
  },

  methods: {
    deleteCard(bin) {
      this.$buefy.modal.open({
        parent: this,
        component: ConfirmDialog,
        hasModalCard: true,
        canCancel: true,
        props: {
          message: '<h4>Are you sure you want to remove this payment method?</h4><p>This will not affect any of your open orders that use this method.</p>'
        },
        events: {
          confirm: () => {
            this.loading = true
            this.$store.dispatch('app/deletePaymentMethod', { bin: bin })
            .then(() => {
              this.previousCards()
              this.cardChoice = ''
              this.loading = false
            })
          }
        },
      })
    },
    initBrainTree() {
      const paymentComponent = this

      braintree.client.create({
        authorization: paymentComponent.clientToken,
      }, (err, instance) => {
        paymentComponent.braintreeInstance = instance

        if(err) {
          console.error(err)
          return
        }

        // Card in Hosted Fields
        braintree.hostedFields.create(paymentComponent.hostedFieldsConfig, (hostedFieldsErr, hostedFieldsInstance) => {
          this.hostedFieldsInstance = hostedFieldsInstance
          const form = document.querySelector('#braintree-checkout')

          if(hostedFieldsErr) {
            console.error(hostedFieldsErr)
          }

          // On field focus
          hostedFieldsInstance.on('focus', paymentComponent.handleHostedFieldsFocus);

          // Off field focus
          hostedFieldsInstance.on('blur', paymentComponent.handleHostedFieldsBlur);

          // On submit
          form.addEventListener('submit', event => paymentComponent.handlePayment(event, hostedFieldsInstance))
        })

        // PayPal
        braintree.paypalCheckout.create({
          client: instance
        }, (paypalCheckoutErr, paypalCheckoutInstance) => {
          this.paypalCheckoutInstance = paypalCheckoutInstance
          paypalCheckoutInstance.loadPayPalSDK({
            currency: 'USD',
            intent: 'capture',
            commit: false,
            vault: true
          }, () => {

            const FUNDING_SOURCES = [
              paypal.FUNDING.PAYPAL,
              paypal.FUNDING.CREDIT
            ]

            FUNDING_SOURCES.forEach((fundingSource) => {
              let button = paypal.Buttons({
                fundingSource: fundingSource,

                createOrder() {
                  return paypalCheckoutInstance.createPayment({
                    flow: 'checkout',
                    amount: paymentComponent.cartTotal,
                    currency: 'USD',
                    intent: 'capture'
                  })
                },

                onApprove(data, actions) {
                  paymentComponent.loading = true
                  paymentComponent.paymentError = ''
                  paymentComponent.payPalError = ''

                  return paypalCheckoutInstance.tokenizePayment(Object.assign({}, data, { vault: false }), (err, payload) => {
                    paymentComponent.payPalApproved = true
                    paymentComponent.payPalNonce = payload.nonce
                    paymentComponent.loading = false
                  })
                },

                onCancel(data) {
                  // Payment Canceled
                },

                onError(err) {
                  if (!err.includes('Detected popup close')) {
                    paymentComponent.payPalApproved = false
                    paymentComponent.payPalError = err
                  }
                  else {
                    if(!paymentComponent.payPalApproved) {
                      paymentComponent.payPalApproved = false
                      paymentComponent.payPalError = err
                    }
                  }
                  paymentComponent.loading = false
                }
              })

              if (button.isEligible()) {
                if(fundingSource === 'paypal') {
                  button.render('#paypal-button')
                }
              }
            })
          })
        })

        // Vault Manager
        braintree.vaultManager.create({
          client: paymentComponent.braintreeInstance
        }, (vaultManagerErr, vaultManagerInstance) => {
          this.vaultManagerInstance = vaultManagerInstance
          paymentComponent.vaultInstance = vaultManagerInstance
          paymentComponent.loading = true
          paymentComponent.previousCards()
        })
      })
    },
    initBrainTreeVault() {
      const paymentComponent = this

      braintree.client.create({
        authorization: paymentComponent.clientToken,
      }, (err, instance) => {
        paymentComponent.braintreeInstance = instance

        if(err) {
          console.error(err)
          paymentComponent.loading = false
          return
        }

        // Vault Manager
        braintree.vaultManager.create({
          client: paymentComponent.braintreeInstance
        }, (vaultManagerErr, vaultManagerInstance) => {
          paymentComponent.vaultInstance = vaultManagerInstance
          paymentComponent.loading = true
          paymentComponent.previousCards()
        })
      })
    },
    makeDefault(bin) {
      this.$buefy.modal.open({
        parent: this,
        component: ConfirmDialog,
        hasModalCard: true,
        canCancel: true,
        props: {
          buttonMessage: 'Make Default',
          message: '<p>Are you sure you want to make this your default payment method?</p>'
        },
        events: {
          confirm: () => {
            this.loading = true
            this.$store.dispatch('app/defaultPaymentMethod', { bin: bin })
            .then(() => {
              this.previousCards()
              this.loading = false
            })
          }
        },
      })
    },
    async previousCards() {
      let paymentComponent = this
      paymentComponent.setLoading(true)

      this.vaultInstance.fetchPaymentMethods({
        defaultFirst: true
      }, (err, paymentMethods) => {
        if(err) {
          paymentComponent.setLoading(false)
          return
        }

        let cardPayments = paymentMethods.filter(method => method.type == "CreditCard")
        if(cardPayments.length === 0) {
          paymentComponent.cardChoice = 'new'
        }
        paymentComponent.previousPayments = cardPayments
        paymentComponent.setLoading(false)
      })
    },
  }
}
