import makerjs from 'makerjs'

function FlagGusset(baseLength, poleHeight, poleLength, flagHeight) {
  const points = [
    [0, 0], // going clockwise
    [0, poleHeight],
    [poleLength, poleHeight],
    [baseLength, flagHeight],
    [baseLength, 0],
  ]

  this.models = {
    flag_group: new makerjs.models.ConnectTheDots(true, points)
  }
}

FlagGusset.metaParameters = [
  {
    title: "Bottom Length",
    type: "range",
    min: 0,
    max: 24,
    value: 6,
    default: 6,
    tooltip_text: "Overall length of part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/flaggus_length.png",
    group: 'flag_group',
    group_title: 'Flag'
  },
  {
    title: "Pole Height",
    type: "range",
    min: 0,
    max: 12,
    value: 0.5,
    default: 0.5,
    tooltip_text: "Height of the 'pole' on the left",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/flaggus_pheight.png",
    group: 'flag_group',
    group_title: 'Flag'
  },
  {
    title: "Pole Length",
    type: "range",
    min: 0,
    max: 24,
    value: 2,
    default: 2,
    tooltip_text: "Length of the 'pole' on the left",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/flaggus_plength.png",
    group: 'flag_group',
    group_title: 'Flag'
  },
  {
    title: "Flag Height",
    type: "range",
    min: 0,
    max: 24,
    value: 2.5,
    default: 2.5,
    tooltip_text: "Height of the flag on the right",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/flaggus_fheight.png",
    group: 'flag_group',
    group_title: 'Flag'
  },
  { type: "bends", value: [], default: [], hidden: true, group: ['bend0', 'bend1', 'bend2', 'bend3'] }
]

export default FlagGusset
