import makerjs from 'makerjs'

function NamePlate(width, height, cornerRadius, font, text, fontSize, boltMargin, boltDiameter) {
  // Build the containing rect
  const rect = new makerjs.models.RoundRectangle(width, height, cornerRadius)
  makerjs.model.center(rect)

  // create a bolt rectangle inside of the containing rect
  const overallBoltMargin = boltMargin * 2
  boltDiameter /= 2
  const bolts = new makerjs.models.BoltRectangle(width - overallBoltMargin, height - overallBoltMargin, boltDiameter)
  makerjs.model.center(bolts)

  this.models = {
    rect_group: rect,
    bolts
  }

  // Generate and scale the font to match the intended size
  if (text !== "") {
    const textModel = new makerjs.models.Text(font, text, fontSize, false, false, 4)
    const textSize = makerjs.measure.modelExtents(textModel)
    makerjs.model.scale(textModel, fontSize / textSize.height)
    makerjs.model.center(textModel)
    this.models.text_group = textModel
  }
}

NamePlate.metaParameters = [
    { title: "Plate Width", type: "range", min: 0, max: 24, value: 7, default: 7, group: 'rect_group', group_title: 'Plate' },
    { title: "Plate Height", type: "range", min: 0, max: 24, value: 3, default: 3, group: 'rect_group', group_title: 'Plate' },
    { title: "Corner Radius", type: "range", min: 0, max: 6, value: 0.25, default: 0.25, group: 'rect_group', group_title: 'Plate' },
    { title: "Font", type: "font", stencil: true, value: 'Allerta', default: 'Allerta', group: 'text_group', group_title: 'Text' },
    { title: "Text", type: "text", value: '12345', default: '12345', group: 'text_group' },
    { title: "Font Size", type: "range", min: 0.75, max: 12, value: 1.5, default: 1.5, group: 'text_group', group_title: 'Text' },
    { title: "Bolt Margin", type: "range", min: 0.25, max: 6, value: 0.5, default: 0.5, group: 'bolts', group_title: 'Bolts' },
    { title: "Bolt Diameter", type: "range", min: 0.0625, max: 1, value: 0.25, default: 0.25, group: 'bolts', group_title: 'Bolts' },
]

export default NamePlate
