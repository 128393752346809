import makerjs from 'makerjs'

function LBracket(sideOneLength, sideOneWidth, radiusOne, sideTwoLength, sideTwoWidth, radiusTwo) {
  const rectOne = new makerjs.models.RoundRectangle(sideOneWidth, sideOneLength, radiusOne)
  const rectTwo = new makerjs.models.RoundRectangle(sideTwoLength, sideTwoWidth, radiusTwo)

  this.models = {
    bracket_group: makerjs.model.combineUnion(rectOne, rectTwo)
  }
}

LBracket.metaParameters = [
  {
    title: "Side One Length",
    type: "range",
    min: 0,
    max: 24,
    value: 6,
    default: 6,
    tooltip_text: "Length of the vertical side of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/lbracket_sideone.png",
    group: 'bracket_group',
    group_title: 'Bracket'
  },
  {
    title: "Side One Width",
    type: "range",
    min: 0,
    max: 24,
    value: 2,
    default: 2,
    tooltip_text: "Width of the vertical side of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/lbracket_sideonewidth.png",
    group: 'bracket_group',
    group_title: 'Bracket'
  },
  {
    title: "Left Corner Radius",
    type: "range",
    min: 0,
    max: 6,
    value: 0.25,
    default: 0.25,
    tooltip_text: "Radius of outside corners on first rectangle",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/l_bracket_LeftRadius.png",
    group: 'bracket_group',
    group_title: 'Bracket'
  },
  {
    title: "Side Two Length",
    type: "range",
    min: 0,
    max: 24,
    value: 6,
    default: 6,
    tooltip_text: "Length of the horzontal side of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/lbracket_sidetwo.png",
    group: 'bracket_group',
    group_title: 'Bracket'
  },
  {
    title: "Side Two Width",
    type: "range",
    min: 0,
    max: 24,
    value: 2,
    default: 2,
    tooltip_text: "Width of the horzontal side of the part",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/lbracket_sidetwowidth.png",
    group: 'bracket_group',
    group_title: 'Bracket'
  },
  {
    title: "Right Corner Radius",
    type: "range",
    min: 0,
    max: 6,
    value: 0.25,
    default: 0.25,
    tooltip_text: "Radius of outside corners on second rectangle",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/l_bracket_RightRadius.png",
    group: 'bracket_group',
    group_title: 'Bracket'
  },
  { type: "bends", value: [], default: [], hidden: true, group: ['bend0', 'bend1', 'bend2', 'bend3'] }
]

export default LBracket
