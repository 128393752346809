import scs from 'shared/scs'

// initial state
const state = {
  loading: false,
  materials: [],
}

// getters
const getters = {
  all: (state) => state.materials,
  get: (state) => (id) => state.materials.find(m => m.id == id) || {}
}

// actions
const actions = {
  async fetch ({ commit }, data = {}) {
    commit('setLoading', true)
    const response = await scs.getMaterials(data)
    const materials = await response.json()
    commit('setMaterials', materials.data)
    commit('setLoading', false)
  },
}

// mutations
const mutations = {
  setLoading(state, on) {
    state.loading = on
  },

  setMaterials(state, materials) {
    state.materials = materials
  },
}

export default {
  namespaced: true,
  state:     state,
  mutations: mutations,
  actions:   actions,
  getters:   getters
}
