export const poBoxValidator = {
  getMessage: () => 'Sorry, we cannot ship to P.O. Box addresses.',
  validate: value => {
    const regex = /^(?!.*(?:(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(box|bin)[-.\s]*)|.*((p |post)[-.\s]*(box|bin)[-.\s]*)))).*$/
    return regex.test(value.toLowerCase())
  }
}

export const passwordValidator = {
  getMessage(field, args, data) {
    return data?.message ? `The ${field} field ${data.message}` : `The ${field} field is not a valid password`
  },
  validate(value, args) {
    const minLength = args.minLength || 8
    if (minLength > 0 && value.length < minLength) {
      return {
        valid: false,
        data: { message: `must be at least ${minLength} characters` }
      }
    }

    if (args?.complex) {
      const categories = [/[A-Z]/, /[a-z]/, /[0-9]/, /[^A-Za-z0-9]/]
      const categoryMatches = categories.filter(category => category.test(value)).length
      if (categoryMatches < 3) {
        return {
          valid: false,
          data: {
            message: 'must contain at least 3 of the following: uppercase letter, lowercase letter, number, special character'
          }
        }
      }
    }
    return true
  }
}
