import Vue from 'vue'
import scs from 'shared/scs'
import { firstBy } from 'thenby'

import * as common from 'shared/store/common.js'

// initial state
const initialState = {
  finish_options: {}
}

// getters
const getters = {
  all: ({ finish_options: finishOptions }) => Object.keys(finishOptions)
    .map(id => finishOptions[id])
    .filter(item => !item.deleted)
    .sort(firstBy('finish_type')),
  allForType: ({ finish_options: finishOptions }) => type => Object.keys(finishOptions)
    .map(id => finishOptions[id])
    .filter(item => item.finish_type === type),
  allActiveForType: ({ finish_options: finishOptions }) => type => Object.keys(finishOptions)
    .map(id => finishOptions[id])
    .filter(item => item.public && !item.deleted)
    .filter(item => item.finish_type === type),
  find: ({ finish_options: finishOptions }) => value => Object.keys(finishOptions)
    .map(id => finishOptions[id])
    .filter(item => item.value === value)[0] || {}
}

// actions
const actions = {
  create({ commit }, data) {
    return scs.createFinishOption(data)
      .then((response) => {
        commit('setFinishOption', response.data)
      })
  },
  delete({ commit }, data) {
    return scs.deleteFinishOption(data)
      .then(() => {
        commit('removeFinishOption', data)
      })
  },
  fetch({ commit }, data = {}) {
    return scs.getFinishOptions(data)
      .then(response => {
        commit('setPagination', response.headers)
        return response.json()
      })
      .then((response) => {
        commit('setFinishOptions', response.data)
      })
  },
  getFinishOption({ commit }, finishingOption) {
    return scs.getFinishOption({ id: finishingOption.id })
      .then(response => {
        commit('setFinishOption', response.data)
      })
  },
  update({ commit }, data) {
    return scs.updateFinishOption(data)
      .then((response) => {
        commit('setFinishOption', response.data)
      })
  },
}

// mutations
const mutations = {
  setFinishOption(state, finishOption) {
    Vue.set(state.finish_options, finishOption.id, finishOption)
  },
  removeFinishOption(state, finishOption) {
    Vue.delete(state.finish_options, finishOption.id)
  },
  setFinishOptions(state, finishOptions) {
    state.finish_options = {}
    for (let i = 0; i < finishOptions.length; i++) {
      const option = finishOptions[i]
      Vue.set(state.finish_options, option.id, option)
    }
  },
}

export default {
  namespaced: true,
  state: { ...common.state, ...initialState },
  mutations: { ...common.mutations, ...mutations },
  actions: { ...common.actions, ...actions },
  getters: { ...common.getters, ...getters }
}
