import makerjs from 'makerjs'

function Disc(diameter) {
  this.paths = {
    disc: new makerjs.paths.Circle([0, 0], diameter / 2)
  }
}

Disc.metaParameters = [
    { title: "Diameter", type: "range", min: 0, max: 24, value: 6, default: 6, group: 'disc', group_title: 'Disc' },
]

export default Disc
