import makerjs from 'makerjs'
import BoltCircle from './BoltCircle'

function SquareFlange(sideLength, cornerRadius, centerHoleDiameter, holeCircleDiameter, holeDiameter, holeCount) {
  this.paths = {
    inner: new makerjs.paths.Circle([0, 0], centerHoleDiameter / 2)
  }
  this.models = {
    square_group: makerjs.model.center(new makerjs.models.RoundRectangle(sideLength, sideLength, cornerRadius)),
    bolts: new BoltCircle(holeCircleDiameter / 2, holeDiameter / 2, holeCount)
  }
}

SquareFlange.metaParameters = [
  {
    title: "Side Length",
    type: "range",
    min: 0,
    max: 24,
    value: 6,
    default: 6,
    tooltip_text: "Overall part height and length",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/sqrnd_length.png",
    group: 'square_group',
    group_title: 'Plate'
  },
  {
    title: "Corner Radius",
    type: "range",
    min: 0,
    max: 3,
    value: 0.5,
    default: 0.5,
    tooltip_text: "Radius of the outside corners",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/sqrnd_corner.png",
    group: 'square_group',
    group_title: 'Plate'
  },
  {
    title: "Inner Hole Diameter",
    type: "range",
    min: 0,
    max: 24,
    value: 3,
    default: 3,
    tooltip_text: "Diameter of the center hole",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/round_flange_innerdia.png",
    group: 'inner',
    group_title: 'Inner Hole'
  },
  {
    title: "Hole Circle Diameter",
    type: "range",
    min: 0,
    max: 24,
    value: 4.5,
    default: 4.5,
    tooltip_text: "Diameter of the path of the bolt holes",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/round_flange_pathdia.gif",
    group: 'bolts',
    group_title: 'Holes'
  },
  {
    title: "Hole Diameter",
    type: "range",
    min: 0,
    max: 6,
    value: 0.5,
    default: 0.5,
    tooltip_text: "Diameter of the bolt holes",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/round_flange_ohole_dia.png",
    group: 'bolts',
    group_title: 'Holes'
  },
  {
    title: "Hole Count",
    type: "range",
    units: "none",
    step: 1,
    min: 1,
    max: 24,
    value: 12,
    default: 12,
    tooltip_text: "Number of evenly spaced bolt holes in the flange",
    tooltip_image: "https://cdn.sendcutsend.com/images/parts/help/round_flange_holes.gif",
    group: 'bolts',
    group_title: 'Holes'
  },
  { type: "bends", value: [], default: [], hidden: true, group: ['bend0', 'bend1', 'bend2', 'bend3'] }
]

export default SquareFlange
