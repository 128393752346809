import makerjs from 'makerjs'

function OneUp(height) {
  const paths = [
    "M11.3235,10.2649 L11.3235,10.9576 L12.0163,10.9576 L12.0163,10.2649 Z",
    "M11.3235,3.4124 L11.3235,4.1052 L12.0163,4.1052 L12.0163,3.4124 Z",
    "M8.8988,7.2224 L8.8988,5.9727 L7.6036,5.9727 L7.6036,7.2224 L8.2154,7.2224 L8.2154,7.8251 L8.8988,7.8251 L8.8988,9.7076 L7.0464,9.7076 L7.0464,7.8251 L5.7211,7.8251 L5.7211,9.7076 L6.3235,9.7076 L6.3235,10.2649 L9.4862,10.2649 L9.4862,9.7076 L10.1187,9.7076 L10.1187,7.2224 Z",
    "M7.6036,4.1052 L7.6036,5.2649 L8.8988,5.2649 L8.8988,4.1052 Z",
    "M4.4711,10.2649 L4.4711,10.9576 L5.1639,10.9576 L5.1639,10.2649 Z",
    "M4.4711,3.4124 L4.4711,4.1052 L5.1639,4.1052 L5.1639,3.4124 Z",
    "M13.206,2.2377 L13.206,12.2377 L3.206,12.2377 L3.206,2.2377 Z"
  ]

  const oneUpModels = { }

  paths.forEach((p, i) => {
    oneUpModels[i] = makerjs.model.mirror(makerjs.importer.fromSVGPathData(p), true, false)
  })

  class OneUpGroup {
    constructor(models) {
      this.models = models
    }
  }

  this.models = {
    one_up_group: new OneUpGroup(oneUpModels)
  }

  // Apply a scaling factor
  const m = makerjs.measure.modelExtents(this)
  makerjs.model.scale(this, height / m.height)
  makerjs.model.rotate(this, 180)
}

OneUp.metaParameters = [
  { title: "Height", type: "range", min: 3, max: 24, value: 5, default: 5, group: 'one_up_group', group_title: 'Plate' },
]

export default OneUp
