import makerjs from 'makerjs'
import { adjustFontSize, adjustMetric } from 'shared/components/PartBuilder/parts/utilities/common'

function VanityPlate(topFont, topText, middleFont, middleText, middleFontSize, bottomFont, bottomText, units) {
  const plateWidth = adjustMetric(units, 12)
  const plateHeight = adjustMetric(units, 6)
  const cornerRadius = adjustMetric(units, 0.5)
  const boltWidth = adjustMetric(units, 7)
  const boltHeight = adjustMetric(units, 4.75)
  const boltDiameter = adjustMetric(units, 0.25)
  const topFontSize = adjustMetric(units, 0.75)
  const bottomFontSize = adjustMetric(units, 0.60)

  // Build the containing rect
  const rectangleGroup = new makerjs.models.RoundRectangle(plateWidth, plateHeight, cornerRadius)

  // Create a bolt rectangle inside of the containing rect
  const bolts = new makerjs.models.BoltRectangle(boltWidth, boltHeight, boltDiameter / 2)

  // Place the models
  makerjs.model.center(rectangleGroup)
  makerjs.model.center(bolts)
  const boltRectSize = makerjs.measure.modelExtents(bolts)

  this.models = {
    rectangle_group: rectangleGroup,
    bolts
  }

  if (middleText !== "") {
    const middleTextModel = new makerjs.models.Text(middleFont, middleText, middleFontSize, false, false, 4)
    adjustFontSize(middleTextModel, middleFontSize)
    this.models.middleText_group = middleTextModel
  }

  if (topText !== "") {
    const topTextModel = new makerjs.models.Text(topFont, topText, topFontSize, false, false, 4)
    adjustFontSize(topTextModel, topFontSize)
    makerjs.model.moveRelative(topTextModel, [0, boltRectSize.high[1] - boltDiameter / 2])
    this.models.topText_group = topTextModel
  }

  if (bottomText !== "") {
    const bottomTextModel = new makerjs.models.Text(bottomFont, bottomText, bottomFontSize, false, false, 4)
    adjustFontSize(bottomTextModel, bottomFontSize)
    makerjs.model.moveRelative(bottomTextModel, [0, boltRectSize.low[1] + boltDiameter / 2])
    this.models.bottomText_group = bottomTextModel
  }
}

VanityPlate.metaParameters = [
  { title: "Top Font", type: "font", stencil: true, value: 'Allerta', default: 'Allerta', group: 'topText_group', group_title: 'Top Text' },
  { title: "Top Text", type: "text", value: 'Nevada', default: 'Nevada', group: 'topText_group', group_title: 'Top Text' },
  { title: "Middle Font", type: "font", stencil: true, value: 'Allerta', default: 'Allerta', group: 'middleText_group', group_title: 'Main Text' },
  { title: "Middle Text", type: "text", value: 'SCS RULES', default: 'SCS RULES', group: 'middleText_group', group_title: 'Main Text' },
  { title: "Middle Font Size", type: "range", min: 1.0, max: 2.0, value: 1.70, default: 1.70, group: 'middleText_group', group_title: 'Main Text' },
  { title: "Bottom Font", type: "font", stencil: true, value: 'Allerta', default: 'Allerta', group: 'bottomText_group', group_title: 'Bottom Text' },
  { title: "Bottom Text", type: "text", value: 'Silver State', default: 'Silver State', group: 'bottomText_group', group_title: 'Bottom Text' },
  { type: "units", hidden: true }
]

export default VanityPlate
