import { Decimal } from 'decimal.js'

export default {
  methods: {
    asFormattedDecimal(value, precision = 3) {
      return parseFloat(Decimal(value).toFixed(precision))
    },
    copyUrlToClipboard(type, url) {
      this.$store.dispatch('app/link', { type, url })
        .then((result) => {
          this.copyToClipboard(result, 'Link Copied')
        })
        .catch(() => {
          this.copyToClipboard(url, 'Link Copied')
        })
    },
    copyToClipboard(text, message='') {
      const aux = document.createElement('input')
      aux.setAttribute('value', text)
      document.body.appendChild(aux)
      aux.select()
      if (!document.execCommand('copy')) {
        alert(text)
      }
      else if (message) {
        this.notifyMessage(message)
      }
      aux.remove()
    },
    cdnify(upload_url) {
      const urls = upload_url.split('/attachments')
      return urls?.length ? `https://cdn.sendcutsend.com/attachments${urls[1].split('?')[0]}` : upload_url
    },
    csvExport(report, columns=null, filename="export.csv") {
      let csvContent = "data:text/csv;charset=utf-8,"
      csvContent += [
        (columns || Object.keys(report[0]).join(",")),
        ...report.map(item => Object.values(item).join(","))
      ].join("\n").replace(/(^\[)|(\]$)|#/gm, "")

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", filename);
      link.click();
    },
    forceDownload(blob, filename = '') {
      const a = document.createElement('a')
      a.download = filename
      a.href = blob
      // For Firefox https://stackoverflow.com/a/32226068
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
    notifyMessage(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success'
      })
    },
  },
}
