import Vue from 'vue'
import scs from 'shared/scs'
import cloneDeep from 'lodash/cloneDeep'
import * as common from 'shared/store/common.js'
import { ToastProgrammatic as Toast } from 'buefy'

const PER_PAGE = 25

// initial state
const drawingState = {
  data: {
    // {id}: {drawing}
  },

  uploading: { },

  pages: {
    // folder:{folderID}: {last page retrieved}
  },

  filters: {
    // folderID: {id}
    // query: {queryString}
  },

  search: {
    ids: []
  },

  batch_select: [],
}

// getters
const getters = {
  count(state) {
    return Object.keys(state.data).length
  },

  get(state) {
    return id => (typeof id === 'string' ? state.uploading[id] : state.data[id])
  },

  all(state, _, rootState) {
    const drawings = Object.keys(state.data) // Get array of all drawing ids
      .sort((a, b) => b - a) // Sort in desc order
      .map(id => state.data[id]) // Map to drawings
      .filter(drawing => {
        let match = true

        // If a folderID is used as a filter, return only drawings that
        // have the matching folder (or no folder).
        const { folderID } = state.filters
        if (folderID) {
          if (folderID === 'uncategorized') {
            match = match && !drawing.folder
          } else {
            match = match && drawing.folder && state.filters.folderID === drawing.folder.id
          }
        }

        // If a query string is used as a filter, return only ids that match
        // the result of the search query
        const { query } = state.filters
        if (query) {
          const results = state.search.ids || []
          match = match && results.includes(drawing.id)
        }

        return match
      })

      let result = drawings
      if (rootState.app.drawingApiVersion < 6) {
        const uploading = Object.keys(state.uploading)
          .map(id => state.uploading[id])

        result = [...uploading, ...result]
      }

    return result
  }
}

// actions
const actions = {
  async batchUploadKeys(_, files) {
    const resp = await scs.drawingS3Urls(files)
    return resp.data
  },

  batchDelete({ commit }, data) {
    return scs.deleteMultipleDrawings(data)
      .then(response => {
        commit('removeDrawings', data)
        commit('updateStats', response.stats)
      })
  },

  delete({ commit }, data) {
    return scs.deleteDrawing(data)
      .then(response => {
        commit('removeDrawing', data)
        commit('updateStats', response.stats)
      })
  },
  fetch({ commit, rootGetters }, data = {}) {
    data.per_page = data.per_page || PER_PAGE
    data.user_token = rootGetters['app/userToken']

    return scs.getDrawings(data)
      .then(response => {
        commit('setPagination', response.headers)
        return response.json()
      })
      .then(response => {
        if (data.search) {
          // Store search results if this fetch is a search request
          commit('setSearchResults', response.data.map(d => d.id))
        } else {
          // When not searching, store the most recent page to keep track of
          // which drawings have already been pulled
          commit('setPage', data)
        }
        commit('setDrawings', { data, drawings: response.data })
        commit('updateStats', response.stats)
        return response.data
      })
  },

  async filter({ state, commit, rootState }, data = {}) {
    const folderID = data.folderID ? data.folderID : undefined

    // Set the cached total count for the filter. TotalCount is only updated
    // directly after a fetch.
    const folder = rootState.folders.data[folderID]
    commit('setTotalCount', folder ? folder.size : state.stats[folderID || 'all'])
    commit('setFilters', data)
  },

  refresh({ commit, rootGetters }, data) {
    data.user_token = rootGetters['app/userToken']

    return scs.refreshDrawing(data)
      .then(response => {
        commit('setDrawing', response.data)
        return response.data
      })
  },

  async refreshDrawings({ commit, rootGetters }, drawingIDs) {
    const response = await scs.getDrawings({
      ids: drawingIDs,
      user_token: rootGetters['app/userToken']
    })
    const json = await response.json()
    const updatedDrawings = json.data

    for (let i = 0; i < updatedDrawings.length; i++) {
      commit('setDrawing', updatedDrawings[i])
    }
  },

  clearDrawings({ state, commit }, folder) {
    const toDelete = Object
      .keys(state.data)
      .filter(key => (
        folder === 'uncategorized'
          ? !state.data[key].folder
          : state.data[key].folder.id === folder))
      .map(key => state.data[key])

    for (let i = 0; i < toDelete.length; i++) {
      commit('removeDrawing', toDelete[i])
    }

    commit('clearPage', folder)
  },

  search({ commit }, query) {
    commit('clearSearch')

    // Searching should take the existing filters into account
    commit('setFilters', { query })
  },

  async reprocessDrawing({ commit }, drawing) {
    const result = await scs.reprocessDrawing(drawing.id)
    commit('setDrawing', result.data)
  },

  async convertDrawing({ rootState }, { url }) {
    try {
      const drawingApi = rootState.app.drawingApiHost
      const resp = await fetch(`${drawingApi}/convert`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          original_url: url.split('?')[0],
          // TODO: Remove this when admins are happy
          poly_pricing: rootState.app.usePolyPricing,
          version: rootState.app.drawingApiVersion
        })
      })

      if (!resp.ok) {
        const message = 'An error occurred while processing your drawing'
        Toast.open({ message, type: 'is-danger', duration: 5000 })
        throw new Error(message)
      }

      return await resp.json()
    } catch (e) {
      const message = 'Drawing took too long to process'
      Toast.open({ message, type: 'is-danger', duration: 5000 })
      throw new Error(message)
    }
  },

  async create({ commit, rootGetters }, data) {
    data.user_token = rootGetters["app/userToken"]
    const response = await scs.createDrawing(data.url, data)

    // Remove temporary "uploading" drawing
    commit('removeUploading', { id: data.file.name })

    // Insert created drawing
    commit('setDrawing', { ...data, ...response.data })
    commit('updateStats', response.stats)
    return response.data
  },

  async uploadFile({ commit }, { file, url }) {
    commit('setUploading', { id: file.name, state: 'uploading' })
    await scs.uploadFileToS3(url, file)
  },

  update({ commit, state, rootGetters }, data) {
    data.id = parseInt(data.id, 10)
    data.user_token = rootGetters['app/userToken']
    const drawing = cloneDeep(state.data[data.id])

    // Update the drawing immediately in good faith
    const newDrawing = { ...drawing, ...data }
    commit('setDrawing', newDrawing)

    return scs
      .updateDrawing(newDrawing)
      .then(response => {
        commit('setDrawing', response.data)
        commit('updateStats', response.stats)
        return response.data
      })
      .catch(() => {
        // Reset the drawing if the update failed
        commit('setDrawing', drawing)
      })
  }
}

// mutations
const mutations = {
  addBatchSelect(state, item) {
    state.batch_select.push(item)
  },

  clear(state) {
    state.data = {}
  },

  clearPage(state, folder) {
    const pageKey = `folder:${folder || 'all'}`
    Vue.set(state.pages, pageKey, 0)
  },

  clearSearch(state) {
    // Reset query results when a new search is performed
    state.page = 0
    Vue.set(state.search, 'ids', [])
  },

  removeBatchSelect(state, itemId) {
    state.batch_select = state.batch_select.filter(id => id !== itemId)
  },

  setDrawings(state, { drawings }) {
    // Store any retrieved drawings in data
    for (let i = 0; i < drawings.length; i++) {
      const drawing = drawings[i]
      Vue.set(state.data, drawing.id, drawing)
    }
  },

  setBatchSelect(state, batch) {
    state.batch_select = batch
  },

  setDrawing(state, drawing) {
    // { ...drawing } creates a new object. The reason for doing this is to
    // enable vue reactivity on properties that have been added/deleted.
    // Vue.set does the same thing, but for the state.data object.
    Vue.set(state.data, drawing.id, { ...drawing })
  },

  setFilters(state, filters) {
    Vue.set(state, 'filters', filters)
  },

  setPage(state, { folder, page }) {
    // Store the max page retrieved because we'll already have this data in memory
    const pageKey = `folder:${folder || 'all'}`
    Vue.set(state.pages, pageKey, Math.max(state.pages[pageKey] || 1, page))
  },

  setSearchResults(state, ids) {
    for (let i = 0; i < ids.length; i++) {
      state.search.ids.push(ids[i])
    }
  },

  setUploading(state, drawing) {
    Vue.set(state.uploading, drawing.id, drawing)
  },

  removeUploading(state, drawing) {
    Vue.delete(state.uploading, drawing.id)
  },

  removeDrawing(state, drawing) {
    Vue.delete(state.data, parseInt(drawing.id, 10))
  },

  removeDrawings(state, { ids }) {
    ids.forEach(id => {
      Vue.delete(state.data, parseInt(id, 10))
    })
  }
}

export default {
  namespaced: true,
  state: { ...common.state, ...drawingState },
  mutations: { ...common.mutations, ...mutations },
  actions: { ...common.actions, ...actions },
  getters: { ...common.getters, ...getters }
}
