import { ref } from 'vue'

import drawingAPI from 'shared/drawingAPI'

export function useDrawingDfm() {
  const status = ref(null)

  const checkDfm = async (model, units, material) => {
    status.value = 'checking'
    const data = await drawingAPI.checkDFM(model, units, material)
    status.value = 'checked'

    return data
  }

  const resetDfm = () => {
    status.value = null
  }

  return {
    status,
    checkDfm,
    resetDfm
  }
}
