/**
 * Parse the data required to calculate bend measurements
 * @param {Material} material SCS material object
 * @param {String} units in|mm
 * @returns Data to calculate bend measurements
 */
export function bendData(material, units) {
  const operation = (material.operations || []).find(op => op.operation === 'bending') || {}
  const config = operation?.configuration || {}

  const kfactor = parseFloat(config.k_factor)
  const minBendAngle = parseFloat(config.min_bend_angle)
  const maxBendAngle = parseFloat(config.max_bend_angle)
  const minDieContactSoft = parseFloat(config.min_die_contact_soft) || 0.9
  const minDieContactHard = parseFloat(config.min_die_contact_hard) || 0.3
  const minFlangeContact = parseFloat(config.min_flange_contact) || 0.03

  let minFlangeLength = parseFloat(config.min_flange_length)
  let bottomDie = parseFloat(config.bottom_die)
  let radius = parseFloat(config.effective_bend_radius)
  let thickness = parseFloat(material.thickness)

  if (units === 'mm') {
    bottomDie *= 25.4
    minFlangeLength *= 25.4
    radius *= 25.4
    thickness *= 25.4
  }

  // minFlangeLength must be longer than 1/2 the die width
  minFlangeLength = Math.max(minFlangeLength, bottomDie / 2)

  return {
    kfactor,
    radius,
    thickness,
    bottomDie,
    minBendAngle,
    maxBendAngle,
    minFlangeLength,
    minFlangeContact,
    minDieContactHard,
    minDieContactSoft,
  }
}

/**
 * Calculate the bend allowance
 * @param {Material} material SCS material object
 * @param {String} units in|mm
 * @param {Number} angle Angle of the bend
 * @returns Bend allowance
 */
export function bendAllowance(material, units, angle) {
  // Find the bend operation
  const { kfactor, radius, thickness } = bendData(material, units)

  // Convert angle to radians
  const rad = (angle * Math.PI) / 180

  // Calculate bend allowance
  return rad * (radius + (kfactor * thickness))
}

/**
 * Calculate the bend deduction for a bend
 * @param {Material} material SCS material object
 * @param {String} units in|mm
 * @param {Number} angle Angle of the bend
 * @returns Bend deduction
 */
export function bendDeduction(material, units, angle) {
  const { radius, thickness } = bendData(material, units)

  // Calculate the bend allowance
  const allowance = bendAllowance(material, units, angle)

  // Convert angle to radians
  const rad = (angle * Math.PI) / 180

  // Calculate the bend deduction
  return 2 * (radius + thickness) * Math.tan(rad / 2) - allowance
}
