export default {
  props: {
    showClearance: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    cskHoleToEdgeDistance() {
      const cskOperation = this.item.material?.operations?.find(op => op.operation === 'countersinking')
      if (!cskOperation?.configuration) return 0
      return parseFloat(cskOperation.configuration.min_hole_to_edge_distance || '0')
    },

    tapHoleToEdgeDelta() {
      const tapOperation = this.item.material?.operations?.find(op => op.operation === 'tapping')
      if (!tapOperation?.configuration) return 0
      return parseFloat(tapOperation.configuration.min_hole_to_edge_delta || '0')
    }
  },

  methods: {
    addClearance() {
      if (!this.$refs.svgContainer) return

      // Remove any previously added clearance rings.
      this.removeClearance()

      const group = document.createElementNS("http://www.w3.org/2000/svg", "g")
      group.setAttribute('transform', 'matrix(1,0,0,-1,0,0)')
      group.id = "clearance"

      // Add clearance rings for holes to the svg group tag.
      if (this.cskHoleToEdgeDistance > 0) {
        this.addCskClearance(group)
      }
      if (this.tapHoleToEdgeDelta > 0) {
        this.addTappingClearance(group)
      }
      this.addHardwareClearance(group)

      // Insert before holes.
      const $svgContainer = this.$refs.svgContainer
      const $holes = $svgContainer.querySelector('.holes')
      $svgContainer.querySelector('svg').insertBefore(group, $holes)
    },

    addCskClearance(group) {
      const $svgContainer = this.$refs.svgContainer
      this.cskConfig.forEach(csk => {
        if (csk.id && csk.csk_id && csk.csk_direction) {
          const $hole = $svgContainer.querySelector(`#${csk.id}`)
          if (!$hole) return

          const conversionFactor = this.drawing.units === 'mm' ? 25.4 : 1
          const ringRadius = this.cskHoleToEdgeDistance * conversionFactor
          this.addClearanceRing(group, $hole, ringRadius)
        }
      })
    },

    addTappingClearance(group) {
      const $svgContainer = this.$refs.svgContainer
      this.tapConfig.forEach(tap => {
        if (tap.id && tap.tap) {
          const $hole = $svgContainer.querySelector(`#${tap.id}`)
          if (!$hole) return

          // Extract the radius from the hole element, may be a circle or a path.
          const radiusStr = $hole.tagName === 'circle' ? $hole.getAttribute('r') : $hole.getAttribute('radius')
          const holeRadius = parseFloat(radiusStr || '0')
          if (!holeRadius) return

          const conversionFactor = this.drawing.units === 'mm' ? 25.4 : 1
          const ringRadius = holeRadius + (this.tapHoleToEdgeDelta * conversionFactor)
          this.addClearanceRing(group, $hole, ringRadius)
        }
      })
    },

    addHardwareClearance(group) {
      const $svgContainer = this.$refs.svgContainer
      this.hardwareConfig.forEach(hw => {
        if (hw.id && hw.hardware_id && hw.hardware_direction) {
          const $hole = $svgContainer.querySelector(`#${hw.id}`)
          if (!$hole) return

          const clToEdgeDistance = this.$store.getters['hardware/minClToEdgeDistance'](hw.hardware_id)

          const conversionFactor = this.drawing.units === 'mm' ? 25.4 : 1
          const ringRadius = clToEdgeDistance * conversionFactor

          this.addClearanceRing(group, $hole, ringRadius)
        }
      })
    },

    // Add a ring to the svg group tag. Radius must be in units of the drawing.
    addClearanceRing(group, $hole, radius) {
      if (!$hole || !radius || radius <= 0) return

      const center = {
        x: parseFloat($hole.getAttribute('cx')),
        y: parseFloat($hole.getAttribute('cy'))
      }

      group.innerHTML += `<circle cx="${center.x}" cy="${center.y}" r="${radius}" />`
    },

    removeClearance() {
      if (!this.$refs.svgContainer) return

      const $svgContainer = this.$refs.svgContainer
      const $old = $svgContainer.querySelector("#clearance")
      if ($old) $old.remove()
    }
  },
  watch: {
    showClearance() {
      if (this.showClearance) {
        this.addClearance()
      } else {
        this.removeClearance()
      }
    },
  }
}
