export default {
  namespaced: true,

  state: {
    urls: [],
    refresh: 0,
    loading: false
  },

  actions: {
    print({ commit, state }, urls) {
      let printUrls = typeof urls == 'string' ? [urls] : urls

      printUrls = printUrls.map(url => {
        commit('incrementRefresh')

        if (/#/.test(url)) {
          return url.replace(/#/, `?ref=${state.refresh}#`)
        }

        const sep = /\?/.test(url) ? '#' : '&'
        return url + `${sep}ref=${state.refresh}`
      })

      commit('setLoading', true)
      commit('setUrls', printUrls)
    },

    printed({ commit, state }) {
      commit('dequeueUrl')

      if (state.urls == 0) {
        commit('setLoading', false)
      }
    },
  },

  mutations: {
    dequeueUrl(state, url) {
      state.urls.shift()
    },

    incrementRefresh(state) {
      state.refresh++
    },

    setLoading(state, on) {
      state.loading = on
    },

    setUrls(state, urls) {
      state.urls = urls
    }
  },
}
