import makerjs from 'makerjs'

function Belt(leftOuterDiameter, centerDistance, rightOuterDiameter) {
  this.models = {
    belt_group: new makerjs.models.Belt(leftOuterDiameter / 2, centerDistance, rightOuterDiameter / 2)
  }
}

Belt.metaParameters = [
  { title: "Left Diameter", type: "range", min: 0, max: 6, value: 2, default: 2, group: 'belt_group', group_title: 'Belt' },
  { title: "Distance Between Centers", type: "range", min: 0, max: 12, value: 2, default: 2, group: 'belt_group', group_title: 'Belt' },
  { title: "Right Diameter", type: "range", min: 0, max: 6, value: 1, default: 1, group: 'belt_group', group_title: 'Belt' },
]

export default Belt
