import find from 'lodash/find'
import filter from 'lodash/filter'
import opentype from 'opentype.js'

const ALL_FONTS = [
  {
    name: 'Allerta',
    url: 'https://cdn.sendcutsend.com/fonts/stencil/AllertaStencil-Regular.ttf',
    stencil: true
  },
  {
    name: 'Anonymous',
    url: 'https://cdn.sendcutsend.com/fonts/positive/Anonymous.ttf',
    stencil: false
  },
  {
    name: 'Black Ops One',
    url: 'https://cdn.sendcutsend.com/fonts/stencil/BlackOpsOne-Regular-3.ttf',
    stencil: true
  },
  {
    name: 'Cousine-Regular',
    url: 'https://cdn.sendcutsend.com/fonts/positive/Cousine-Regular.ttf',
    stencil: false
  },
  {
    name: 'Emblema One',
    url: 'https://cdn.sendcutsend.com/fonts/stencil/EmblemaOne-Regular.ttf',
    stencil: true
  },
  {
    name: 'Hack',
    url: 'https://cdn.sendcutsend.com/fonts/positive/Hack-Regular.ttf',
    stencil: false
  },
  {
    name: 'Keania One',
    url: 'https://cdn.sendcutsend.com/fonts/stencil/KeaniaOne-Regular.ttf',
    stencil: true
  },
  {
    name: 'NotoMono',
    url: 'https://cdn.sendcutsend.com/fonts/positive/NotoMono-Regular.ttf',
    stencil: false
  },
  {
    name: 'Plaster',
    url: 'https://cdn.sendcutsend.com/fonts/stencil/Plaster-Regular.ttf',
    stencil: true
  },
  {
    name: 'SpaceMono',
    url: 'https://cdn.sendcutsend.com/fonts/positive/SpaceMono-Regular.ttf',
    stencil: false
  },
  {
    name: 'Stardos',
    url: 'https://cdn.sendcutsend.com/fonts/stencil/StardosStencil-Regular.ttf',
    stencil: true
  },
  {
    name: 'Stardos Bold',
    url: 'https://cdn.sendcutsend.com/fonts/stencil/StardosStencil-Bold.ttf',
    stencil: true
  },
  {
    name: 'UbuntuMono',
    url: 'https://cdn.sendcutsend.com/fonts/positive/UbuntuMono-R.ttf',
    stencil: false
  },
  {
    // Dimension font
    name: 'dimfont',
    url: "https://cdn.sendcutsend.com/fonts/general/Montserrat-Thin.ttf"
  }
]

export default {
  namespaced: true,
  state: {
    active_category: 0,
    fonts: ALL_FONTS,
    state: 'gallery',
    previous_units: 'in',
    units: 'in',
    selected_part: {},
    loaded: false,
    model: {},
    highlightedGroup: '',
    selectedGroup: '',
  },
  actions: {
    async loadFonts() {
      if (window.fonts == null) {
        await Promise.all(ALL_FONTS.map(async f => {
          // Font objects are very large. They can end up causing memory issues
          // in vuex/vue-devtools if they are kept in the store. Storing them on
          // the window object and retrieving them later can resolve this issue
          window.fonts = {}

          if (window.fonts[f.name] == null) {
            const font = await opentype.load(f.url)
            window.fonts[f.name] = font
          }
        }))
      }
    }
  },
  getters: {
    activeCategory: (state) => state.active_category,
    allParts: (state) => state.parts,
    getPart: state => id => find(state.parts, d => d.id === id),
    getUnits: state => state.units,
    getDisplayDims: state => {
      const dimsWidth = Number(state.model?.models?.dims?.original_extents?.width).toFixed(3)
      const dimsHeight = Number(state.model?.models?.dims?.original_extents?.height).toFixed(3)
      const units = state.units === 'in' ? '"' : 'mm'
      if (dimsWidth !== 'NaN') {
        return `${dimsWidth}${units} x ${dimsHeight}${units}`
      }
      return ''
    },
    allFonts: (state) => filter(state.fonts, (f) => f.name),
    allStencilFonts: (state) => filter(state.fonts, (f) => f.stencil === true),
    allRegularFonts: (state) => filter(state.fonts, (f) => f.stencil === false),
  },
  mutations: {
    setActiveCategory(state, category) {
      state.active_category = category
    },
    setHighlightedGroup(state, group) {
      state.highlightedGroup = group
    },
    setLoaded(state, loaded) {
      state.loaded = loaded
    },
    setModel(state, model) {
      state.model = model
    },
    setState(state, dialogState) {
      state.state = dialogState
    },
    setSelectedGroup(state, group) {
      state.selectedGroup = group
    },
    setSelectedPart(state, selectedPart) {
      state.selected_part = selectedPart
    },
    setUnits(state, units) {
      state.previous_units = state.units
      state.units = units
    },
    addFont(state, font) {
      state.fonts.push(font)
    }
  }
}
