import makerjs from 'makerjs'
import Vector from 'shared/util/vector'

/**
 * Parse the data required to calculate bend measurements
 * @param {Model} model SCS model file json object
 * @param {String} layer Which layer do you want a model for (default 'cut')
 * @returns A makerjs model
 */
export function toMakerJS(model, layer = 'cut') {
  const polylines = model[layer] || []

  const paths = polylines.reduce((result, polyline) => {
    return { ...result, ...polyToMakerJS(polyline) }
  }, {})

  return { paths }
}

/**
 * Convert a single polyline into a makerjs model
 * @param {Polyline} poly A polyline from an SCS model
 * @returns A set of makerjs paths
 */
export function polyToMakerJS(poly) {
  const paths = {}
  const entities = poly.entities || []

  // Iterate over each polylines entities
  entities.forEach(entity => {
    switch(entity.type) {
      case 'arc':
        if (entity.clockwise) {
          paths[entity.id] = new makerjs.paths.Arc([entity.center.x, entity.center.y], entity.radius, entity.endAngle, entity.startAngle)
        }
        else {
          paths[entity.id] = new makerjs.paths.Arc([entity.center.x, entity.center.y], entity.radius, entity.startAngle, entity.endAngle)
        }
        break;
      case 'line':
        paths[entity.id] = new makerjs.paths.Line([entity.start.x, entity.start.y], [entity.end.x, entity.end.y])
        break;
    }
  })

  return paths
}

/**
 * Given an SCS Model, return an SCS Model with only interior geometry
 * @param {SCSModel} model
 * @returns {SCSModel} A model without outer contour
 */
export function interiorGeometry(model) {
  // Find the outer contour
  const outerPoly = model.cut.reduce((prev, current) => (prev.area > current.area) ? prev : current)

  // Return a new model with the outer contour removed
  return { ...model, cut: model.cut.filter(poly => poly.id != outerPoly.id) }
}

/**
 * Given an SCS Model, return an SCS Model with only exterior geometry
 * @param {SCSModel} model
 * @returns {SCSModel} A model without outer contour
 */
export function exteriorGeometry(model) {
  // Find the outer contour
  const outerPoly = model.cut.reduce((prev, current) => (prev.area > current.area) ? prev : current)

  // Return a new model with the outer contour removed
  return { ...model, cut: model.cut.filter(poly => poly.id == outerPoly.id) }
}

/**
 * Get a list of intersections between two models
 * @param {Model} modelA First model
 * @param {Model} modelB Second model
 * @returns A list of intersections
 */
export function intersections(modelA, modelB) {
  let points = []
  for (let key in modelA.paths) {
    var pathA = modelA.paths[key]

    for (let key in modelB.paths) {
      const pathB = modelB.paths[key]
      const int = makerjs.path.intersection(pathA, pathB)
      if (int) points = points.concat(int.intersectionPoints)
    }
  }

  return points
}

/**
 * Determine whether a model contains a polyline
 * @param {Model} model The makerjs model that may contain a path
 * @param {Polyline} polyline A polyline to check for containment
 * @returns {Boolean} Does this model contain a polyline
 */
export function containsPoly(model, poly) {
  const polyModel = { paths: polyToMakerJS(poly) }
  const pointList = modelPoints(polyModel)


  // Determine if every point exists within the model
  return pointList.length > 0 && pointList.every(point => makerjs.measure.isPointInsideModel(point, model))
}

/**
 * Get a series of points that make up a makerjs model
 * @param {Model} model The makerjs model to build a point list for
 * @returns {List<Point>} A point list representing a makerjs model
 */
export function modelPoints(model) {
  let points = []

  // Gather key points for each of the paths in a model (approximates arcs)
  makerjs.model.walkPaths(model, (_model, _id, path) => {
    const keyPoints = makerjs.path.toKeyPoints(path)
    points = points.concat(keyPoints)
  })

  return points
}

/**
 * Get area of a makerjs model
 * @param {Model} model The makerjs model to calculate area for
 * @returns {Number} The area of the model
 */
export function getArea(model) {
  const points = modelPoints(model)

  let area = 0

  // Calculate area using the shoelace formula
  for (let i = 0; i < points.length; i++) {
    const j = (i + 1) % points.length
    area += points[i][0] * points[j][1]
    area -= points[j][0] * points[i][1]
  }

  return Math.abs(area) / 2
}

function dieCorners(p1, p2, dist) {
  const { x: x1, y: y1 } = p1
  const { x: x2, y: y2 } = p2

  // Determine start and end vectors
  const start = new Vector(x1, y1)
  const end = new Vector(x2, y2)

  // Calculate directional vectors with length (0.5 bend allowance)
  const direction = end.subtract(start).normalize()
  const perpendicular = new Vector(direction.y, -1 * direction.x).multiply(dist)

  // Calculate points for each of the 4 corners
  return {
    p1: start.add(perpendicular),
    p2: end.add(perpendicular),
    p3: start.subtract(perpendicular),
    p4: end.subtract(perpendicular),
  }
}

/**
 * Calculate the paths for bend and die lines
 * @param {BendLine} bendLine A bend line definition from an SCS model file
 * @param {Number} dist The distance to expand by (one side)
 * @returns Paths for bend and die lines
 */
export function bendVectors(bendLine, dist) {
  const { p1, p2, p3, p4 } = dieCorners(bendLine.start, bendLine.end, dist)
  const { start, end } = bendLine

  return {
    dieA: new makerjs.paths.Line([p1.x, p1.y], [p2.x, p2.y]), // Line from p1 to p2
    dieB: new makerjs.paths.Line([p3.x, p3.y], [p4.x, p4.y]), // Line from p3 to p4
    bend: new makerjs.paths.Line([start.x, start.y], [end.x, end.y]), // Line from start to end
  }
}

/**
 * Determine how much contact a path has when intersected with a model
 * @param {Model} model A makerjs model
 * @param {Path} path A makerjs path to measure for contact
 * @returns {Number} The total contact distance a path makes when intersected with a model
 */
export function contact(model, path) {
  // Clone the model so we don't modify the original
  const og = makerjs.model.clone(model)

  // Get the intersection from the perspective of the path. These are the points
  // where the path intersects with the model
  const result = makerjs.model.combineIntersection(og, { paths: { p: path } })
  const pathIntersection = result.models.b

  // Calculate the total length of the path intersection
  return makerjs.measure.modelPathLength(pathIntersection)
}

/**
 * Expand a bend line into a model created by the die lines
 * @param {BendLine} bendLine A bend line definition from an SCS model file
 * @param {Number} dist The distance to expand by (one side)
 * @returns {Model} The model created by expanding the bend line by the bottomDie length
 */
export function expandBendLine(bendLine, dist) {
  const { p1, p2, p3, p4 } = dieCorners(bendLine.start, bendLine.end, dist)

  return {
    paths: {
      p_1: new makerjs.paths.Line([p1.x, p1.y], [p2.x, p2.y]),
      p_2: new makerjs.paths.Line([p2.x, p2.y], [p4.x, p4.y]),
      p_3: new makerjs.paths.Line([p4.x, p4.y], [p3.x, p3.y]),
      p_4: new makerjs.paths.Line([p3.x, p3.y], [p1.x, p1.y]),
    }
  }
}
