import { ref } from 'vue'
import { useStore } from 'customer/components/composables/store'

// global state, created in module scope
const quantity = ref(1)

export function useMarketplace() {
  const store = useStore()

  const getCheckoutUrl = (referenceNumber) => {
    const product = store.getters['customer_products/get'](referenceNumber)
    if (!product) return null
    const appHost = store.getters['app/appHost']
    return `${appHost}${product.checkout_path}&quantity=${quantity.value}`
  }

  return {
    quantity,
    getCheckoutUrl
  }
}
