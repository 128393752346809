import {
  isDesignServiceItem,
  isDesignServicesOrder,
  isDesignServicesDrawingOrder,
  isDesignServicesTemplateOrder,
  isLinkedDesignServiceOrder,
  isMarketplaceProductItem,
  isNonProductionItem
} from 'shared/orderTypes'

export default {
  methods: {
    associatedOrderHtml(order, item, noLink = false) {
      let title = ''
      if (item.associated_order) {
        const orderUrl = `/admin#/orders/?ids=${item.associated_order.id}`
        const orderLink = noLink ? `Order ${item.associated_order.reference_number}` : `<a href="${orderUrl}">Order ${item.associated_order.reference_number}</a>`
        title = `<span>${this.isLinkedDesignServiceOrder(order) ? 'Purchased in ' : 'Fulfilled in '}${orderLink}</span>`
      }
      return title
    },
    designServicesTitle(item) {
      return item?.item_type?.replaceAll('_', ' ')
    },
    drawingAcceptUrls(order) {
      const links = order.line_items.map((item) => item.drawing?.accept_url)
      return links.filter((link) => link)
    },
    linkedDesignServiceOrderInvoicePath(order) {
      let path = ''
      if (this.isLinkedDesignServiceOrder(this.order)) {
        const ds_line_items = order?.line_items.filter(item => isDesignServiceItem(item))
        path = `/public#/invoice?code=${ds_line_items[0].associated_order.code}`
      }
      else {
        path = order.invoice_path
      }
      return path
    },
    designServicesLogo(item) {
      let logo = ''
      if (item?.item_type === 'design_services_drawing') {
        logo = 'https://cdn.sendcutsend.com/images/design-services-drawing.svg'
      }
      else if (item?.item_type === 'design_services_template') {
        logo = 'https://cdn.sendcutsend.com/images/design-services-template.svg'
      }
      return logo
    },
    isDesignServiceItem,
    isDesignServicesOrder,
    isLinkedDesignServiceOrder,
    isDesignServicesDrawingOrder,
    isDesignServicesTemplateOrder,
    isMarketplaceProductItem,
    isNonProductionItem
  }
}
