import Vue from 'vue'
import scs from 'shared/scs'

import * as common from 'shared/store/common.js'

const getters = {
  all({ data }) {
    return Object.keys(data).sort().reverse().map(id => data[id])
  },
  get: ({ data }) => id => data[id],
}

const actions = {
  async fetch({ commit }, data) {
    commit('setLoading', true)

    try {
      const resp = await scs.getVendorResources(data)
      const json = await resp.json()
      commit('setPagination', resp.headers)
      commit('setVendorResources', json.data)
    } finally {
      commit('setLoading', false)
    }
  },
  async create({ commit }, data) {
    commit('setLoading', true)
    try {
      const json = await scs.createVendorResource(data)
      commit('setVendorResource', json.data)
    } finally {
      commit('setLoading', false)
    }
  },
  async update({ commit }, data) {
    commit('setLoading', true)

    try {
      const response = await scs.updateVendorResource(data)
      commit('setVendorResource', response.data)
    } finally {
      commit('setLoading', false)
    }
  },
  async delete({ commit }, data) {
    commit('setLoading', true)

    try {
      await scs.deleteVendorResource(data)
      commit('removeVendorResource', data.id)
    } finally {
      commit('setLoading', false)
    }
  },
  async refreshVendorResource({ commit }, data) {
    commit('setLoading', true)

    try {
      const response = await scs.getVendorResource(data)
      const json = await response.json()

      if (json.data) {
        json.data.forEach(vendor => {
          commit('setVendorResource', vendor)
        })
      }
    } finally {
      commit('setLoading', false)
    }
  },
  async clear({ commit }) {
    return commit('clearVendorResources')
  }
}

const mutations = {
  setVendorResource(state, vendorResource) {
    Vue.set(state.data, vendorResource.id, vendorResource)
  },
  setVendorResources(state, vendorResources) {
    Vue.set(state, 'data', {})
    if (vendorResources) {
      vendorResources.forEach(vendorResource => {
        Vue.set(state.data, vendorResource.id, vendorResource)
      })
    }
  },
  removeVendorResource(state, id) {
    Vue.delete(state.data, id)
  },
  setLoading(state, loading) {
    state.loading = loading
  },
  clearVendorResources(state) {
    Vue.set(state, 'data', {})
  },
}

// initial state
const state = {
  loading: false,
  data: {}
}

export default {
  namespaced: true,
  state: { ...common.state, ...state },
  mutations: { ...common.mutations, ...mutations },
  actions: { ...common.actions, ...actions },
  getters: { ...common.getters, ...getters }
}
