import Vue from 'vue'
import scs from 'shared/scs'

// initial state
const initialState = {
  audits: [],
  totalCount: 0,
  perPage: 0,
  totalPages: 0,
  lastPage: false,
  stats: {},

  invites: {},
  invite_ids: [],
}

// getters
const getters = {
  invites: (state) => state.invite_ids.map(id => state.invites[id]),
  stats: (state) => state.stats,
  audits: (state) => state.audits
}

// actions
const actions = {
  fetchInvites({ commit, state }, data) {
    return scs.getResourceInvitations({ ...data, path: state.path })
      .then((response) => {
        commit('setInvites', response.data)
      })
  },
  resendInvite({ commit, state }, data) {
    return scs.resendResourceInvitation({ ...data, path: state.path })
      .then((response) => {
        commit('setInvite', response.data)
      })
  },
  deleteInvite({ commit, state }, data) {
    return scs.deleteResourceInvitation({ ...data, path: state.path })
      .then(() => {
        commit('removeInvite', { id: data.invite_id })
      })
  },
  fetchAudits({ commit }, data) {
    commit('setAudits', [])
    return scs.getResourceAudits(data)
      .then((response) => {
        commit('setAudits', response.data)
      })
  },

  resetPagination({ commit }) {
    const headers = new Headers()
    headers.append('X-Total-Count', 0)
    headers.append('X-Per-Page', 25)
    headers.append('X-Total-Pages', 0)
    headers.append('X-Last-Page', false)
    commit('setPagination', headers)
  }
}

// mutations
const mutations = {
  setAudits(state, audits) {
    state.audits = audits
  },
  setPagination(state, headers) {
    state.totalCount = parseInt(headers.get('X-Total-Count'), 10)
    state.perPage = parseInt(headers.get('X-Per-Page'), 10)
    state.totalPages = parseInt(headers.get('X-Total-Pages'), 10)
    state.lastPage = (headers.get('X-Last-Page') || '').toString() === "true"
  },
  setStats(state, stats) {
    state.stats = stats
  },
  setTotalCount(state, count) {
    state.totalCount = count
  },
  updateStats(state, stats) {
    Vue.set(state, 'stats', {
      ...state.stats,
      ...stats
    })
  },
    // Resource Invites

    clearInvites(state) {
      state.invites = {}
      state.invite_ids = []
    },
    removeInvite(state, invite) {
      delete state.invites[invite.id]
      state.invite_ids = state.invite_ids.filter(id => id !== invite.id)
    },
    setInvites(state, invites) {
      for (let i = 0; i < invites.length; i++) {
        // In some strange case, the query for invites may return the same invite id
        // in two different pages. Since the invite is already in the store, don't
        // add it a second time.
        if (state.invite_ids.indexOf(invites[i].id) === -1) {
          state.invite_ids.push(invites[i].id)
        }
        Vue.set(state.invites, invites[i].id, invites[i])
      }
    },
    setInvite(state, invite) {
      Vue.set(state.invites, invite.id, invite)
    }
}

const state = initialState
export {
  state,
  getters,
  actions,
  mutations
}
