import makerjs from 'makerjs'

function BoltCircle(boltRadius, holeRadius, boltCount, firstBoltAngle = 0, idOffset = 0) {
  const points = makerjs.models.Polygon.getPoints(boltCount, boltRadius, firstBoltAngle)
  const ids = points.map((p, i) => `bolt_${i}${idOffset}`)

  this.models = {
    bolts: new makerjs.models.Holes(holeRadius, points, ids)
  }
}

export default BoltCircle
