import { ViewNotesDialog } from 'shared/components/notes'

export default {
  methods: {
    hasNotesClass(item) {
      return item.has_notes ? 'has-notes' : ''
    },
    hasNotesIcon(item) {
      return item.has_notes ? 'mdi-comment-alert' : 'mdi-comment-outline'
    },
    viewNotes(resource, resource_id, title, onClose, options = {}) {
      const modal = this.$buefy.modal.open({
        parent: this,
        component: ViewNotesDialog,
        hasModalCard: true,
        canCancel: true,
        props: { resource, resource_id, title, options }
      })

      if (typeof onClose == "function") {
        modal.$on('close', onClose)
      }
    }
  },
}
