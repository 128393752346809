// REQUIRES:  nextPage function to be defined in base component
import infiniteLoading from 'vue-infinite-loading'

export default {
  data() {
    return {
      type: '',
      currentPage: 0,
      changeToResetInfiniteLoad: +new Date()
    }
  },

  components: {
    infiniteLoading,
  },

  computed: {
    store() {
      return this.$store.state[this.type] || {}
    },

    totalCount() {
      return this.store.totalCount
    },

    totalPages() {
      return this.store.totalPages
    },

    perPage() {
      return this.store.perPage
    },

    lastPage() {
      return this.store.lastPage
    },

    showPagination() {
      return this.totalCount > this.perPage
    },
  },

  methods: {
    initInfiniteLoad(type, nextPage) {
      this.type = type
      this.nextPage = nextPage
    },

    resetPagination() {
      this.$store.dispatch(`${this.type}/resetPagination`)
    },

    async infiniteHandler($infinityState) {
      try {
        const data = await this.nextPage(this.currentPage + 1)

        if (data.length > 0) {
          $infinityState.loaded() // items were found and loaded
        }

        if (this.lastPage || data.length === 0) {
          $infinityState.complete() // no more items were found
        }
      } catch (e) {
        console.error(e)
        $infinityState.error('Something went wrong :(')
      }
    },

    resetAndTriggerLoad(page = 0) {
      this.currentPage = page
      this.changeToResetInfiniteLoad += 1
    },
  }
}

//  infinite loading
/** STEP 1: --- Copy and paste this below the v-for/list elements.
 *              customize verbage as needed
 *
    <infinite-loading  spinner="spiral" :identifier="changeToResetInfiniteLoad" @infinite="infiniteHandler">
      <span slot="spinner">Loading orders...</span>
      <span slot="no-more"></span>
      <span slot="no-results" class='has-text-grey no-results'>No Orders Found</span>
      <div slot="error"> Something went wrong :( </div>
    </infinite-loading>

* STEP 2: --- If list has a <transition-group, add v-show clause to hide when there are no items present.
*             Because we can only reset the infinity load component AFTER the DOM has been updated
*         Example:  <transition-group name="list" tag="p" v-show="hasOrders">
*
* STEP 3: --- make sure component's nextPage function is literally named 'nextPage'
* STEP 4: --- replace all calls to nextPage with a call to the below reset instead.
* STEP 5 --- reset function:
    reset: function() {
      return this.$store.dispatch('orders/clear').then(() => {
        this.resetAndTriggerLoad()
      })
    },
*/
