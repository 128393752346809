export default {
  computed: {
    myUserID() {
      return this.$store.getters['app/userID']
    },
    userIsOwner() {
      return this.$store.getters['app/isOwner']
    }
  },
  methods: {
    hasPermission(permission, action = '*') {
      return !!this.$store.getters['app/hasPermission']({ permission, action })
    },
  }
}
