import scs from 'shared/scs'

export default {
  namespaced: true,

  state: {},

  actions: {
    notifyBackInStock({}, material) {
      const resource = { type: 'materials', id: material.id }
      scs.createPendingNotification(resource, { condition: 'back_in_stock' })
    }
  },

  mutations: {}
}
