import Vue from 'vue'
import uniq from 'lodash/uniq'
import { getHoles, fetchSvg } from 'shared/util/svg'

import tapSizes from 'shared/constants/tap_sizes'
import countersinks from 'shared/constants/countersink'
import dimpleSizes from 'shared/constants/dimple_sizes'

// largest tap size in inches.
const largestTapSize = Number(window.appData.max_tap_size)

function toInches(value, drawingUnits) {
  if (drawingUnits === 'mm') {
    return value / 25.4
  }

  return value
}

export default {
  namespaced: true,

  state: {
    holes: {},
    holeErrors: [],
    holeMaxRadius: {},
    diameters: [],
    highlighted: [],
    selected: [],
    svg: '',
    drawingUnits: 'in',
    material: {}
  },

  getters: {
    allDiameters(state) {
      return state.diameters
        .filter(d => toInches(d, state.drawingUnits) <= largestTapSize)
        .map(d => ({ diameter: d }))
    },

    // Returns a list of all operation radii that we need to test for collision
    allHoleOpRadii(state, getters, rootState, rootGetters) {
      const hardwareRadii = rootGetters['hardware/all'].map(h => Number(h.min_cl_to_edge_distance))
      const tapMajorRadii = tapSizes.map(t => Number(t.drill) / 2)
      const cskMajorRadii = countersinks.map(c => Number(c.major) / 2)
      const dimpleRadii = dimpleSizes.map(d => Number(d.tooling) / 2)
      return uniq([...hardwareRadii, ...tapMajorRadii, ...cskMajorRadii, ...dimpleRadii]).sort()
    },

    cskData(state) {
      return Object.keys(state.holes)
        .map(id => state.holes[id])
        .filter(h => h.operation === 'countersinking')
        .map(h => ({ id: h.id, csk_id: h.data.csk_id, csk_direction: h.data.csk_direction }))
    },

    dimpleData(state) {
      return Object.keys(state.holes)
        .map(id => state.holes[id])
        .filter(h => h.operation === 'dimple_forming')
        .map(h => ({ id: h.id, dimple_id: h.data.dimple_id, dimple_direction: h.data.dimple_direction }))
    },

    hardwareData(state) {
      return Object.keys(state.holes)
        .map(id => state.holes[id])
        .filter(h => h.operation === 'hardware')
        .map(h => ({ id: h.id, hardware_id: h.data.hardware_id, hardware_direction: h.data.hardware_direction }))
    },

    maxDiameterForHole: (state) => (h) => {
      if (state.holeMaxRadius[h.id] >= 0) {
        return state.holeMaxRadius[h.id] * 2
      }
      return -1
    },

    tapData(state) {
      return Object.keys(state.holes)
        .map(id => state.holes[id])
        .filter(h => h.operation === 'tapping')
        .map(h => ({ id: h.id, tap: h.data.tap }))
    },

    tappableHoles(state) {
      return Object.keys(state.holes)
        .map(id => state.holes[id])
        .filter(h => {
          const radius = state.drawingUnits === 'mm' ? h.radius / 25.4 : h.radius
          return (radius * 2) <= largestTapSize
        })
        .sort((a, b) => a.radius - b.radius)
    },

    // This is the data that will be stored with the line item
    operationConfig(state, getters) {
      return {
        cskConfig: getters.cskData,
        hardwareConfig: getters.hardwareData,
        tapConfig: getters.tapData,
        dimpleConfig: getters.dimpleData
      }
    },

    operationDiameter: (state, getters, rootState) => (h) => {
      switch (h.operation) {
        case "tapping": {
          return tapSizes.find(t => t.tap === h.data.tap)?.drill
        }
        case "countersinking": {
          return countersinks.find(csk => csk.id === h.data.csk_id)?.drill
        }
        case "dimple_forming": {
          return dimpleSizes.find(d => d.id === h.data.dimple_id)?.hole_diameter
        }
        case "hardware": {
          const holeDiameter = rootState.hardware.data[h.data.hardware_id]?.hole_diameter
          return holeDiameter ? parseFloat(holeDiameter) : null
        }
        default: {
          return null
        }
      }
    },

    holeHasOperation: (state, getters) => (id) => {
      const hole = state.holes[id]

      return !!getters.operationDiameter(hole)
    },

    isHoleResized: (state, getters) => (id) => {
      const hole = state.holes[id]

      const origDiameter = toInches(hole.diameter, state.drawingUnits)
      if (origDiameter == null) {
        return false
      }

      const opDiameter = getters.operationDiameter(hole)
      if (opDiameter == null) {
        return false
      }

      return origDiameter?.toPrecision(4) !== opDiameter?.toPrecision(4)
    }
  },

  actions: {
    clearHoles({ commit }) {
      commit('setHoles', {})
    },

    clearSelected({ commit }) {
      commit('setSelected', [])
    },

    async findHoles({ commit, dispatch }, svgUrl) {
      dispatch('clearHoles')

      const svg = await fetchSvg(svgUrl) // Retrieve svg
      const holeList = getHoles(svg)     // Parse holes list

      commit('setSvg', svg)

      const holes = {}
      const diameters = []
      holeList.forEach(hole => {
        // In the case of a washer, there will be two holes with the same ID (origin)
        // When this happens, only keep the inner circle
        if (!holes[hole.id] || holes[hole.id].radius > hole.radius) {
          holes[hole.id] = hole

          // Add any attributes holes will need moving forward
          holes[hole.id].operation = null
        }
        diameters.push(hole.diameter)
      })

      // Map holes into an id addressable hash
      commit('setHoles', holes)
      commit('setDiameters', uniq(diameters))
    },

    loadHoleConfig({ commit }, { tapConfig, cskConfig, hardwareConfig, dimpleConfig }) {
      tapConfig.forEach(({ id, tap }) => {
        commit('setHoleOperation', { id, operation: "tapping" })
        const data = { tap }
        commit('setHoleData', { id, data })
      })
      cskConfig.forEach(({ id, ...config }) => {
        const data = {
          csk_id: config.csk_id,
          csk_direction: config.csk_direction
        }
        commit('setHoleOperation', { id, operation: "countersinking" })
        commit('setHoleData', { id, data })
      })
      dimpleConfig.forEach(({ id, ...config }) => {
        commit('setHoleOperation', { id, operation: "dimple_forming" })
        const data = {
          dimple_id: config.dimple_id,
          dimple_direction: config.dimple_direction
        }
        commit('setHoleData', { id, data })
      })
      hardwareConfig.forEach(({ id, ...config }) => {
        commit('setHoleOperation', { id, operation: "hardware" })
        const data = {
          hardware_id: config.hardware_id,
          hardware_direction: config.hardware_direction
        }
        commit('setHoleData', { id, data })
      })
    },

    setGroupData({ commit }, { ids, data }) {
      ids.forEach(id => commit('setHoleData', { id, data }))
    },

    setGroupOperation({ commit }, { ids, operation }) {
      ids.forEach(id => commit('setHoleOperation', { id, operation }))
    }
  },

  mutations: {
    setDiameters(state, diameters) {
      state.diameters = diameters
    },

    setDrawingUnits(state, units) {
      state.drawingUnits = units
    },

    setHighlighted(state, ids) {
      state.highlighted = ids
    },

    setHoleOperation(state, { id, operation }) {
      if (id && state.holes[id]) {
        state.holes[id].operation = operation
        Vue.set(state.holes[id], 'data', {})
      }
    },

    setHoleErrors(state, errors) {
      state.holeErrors = errors
    },

    setHoleData(state, { id, data }) {
      if (id && state.holes[id]) {
        Vue.set(state.holes[id], 'data', data)
      }
    },

    setHoleMaxRadius(state, holeMaxRadius) {
      state.holeMaxRadius = holeMaxRadius
    },

    setHoles(state, holes) {
      state.holes = holes
    },

    setLargestOpRadii(state, radii) {
      state.largestOpRadii = radii
    },

    setMaterial(state, material) {
      Vue.set(state, 'material', material)
    },

    setSelected(state, ids) {
      state.selected = ids
    },

    setSvg(state, svg) {
      state.svg = svg
    },
  }
}
