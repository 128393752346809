import makerjs from 'makerjs'

function Square(sideLength, cornerRadius) {
  this.models = {
    square_group: makerjs.model.center(new makerjs.models.RoundRectangle(sideLength, sideLength, cornerRadius))
  }
}

Square.metaParameters = [
  { title: "Side Length", type: "range", min: 0, max: 24, value: 6, default: 6, group: 'square_group', group_title: 'Plate' },
  { title: "Corner Radius", type: "range", min: 0, max: 6, value: 0.5, default: 0.5, group: 'square_group', group_title: 'Plate' },
  { type: "bends", value: [], default: [], hidden: true, group: ['bend0', 'bend1', 'bend2', 'bend3'] }
]

export default Square
