export default {
    data() {
      return {
        containerHeight: 0,
        headerClass: '',
        adjustmentHeight: 0
      }
    },
    methods: {
      resizeContainer() {
        let header = document.getElementsByClassName(this.headerClass)
        let header_height = header && header.length > 0 ? header[0].offsetHeight : 0
        this.containerHeight = this.height - header_height - this.adjustmentHeight
      },
    },
    watch: {
      height() {
        this.resizeContainer()
      },
      width() {
        this.resizeContainer()
      },
    }
  }
