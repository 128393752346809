import gsap from 'gsap'

export default {
  data() {
    return {
      dialogSize: {},
      width: 800,
      height: 500,
    }
  },
  methods: {
    // Default implementation for closing a dialog.
    closeDialog() {
      this.$parent.close()
    },
    setDialogSize(width, height) {
      let newWidth = Math.min(document.documentElement.clientWidth, width)
      let newHeight = Math.min(document.documentElement.clientHeight * 0.80, height)
      this.dialogSize = { width: newWidth, height: newHeight }
    },
  },
  watch: {
    dialogSize(newSize) {
      gsap.to(this.$data, { duration: 0.25, width: newSize.width, height: newSize.height, onComplete: () => {
        if (typeof(Event) === 'function') {
          // modern browsers
          window.dispatchEvent(new Event('resize'));
        } else {
          // for IE and other old browsers
          // causes deprecation warning on modern browsers
          var evt = window.document.createEvent('UIEvents');
          evt.initUIEvent('resize', true, false, window, 0);
          window.dispatchEvent(evt);
        }
      } })
    },
  }
}
